import { put, takeEvery, select } from 'redux-saga/effects';
import apiService, { getError } from 'shared/services/api';
import pick from 'lodash/pick';
import {
  SURVEYS_MANAGE_FETCH_CAMPAIGNS,
  SURVEYS_MANAGE_FETCH_SURVEY,
  SURVEYS_MANAGE_SAVE_SURVEY,
  SURVEYS_MANAGE_FETCH_MONKEY_SURVEYS,
  SURVEYS_MANAGE_UPDATE_BUCKETS,
  SURVEYS_MANAGE_CALCULATE_BUCKETS,
  SURVEYS_MANAGE_LAUNCH,
  SURVEYS_MANAGE_CANCEL,
  SURVEYS_MANAGE_REOPEN,
  SURVEYS_FETCH_EXCLUDE_LIST,
} from '../../reducers/manage/';
import { getHourFromDate, utcDateToLocal } from 'shared/services/date';
import { replace } from 'connected-react-router';
import { routes } from 'shared/constants/routes';
import axios from 'axios';
import { toast } from 'react-toastify';
import { TOAST_MESSAGE } from 'shared/constants/message';

axios.defaults.validateStatus = (status) => {
  return [200, 400, 500, 404].includes(status);
};

const transformSurveyForForm = (survey) => ({
  ...survey,
  excludedSurveys: survey.excludedSurveyIds,
  dateFrom: utcDateToLocal(survey.dateFrom),
  dateFromTime: getHourFromDate(utcDateToLocal(survey.dateFrom)),
  dateTo: utcDateToLocal(survey.dateTo),
  dateToTime: getHourFromDate(utcDateToLocal(survey.dateTo)),
});

function* fetchSurvey(action) {
  try {
    const { data } = yield apiService({}).get(`/surveys/${action.payload.id}`);
    yield put({
      type: SURVEYS_MANAGE_FETCH_SURVEY.SUCCESS,
      payload: transformSurveyForForm(data),
    });
  } catch (err) {
    yield put({
      type: SURVEYS_MANAGE_FETCH_SURVEY.FAILURE,
      payload: getError(err),
    });
  }
}

function* fetchMonkeySurveys() {
  try {
    const { data } = yield apiService({}).get('/surveys/survey-monkey');
    yield put({
      type: SURVEYS_MANAGE_FETCH_MONKEY_SURVEYS.SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield put({
      type: SURVEYS_MANAGE_FETCH_MONKEY_SURVEYS.FAILURE,
      payload: getError(err),
    });
  }
}
function* calculateBuckets(action) {
  try {
    const { data } = yield apiService({}).put(`/surveys/${action.payload.id}/calculate`);
    yield put({
      type: SURVEYS_MANAGE_CALCULATE_BUCKETS.SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield put({
      type: SURVEYS_MANAGE_CALCULATE_BUCKETS.FAILURE,
      payload: getError(err),
    });
  }
}
function* launchSurvey(action) {
  try {
    yield apiService({}).put(`/surveys/${action.payload.surveyId}/launch`);
    const { data } = yield apiService({}).get(`/surveys/${action.payload.surveyId}`);

    yield put({
      type: SURVEYS_MANAGE_LAUNCH.SUCCESS,
      payload: transformSurveyForForm(data),
    });
  } catch (err) {
    yield put({
      type: SURVEYS_MANAGE_LAUNCH.FAILURE,
      payload: getError(err),
    });
  }
}
function* cancelSurvey(action) {
  try {
    yield apiService({}).put(`/surveys/${action.payload.surveyId}/cancel`);
    const { data } = yield apiService({}).get(`/surveys/${action.payload.surveyId}`);

    yield put({
      type: SURVEYS_MANAGE_CANCEL.SUCCESS,
      payload: transformSurveyForForm(data),
    });
  } catch (err) {
    yield put({
      type: SURVEYS_MANAGE_CANCEL.FAILURE,
      payload: getError(err),
    });
  }
}

function* reopenSurvey(action) {
  try {
    const response = yield apiService({}).put(`/surveys/${action.payload.surveyId}/reopen`);
    const { data } = yield apiService({}).get(`/surveys/${action.payload.surveyId}`);

    if(response?.status !== 200) {
      toast.error(TOAST_MESSAGE[500])
    }

    yield put({
      type: SURVEYS_MANAGE_REOPEN.SUCCESS,
      payload: transformSurveyForForm(data),
    });
  } catch (err) {
    toast.error(TOAST_MESSAGE[500])
    yield put({
      type: SURVEYS_MANAGE_REOPEN.FAILURE,
      payload: getError(err),
    });
  }
}

function* fetchCampaigns() {
  try {
    const { data } = yield apiService({}).get('/campaigns?size=100000&sort=id,desc');
    yield put({
      type: SURVEYS_MANAGE_FETCH_CAMPAIGNS.SUCCESS,
      payload: data.content,
    });
  } catch (err) {
    yield put({
      type: SURVEYS_MANAGE_FETCH_CAMPAIGNS.FAILURE,
      payload: getError(err),
    });
  }
}
function* saveSurvey(action) {
  try {
    let data = {};
    let status = undefined;
    if (!action.id) {
      const { data: createData, status: createStatus } = yield apiService({}).post('/surveys', action.payload);
      data = createData;
      status = createStatus
    } else {
      const { data: saveData, status: updateStatus } = yield apiService({}).put(`/surveys/${action.id}`, action.payload);
      data = saveData;
      status = updateStatus
    }
    if(status === 200) {
      yield put({
        type: SURVEYS_MANAGE_SAVE_SURVEY.SUCCESS,
        payload: data,
      });
      if (!action.id) {
        const { app: { country } } = yield select();
        yield put(replace({ pathname: routes.surveys.edit(data.id).path, search: `?country=${country}` }));
      }
    } else {
      toast.error(data.message || TOAST_MESSAGE[500])
      yield put({
        type: SURVEYS_MANAGE_SAVE_SURVEY.FAILURE,
        payload: getError(err),
      });
    }
    
  } catch (err) {
    yield put({
      type: SURVEYS_MANAGE_SAVE_SURVEY.FAILURE,
      payload: getError(err),
    });
  }
}
function* updateBuckets(action) {
  try {
    let data = [];
    if (action.payload.deleteBuckets.length) {
      const { data: deleteData } = yield apiService({}).delete(
        `/surveys/${action.payload.id}/buckets?bucketIds=${action.payload.deleteBuckets.join(',')}`,
      );
      data = deleteData;
    }

    if (action.payload.newBuckets.length) {
      const { data: createData } = yield apiService({}).post(
        `/surveys/${action.payload.id}/buckets`,
        action.payload.newBuckets,
      );
      data = createData;
    }
    if (action.payload.updateBuckets.length) {
      const { data: saveData } = yield apiService({}).put(
        `/surveys/${action.payload.id}/buckets`,
        action.payload.updateBuckets.map((bucket) =>
          pick(bucket, ['id', 'ageFrom', 'ageTo', 'answersCap', 'education', 'gender', 'users']),
        ),
      );
      data = saveData;
    }

    yield put({
      type: SURVEYS_MANAGE_UPDATE_BUCKETS.SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield put({
      type: SURVEYS_MANAGE_UPDATE_BUCKETS.FAILURE,
      payload: getError(err),
    });
  }
}

function* fetchToExcludeSurveys(action) {
  try {
    const { data } = yield apiService({}).get('/surveys/titles');
    const normalizedData = Object.entries(data).map(d => ({ id: d[0], title: d[1] }));
    yield put({
      type: SURVEYS_FETCH_EXCLUDE_LIST.SUCCESS,
      payload: normalizedData,
    });
  } catch (err) {
    yield put({ type: SURVEYS_FETCH_EXCLUDE_LIST.FAILURE, payload: getError(err) });
  }
}

export default [
  function* () {
    yield takeEvery(SURVEYS_MANAGE_FETCH_SURVEY.REQUEST, fetchSurvey);
  },
  function* () {
    yield takeEvery(SURVEYS_MANAGE_SAVE_SURVEY.REQUEST, saveSurvey);
  },
  function* () {
    yield takeEvery(SURVEYS_MANAGE_FETCH_CAMPAIGNS.REQUEST, fetchCampaigns);
  },
  function* () {
    yield takeEvery(SURVEYS_MANAGE_UPDATE_BUCKETS.REQUEST, updateBuckets);
  },
  function* () {
    yield takeEvery(SURVEYS_MANAGE_CALCULATE_BUCKETS.REQUEST, calculateBuckets);
  },
  function* () {
    yield takeEvery(SURVEYS_MANAGE_LAUNCH.REQUEST, launchSurvey);
  },
  function* () {
    yield takeEvery(SURVEYS_MANAGE_CANCEL.REQUEST, cancelSurvey);
  },
  function* () {
    yield takeEvery(SURVEYS_MANAGE_REOPEN.REQUEST, reopenSurvey);
  },
  function* () {
    yield takeEvery(SURVEYS_MANAGE_FETCH_MONKEY_SURVEYS.REQUEST, fetchMonkeySurveys);
  },
  function* () {
    yield takeEvery(SURVEYS_FETCH_EXCLUDE_LIST.REQUEST, fetchToExcludeSurveys);
  },
];
