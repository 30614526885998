import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import {reduxForm, Field, getFormValues} from 'redux-form';
import {map, get, find, omit, debounce} from 'lodash';
import {SurveyQuestionsModalForm} from 'components';
import Button from 'shared/components/Button';
import ContentLoader from 'shared/components/ContentLoader';
import TextField from 'shared/components/TextField';
import TextFieldI18N from 'shared/components/TextFieldI18N';
import FormSection from 'shared/components/FormSection';
import HeaderTextField from 'shared/components/HeaderTextField';
import AutocompleteField from 'shared/components/AutocompleteFieldNew';
import {ReactComponent as PexelLogo} from 'assets/icons/pexel.svg';
import ConfirmModal from 'shared/components/ConfirmModal';
import {orderAlphabetically} from 'shared/utils/fields';
import {
    fetchCampaigns,
    saveSurvey,
    fetchSurvey,
    resetToInit,
    fetchMonkeySurveys,
    launchSurvey,
    cancelSurvey,
    reopenSurvey,
    populateFormFields,
    fetchSurveysToExclude,
} from 'modules/Survey/reducers/manage';
import formValidation from './formValidation';
import SurveyMonkey from 'modules/Survey/components/SurveyMonkey';
import PushNotifications from 'modules/Survey/components/PushNotifications';
import SurveyPeriod from 'modules/Survey/components/SurveyPeriod';
import UseImageModal from 'modules/Survey/components/UseImageModal';
import SurveyBuckets from 'modules/Survey/components/SurveyBuckets';
import RadioField from 'shared/components/RadioField';
import MultiSelectField from 'shared/components/MultiSelectField';
import {updateSurveyBuckets, calculateBuckets} from 'modules/Survey/reducers/manage';
import {SurveyStatus, SURVEY_STATUS_LABEL} from 'modules/Survey/constants';
import * as types from '../../types/SurveryManageTypes';
import CampaignHeader from 'shared/components/CampaignHeader';
import styles from './styles.module.scss';
import {routes} from 'shared/constants/routes';
import Storage from 'shared/services/storage';
import {Redirect} from 'react-router-dom';
import EditSurveyQuestions from '../../components/EditSurveyQuestions';
import { CONFIRMATION_MESSAGE } from 'shared/constants/message';

const FORM_NAME = 'survey/MANAGE';

const FormStorage = new Storage(FORM_NAME);

class List extends PureComponent<types.SurveyManageProps, types.SurveyManageState> {
    state: types.SurveyManageState = {
        isModalOpen: false,
        confirmCancelModal: false,
        confirmReopenModal: false,
        redirect: false,
        isSurveyQuestionsModalOpen: false,
    };

    componentDidMount() {
        const id = get(this.props, 'match.params.id');
        this.props.fetchSurveysToExclude();
        this.props.fetchCampaigns();
        this.props.fetchMonkeySurveys();
        if (id) {
            this.props.fetchSurvey({id});
        }
        this.loadFormState();
    }

    componentDidUpdate(prevProps) {
        const id = get(this.props, 'match.params.id');
        const prevId = get(prevProps, 'match.params.id');
        if (id !== prevId) {
            this.props.fetchSurvey({id});
        }
        if (this.props.successfulCanceled === true) {
            this.setState({
                redirect: true,
            });
        }
        this.preserveFormState();
    }

    componentWillUnmount() {
        this.props.reset();
        this.props.resetToInit();
        FormStorage.clear();
    }

    preserveFormState = debounce(() => {
        FormStorage.save(this.props.formValues);
    }, 300);

    loadFormState = () => {
        if (this.props.location.pathname.includes('create')) {
            const formState = FormStorage.load();
            this.props.populateFormFields(formState);
        }
    };

    launch = () => {
        const id = get(this.props, 'match.params.id');
        this.props.launchSurvey({surveyId: id});
    };
    reopen = () => {
        this.setState({
            confirmReopenModal: !this.state.confirmReopenModal,
        });

        const id = get(this.props, 'match.params.id');
        this.props.reopenSurvey({surveyId: id});
    };
    toogleConfirmCancel = () => {
        this.setState({
            confirmCancelModal: !this.state.confirmCancelModal,
        });
    };
    toggleConfirmReopen = () => {
        this.setState({
            confirmReopenModal: !this.state.confirmReopenModal,
        });
    };
    cancel = () => {
        const id = get(this.props, 'match.params.id');
        this.props.cancelSurvey({surveyId: id});
    };

    handleOpenSurveyQuesitonsModal = () => {
        this.setState({isSurveyQuestionsModalOpen: true});
    }

    submit = () => {
        const countryOfAvailability = this.props.selectedCountry;
        const id = get(this.props, 'match.params.id');
        const {
            formValues: {
                title,
                dateTo,
                description,
                dateFrom,
                dateFromTime,
                dateToTime,
                imageUrl,
                points,
                respondentsLimit,
                pushNoteTitle,
                pushNoteBody,
                pushNoteEnabled,
                pointsIncomplete,
                excludedSurveys,
            },
        } = this.props;
        const excludedSurveyIds = map(excludedSurveys, (survey) => Number(survey.value));
        if (id) {
            this.props.saveSurvey({
                id,
                title,
                dateTo,
                description,
                dateFrom,
                dateFromTime,
                dateToTime,
                imageUrl,
                points,
                respondentsLimit,
                pushNoteTitle,
                pushNoteBody,
                pushNoteEnabled,
                pointsIncomplete,
                excludedSurveyIds,
            });

            return;
        }
        this.props.saveSurvey({
            ...omit(this.props.formValues, ['excludedSurveys']),
            surveyMonkeyTitle: find(this.props.monkeySurveys, {
                value: this.props.formValues.surveyMonkeySurveyId,
            }).label,
            excludedSurveyIds,
            countryOfAvailability,
        });
    };

    render() {
        const {campaigns, handleSubmit, savingStates, fetchingStates, monkeySurveys, cancelingStates, reopenStatus} = this.props;
        const surveyId = get(this.props, 'match.params.id');
        if (this.state.redirect) {
            return (
                <Redirect
                    to={{
                        pathname: routes.surveys.default.path,
                        search: this.props.history.location.search,
                    }}
                />
            );
        }

        return (
            <>
                <ConfirmModal
                    paragraph="Are you sure you want to cancel this survey?"
                    isOpen={this.state.confirmCancelModal}
                    onClose={this.toogleConfirmCancel}
                    onConfirm={this.cancel}
                    isLoading={cancelingStates.isLoading}
                    title="Cancel a survey"
                    confirmLabel="Cancel"
                    cancelLabel="Deny"
                />

                <ConfirmModal
                    paragraph={CONFIRMATION_MESSAGE.reopen}
                    isOpen={this.state.confirmReopenModal}
                    onClose={this.toggleConfirmReopen}
                    onConfirm={this.reopen}
                    title="Reopen a survey"
                    confirmLabel="Reopen"
                    cancelLabel="Deny"
                />

                <ContentLoader states={fetchingStates} isNew={!surveyId}>
                    <UseImageModal
                        isOpen={this.state.isModalOpen}
                        onClose={() => this.setState({isModalOpen: false})}
                        onAdd={(value) => {
                            this.props.change('imageUrl', value);
                        }}
                    />
                    <div>
                        <form onSubmit={handleSubmit(this.submit)}>
                            <CampaignHeader>
                                <div className="SurveyHeader">
                                    <Field
                                        component={HeaderTextField}
                                        name="title"
                                        placeholder="Enter survey internal name"
                                        className="CampaignHeader__input"
                                    />
                                    <div className={styles.SurverHeaderStatus}>
                                        Current Status:{' '}
                                        {this.props.data.status && SURVEY_STATUS_LABEL[this.props.data.status]}
                                    </div>
                                </div>
                                <div className={styles.CampaignHeaderRight}>
                                    <div className={styles.SurveyButtons}>
                                        <EditSurveyQuestions edited={this.props.data.questionsEdited}/>
                                        {this.props.data.status !== SurveyStatus.CLOSED && (
                                            <Button
                                                type="primary"
                                                isLoading={savingStates.isLoading}
                                                disabled={savingStates.isLoading}
                                            >
                                                Save
                                            </Button>
                                        )}
                                        {surveyId && this.props.data.status === SurveyStatus.OPEN && (
                                            <Button
                                                buttonType="button"
                                                onClick={this.launch}
                                                isLoading={this.props.launchingStates.isLoading}
                                            >
                                                Launch
                                            </Button>
                                        )}
                                        {surveyId && this.props.data.status === SurveyStatus.SCHEDULED && (
                                            <Button
                                                buttonType="button"
                                                onClick={this.toggleConfirmReopen}
                                                isLoading={reopenStatus.isLoading}
                                            >
                                                Reopen
                                            </Button>
                                        )}
                                        {surveyId &&
                                            [SurveyStatus.SCHEDULED, SurveyStatus.LIVE].includes(
                                                this.props.data.status,
                                            ) && (
                                                <Button
                                                    buttonType="button"
                                                    onClick={this.toogleConfirmCancel}
                                                    isLoading={this.props.cancelingStates.isLoading}
                                                    cancelButton={true}
                                                >
                                                    Cancel
                                                </Button>
                                            )}
                                    </div>
                                </div>
                            </CampaignHeader>
                            <div className={styles.ManageSurvey}>
                                <FormSection isActive>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <Field
                                                component={AutocompleteField}
                                                name="campaignId"
                                                dataSource={campaigns}
                                                label="Campaign"
                                                className="CampaignHeader__input"
                                                disabled={surveyId}
                                                isRequired
                                            />
                                            <div className="row">
                                                <div className="col-md-10">
                                                    <Field
                                                        component={TextField}
                                                        name="imageUrl"
                                                        label="Image url"
                                                        className="CampaignHeader__input"
                                                        isRequired
                                                    />
                                                </div>
                                                <div className="col-md-2">
                                                    <button
                                                        onClick={() => this.setState({isModalOpen: true})}
                                                        type="button"
                                                        className={styles.PexelButton}
                                                    >
                                                        <PexelLogo/>
                                                    </button>
                                                </div>
                                            </div>
                                            <Field
                                                component={TextFieldI18N}
                                                name="description"
                                                label="Description"
                                                className="CampaignHeader__input"
                                                multiLine
                                            />
                                            <h2>Survey monkey</h2>
                                            <SurveyMonkey surveyId={surveyId} monkeySurveys={monkeySurveys}/>

                                            <h2>Push notification</h2>
                                            <PushNotifications
                                                readOnly={Boolean(this.props.formValues.status) && this.props.formValues.status !== 'OPEN'} pushNoteBody={this.props?.formValues?.pushNoteBody}/>

                                            <SurveyPeriod
                                                disabled={
                                                    this.props.formValues.status && this.props.formValues.status !== 'OPEN'
                                                }
                                            />

                                            <div className="row">
                                                <div className="col-md-4">
                                                    <Field
                                                        component={TextField}
                                                        name="points"
                                                        label="Points"
                                                        className="CampaignHeader__input"
                                                        tooltip="Choose the amount of points a user will get for this survey"
                                                        isRequired
                                                    />
                                                </div>
                                                <div className="col-md-4">
                                                    <Field
                                                        component={TextField}
                                                        name="pointsIncomplete"
                                                        label="Points incomplete"
                                                        className="CampaignHeader__input"
                                                        isRequired
                                                    />
                                                </div>
                                                <div className="col-md-4">
                                                    <Field
                                                        component={TextField}
                                                        name="respondentsLimit"
                                                        label="Cap"
                                                        tooltip="If needed you can set a cap for the number of respondents you want this survey to have"
                                                        className="CampaignHeader__input"
                                                        disabled={this.props.formValues.live}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-8">
                                            {!surveyId && (
                                                <div>
                                                    To enable buckets, please save survey first
                                                    <br/>
                                                    <br/>
                                                </div>
                                            )}
                                            {surveyId && (
                                                <SurveyBuckets
                                                    id={surveyId}
                                                    capacity={this.props.formValues.respondentsLimit}
                                                    buckets={this.props.formValues.surveyBuckets}
                                                    live={this.props.formValues.live}
                                                    updatingSurveyBuckets={this.props.updatingSurveyBuckets}
                                                    calculateState={this.props.calculateState}
                                                    updateSurveyBuckets={this.props.updateSurveyBuckets}
                                                    calculateBuckets={this.props.calculateBuckets}
                                                />
                                            )}
                                            <Field
                                                component={MultiSelectField}
                                                dataSource={this.props.surveysToExclude.map((survey) => ({
                                                    value: survey.id,
                                                    label: survey.title,
                                                }))}
                                                disabled={this.props.formValues.live}
                                                name="excludedSurveys"
                                                label="Excluded surveys"
                                            />
                                            {/*<Field*/}
                                            {/*  component={RadioField}*/}
                                            {/*  disabled*/}
                                            {/*  options={[*/}
                                            {/*    {*/}
                                            {/*      value: 'NORMAL',*/}
                                            {/*      label: 'Normal',*/}
                                            {/*    },*/}
                                            {/*    {*/}
                                            {/*      value: 'EVEN',*/}
                                            {/*      label: 'Even',*/}
                                            {/*    },*/}
                                            {/*    {*/}
                                            {/*      value: 'FAST',*/}
                                            {/*      label: 'Fast',*/}
                                            {/*    },*/}
                                            {/*  ]}*/}
                                            {/*  name="deliveryMode"*/}
                                            {/*  label="Delivery mode"*/}
                                            {/*/>*/}
                                        </div>
                                    </div>
                                </FormSection>
                            </div>
                        </form>
                    </div>
                </ContentLoader>
            </>
        );
    }
}

const mapStateToProps = (
    {
        surveys: {
            manage: {
                campaigns,
                save,
                toExclude,
                survey,
                monkeySurveys,
                cancel,
                reopen,
                launch,
                updatingSurveyBuckets,
                calculate,
            },
            list,
        },
        ...state
    },
    ownProps,
) => {
    const surveyData = survey.data || {excludedSurveys: []};
    const surveysToExclude = toExclude.data || [];
    return {
        data: surveyData,
        savingStates: save.states,
        launchingStates: launch.states,
        cancelingStates: cancel.states,
        reopenStatus: reopen.states,
        successfulCanceled: cancel.successfulCanceled,
        monkeySurveys: orderAlphabetically(
            map(monkeySurveys.data, ({id, nickname}) => ({
                label: nickname,
                value: id,
            })),
        ),
        fetchingStates: {
            ...survey.states,
            isLoading:
                survey.states.isLoading || monkeySurveys.states.isLoading || campaigns.states.isLoading,
        },
        campaigns: orderAlphabetically(
            map(campaigns.data, ({id, name}) => ({
                label: name,
                value: id,
            })),
        ),
        campaignsStates: campaigns.states,
        isLoading:
            campaigns.states.isLoading || survey.states.isLoading || monkeySurveys.states.isLoading,
        formValues: getFormValues(FORM_NAME)(state) || {},
        initialValues: {
            pushNoteEnabled: true,
            ...surveyData,
            excludedSurveys:
                surveysToExclude
                .filter((survey) =>
                    surveyData.excludedSurveys
                        ? surveyData.excludedSurveys.includes(Number(survey.id))
                        : false
                )
                .map((survey) => ({value: survey.id, label: survey.title})) || []
                },
        surveysToExclude,
        surveysStates: list.states,
        updatingSurveyBuckets,
        calculateState: calculate,
        selectedCountry: state.app.country,
    };
};
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchCampaigns,
            saveSurvey,
            fetchSurvey,
            resetToInit,
            fetchMonkeySurveys,
            fetchSurveysToExclude,
            launchSurvey,
            cancelSurvey,
            reopenSurvey,
            updateSurveyBuckets,
            calculateBuckets,
            populateFormFields,
        },
        dispatch,
    );

export default compose<any>(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        form: FORM_NAME,
        enableReinitialize: true,
        validate: formValidation,
    }),
)(List);
