import React from 'react'
import MuiGrid from '@material-ui/core/Grid'
import {
    Button,
    DateTimeField,
    FieldLabel,
    Form,
    FormHeader,
    FormProps,
    Page,
    SwitchField,
    TextField,
    TextHeaderField,
    TextIntlField,
} from 'components'
import { schema } from './validation'
import { CharityFormData } from './types'

export const CharityForm = ({ defaultValues, onSubmit }: FormProps<CharityFormData>) => (
    <Form<CharityFormData> defaultValues={defaultValues} onSubmit={onSubmit} schema={schema}>
        {({ isSubmitDisabled, isSubmitting }) => (
            <Page
                header={
                    <FormHeader
                        field={<TextHeaderField name="name" placeholder="Enter charity name" />}
                        cta={
                            <Button disabled={isSubmitDisabled} loading={isSubmitting}>
                                Save
                            </Button>
                        }
                    />
                }>
                <MuiGrid container>
                    <MuiGrid item xs={7}>
                        <TextIntlField label="Description" multiline name="description" required />
                        <SwitchField
                            label="Enable push notification"
                            name="pushNoteEnabled"
                            readOnly={Boolean(defaultValues)}
                        />
                        <TextIntlField
                            label="Push notification and in-app title"
                            maxLength={65}
                            name="pushNoteTitle"
                            required
                        />
                        <TextIntlField
                            label="Push note body"
                            maxLength={240}
                            multiline
                            name="pushNoteBody"
                        />
                        <DateTimeField name="pushNoteDate" label="Push note date and time" />
                        <TextField
                            label="Points needed for 1 euro to charity"
                            name="pointsNeeded"
                            required
                        />
                        <TextField
                            label="Maximum amount of euro"
                            name="maximumAmount"
                            tooltip="This is the maximum amount of euro we want to contribute to charity. When this amount is reached the charity will be closed."
                        />
                        <TextField label="Image URL" name="imageUrl" required />
                        <FieldLabel
                            label="Period"
                            required
                            tooltip="The period you want the charity donation to be live."
                        />
                        <MuiGrid container spacing={3}>
                            <MuiGrid item xs>
                                <DateTimeField
                                    label="From (date and time)"
                                    name="dateFrom"
                                    required
                                />
                            </MuiGrid>
                            <MuiGrid item xs>
                                <DateTimeField name="dateTo" label="To (date and time)" required />
                            </MuiGrid>
                        </MuiGrid>
                    </MuiGrid>
                </MuiGrid>
            </Page>
        )}
    </Form>
)
