import update from 'immutability-helper';

import map from 'lodash/map';
import { createReducer } from 'shared/utils/redux';
import { createRequestTypes } from 'shared/utils/request';

export const UPLOAD_MEDIA = createRequestTypes('files/UPLOAD_MEDIA', [
  'PROGRESS',
]);

export const uploadMedia = ({ file, id, prefix, bucket }) => ({
  type: UPLOAD_MEDIA.REQUEST,
  payload: { id, file, prefix, bucket },
});

const initState = {
  uploadMedia: [],
};

export default createReducer(initState, {
  [UPLOAD_MEDIA.SUCCESS]: (state, action) => {
    return update(state, {
      uploadMedia: {
        $set: map(state.uploadMedia, (file) => {
          if (file.id !== action.payload.id) {
            return file;
          }
          return {
            ...file,
            success: action.payload,
          };
        }),
      },
    });
  },
  [UPLOAD_MEDIA.PROGRESS]: (state, action) => {
    return update(state, {
      uploadMedia: {
        $set: map(state.uploadMedia, (file) => {
          if (file.id !== action.payload.id) {
            return file;
          }
          return {
            ...file,
            progress: action.payload.progress,
          };
        }),
      },
    });
  },
  [UPLOAD_MEDIA.REQUEST]: (state, action) => {
    return update(state, {
      uploadMedia: {
        $push: [
          {
            id: action.payload.id,
            data: action.payload,
            progress: 0,
          },
        ],
      },
    });
  },
});
