import React from 'react'
import MuiBox from '@material-ui/core/Box'
import MuiGrid from '@material-ui/core/Grid'
import { Form, FormProps, AutocompleteField, TextField, DateTimeField, Button } from 'components'
import { schema } from './validation'
import { LocationFormData } from './types'

interface LocationDetailsFormProps extends FormProps<LocationFormData>{
    onCancel: () => void
}

export const LocationDetailsForm = ({ defaultValues, onCancel, onSubmit }: LocationDetailsFormProps) => (
    <Form<LocationFormData> defaultValues={defaultValues} onSubmit={onSubmit} schema={schema}>
        {({ isSubmitDisabled, isSubmitting }) => (
            <>
                <TextField
                    label="Latitude"
                    name="latitude"
                    readOnly
                    small
                    tooltip="To edit this field drag the center of the circle"
                />
                <TextField
                    label="Longitude"
                    name="longitude"
                    readOnly
                    small
                    tooltip="To edit this field drag the center of the circle"
                />
                <DateTimeField label="Date from" name="dateFrom" small />
                <DateTimeField label="Date to" name="dateTo" small />
                <TextField
                    label="Radius"
                    name="radius"
                    small
                    tooltip="To edit this field change the radius of the circle"
                />
                <TextField label="Filter name" name="filterName" small />
                <AutocompleteField
                    label="Location type"
                    name="locationType"
                    options={[
                        {
                            value: 'OOH',
                            label: 'OOH',
                        },
                        {
                            value: 'DOOH',
                            label: 'DOOH',
                        },
                        {
                            value: 'Stores',
                            label: 'Stores',
                        },
                        {
                            value: 'Venues',
                            label: 'Venues',
                        }
                    ]}
                    small
                />
                <TextField label="Location detail name" name="locationDetailName" small />
                <TextField label="Location detail ID" name="locationDetailId" small />
                <TextField label="Address" name="address" small />
                <TextField label="Postal code" name="postalCode" small />
                <TextField label="City" name="city" small />
                <TextField label="Province" name="province" small />
                <TextField label="Country" name="country" small />
                <MuiBox display="flex" justifyContent="flex-end">
                    <Button onClick={onCancel} color="default" type="button" size="small">
                        Cancel
                    </Button>
                    <MuiBox ml={1}>
                        <Button disabled={isSubmitDisabled} loading={isSubmitting} size="small">
                            Save
                        </Button>
                    </MuiBox>
                </MuiBox>
            </>
        )}
    </Form>
)
