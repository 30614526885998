import React from 'react'
import MuiBox from '@material-ui/core/Box'
import MuiSwitch from '@material-ui/core/Switch'
import { FieldWrapper, FieldLabel } from 'components'

interface SwitchFieldProp {
    defaultValue?: boolean
    disabled?: boolean
    label: string
    name: string
    readOnly?: boolean
    tooltip?: string
}

export const SwitchField = ({
    defaultValue = false,
    disabled,
    label,
    name,
    readOnly,
    tooltip,
}: SwitchFieldProp) => {

    const handleChange = (onChange) => (event) => {
        const { checked } = event.target
        !readOnly && onChange(checked)
    }

    return (
        <FieldWrapper
            defaultValue={defaultValue}
            disabled={disabled}
            name={name}
            tooltip={tooltip}>
            {({ ref, value, onChange, ...field }) => (
                <MuiBox display="flex">
                    <FieldLabel label={label} />
                    <MuiSwitch
                        {...field}
                        checked={value}
                        color="primary"
                        onChange={handleChange(onChange)}
                    />
                </MuiBox>
            )}
        </FieldWrapper>
    )
}
