import { put, takeEvery, take, call, select } from 'redux-saga/effects';
import { replace } from 'connected-react-router';
import { v4 as uuidv4 } from 'uuid';
import map from 'lodash/map';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import pick from 'lodash/pick';
import { toast } from 'react-toastify';
import apiService, { getError } from 'shared/services/api';
import { routes } from 'shared/constants/routes';
import {
  SAVE_CAMPAIGN_DATA,
  FETCH_CAMPAIGN_DATA,
  UPLOAD_MEDIA,
  DELETE_MEDIA_FROM_ACR,
  UPLOAD_LOCATIONS,
  FETCH_CLIENTS,
  startCheckingForS3Files,
  CHECK_FOR_S3_FILES,
  fetchAcrMedia,
  stopCheckingForS3Files,
  FETCH_ACR_MEDIA,
  DELETE_LOCATIONS,
} from '../../reducers/manage/';
import { uploadFileToS3, fetchCampaignFilesFromS3 } from 'shared/services/aws';

function* uploadMedia(action) {
  try {
    const { file, id, campaignId, campaignName, client, filter = '' } = action.payload;
    const extensionRegexp = /(?:\.([^.]+))?$/;
    const ext = extensionRegexp.exec(file.name)[1].toLowerCase();

    const channel = yield call(uploadFileToS3, {
      Key: `${campaignId}/${uuidv4()}.${ext}`,
      Body: file,
      Bucket: process.env.REACT_APP_AWS_BUCKET,
      Metadata: {
        'x-acr-campaign': campaignName,
        'x-acr-client': client,
        'x-acr-filter': filter,
        'x-acr-filename': file.name,
        'x-acr-campaign-id': campaignId,
      },
    });
    while (true) {
      const { progress = 0, data, success, error } = yield take(channel);
      if (success) {
        const {
          campaigns: {
            manage: {
              campaign: { processingS3FilesChecker },
            },
          },
        } = yield select();
        if (!processingS3FilesChecker) {
          yield put(startCheckingForS3Files(campaignId));
        }
        yield put({
          type: UPLOAD_MEDIA.SUCCESS,
          payload: data,
        });
      }
      if (error) {
        yield put({ type: UPLOAD_MEDIA.FAILURE, payload: { id, error } });
        return;
      }
      yield put({ type: UPLOAD_MEDIA.PROGRESS, payload: { id, progress } });
    }
  } catch (err) {
    yield put({ type: UPLOAD_MEDIA.FAILURE, payload: getError(err) });
  }
}
function* fetchCampaign(action) {
  try {
    const { data } = yield apiService({}).get(`/campaigns/${action.payload.id}`);
    let media = [];
    const { data: mediaData } = yield apiService({}).get(`/campaigns/${action.payload.id}/media`);
    media = mediaData;
    const files = yield fetchCampaignFilesFromS3(data.id);
    yield put({
      type: FETCH_CAMPAIGN_DATA.SUCCESS,
      payload: {
        data,
        media,
        files,
      },
    });
  } catch (err) {
    yield put({ type: FETCH_CAMPAIGN_DATA.FAILURE, payload: getError(err) });
  }
}
function* fetchAcrMediaSaga(action) {
  try {
    const { data: media } = yield apiService({}).get(
      `/campaigns/${action.payload.campaignId}/media`,
    );

    yield put({
      type: FETCH_ACR_MEDIA.SUCCESS,
      payload: media,
    });
  } catch (err) {
    yield put({ type: FETCH_ACR_MEDIA.FAILURE, payload: getError(err) });
  }
}

function* saveCampaign(action) {
  let data = {};
  try {
    if (action.id) {
      const { data: editData } = yield apiService({}).put(
        `/campaigns/${action.id}`,
        pick(action.payload, ['name', 'clientName', 'dateFrom', 'dateTo']),
      );
      data = editData;
    } else {
      const { data: createData } = yield apiService({}).post('/campaigns', action.payload);
      data = createData;
    }
    yield put({
      type: SAVE_CAMPAIGN_DATA.SUCCESS,
      payload: data,
    });
    toast.success('Campaign has been saved');
    const { app: { country } } = yield select();
    yield put(replace({ pathname: routes.campaigns.default.path, search: `?country=${country}` }));
  } catch (err) {
    toast.error(get(err, 'response.data.message') || get(err, 'message'));
    yield put({ type: SAVE_CAMPAIGN_DATA.FAILURE, payload: getError(err) });
  }
}

function* checkS3Files(action) {
  try {
    const files = yield fetchCampaignFilesFromS3(action.payload.campaignId);
    if (!files.length) {
      yield put(stopCheckingForS3Files());
      yield put(fetchAcrMedia({ campaignId: action.payload.campaignId }));
    }
    yield put({
      type: CHECK_FOR_S3_FILES.SUCCESS,
      payload: files,
    });
  } catch (err) {
    yield put({ type: CHECK_FOR_S3_FILES.FAILURE, payload: getError(err) });
  }
}

function* deleteMediaFromAcr(action) {
  try {
    yield apiService({}).delete(
      `/campaigns/${action.payload.campaignId}/media/${action.payload.fileId}`,
    );
    yield put({
      type: DELETE_MEDIA_FROM_ACR.SUCCESS,
      payload: action.payload,
    });
  } catch (err) {
    yield put({ type: DELETE_MEDIA_FROM_ACR.FAILURE, payload: getError(err) });
  }
}

function* deleteLocations(action) {
  try {
    yield apiService({}).delete(`/campaigns/${action.payload.campaignId}/locations`);
    yield put({
      type: DELETE_LOCATIONS.SUCCESS,
      payload: action.payload,
    });
  } catch (err) {
    yield put({ type: DELETE_LOCATIONS.FAILURE, payload: getError(err) });
  }
}

function* uploadLocations(action) {
  try {
    const { data } = yield apiService({}).put(`/campaigns/${action.payload.campaignId}/locations/csv`, {
      base64encodedCsv: action.payload.locationsContent,
      filterName: action.payload.filterName,
    });
    yield put({
      type: UPLOAD_LOCATIONS.SUCCESS,
      payload: data,
    });
    toast.success('Locations has been updated');
  } catch (err) {
    yield put({ type: UPLOAD_LOCATIONS.FAILURE, payload: getError(err) });
  }
}

function* fetchClients() {
  try {
    const { data } = yield apiService({}).get(`/campaigns/clients?size=100000`);
    yield put({
      type: FETCH_CLIENTS.SUCCESS,
      payload: data.content,
    });
  } catch (err) {
    yield put({ type: FETCH_CLIENTS.FAILURE, payload: getError(err) });
  }
}

export default [
  function* () {
    yield takeEvery(SAVE_CAMPAIGN_DATA.REQUEST, saveCampaign);
  },
  function* () {
    yield takeEvery(FETCH_CAMPAIGN_DATA.REQUEST, fetchCampaign);
  },
  function* () {
    yield takeEvery(UPLOAD_MEDIA.REQUEST, uploadMedia);
  },
  function* () {
    yield takeEvery(DELETE_MEDIA_FROM_ACR.REQUEST, deleteMediaFromAcr);
  },
  function* () {
    yield takeEvery(UPLOAD_LOCATIONS.REQUEST, uploadLocations);
  },
  function* () {
    yield takeEvery(FETCH_CLIENTS.REQUEST, fetchClients);
  },
  function* () {
    yield takeEvery(CHECK_FOR_S3_FILES.REQUEST, checkS3Files);
  },
  function* () {
    yield takeEvery(FETCH_ACR_MEDIA.REQUEST, fetchAcrMediaSaga);
  },
  function* () {
    yield takeEvery(DELETE_LOCATIONS.REQUEST, deleteLocations);
  },
];
