import { Order } from './TableHead'

const descendingComparator = <T>(a: T, b: T, orderBy: keyof T) => {
    if (b[orderBy] < a[orderBy]) {
        return -1
    }
    if (b[orderBy] > a[orderBy]) {
        return 1
    }
    return 0
}

export const getComparator = <T>(order: Order, orderBy: keyof T): (a: T, b: T) => number =>
    order === 'desc'
        ? (a, b) => descendingComparator<T>(a, b, orderBy)
        : (a, b) => -descendingComparator<T>(a, b, orderBy)

export const stableSort = <T>(array: T[], comparator: (a: T, b: T) => number) => {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0])
        if (order !== 0) return order
        return a[1] - b[1]
    })
    return stabilizedThis.map((el) => el[0])
}
