import React, { PureComponent } from 'react';
import { bindActionCreators, compose } from 'redux';
import Button from 'shared/components/Button';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { ReactComponent as Logo } from 'assets/logo_big.svg';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { performLogin } from '../../reducers/login';
import formValidate from './formValidate';
import styles from './styles.module.scss';
import TextField from 'shared/components/TextField';
import { LoginUser, LoginPassword } from 'shared/components/Icons';
import * as types from '../../types/AuthLoginTypes';
export const LOGIN_FORM_NAME = 'forms/LOGIN_FORM';

class Login extends PureComponent<types.LoginProps> {
  login = () => {
    this.props.performLogin(this.props.formValues);
  };

  render() {
    const { handleSubmit, isLoading, loginError } = this.props;
    return (
      <div className={styles.Login}>
        <div className={styles.LoginWrapper}>
          <div className={styles.LoginHeader}>
            <Logo className={styles.LoginHeaderLogo} />
          </div>

          <form onSubmit={handleSubmit(this.login)}>
            <div className={styles.LoginInputWrapper}>
              <Field
                name="email"
                id="email"
                placeholder="Email"
                component={TextField}
                type="email"
                className={styles.LoginInput}
                iconLeft={<LoginUser className={styles.LoginIconLeft} />}
              />
            </div>
            <div className={styles.LoginInputWrapper}>
              <Field
                name="password"
                id="password"
                placeholder="Password"
                component={TextField}
                type="password"
                iconLeft={<LoginPassword className={styles.LoginIconLeft} />}
                className={styles.LoginInput}
              />
            </div>

            <Button type="primary" isLoading={isLoading} className={styles.LoginSubmit}>
              Login
            </Button>
            {loginError && <span>Wrong credentials</span>}
          </form>
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ auth, ...state }) => ({
  formValues: formValueSelector(LOGIN_FORM_NAME)(state, 'email', 'password'),
  isLoading: get(auth, 'login.states.isLoading'),
  loginError: get(auth, 'login.states.hasError'),
  initialValues: {
    email: '',
    password: '',
  },
});
const mapDispatchToProps = (dispatch) => bindActionCreators({ performLogin }, dispatch);
export default compose<any>(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: LOGIN_FORM_NAME,
    validate: formValidate,
  }),
)(Login);
