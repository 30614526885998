import React from 'react'
import { useDetails } from 'hooks'
import { useGiftShopManagement, GiftCardResponse } from 'modules/giftshop'
import { ContentLoader, GiftCardForm } from 'components'
import { routes } from 'shared/constants/routes'

export const GiftCardDetails = () => {
    const { fetchGiftCard, createGiftCard, updateGifCard } = useGiftShopManagement()
    const { data, onSave, requestStatus } = useDetails<GiftCardResponse>({
        fetch: fetchGiftCard,
        create: createGiftCard,
        update: updateGifCard,
        redirectTo: routes.giftshop.default.path,
    })

    const defaultValues = data || { pushNoteEnabled: true }

    return (
        <ContentLoader status={requestStatus}>
            <GiftCardForm defaultValues={defaultValues} onSubmit={onSave} />
        </ContentLoader>
    )
}
