import React from 'react';
import { Route } from 'react-router-dom';
import { routes } from 'shared/constants/routes';
import { UsersList } from './UsersList';
import { UserDetails } from './UserDetails';

export const Users = () => (
  <>
    <Route path={routes.users.default.path} component={UsersList} exact />
    <Route
      component={UserDetails}
      exact
      path={routes.users.manage('(edit)/:id?').path}
    />
  </>
);
