import React, { PureComponent } from 'react';
import classnames from 'classnames';
import map from 'lodash/map';
import styles from './styles.module.scss';
import FieldError from '../FieldError';

interface RadioFieldProps {
  onClick?: (...args: any[]) => void;
  className?: string;
  labelLeft?: boolean;
  noMargin?: boolean;
  small?: boolean;
  disabled?: boolean;
  label?: React.ReactNode | string;
  input?: any;
  meta?: any;
  options?: {
    value: string;
    label: string;
  }[];
  isRequired?: boolean;
}

class RadioField extends PureComponent<RadioFieldProps> {
  onClick = (e) => {
    if (this.props.onClick) {
      this.props.onClick(e);
    }
  };

  render() {
    const {
      input,
      label,
      meta,
      noMargin,
      className,
      labelLeft,
      options,
      disabled,
      small,
    } = this.props;

    // NIE MA KLASY RADIOFIELD SMALL!!!
    const classes = classnames(styles.RadioField, className, {
      [styles.RadioFieldLabelLeft]: labelLeft,
      [styles.RadioFieldNoMargin]: noMargin,
      'RadioField--small': small,
    });

    const labelClasses = classnames(styles.RadioFieldLabel, className, {
      [styles.RadioFieldLabelLeft]: labelLeft,
      [styles.RadioFieldLabelDisabled]: disabled,
    });

    // NIE MA KLASY RADIOFIELD INPUT SMALL
    const inputClasses = classnames(styles.RadioFieldInput, {
      'RadioField__input--small': small,
    });

    const inputLabelClasses = classnames(styles.RadioFieldInputLabel, className, {
      [styles.RadioFieldInputLabelDisabled]: disabled,
      [styles.RadioFieldInputLabelSmall]: small,
    });
    const wrapperClasess = classnames(styles.RadioFieldInputWrapper, className, {
      [styles.RadioFieldInputWrapperError]: meta.touched && meta.error,
    });

    return (
      <div className={classes} onClick={this.onClick}>
        {label && (
          <label className={labelClasses}>
            {label} {this.props.isRequired && '*'}
          </label>
        )}
        <div className={wrapperClasess}>
          {map(options, (option) => (
            <div key={`${input.name}-${option.value}`} className={inputClasses}>
              <input
                className={styles.RadioFieldInputField}
                type="radio"
                name={input.name}
                value={option.value}
                readOnly={disabled}
                checked={String(option.value) === String(input.value)}
                onChange={!disabled ? input.onChange : null}
                id={`${input.name}-${option.value}`}
              />
              <label htmlFor={`${input.name}-${option.value}`} className={inputLabelClasses}>
                {option.label}
              </label>
            </div>
          ))}
        </div>
        <FieldError meta={meta} />
      </div>
    );
  }
}

export default RadioField;
