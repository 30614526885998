import React from 'react';
import { Field } from 'redux-form';
import TextField from 'shared/components/TextField';
import AutocompleteField from 'shared/components/AutocompleteFieldNew';

interface SurveyMonkeyTypes {
  surveyId: string;
  monkeySurveys?: {
    label: string;
    value: string;
  }[];
}

const SurveyMonkey = ({ surveyId, monkeySurveys }: SurveyMonkeyTypes) => {
  return (
    <>
      <Field
        component={AutocompleteField}
        name="surveyMonkeySurveyId"
        dataSource={monkeySurveys}
        label="Survey Monkey survey"
        className="CampaignHeader__input"
        disabled={surveyId}
        isRequired
      />
      <Field
        component={TextField}
        name="surveyMonkeyMobileCollectorHash"
        label="Mobile Collector"
        tooltip="Put mobile collector for this survey from Survey Monkey and paste it here"
        className="CampaignHeader__input"
        disabled={surveyId}
        isRequired
      />
    </>
  );
};

export default SurveyMonkey;
