import { put, takeEvery, select } from 'redux-saga/effects';
import apiService, { getError } from 'shared/services/api';
import { push } from 'connected-react-router';
import {
  MANAGE_QUESTION_FETCH_CATEGORIES,
  MANAGE_QUESTION_FETCH_BUCKET_CATEGORIES,
  MANAGE_QUESTION_FETCH_QUESTION,
  MANAGE_QUESTION_SAVE,
} from '../../../reducers/question/manage/';
import { routes } from 'shared/constants/routes';
import { TOAST_MESSAGE } from 'shared/constants/message';
import { toast } from 'react-toastify'

const sortByProp = (a, b) => {
    if (![a.answerOrder, b.answerOrder].includes(undefined)) {
        if (a.answerOrder < b.answerOrder) {
            return -1
        }
        if (a.answerOrder > b.answerOrder) {
            return 1
        }
    }
    return 0
}

function* fetchCategories() {
  try {
    const { data } = yield apiService({}).get('/profile/questions/categories');
    yield put({
      type: MANAGE_QUESTION_FETCH_CATEGORIES.SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield put({
      type: MANAGE_QUESTION_FETCH_CATEGORIES.FAILURE,
      payload: getError(err),
    });
  }
}

function* fetchQuestion(action) {
  try {
    const { data } = yield apiService({}).get(`/profile/questions/${action.payload.id}`);
    yield put({
      type: MANAGE_QUESTION_FETCH_QUESTION.SUCCESS,
      payload: { ...data, possibleAnswers: data.possibleAnswers.sort(sortByProp), },
    });
  } catch (err) {
    yield put({
      type: MANAGE_QUESTION_FETCH_QUESTION.FAILURE,
      payload: getError(err),
    });
  }
}

function* saveQuestion(action) {
  try {
    let data = {};
    let hasError = false
    if (action.questionId) {
      const payload = {
          ...action.payload,
          possibleAnswers: action.payload.possibleAnswers.map(answer => ({ profileQuestionId: parseInt(action.questionId, 10), ...answer }))
      }
      const response = yield apiService({}).put(`/profile/questions/${action.questionId}`, payload);
      data = response?.data;

      if(response?.status == 200) {
        toast.success(TOAST_MESSAGE.question.updated)        
      } else {
        hasError = true
      }  
    } else {
      const response =  yield apiService({}).post('/profile/questions', action.payload);
      data = response?.data;

      if(response?.status == 200) {
        toast.success(TOAST_MESSAGE.question.created)
      } else {
        hasError = true        
      }       
    }

    if(hasError) {
      toast.error(data?.message || TOAST_MESSAGE[500])
      yield put({
        type: MANAGE_QUESTION_SAVE.FAILURE,
        payload: getError(err),
      });
    } else {
      yield put({
        type: MANAGE_QUESTION_SAVE.SUCCESS,
        payload: data,
      });
      const { app: { country } } = yield select();
      yield put(push({ pathname: routes.completionSurveys.questions.list.path, search: `?country=${country}` }));
    } 
  } catch (err) {
    toast.error(TOAST_MESSAGE[500])
    yield put({
      type: MANAGE_QUESTION_SAVE.FAILURE,
      payload: getError(err),
    });
  }
}

function* fetchBucketCategories(action) {
  try {
    const { data } = yield apiService({}).get('/profile/questions/bucket-categories');
    yield put({
      type: MANAGE_QUESTION_FETCH_BUCKET_CATEGORIES.SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield put({
      type: MANAGE_QUESTION_FETCH_BUCKET_CATEGORIES.FAILURE,
      payload: getError(err),
    });
  }
}

export default [
  function* () {
    yield takeEvery(MANAGE_QUESTION_FETCH_QUESTION.REQUEST, fetchQuestion);
  },
  function* () {
    yield takeEvery(MANAGE_QUESTION_SAVE.REQUEST, saveQuestion);
  },
  function* () {
    yield takeEvery(MANAGE_QUESTION_FETCH_CATEGORIES.REQUEST, fetchCategories);
  },
  function* () {
    yield takeEvery(MANAGE_QUESTION_FETCH_BUCKET_CATEGORIES.REQUEST, fetchBucketCategories);
  },
];
