import * as yup from 'yup'
import { stringIntl } from 'services/validation'

const date = yup.date().transform((cv, ov) => (ov === null ? undefined : cv))

const number = yup
    .number()
    .transform((cv, ov) => (ov === '' ? undefined : cv))
    .required()
    .integer()
    .min(0)

export const schema = yup.object({
    title: yup.string().required(),
    description: stringIntl('description'),
    pushNoteTitle: stringIntl('pushNoteTitle'),
    pushNoteBody: stringIntl('pushNoteBody', false),
    pushNoteDate: date.nullable().notRequired(),
    imageUrl: yup.string().url().required(),
    maximumAmountPerUser: number,
    maximumAmountMonth: number,
    pointsNeeded: number,
    availableGiftcards: number,
    dateFrom: date.required().when('dateTo', (dateTo, s) => {
        if (!dateTo) {
            return s
        }

        return yup.date().max(dateTo)
    }),
    dateTo: date.required().min(yup.ref('dateFrom')),
    status: yup.mixed().oneOf(['OPEN', 'CLOSED'], 'Select option').required(),
})
