import React from 'react';
import styles from './styles.module.scss';

interface FormButtonsProps {
  children: React.ReactNode;
}

export default ({ children }: FormButtonsProps) => (
  <div className={styles.FormButtons}>{children}</div>
);
