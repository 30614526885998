import { makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((t: Theme) => ({
    root: {
        tableLayout: 'fixed',
    },
    columnResizer: {
        width: '2px',
        borderBottom: '1px solid rgb(224, 224, 224)',
    },
    columnResizerActive: {
        position: 'relative',
        '&::before': {
            position: 'absolute',
            width: '2px',
            height: '22px',
            top: '50%',
            left: '50%',
            marginTop: '-11px',
            marginLeft: '-1px',
            background: 'rgb(224, 224, 224)',
            content: '""',
        },
        '&:hover::before': {
            background: t.palette.primary.main,
        },
    },
}))
