import React from 'react';
import classnames from 'classnames';
import styles from './styles.module.scss';

interface CampaignHeaderTypes {
  children: React.ReactNode;
}

const CampaignHeader = ({ children }: CampaignHeaderTypes) => {
  const classname = classnames(styles.CampaignHeader);
  return <div className={classname}>{children} </div>;
};

export default CampaignHeader;
