import moment from 'moment'
import { utcDateToLocal } from 'shared/services/date';
import { LocationResponse } from './types'


export const csvifyLocations = (dateHandler: (date: Date) => Date) => (data: LocationResponse[]) => {
    const formatField = (field?: string | number | null) => Boolean(field) ? field : ''
    const formatDate = (date: Date) => moment(dateHandler(date)).format('YYYY-MM-DDTHH:mm:ssZZ')

    const csvContent = data.map(({
        latitude,
        longitude,
        dateFrom,
        dateTo,
        radius,
        filterName = '',
        locationType = '',
        locationDetailName = '',
        locationDetailId = '',
        address = '',
        postalCode = '',
        city = '',
        province = '',
        country = '',
    }) => `${formatField(latitude)},${formatField(longitude)},${formatDate(dateFrom)},${formatDate(dateTo)},${formatField(radius)},${formatField(filterName)},${formatField(locationType)},${formatField(locationDetailName)},${formatField(locationDetailId)},${formatField(address)},${formatField(postalCode)},${formatField(city)},${formatField(province)},${formatField(country)}\r\n`).join('')

    const csv = `latitude,longitude,dateFrom,dateTo,radius,filterName,locationType,locationDetailName,locationDetailId,address,postalCode,city,province,country\r\n${csvContent}`

    return csv
}
