import qs from 'query-string';
import { createReducer } from 'shared/utils/redux';
import { Country } from 'shared/types';

const COUNTRY_SELECT = 'app/COUNTRY_SELECT';

export const performCountrySelect = (counry: Country) => ({
  type: COUNTRY_SELECT,
  payload: {
    counry,
  },
});

const isCountry = (country?: string): country is Country => ['NL', 'BE_FL', 'FR', 'BE_WL', 'UK', 'DE'].includes(country || '');
const queryCountry = qs.parse(window.location.search).country as Country;
const initState: Country = isCountry(queryCountry) ? queryCountry : 'NL';

export default createReducer(initState, {
  [COUNTRY_SELECT]: (state, action) => action.payload.counry,
});
