import React from 'react';
import classnames from 'classnames';
import { ReactComponent as SortIcon } from 'assets/icons/sort-down.svg';
import RotateLoader from '../RotateLoader';
import styles from './styles.module.scss';
import ColumnResizer from 'react-column-resizer';

interface TableProps {
  children?: React.ReactNode;
  isLoading?: boolean;
}

interface TbodyProps {
  children?: React.ReactNode;
}

interface TheadProps {
  children?: React.ReactNode;
}

interface ThProps {
  children?: React.ReactNode;
  sortable?: boolean;
  onClick?: (...args: any[]) => any;
  active?: boolean;
  currentSortType?: string;
  short?: boolean;
}

interface LastThProps {
  children?: React.ReactNode;
  sortable?: boolean;
  onClick?: (...args: any[]) => any;
  active?: boolean;
  currentSortType?: string;
  short?: boolean;
}

interface TrProps {
  children?: React.ReactNode;
  onClick?: (...args: any[]) => any;
  active?: boolean;
}

interface TableFiltersProps {
  children?: React.ReactNode;
}

interface TdProps {
  children?: React.ReactNode;
}

interface LastTdProps {
  children?: React.ReactNode;
}

export const Table = ({ children, isLoading }: TableProps) => {
  const classes = classnames(styles.Table, {
    'Table--is-loading': isLoading,
  });

  const loaderClasses = classnames(styles.TableWrapperLoader, {
    [styles.TableWrapperLoaderActive]: isLoading,
  });
  return (
    <div className={styles.TableWrapper}>
      <div className={loaderClasses}>{isLoading && <RotateLoader />}</div>
      <table className={classes}>{children}</table>
    </div>
  );
};
export const Tbody = ({ children }: TbodyProps) => (
  <tbody className={styles.Tbody}>{children}</tbody>
);
export const Thead = ({ children }: TheadProps) => (
  <thead className={styles.Thead}>{children}</thead>
);

export const Th = ({
  children,
  sortable,
  onClick,
  active,
  currentSortType,
  short,
}: ThProps) => {
  const classes = classnames(styles.Th, {
    [styles.ThActive]: active,
    [styles.ThSortable]: onClick && sortable,
    [styles.ThShort]: short,
  });
  const sortClasses = classnames(styles.ThSortIcon, {
    [styles.ThSortIconHidden]: !active,
    [styles.ThSortIconRotate]: currentSortType === 'asc',
  });

  return (
    <>
      <th className={classes} onClick={onClick}>
        {children}
        {sortable && (
          <button className={styles.ThSort}>
            <SortIcon className={sortClasses} />
          </button>
        )}
      </th>
      <ColumnResizer className={styles.HeadResizer} />
    </>
  );
};

export const LastTh = ({
  children,
  sortable,
  onClick,
  active,
  currentSortType,
  short,
}: LastThProps) => {
  const classes = classnames(styles.Th, {
    [styles.ThActive]: active,
    [styles.ThSortable]: onClick && sortable,
    [styles.ThShort]: short,
  });
  const sortClasses = classnames(styles.ThSortIcon, {
    [styles.ThSortIconHidden]: !active,
    [styles.ThSortIconRotate]: currentSortType === 'asc',
  });

  return (
    <>
      <th className={classes} onClick={onClick}>
        {children}
        {sortable && (
          <button className={styles.ThSort}>
            <SortIcon className={sortClasses} />
          </button>
        )}
      </th>
    </>
  );
};

export const Tr = ({ children, onClick, active }: TrProps) => {
  const classes = classnames(styles.Tr, {
    [styles.TrClicable]: !!onClick,
    [styles.TrActive]: active,
  });

  return (
    <tr className={classes} onClick={onClick}>
      {children}
    </tr>
  );
};

export const TableFilters = ({ children }: TableFiltersProps) => (
  <div className={styles.TableFilters}>
    <div className="row">
      <div className="col-md-12 d-flex justify-content-end">{children}</div>
    </div>
  </div>
);
export const Td = ({ children }: TdProps) => (
  <>
    <td className={styles.Td}>{children}</td>
    <td></td>
    {/* <ColumnResizer className={styles.BodyResizer} disabled={true} /> */}
  </>
);

export const LastTd = ({ children }: LastTdProps) => (
  <td className={styles.Td}>{children}</td>
);
