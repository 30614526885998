import React, { useState, useEffect, useMemo, FC } from 'react';
import { Field } from 'redux-form';
import TextFieldI18N from 'shared/components/TextFieldI18N';
import Switch from 'shared/components/Switch';
import styles from './styles.module.scss';
import IconButton from 'shared/components/IconButton';
import { Spellcheck } from 'shared/components/Icons';
import { useCountry } from 'hooks';
import { LANGUAGE_TRANSLATION } from 'shared/constants/lang';
import { trim } from 'lodash';
import { toast } from 'react-toastify';

interface PushNotificationsProps {
    readOnly?: boolean
    pushNoteBody?: any;
}

const PushNotifications: FC<PushNotificationsProps> = ({ readOnly, pushNoteBody }) => {

  const [spellCheckOpen, setSpellCheckOpen] = useState(false);
  const [misspellWords, setMisspellWords] = useState<string[]>([]);
  const { selectedCountryCode } = useCountry();

  const notification_body = pushNoteBody
    ? pushNoteBody[LANGUAGE_TRANSLATION[selectedCountryCode]]
    : '';

  const dictionary = useMemo(() => {
    const dictionary_obj: {[key: string]: boolean} = {};
    let dictionary: string[] = []
    
    if (LANGUAGE_TRANSLATION[selectedCountryCode] === 'NL') {
      dictionary = require('an-array-of-dutch-words');
    } else if (LANGUAGE_TRANSLATION[selectedCountryCode] === 'FR') {
      dictionary = require('an-array-of-french-words');
    } else if (LANGUAGE_TRANSLATION[selectedCountryCode] === 'EN') {
      dictionary = require('an-array-of-english-words');
    }

    for (let i = 0; i < dictionary.length; i++) {
      dictionary_obj[dictionary[i].toLocaleLowerCase()] = true;
    }

    return dictionary_obj

  }, [selectedCountryCode]);

  const handleSpellCheck = () => {
    setSpellCheckOpen(false);
    setMisspellWords([])

    if (!spellCheckOpen && notification_body) {
      try {
        setSpellCheckOpen(true);
        const input_words: string[] = notification_body.split(/[\n ]/)
          .filter((item: string) => trim(item).length > 1);

        if (input_words.length > 0) {
          const wrong_words = input_words.filter(item => {
            if(dictionary[item.toLocaleLowerCase()]) {
              return false      
            }  
            return true   
          })      
          setMisspellWords(wrong_words);
        }
      } catch (error) {        
        toast.error(error.message);
      }
    } 
  };

  useEffect(() => {
    setSpellCheckOpen(false);
  }, [pushNoteBody]);


  return (
    <>
        <Field
            component={Switch}
            label="Enable push notification"
            readOnly={readOnly}
            name="pushNoteEnabled"
        />
      <Field
        component={TextFieldI18N}
        name="pushNoteTitle"
        label="Push notification and in-app title"
        className="CampaignHeader__input"
        isRequired
        maxLength={65}
      />
      <div className={styles.bodyField}>
        <Field
          component={TextFieldI18N}
          name="pushNoteBody"
          label="Push notification body"
          className="CampaignHeader__input"
          isRequired
          multiLine
          maxLength={240}
        />
        <div>
          <div className={styles.hint}>
            Possible placeholders:
            <br />
            {`{{username}}, {{name}}, {{points}}, {{timeToEnd}}, {{endDate}}`}
          </div>
          {spellCheckOpen && (
            <div className={styles.spellCheckContainer}>
              <div style={{ flex: 1 }}>
                {misspellWords.map((word, index) => (
                  <div key={index} className={styles.misspell}>
                    {word}
                  </div>
                ))}

                {misspellWords.length == 0 && (
                  <div className={styles.noError}>
                    You are nicely done!
                  </div>
                )}
              </div>

              <div>
                <IconButton
                  onClick={() => {
                    setSpellCheckOpen(false);
                  }}
                >
                  <Spellcheck />
                </IconButton>
              </div>
            </div>
          )}
        </div>

        {!spellCheckOpen && notification_body ? (
          <div
            className={styles.spellCheckButton}
            onClick={() => {
              handleSpellCheck();
            }}
          >
            <IconButton>
              <Spellcheck />
            </IconButton>
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default PushNotifications;
