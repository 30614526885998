import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(() => ({
    hidden: {
        visibility: 'hidden',
        height: 0,
        width: 0,
        position: 'absolute',
    },
    readOnly: {
        color: 'rgba(0, 0, 0, 0.23)',
    },
}))
