export const validationMessage = {
  filterNamePunctuation: "Valid Input: Alphanumeric, '_', or '-'",
  user_ids: `This can only contain numbers and the special characters 'space', 'new line', and 'comma'.`,
};

export const TOAST_MESSAGE = {
  500: 'Something went wrong. Please try again later.',
  campaign: {
    created: 'Campaign successfully saved',
    updated: 'Campaign successfully saved'
  },
  category: {
    deleted: 'Category successfully deleted',
    unable_delete: 'This category cannot be deleted because it contains questions.',
    created: 'Category successfully saved',
    updated: 'Category successfully saved'
  },
  question: {
    deleted: 'Question successfully deleted',
    created: 'Question successfully saved',
    updated: 'Question successfully saved'
  },
  user: {
    updated: 'User successfully saved',
    required: 'Please fill all required fields.'
  }
}

export const CONFIRMATION_MESSAGE = {
  reopen: 'Are you sure you want to reopen this survey?'
}
