import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, Field, getFormValues } from 'redux-form';
import map from 'lodash/map';
import get from 'lodash/get';
import find from 'lodash/find';
import filter from 'lodash/filter';
import Button from 'shared/components/Button';
import ContentLoader from 'shared/components/ContentLoader';
import TextField from 'shared/components/TextField';
import TextFieldI18N from 'shared/components/TextFieldI18N';
import CheckboxField from 'shared/components/CheckboxField';
import SelectField from 'shared/components/SelectField';
import FormSection from 'shared/components/FormSection';
import HeaderTitle from 'shared/components/HeaderTitle';
import { Trash } from 'shared/components/Icons';
import { LANGUAGES } from 'shared/constants/lang';
import RadioField from 'shared/components/RadioField';
import { orderAlphabetically } from 'shared/utils/fields';
import {
  fetchCategories,
  fetchBucketCategories,
  saveQuestion,
  fetchQuestion,
  resetToInit,
} from 'modules/CompletionSurveys/reducers/question/manage';
import formValidation from './formValidation';
import AnswerComposer from '../../components/AnswerComposer';
import styles from './styles.module.scss';
import campaignStyles from '../../../Campaign/containers/Manage/styles.module.scss';
import AutocompleteField from 'shared/components/AutocompleteFieldNew';
import * as types from '../../types/QuestionManageTypes';
import CampaignHeader from 'shared/components/CampaignHeader';
import { toast } from 'react-toastify';
const FORM_NAME = 'completionSurvey/question/MANAGE';
const QUESTION_TYPES = [
  { value: 'OPEN', label: 'Open' },
  { value: 'CHECKBOX', label: 'Checkbox' },
  { value: 'RADIO', label: 'Radio' },
  { value: 'REGEX', label: 'Regex' },
];

class QuestionsManage extends PureComponent<types.QuestionManageProps> {
  componentDidMount() {
    const id = get(this.props, 'match.params.id');
    this.props.fetchCategories();
    this.props.fetchBucketCategories();
    if (id) {
      this.props.fetchQuestion({ id });
    }
  }
  componentDidUpdate(prevProps) {
    const id = get(this.props, 'match.params.id');
    const prevId = get(prevProps, 'match.params.id');
    if (id !== prevId) {
      this.props.fetchQuestion({ id });
    }
  }
  componentWillUnmount() {
    this.props.reset();
    this.props.resetToInit();
  }

  submit = () => {
    const questionId = get(this.props, 'match.params.id');
    if (questionId) {
      this.props.saveQuestion({ ...this.props.formValues, questionId });
    } else {
      const countryOfAvailability = this.props.selectedCountry;
      console.log(countryOfAvailability);
      console.log(this.props.formValues);
      console.log(this.props.formValues.possibleAnswers);
      this.props.saveQuestion({ ...this.props.formValues, countryOfAvailability });
    }
  };

  render() {
    const { handleSubmit, savingStates, fetchingStates, categories, formValues } = this.props;
    const categoryId = get(this.props, 'match.params.id');
    const possibleAnswers = get(formValues, 'answers');
    const type = get(formValues, 'type');
    return (
      <ContentLoader states={fetchingStates} isNew={!categoryId}>
        <div>
          <form onSubmit={handleSubmit(this.submit)}>
            <CampaignHeader>
              <HeaderTitle>Manage questions</HeaderTitle>
              <div className={campaignStyles.CampaignHeaderRight}>
                <Button type="primary" isLoading={savingStates.isLoading}>
                  Save
                </Button>
              </div>
            </CampaignHeader>
            <div className={styles.QuestionsManage}>
              <FormSection isActive>
                <div className="row">
                  <div className="col-md-12">
                    <h4>Question titles</h4>
                    <Field
                      component={TextFieldI18N}
                      name="titles"
                      label="Title"
                      tooltip="Write the question you would like to ask here."
                      className="CampaignHeader__input"
                      isRequired
                    />
                    <Field
                      component={TextFieldI18N}
                      name="shortTitles"
                      label="In-app short title"
                      className="CampaignHeader__input"
                      isRequired
                    />
                    <h4>Additional data</h4>
                    <Field
                      component={TextField}
                      name="filterName"
                      label="Filter name"
                      tooltip="Write a short but clear label for the question (Beware that this will be shown in the app!)."
                      className="CampaignHeader__input"
                      isRequired
                    />
                    <Field
                      component={AutocompleteField}
                      dataSource={categories}
                      name="categoryId"
                      placeholder="Select question category"
                      label="Category"
                      className="CampaignHeader__input"
                      isRequired
                    />
                    <Field
                      component={TextField}
                      name="points"
                      label="Points"
                      tooltip="Choose the amount of points a user will get for this question"
                      className="CampaignHeader__input"
                      isRequired
                    />
                    <Field
                      component={RadioField}
                      options={[
                        {
                          value: true,
                          label: 'Visible',
                        },
                        {
                          value: false,
                          label: 'Hidden',
                        },
                      ]}
                      name="visible"
                      label="Visible"
                      isRequired
                    />
                    <h4>Answers </h4>
                    <div className="row">
                      <div className="col-md-8">
                        <Field
                          component={SelectField}
                          name="type"
                          dataSource={QUESTION_TYPES}
                          placeholder="Select question type"
                          label="Question type"
                          className="CampaignHeader__input"
                          isRequired
                          readOnly
                        />
                      </div>
                      {['RADIO', 'CHECKBOX'].includes(type) && (
                        <div className="col-md-4" style={{ marginTop: '44px' }}>
                          <Field component={CheckboxField} name="categorizedAnswers" className="CampaignHeader__input">
                            Categorized answers
                          </Field>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {['OPEN', 'REGEX'].includes(type) && (
                  <Field
                    component={TextField}
                    name="possibleAnswers[0][placeholder]"
                    label="Placeholder"
                    className="CampaignHeader__input"
                    isRequired
                  />
                )}
                {type === 'REGEX' && (
                  <div>
                    <Field
                      component={TextField}
                      name="possibleAnswers[0][value]"
                      label="Regex"
                      tooltip="Put here correct regex e.g. /d+/"
                      className="CampaignHeader__input"
                      isRequired
                    />
                  </div>
                )}
                {['RADIO', 'CHECKBOX'].includes(type) && <AnswerComposer />}
              </FormSection>
            </div>
          </form>
        </div>
      </ContentLoader>
    );
  }
}

const mapStateToProps = (
  {
    completionSurveys: {
      question: {
        manage: { save, question, categories },
      },
    },
    ...state
  },
  ownProps,
) => ({
  categories: orderAlphabetically(
    map(categories.data, category => ({
      label: get(category.titles, 'en') || get(category.titles, Object.keys(category.titles)[0]),
      value: category.id,
    })),
  ),
  savingStates: save.states,
  fetchingStates: question.states,
  formValues: getFormValues(FORM_NAME)(state),
  initialValues: get(ownProps, 'match.params.id') ? question.data : { visible: false },
  selectedCountry: state.app.country,
});
const mapDispatchToProps = { saveQuestion, fetchQuestion, resetToInit, fetchCategories, fetchBucketCategories };

export default compose<any>(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    validate: formValidation,
  }),
)(QuestionsManage);
