import React from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { routes } from 'shared/constants/routes';

interface TypeScriptAuthorizedRouteProps {
  isAuthenticated?: boolean;
  component: React.ElementType;
}

export const AuthorizedRoute = ({ component:Component, ...rest } : TypeScriptAuthorizedRouteProps) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return rest.isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: routes.auth.login.path,
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
};
// TODO: Wait for authorization
const mapStateToProps = () => ({ isAuthenticated: true });

export default compose<React.ElementType>(withRouter, connect(mapStateToProps))(AuthorizedRoute);
