import { makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((t: Theme) => ({
    file: {
        visibility: 'hidden',
        width: 0,
        height: 0,
        position: 'absolute',
        pointerEvents: 'none',
    },
    field: {
        borderRadius: '4px 0 0 4px',
    },
    button: {
        height: '100%',
        borderRadius: '0 4px 4px 0',
        border: '1px solid rgba(0, 0, 0, 0.23)',
        borderLeft: 'none',
        backgroundColor: '#fff',
    },
}))
