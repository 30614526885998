export const AUTHORIZED_PATH = '/app';
export const UNAUTHORIZED_PATH = '/auth';

export const routes = {
  app: {
    default: {
      title: 'Dashboard',
      path: '/app',
    },
  },
  auth: {
    default: {
      title: 'Auth',
      path: '/auth',
    },
    login: {
      title: 'Login',
      path: '/auth/login',
    },
  },
  campaigns: {
    default: {
      title: 'Campaigns',
      path: '/app/campaigns',
    },
    list: {
      title: 'Campaigns',
      path: '/app/campaigns',
    },
    create: {
      title: 'Create Campaign',
      path: '/app/campaigns/create',
    },
    manage: id => ({
      title: 'Edit Campaign',
      path: `/app/campaigns/${id}`,
    }),
    edit: id => ({
      title: 'Edit Campaign',
      path: `/app/campaigns/edit/${id}`,
    }),
  },
  clients: {
    default: {
      title: 'Clients',
      path: '/app/clients',
    },
    list: {
      title: 'Clients',
      path: '/app/clients',
    },
  },
  users: {
    default: {
      title: 'Users',
      path: '/app/users',
    },
    list: {
      title: 'Users',
      path: '/app/users',
    },
    manage: (id) => ({
      path: `/app/users/${id}`,
    }),
    edit: (id) => ({
      path: `/app/users/edit/${id}`,
    }),
  },
  settings: {
    default: {
      title: 'Settings',
      path: '/app/settings',
    },
  },
  charities: {
    default: {
      title: 'Charities',
      path: '/app/charities',
    },
    list: {
      title: 'Charities',
      path: '/app/charities',
    },
    create: {
      title: 'Create charity',
      path: '/app/charities/create',
    },
    manage: id => ({
      title: 'Edit charity',
      path: `/app/charities/${id}`,
    }),
    edit: id => ({
      title: 'Edit charity',
      path: `/app/charities/edit/${id}`,
    }),
  },
  giftshop: {
    default: {
      title: 'Gift Shop',
      path: '/app/giftshop',
    },
    list: {
      title: 'Gift Shop',
      path: '/app/giftshop',
    },
    create: {
      title: 'Create gift',
      path: '/app/giftshop/create',
    },
    manage: id => ({
      title: 'Edit gift',
      path: `/app/giftshop/${id}`,
    }),
    edit: id => ({
      title: 'Edit gift',
      path: `/app/giftshop/edit/${id}`,
    }),
  },
  lotteries: {
    default: {
      title: 'Lotteries',
      path: '/app/lotteries',
    },
    list: {
      title: 'Charities',
      path: '/app/lotteries',
    },
    create: {
      title: 'Create lottery',
      path: '/app/lotteries/create',
    },
    manage: id => ({
      title: 'Edit lottery',
      path: `/app/lotteries/${id}`,
    }),
    edit: id => ({
      title: 'Edit lottery',
      path: `/app/lotteries/edit/${id}`,
    }),
  },
  surveys: {
    default: {
      title: 'Surveys',
      path: '/app/surveys',
    },
    list: {
      title: 'Surveys',
      path: '/app/surveys',
    },
    create: {
      title: 'Create survey',
      path: '/app/surveys/create',
    },
    manage: id => ({
      path: `/app/surveys/${id}`,
    }),
    edit: id => ({
      path: `/app/surveys/edit/${id}`,
    }),
  },
  discussions: {
    default: {
      title: 'Discussions',
      path: '/app/discussions/',
    },
    questions: {
      list: {
        path: '/app/discussions/questions',
      },
      create: {
        path: '/app/discussions/questions/create',
      },
      manage: id => ({
        path: `/app/discussions/questions/${id}`,
      }),
      edit: id => ({
        path: `/app/discussions/questions/edit/${id}`,
      }),
    },
    answers: {
      list: {
        path: '/app/discussions/answers',
      },
      create: {
        path: '/app/discussions/answers/create',
      },
      manage: id => ({
        path: `/app/discussions/answers/${id}`,
      }),
      edit: id => ({
        path: `/app/discussions/answers/edit/${id}`,
      }),
    },
  },
  completionSurveys: {
    default: {
      title: 'Profile Surveys',
      path: '/app/profile-completion-surveys',
    },

    questions: {
      list: {
        title: 'Profile Surveys',
        path: '/app/profile-completion-surveys/questions',
      },
      create: {
        path: '/app/profile-completion-surveys/questions/create',
      },
      manage: id => ({
        path: `/app/profile-completion-surveys/questions/${id}`,
      }),
      edit: id => ({
        path: `/app/profile-completion-surveys/questions/edit/${id}`,
      }),
    },
    categories: {
      list: {
        title: 'Profile Surveys',
        path: '/app/profile-completion-surveys/categories',
      },
      create: {
        path: '/app/profile-completion-surveys/categories/create',
      },
      manage: id => ({
        path: `/app/profile-completion-surveys/categories/${id}`,
      }),
      edit: id => ({
        path: `/app/profile-completion-surveys/categories/edit/${id}`,
      }),
    },
  },
};
