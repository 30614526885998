import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Country, WithId } from 'shared/types';
import { getGiftCards, getGiftCard, postGiftCard, putGiftCard } from './api';
import { GiftCardRequest } from './types';

export const useGiftShopManagement = () => {
  const country: Country = useSelector(({ app }: any) => app.country);

  const createGiftCard = async (data: GiftCardRequest): Promise<void> => {
    try {
      await postGiftCard({ ...data, countryOfAvailability: country });
      toast.success('Gift card successfully saved');
    } catch (error) {
      toast.error('Something went wrong. Please try again later.');
    }
  };

  const updateGifCard = async (data: WithId<GiftCardRequest>): Promise<void> => {
    try {
      await putGiftCard({ ...data, countryOfAvailability: country });
      toast.success('Gift card successfully saved');
    } catch (error) {
      toast.error('Something went wrong. Please try again later.');
    }
  };

  return {
    createGiftCard,
    fetchGiftCard: getGiftCard,
    fetchGiftCards: getGiftCards,
    updateGifCard,
  };
};
