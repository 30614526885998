import React, { useEffect } from 'react';
import Modal from 'shared/components/Modal';
import Button from 'shared/components/Button';
import FormButtons from 'shared/components/FormButtons';
import { reduxForm, Field, getFormValues, FieldArray } from 'redux-form';
import RadioField from 'shared/components/RadioField';
import { useSelector } from 'react-redux';
import formValidation from './formValidation';
import AgeBuckets from '../AgeBuckets';
import SurveyBucketOptions from '../SurveyBucketOptions';
import SurveyBucketsPreview from '../SurveyBucketsPreview';
import styles from './styles.module.scss';
import { BUCKET_OPTION_TYPES } from 'shared/constants/buckets';
import { getPreviewBuckets, areBucketsValid } from './helpers';

interface AddBulkSurveyBucketModalTypes {
  isOpen: boolean;
  onClose: () => void;
  onAdd: (...args: any[]) => void;
  capacity: number;
  change: any;
  reset: (...args: any[]) => void;
}

const AddBulkSurveyBucketModal = ({
  isOpen,
  onClose,
  onAdd,
  capacity,
  change,
  reset,
}: AddBulkSurveyBucketModalTypes) => {
  const values = useSelector((state) => getFormValues('AddBulkSurveyBucketModal')(state)) || {};
  const calculatedBuckets = getPreviewBuckets(values, capacity);
  const bucketsValid = areBucketsValid(calculatedBuckets);
  const onCreate = () => {
    onAdd(calculatedBuckets);
    onClose();
  };
  useEffect(() => {
    if (!isOpen) {
      reset();
    }
  }, [isOpen, reset]);
  return (
    <Modal title="Add bulk buckets" isOpen={isOpen} onClose={onClose}>
      <div className={styles.contentWrapper}>
        <div className={styles.content}>
          <div className={styles.innerContent}>
            <div className="row">
              <div className="col-md-12">
                <FieldArray
                  name="ageBuckets"
                  component={AgeBuckets}
                  capacity={capacity}
                  change={change}
                  values={values}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <SurveyBucketOptions
                  name="gender"
                  values={values.gender}
                  label="Gender"
                  change={change}
                  capacity={capacity}
                  options={[
                    { label: 'Male', value: 'MALE' },
                    { label: 'Female', value: 'FEMALE' },
                  ]}
                  isNested={values.genderType === BUCKET_OPTION_TYPES.nested}
                />
              </div>
              <div className="col-md-6">
                <Field
                  component={RadioField}
                  isRequired
                  options={[
                    {
                      value: BUCKET_OPTION_TYPES.nested,
                      label: 'Nested',
                    },
                    {
                      value: BUCKET_OPTION_TYPES.filtered,
                      label: 'Filtered',
                    },
                  ]}
                  name="genderType"
                  label="Gender type"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <SurveyBucketOptions
                  name="education"
                  change={change}
                  values={values.education}
                  capacity={capacity}
                  label="Education"
                  options={[
                    { label: 'Low', value: 'LOW' },
                    { label: 'Medium', value: 'MEDIUM' },
                    { label: 'High', value: 'HIGH' },
                  ]}
                  isNested={values.educationType === BUCKET_OPTION_TYPES.nested}
                />
              </div>
              <div className="col-md-6">
                <Field
                  component={RadioField}
                  isRequired
                  options={[
                    {
                      value: BUCKET_OPTION_TYPES.nested,
                      label: 'Nested',
                    },
                    {
                      value: BUCKET_OPTION_TYPES.filtered,
                      label: 'Filtered',
                    },
                  ]}
                  name="educationType"
                  label="Education type"
                />
              </div>
              <div className="col-md-12">
                <SurveyBucketsPreview capacity={capacity} buckets={calculatedBuckets} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <FormButtons>
        <Button buttonType="button" type="primary" onClick={onCreate} disabled={!bucketsValid}>
          {'Add'}
        </Button>
      </FormButtons>
    </Modal>
  );
};

export default reduxForm({
  form: 'AddBulkSurveyBucketModal',
  enableReinitialize: true,
  validate: formValidation,
})(AddBulkSurveyBucketModal);
