import React, { createContext, useState } from 'react'
import { LocationResponse } from 'modules/campaignM'

interface GMapPRoviderProps {
    children: React.ReactNode
}

interface GMapContextOpts {
    map: google.maps.Map
    setMap: (data: google.maps.Map) => void
    markers: LocationResponse[]
    setMarkers: (markers: LocationResponse[]) => void
}

export const GMapContext = createContext<GMapContextOpts>({
    map: null,
    markers: [],
    setMap: () => {},
    setMarkers: () => {},
})

export const GMapProvider = ({ children }: GMapPRoviderProps) => {
    const [map, setMap] = useState<google.maps.Map>()
    const [markers, setMarkers] = useState<LocationResponse[]>([])

    return (
        <GMapContext.Provider value={{ map, markers, setMap, setMarkers }}>
            {children}
        </GMapContext.Provider>
    )
}
