import forEach from 'lodash/forEach';
import { isNumeric } from 'shared/services/validator';

const requiredFields = ['ageFrom', 'ageTo', 'gender', 'answersCap'];

interface AddSurveyFormTypes {
  ageFrom: number;
  ageTo: number;
  answersCap: string;
  answersCapPercentage: number;
  education: string[];
  gender: string[];
}

export default (values: AddSurveyFormTypes) => {
  const errors = {};
  forEach(requiredFields, (field) => {
    if (!values[field]) {
      errors[field] = 'Fill in';
    }
  });
  if (!isNumeric(values.ageFrom)) {
    errors['ageFrom'] = 'Must be number';
  }
  if (!isNumeric(values.ageTo)) {
    errors['ageTo'] = 'Must be number';
  }
  if (!isNumeric(values.answersCap)) {
    errors['answersCap'] = 'Must be number';
  }

  return errors;
};
