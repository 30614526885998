import React from 'react';
import { Field } from 'redux-form';
import { parseNumber } from 'shared/utils/fields';
import SurveyBucketField from '../SurveyBucketField';
import styles from './styles.module.scss';
import Button from 'shared/components/Button';
import keys from 'lodash/keys';

const calculateAllocationByPercentage = ({ percentage, capacity }) => Math.floor((capacity * percentage) / 100);
const calculateAllocationPercentageByAllocation = ({ allocation, capacity }) =>
  ((allocation * 100) / capacity).toFixed(2);

interface AgeBucketsTypes {
  fields: any;
  capacity: number;
  change:  (...args: any[]) => void;
  values: {
    ageBuckets: {
      ageFrom: number;
      ageTo: number;
      allocation: number;
      allocationPercentage: number;
    }[];
  };
}

export const AgeBuckets = ({ fields, capacity, change, values }: AgeBucketsTypes) => {
  const totalAllocation = (field) => {
    const buckets = values.ageBuckets || [];
    const sum = buckets
      .map((bucket) => bucket[field])
      .filter((number) => !isNaN(number))
      .reduce((a, b) => Number(a) + Number(b), 0);
    return sum;
  };

  const onAllocationPercentageChange = (ageBucket) => (e) => {
    change(
      `${ageBucket}.allocation`,
      calculateAllocationByPercentage({
        percentage: e.target.value,
        capacity,
      })
    );
  };
  const onAllocationChange = (ageBucket) => (e) => {
    change(
      `${ageBucket}.allocationPercentage`,
      calculateAllocationPercentageByAllocation({
        allocation: e.target.value,
        capacity,
      })
    );
  };

  const splitEvenly = () => {
    const activeOptions = keys(values.ageBuckets);
    activeOptions.forEach((key) => {
      const percentage = Number((1 / activeOptions.length) * 100);
      const allocation = Number((capacity * percentage) / 100);
      change(`ageBuckets.${key}.allocation`, allocation.toFixed(0));
      change(`ageBuckets.${key}.allocationPercentage`, percentage.toFixed(2));
    });
  };

  return (
    <table className={styles.table}>
      <thead>
        <tr>
          <th>#</th>
          <th>Age</th>
          <th>Allocation</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {fields.map((ageBucket, index) => (
          <tr key={index}>
            <td className={styles.index}>{index + 1}</td>
            <td className={styles.left}>
              <div className="d-flex">
                <Field
                  name={`${ageBucket}.ageFrom`}
                  type="text"
                  component={SurveyBucketField}
                  parse={parseNumber}
                />
                -
                <Field
                  name={`${ageBucket}.ageTo`}
                  parse={parseNumber}
                  type="text"
                  component={SurveyBucketField}
                />
              </div>
            </td>
            <td className={styles.right}>
              <div className="d-flex">
                <Field
                  name={`${ageBucket}.allocation`}
                  type="text"
                  component={SurveyBucketField}
                  parse={parseNumber}
                  onChange={onAllocationChange(ageBucket)}
                />
                (
                <Field
                  parse={parseNumber}
                  name={`${ageBucket}.allocationPercentage`}
                  type="text"
                  component={SurveyBucketField}
                  onChange={onAllocationPercentageChange(ageBucket)}
                />
                %)
              </div>
            </td>
            <td>
              <Button
                buttonType="button"
                title="Remove Member"
                onClick={() => fields.remove(index)}
                ultraSmall
              >
                Remove
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan={4} className={styles.addBucket}>
            <Button buttonType="button" small onClick={splitEvenly}>
              Split evenly
            </Button>
            <Button buttonType="button" small onClick={() => fields.push({})}>
              Add age bucket +
            </Button>
          </td>
        </tr>
        <tr>
          <td colSpan={3}>
            Allocated {totalAllocation('allocation')}/{capacity} (
            {totalAllocation('allocationPercentage')} / 100%)
          </td>
        </tr>
      </tfoot>
    </table>
  );
};

export default AgeBuckets;
