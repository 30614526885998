import React, { PureComponent } from 'react';
import classnames from 'classnames';
import { Info } from '../Icons';
import styles from './styles.module.scss';

interface FieldLabelProps {
  children?: React.ReactNode;
  tooltip?: string;
  labelLeft?: boolean;
  className?: string;
  isRequired?: boolean;
  htmlFor?: string;
  noMargin?: boolean;
}

class FieldLabel extends PureComponent<FieldLabelProps> {
  render() {
    const { children, tooltip, labelLeft, className, isRequired, htmlFor, noMargin } = this.props;

    const labelClasses = classnames(styles.FieldLabel, className, {
      [styles.FieldLabelLeft]: labelLeft,
      [styles.FieldLabelNoMargin]: noMargin,
    });
    return (
      <label className={labelClasses} htmlFor={htmlFor}>
        {children}
        {isRequired && '*'}
        {tooltip ? (
          <div className={styles.FieldLabelTooltip}>
            <Info />
            <div className={styles.FieldLabelTooltipContent}>{tooltip}</div>
          </div>
        ) : null}
      </label>
    );
  }
}

export default FieldLabel;
