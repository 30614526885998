import React from 'react'
import { useHistory } from 'react-router-dom'
import { useCharityManagement, CharityResponse } from 'modules/charity'
import { Page, PageHeader, Button, DataTable } from 'components'

import { routes } from 'shared/constants/routes'

const COLUMNS = [
    {
        field: 'id',
        headerName: '#',
    },
    {
        field: 'name',
        headerName: 'Name',
    },
    {
        field: 'status',
        headerName: 'Status',
    },
    {
        field: 'dateFrom',
        headerName: 'From',
    },
    {
        field: 'dateTo',
        headerName: 'To',
    },
    {
        field: 'pointsNeeded',
        headerName: 'Points needed',
    },
]

export const CharitiesList = () => {
    const {
        location: { search },
        push,
    } = useHistory()
    const { fetchCharities } = useCharityManagement()

    const handleRowClick = (id: string | number) => {
        push({
            pathname: routes.charities.edit(id).path,
            search,
        })
    }
    const handleCta = () => {
        push({
            pathname: routes.charities.create.path,
            search,
        })
    }

    return (
        <Page
            header={
                <PageHeader title="Charities" cta={<Button onClick={handleCta}>Add new</Button>} />
            }>
            <DataTable<CharityResponse>
                columns={COLUMNS}
                onRowClick={handleRowClick}
                requestHandler={fetchCharities}
            />
        </Page>
    )
}
