import React from 'react';
import classnames from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import { isRouteActive } from 'shared/services/routes';
import { NavContext } from 'shared/context/nav';
import styles from './styles.module.scss';
import * as types from '../../types/NavigationSubItemTypes';

const NavigationSubItem = ({ children, to, exactActive, onClick }: types.NavigationSubItemProps) => {
  const { search } = useLocation();

  return (
    <NavContext.Consumer>
      {({ activeRoute }) => {
        const classes = classnames(styles.NavigationSubItem, {
          [styles.NavigationSubItemActive]: isRouteActive(activeRoute, to, exactActive),
        });
        return (
          <Link className={classes} to={{ pathname: to, search }} onClick={onClick}>
            {children}
          </Link>
        );
      }}
    </NavContext.Consumer>
  );
};

export default NavigationSubItem;
