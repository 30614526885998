import { makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((t: Theme) => ({
    container: {
        marginTop: '10px',
        display: 'flex',
        userSelect: 'none',
        boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 4px -1px',
        borderRadius: '2px',
        cursor: 'pointer',
        backgroundColor: 'rgb(255, 255, 255)',
        width: '81px',
        height: '40px',
        zIndex: 100,
    },
    button: {
        background: 'rgba(0, 0, 0, 0) none repeat scroll 0% 0%',
        display: 'block',
        border: '0px none',
        margin: '0px',
        padding: '0px',
        textTransform: 'none',
        appearance: 'none',
        position: 'relative',
        cursor: 'pointer',
        userSelect: 'none',
        overflow: 'hidden',
        width: '40px',
        height: '40px',
        top: '0px',
        left: '0px',
        outline: 'currentcolor none medium',
        color: t.palette.grey[500],
    },
    active: {
        color: t.palette.primary.main,
    },
    divider: {
        position: 'relative',
        overflow: 'hidden',
        width: '1px',
        height: '30px',
        margin: '5px 0px',
        backgroundColor: 'rgb(230, 230, 230)',
    },
}))
