import React from 'react'
import MuiTableHead from '@material-ui/core/TableHead'
import MuiTableRow from '@material-ui/core/TableRow'
import MuiTableCell from '@material-ui/core/TableCell'
import MuiTableSortLabel from '@material-ui/core/TableSortLabel'
import { SortDirection } from 'services'
import { ColumnResizer } from './ColumnResizer'

export interface Column {
    field: string
    headerName: string
    valueGetter?: (param: unknown) => React.ReactNode
}

interface DataTableHeadProps {
    columns: Column[]
    onSortModelChange: (field: string) => void
    orderBy?: string
    sortingOrder?: SortDirection
}

export const DataTableHead = ({
    columns,
    orderBy,
    sortingOrder,
    onSortModelChange,
}: DataTableHeadProps) => {
    const createSortHandler = (field: string) => () => {
        onSortModelChange(field)
    }

    return (
        <MuiTableHead>
            <MuiTableRow>
                {columns.map((column, index) => (
                    <>
                        {index > 0 && <ColumnResizer />}
                        <MuiTableCell key={column.field}>
                            <MuiTableSortLabel
                                active={orderBy === column.field}
                                direction={orderBy === column.field ? sortingOrder : 'asc'}
                                onClick={createSortHandler(column.field)}>
                                {column.headerName}
                            </MuiTableSortLabel>
                        </MuiTableCell>
                    </>
                ))}
            </MuiTableRow>
        </MuiTableHead>
    )
}
