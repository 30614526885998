import React from 'react'
import { useFormContext, useFieldArray } from 'react-hook-form'

interface FieldArrayProps<TFieldArrayValues> {
    name: string
    children: (field: TFieldArrayValues, index: number) => React.ReactNode
}

export const FieldArray = <TFieldArrayValues,>({ name, children }: FieldArrayProps<TFieldArrayValues>) => {
    const { control } = useFormContext()
    const { fields } = useFieldArray({ control, name })

    return (
        <>
            {fields.map(children)}
        </>
    )
}
