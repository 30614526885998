import { isPlainObject } from 'lodash';
import { store } from '../../../../store';
import { isUrlValid, isNumeric } from 'shared/services/validator';
import * as types from '../../types/LotteryManageFormTypes';
import { LANGUAGE_TRANSLATION } from 'shared/constants/lang';

const requiredFields = [
  'name',
  'imageUrl',
  'description',
  'pointsNeeded',
  'dateFrom',
  'dateTo',
  'drawDate',
  'numberOfPlaces',
  'status',
  'minimumParticipants',
  'pushNoteTitle',
];

export default (values: types.LotteryManageFormValidation) => {
  const errors: any = {};
  const { app: { country } } = store.getState();
  requiredFields.forEach((field) => {
    const value = values[field];
    if (!value) {
      errors[field] = 'Fill in';
    }
    if (isPlainObject(value) && !value[LANGUAGE_TRANSLATION[country]]) {
      errors[field] = `Fill in ${LANGUAGE_TRANSLATION[country]} translation`;
    }
  });
  if (!isNumeric(values.numberOfPlaces)) {
    errors['numberOfPlaces'] = 'Must be number';
  }
  if (!isNumeric(values.pointsNeeded)) {
    errors['pointsNeeded'] = 'Must be number';
  }
  if (!isNumeric(values.minimumParticipants)) {
    errors['minimumParticipants'] = 'Must be number';
  }
  if (!isUrlValid(values.imageUrl)) {
    errors['imageUrl'] = 'Must be url';
  }
  return errors;
};
