import * as yup from 'yup'

const string = yup.string().transform((cv, ov) => (ov === '' ? undefined : cv)).nullable().notRequired()

export const schema = yup.object({
    questions: yup.array().of(yup.object({
        creativeLink: string.url(),
        creativeName: string,
        label: string,
        medium: string,
    }))
})
