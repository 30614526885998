import React from 'react';
import cn from 'classnames';
import ReactColumnResizer from 'react-column-resizer';
import { useStyles } from './DataTable.styles';

interface ColumnResizerProps {
  disabled?: boolean;
}

export const ColumnResizer = ({ disabled }: ColumnResizerProps) => {
  const { columnResizer, columnResizerActive } = useStyles();
  const className = cn(columnResizer, {
    [columnResizerActive]: !disabled,
  });

  return <ReactColumnResizer minWidth={2} className={className} disabled={disabled} />;
};
