import React from 'react';
import { useFormContext } from 'react-hook-form';
import MomentUtils from '@date-io/moment';
import 'moment/locale/en-gb';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { FieldWrapper } from 'components';

interface DateFieldProps {
  defaultValue?: Date | string | null;
  disabled?: boolean;
  label?: string;
  name: string;
  placeholder?: string;
  required?: boolean;
  tooltip?: string;
}

export const DateField = ({
  defaultValue = null,
  disabled,
  label,
  name,
  placeholder = 'DD-MM-YYYY',
  required,
  tooltip,
}: DateFieldProps) => {
  const { trigger } = useFormContext();
  const handleChange =
    onChange =>
    (...args: any[]) => {
      let date = new Date(args[0]);
      if (isNaN(date.getTime())) {
        console.error('Invalid date value');
        return;
      }
      // Adjust the date to the user's local time zone
      date = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
      onChange(date);
      trigger(name);
    };

  return (
    <MuiPickersUtilsProvider utils={MomentUtils} locale="en-GB">
      <FieldWrapper
        defaultValue={defaultValue}
        disabled={disabled}
        label={label}
        name={name}
        required={required}
        tooltip={tooltip}
      >
        {({ ref, value, onChange, ...field }, { invalid }) => (
          <KeyboardDatePicker
            autoOk
            format="DD-MM-YYYY"
            maxDateMessage=""
            minDateMessage=""
            invalidDateMessage=""
            id={name}
            placeholder={placeholder}
            InputProps={{
              error: invalid,
              inputRef: ref,
              ...field,
            }}
            inputVariant="outlined"
            onChange={handleChange(onChange)}
            value={value}
            variant="inline"
          />
        )}
      </FieldWrapper>
    </MuiPickersUtilsProvider>
  );
};
