import React, { PureComponent } from 'react';
import classnames from 'classnames';
import get from 'lodash/get';
import find from 'lodash/find';
import TextField from '../TextField';
import FieldOptions from '../FieldOptions';
import styles from './styles.module.scss';

interface SelectFieldProps {
  label?: React.ReactNode;
  id?: string;
  dataSource: {
    value: number | React.ReactNode;
    label: React.ReactNode;
  }[];
  name?: string;
  input?: {
    value: number;
    onChange: (...args: any[]) => void;
    name?: string;
  };
  meta?: any; //empty object?
  disabled?: boolean;
}

interface SelectFieldState {
  isOpen?: boolean;
}

class SelectField extends PureComponent<SelectFieldProps, SelectFieldState> {
  wrapper = null;
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (this.wrapper && !this.wrapper.contains(event.target) && this.state.isOpen) {
      this.onClose();
    }
  };

  onClick = () => {
    if (this.props.disabled) {
      return;
    }
    this.setState({ isOpen: !this.state.isOpen });
  };

  onClose = () => {
    if (this.props.disabled) {
      return;
    }

    this.setState({ isOpen: false });
  };

  onSelect = (value) => () => {
    this.onClose();
    this.props.input.onChange(value);
  };

  render() {
    const { dataSource, ...props } = this.props;
    const classes = classnames(styles.SelectField, {
      [styles['SelectField--disabled']]: props.disabled,
    });

    const value =
      get(find(dataSource, { value: props.input.value }), 'label') || props.input.value || '';
    const input = { ...props.input, value };

    return (
      <div
        className={classes}
        ref={(n) => {
          this.wrapper = n;
        }}
        onClick={this.onClick}
      >
        <TextField {...props} input={input} icon="arrow_down" hiddenDisabled />
        {this.state.isOpen && (
          <FieldOptions
            dataSource={dataSource}
            isOpen={this.state.isOpen}
            onSelect={this.onSelect}
            name={input.name}
            currentValue={props.input.value}
          />
        )}
      </div>
    );
  }
}

export default SelectField;
