import React, { useContext } from 'react'
import MuiBox from '@material-ui/core/Box'
import MuiTypography from '@material-ui/core/Typography'
import { AddLocationsForm, AddLocationFormData } from 'components/forms'
import { localDateToUtc } from 'shared/services/date'
import { useLocationsManagement, useGooglePlaces } from 'modules/campaignM'
import { GMapContext } from './GMapProvider'

interface GMapSearchProps {
    campaignId: number
    setLocations: (data: any[]) => void
    locations: any[]
}

export const GMapSearch = ({ campaignId, setLocations, locations }: GMapSearchProps) => {
    const { map } = useContext(GMapContext)
    const { onSearch } = useGooglePlaces({ map })
    const { createLocations } = useLocationsManagement()

    const handleSubmit = async (values: AddLocationFormData) => {
        const { places, dateFrom, dateTo, ...rest } = values
        const data = places.map(place => ({
            ...place,
            ...rest,
            campaignId,
            dateFrom: localDateToUtc(dateFrom),
            dateTo: localDateToUtc(dateTo),
        }))
        const response = await createLocations(data)
        setLocations([...locations, ...response])
    }

    return (
        <>
            <MuiTypography variant="h3" gutterBottom>
                Google Places
            </MuiTypography>
            <AddLocationsForm onSearch={onSearch} onSubmit={handleSubmit} />
        </>
    )
}
