import React from 'react'
import { useDetails } from 'hooks'
import { useQuestionCategoryManagement, QuestionCategoryRequest } from 'modules/profile'
import { Page, PageHeader, QuestionCategoryForm, ContentLoader } from 'components'
import { routes } from 'shared/constants/routes'

export const QuestionCategory = () => {
    const {
        createQuestionCategory,
        fetchQuestionCategory,
        updateQuestionCategory,
    } = useQuestionCategoryManagement()
    const { data, onSave, requestStatus } = useDetails<QuestionCategoryRequest>({
        fetch: fetchQuestionCategory,
        create: createQuestionCategory,
        update: updateQuestionCategory,
    })

    return (
        <Page header={<PageHeader title="Manage category" />}>
            <ContentLoader status={requestStatus}>
                <QuestionCategoryForm defaultValues={data} onSubmit={onSave} />
            </ContentLoader>
        </Page>
    )
}
