import React from 'react'
import MuiBox from '@material-ui/core/Box'
import MuiGrid from '@material-ui/core/Grid'
import MuiPagination from '@material-ui/lab/Pagination'
import MuiSelect from '@material-ui/core/Select'
import MuiMenuItem from '@material-ui/core/MenuItem'

interface DataTablePaginationProps {
    onPageChange: (pageNumber: number) => void
    onPageSizeChange: (pageSize: number) => void
    pageNumber: number
    pageSize: number
    totalPages: number
}

// NOTE: backend uses 0 indexed pagination, but on frontend side we need to starting counting from 1
const normalizePageNumber = (pageNumber: number) => pageNumber + 1

export const DataTablePagination = ({
    onPageChange,
    onPageSizeChange,
    pageNumber,
    pageSize,
    totalPages,
}: DataTablePaginationProps) => {
    const handlePageChange = (_: React.ChangeEvent<unknown>, value: number) => {
        onPageChange(value - 1)
    }

    const handlePageSizeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const value = event.target.value as number
        onPageSizeChange(value)
    }

    return (
        <MuiBox mt={4}>
            <MuiGrid alignItems="center" justify="space-between" container>
                <MuiGrid item>
                    <MuiPagination
                        color="primary"
                        count={totalPages}
                        page={normalizePageNumber(pageNumber)}
                        shape="rounded"
                        onChange={handlePageChange}
                        variant="outlined"
                    />
                </MuiGrid>
                <MuiGrid item>
                    <MuiGrid alignItems="center" justify="flex-end" container>
                        <MuiGrid item>
                            <MuiBox mr={1}>Results per page</MuiBox>
                        </MuiGrid>
                        <MuiGrid item>
                            <MuiSelect onChange={handlePageSizeChange} value={pageSize}>
                                <MuiMenuItem value={15}>15</MuiMenuItem>
                                <MuiMenuItem value={30}>30</MuiMenuItem>
                                <MuiMenuItem value={50}>50</MuiMenuItem>
                            </MuiSelect>
                        </MuiGrid>
                    </MuiGrid>
                </MuiGrid>
            </MuiGrid>
        </MuiBox>
    )
}
