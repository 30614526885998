import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { reduxForm, Field, getFormValues } from 'redux-form';
import get from 'lodash/get';
import Button from 'shared/components/Button';
import ContentLoader from 'shared/components/ContentLoader';
import TextField from 'shared/components/TextField';
import TextFieldI18N from 'shared/components/TextFieldI18N';
import FormSection from 'shared/components/FormSection';
import HeaderTextField from 'shared/components/HeaderTextField';
import RadioField from 'shared/components/RadioField';
import SelectField from 'shared/components/SelectField';
import Switch from 'shared/components/Switch';
import { possibleTimeRange } from 'shared/services/date';
import { saveLottery, fetchLottery, resetToInit } from 'modules/Lottery/reducers/manage';
import formValidation from './formValidation';
import styles from './styles.module.scss';
import campaignStyles from '../../../Campaign/containers/Manage/styles.module.scss';
import CampaignHeader from 'shared/components/CampaignHeader';
import DateField from 'shared/components/DateField';
import * as types from '../../types/LotteryManageTypes';
const FORM_NAME = 'lottery/MANAGE';

class List extends PureComponent<types.LotteryManageProps> {
  componentDidMount() {
    const id = get(this.props, 'match.params.id');
    if (id) {
      this.props.fetchLottery({ id });
    }
  }
  componentDidUpdate(prevProps) {
    const id = get(this.props, 'match.params.id');
    const prevId = get(prevProps, 'match.params.id');
    if (id !== prevId) {
      this.props.fetchLottery({ id });
    }
  }
  componentWillUnmount() {
    this.props.reset();
    this.props.resetToInit();
  }

  submit = () => {
    const countryOfAvailability = this.props.selectedCountry;
    this.props.saveLottery({ ...this.props.formValues, countryOfAvailability });
  };
  render() {
    const { handleSubmit, savingStates, fetchingStates } = this.props;
    const lotteryId = get(this.props, 'match.params.id');
    const disabled = !!lotteryId;
    return (
      <ContentLoader states={fetchingStates} isNew={!lotteryId}>
        <form onSubmit={handleSubmit(this.submit)}>
          <CampaignHeader>
            <Field
              component={HeaderTextField}
              name="name"
              placeholder="Enter lottery name"
              className="CampaignHeader__input"
            />
            <div className={campaignStyles.CampaignHeaderRight}>
              <Button type="primary" isLoading={savingStates.isLoading}>
                Save
              </Button>
            </div>
          </CampaignHeader>
          <div className={styles.ManageLottery}>
            <FormSection isActive>
              <div className="row">
                <div className="col-md-7">
                  <Field
                    component={TextField}
                    name="imageUrl"
                    label="Image url"
                    className="CampaignHeader__input"
                    isRequired
                  />
                  <Field
                    component={TextFieldI18N}
                    name="description"
                    label="Description"
                    className="CampaignHeader__input"
                    multiLine
                  />
                    <Field
                        component={Switch}
                        label="Enable push notification"
                        readOnly={Boolean(get(this.props, 'match.params.id'))}
                        name="pushNoteEnabled"
                    />
                  <Field
                    component={TextFieldI18N}
                    name="pushNoteTitle"
                    label="Push notification and in-app title"
                    className="CampaignHeader__input"
                    isRequired
                    maxLength={65}
                  />
                  <Field
                    component={TextFieldI18N}
                    name="pushNoteBody"
                    label="Push note body"
                    className="CampaignHeader__input"
                    multiLine
                    maxLength={240}
                  />
                  <div className="row">
                    <div className="col-md-6 col-lg-6">
                      <Field
                        component={DateField}
                        name="pushNoteDate"
                        label="Push note date"
                        type="date"
                      />
                    </div>
                    <div className="col-md-6 col-lg-6">
                      <Field
                        component={TextField}
                        type="time"
                        name="pushNoteTime"
                        label="Push note time (hh:mm)"
                      />
                    </div>
                  </div>
                  <Field
                    component={TextField}
                    name="pointsNeeded"
                    label="Points needed"
                    tooltip="The minimum number of points a user needs to collect during the lottery period (date from > draw date) in order to compete."
                    className="CampaignHeader__input"
                    isRequired
                  />
                  <div className="row">
                    <div className="col-md-6">
                      <Field
                        component={DateField}
                        className="CampaignHeader__input"
                        name="drawDate"
                        label="Draw date"
                        disabled={disabled}
                        isRequired
                        type="date"
                      />
                    </div>
                    <div className="col-md-6">
                      <Field
                        component={TextField}
                        type="time"
                        name="drawTime"
                        disabled={disabled}
                        label="Draw date time"
                      />
                    </div>
                  </div>
                  <Field
                    component={TextField}
                    name="numberOfPlaces"
                    label="Number of places"
                    tooltip="The number of prize winners we want the lottery to have."
                    className="CampaignHeader__input"
                    isRequired
                  />
                  <Field
                    component={TextField}
                    name="minimumParticipants"
                    tooltip="The minimum of participants that meet the requirements to compete with the lottery, in order for the lottery to take place. When minimum is not reached yet, you should set a new draw date or change the minimum."
                    label="Minimum Participants"
                    isRequired
                  />
                  <Field
                    component={RadioField}
                    options={[
                      {
                        value: 'OPEN',
                        label: 'Open',
                      },
                      {
                        value: 'CLOSED',
                        label: 'Closed',
                      },
                    ]}
                    name="status"
                    label="Status"
                    isRequired
                  />
                  <Field
                    component={RadioField}
                    options={[
                      {
                        value: true,
                        label: 'Visible',
                      },
                      {
                        value: false,
                        label: 'Hidden',
                      },
                    ]}
                    name="visible"
                    label="Visible"
                    isRequired
                  />
                  <div className="row">
                    <div className="col-md-6">
                      <Field
                        component={DateField}
                        name="dateFrom"
                        tooltip="The start date for the lottery to be visible in the app. This is also the date from where we count the point requirements for lottery inclusion."
                        isRequired
                        label="Date From"
                        disabled={disabled}
                      />
                    </div>
                    <div className="col-md-6">
                      <Field
                        component={TextField}
                        type="time"
                        name="dateFromTime"
                        disabled={disabled}
                        label="Date from time"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </FormSection>
          </div>
        </form>
      </ContentLoader>
    );
  }
}

const mapStateToProps = (
  {
    lotteries: {
      manage: { save, lottery },
    },
    ...state
  },
  ownProps,
) => ({
  savingStates: save.states,
  fetchingStates: lottery.states,
  isLoading: lottery.states.isLoading,
  formValues: getFormValues(FORM_NAME)(state),
  initialValues: get(ownProps, 'match.params.id') ? lottery.data : { pushNoteEnabled: true },
  selectedCountry: state.app.country,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ saveLottery, fetchLottery, resetToInit }, dispatch);

export default compose<any>(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    validate: formValidation,
  }),
)(List);
