import React from 'react';
import classnames from 'classnames';
import styles from './styles.module.scss';

interface TypeScriptButtonProps {
  onClick?: () => void;
  children?: React.ReactNode;
  isLoading?: boolean;
  small?: boolean;
  ultraSmall?: boolean;
  icon?: 'plus' | 'paper' | 'csv' | 'filter' | 'trash' | 'upload';
  uppercase?: boolean;
  type?: 'red' | 'blue' | 'yellow' | 'white' | 'default' | 'primary';
  fullWidth?: boolean;
  noMargin?: boolean;
  buttonType?: 'submit' | 'button';
  className?: string;
  disabled?: boolean;
  title?: string;
  cancelButton?: boolean;
  style?: React.CSSProperties;
}

const Button = ({
  className,
  type = 'default',
  buttonType = 'submit',
  style,
  ...props
}: TypeScriptButtonProps) => {
  const buttonClasses = classnames(styles.Button, className, {
    [styles[type]]: type,
    [styles.ButtonUppercase]: props.uppercase,
    [styles.ButtonFullwidth]: props.fullWidth,
    [styles.ButtonNoMargin]: props.noMargin,
    [styles.ButtonSmall]: props.small,
    [styles.ButtonUltraSmall]: props.ultraSmall,
    [styles.CancelButton]: props.cancelButton,
    // [className]: className,
  });

  const loaderClasses = classnames({
    [styles.ButtonLoader]: true,
    [styles.ButtonLoaderActive]: props.isLoading,
    [styles.ButtonLoaderPrimary]: type === 'default',
  });

  return (
    <button
      className={buttonClasses}
      style={style}
      {...props}
      onClick={props.onClick}
      type={buttonType}
    >
      {props.icon && (
        <img
          src={require(`assets/icons/${props.icon}.svg`).default}
          alt="icon"
          className={styles.ButtonIcon}
        />
      )}

      {props.children}

      <div>
        <span className={loaderClasses} />
      </div>
    </button>
  );
};

export default Button;
