import React from 'react'
import MuiTooltip from '@material-ui/core/Tooltip'
import IconInfo from '@material-ui/icons/InfoOutlined'

interface TooltipProps {
    title: string
}

export const Tooltip = (props: TooltipProps) => (
    <MuiTooltip {...props}>
        <IconInfo />
    </MuiTooltip>
)
