import React, { useState } from 'react';
import { connect } from 'react-redux';
import InputMask from 'react-input-mask';
import cn from 'classnames';
import { Country, LanguageTranslation } from 'shared/types';
import FieldWrapper from '../FieldWrapper';
import styles from './styles.module.scss';
import { LANGUAGE_TRANSLATION } from 'shared/constants/lang';
import { useCountry } from 'hooks';

interface SelectItemProps {
  isFilled?: boolean;
  isSelected?: boolean;
  onSelect: (value: Country) => void;
  value: Country;
}

interface TextFieldI18NProps {
  input: any;
  isRequired?: boolean;
  maxLength?: number;
  multiLine: boolean;
  selectedCountry: Country;
}

// const LANGUAGES: Country[] = ['NL'];

const SelectItem = ({ value, isFilled, isSelected, onSelect }: SelectItemProps) => {
  const className = cn(styles['Selector__item'], {
    [styles['Selector__item--selected']]: isSelected,
    [styles['Selector__item--filled']]: isFilled,
  });

  return (
    <li className={className}>
      <button type="button" onClick={() => onSelect(value)}>
        {value}
      </button>
    </li>
  );
};

const TextFieldI18N = ({
  multiLine,
  selectedCountry,
  maxLength,
  ...props
}: TextFieldI18NProps) => {
  const { selectedCountryCode } = useCountry();
  const [translation, setTranslation] = useState<LanguageTranslation>(
    LANGUAGE_TRANSLATION[selectedCountryCode],
  );

  const Component = multiLine ? 'textarea' : InputMask;
  const fieldClassName = cn(styles.Field);
  // const sortedLanguages = LANGUAGES.sort((a, b) => {
  //   const selected = selectedCountry === 'BE_FL' ? 'NL' : selectedCountry;
  //   if (a === selected) {
  //     return -1;
  //   }
  //   if (b === selected) {
  //     return 1;
  //   }
  //   return 0;
  // });
  const value = props.input.value[translation] || '';

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { value } = e.target;
    props.input.onChange({
      ...props.input.value,
      [translation]: maxLength ? value.slice(0, maxLength) : value,
    });
  };

  return (
    <FieldWrapper {...props}>
      <Component className={fieldClassName} onChange={handleChange} value={value} />
      {/* <ul className={styles.Selector}>
        {sortedLanguages.map(c => (
          <SelectItem
            value={c}
            key={c}
            isFilled={Boolean(props.input.value[c])}
            isSelected={translation === c}
            onSelect={selectTranslation}
          />
        ))}
      </ul> */}
      {maxLength && (
        <span className={styles.MaxLengthIndicator}>
          {value.length} / {maxLength}
        </span>
      )}
    </FieldWrapper>
  );
};

const mapStateToProps = ({ app }) => ({
  selectedCountry: app.country,
});

export default connect(mapStateToProps)(TextFieldI18N);
