import React from 'react';
import { CSSTransition } from 'react-transition-group';
import Button from '../Button';
import styles from './styles.module.scss';

interface TypeScriptConfirmModal {
  cancelLabel?: React.ReactNode;
  confirmLabel?: React.ReactNode;
  title?: React.ReactNode;
  paragraph?: React.ReactNode;
  isLoading?: boolean;
  onClose?: (...args: any[]) => void;
  isOpen?: boolean;
  onConfirm?: (...args: any[]) => void;
}

const ConfirmModal = ({
  isOpen,
  onClose,
  title,
  paragraph,
  onConfirm,
  isLoading,
  confirmLabel,
  cancelLabel,
}: TypeScriptConfirmModal) => {
  return (
    <CSSTransition in={isOpen} timeout={300} classNames="confirm-modal-anim" unmountOnExit>
      <div className={styles.ConfirmModal}>
        <button onClick={onClose} className={styles.ConfirmModalClose}>
          <i className="fa fa-close" />
        </button>
        <div className={styles.ConfirmModalWrapper}>
          <div className={styles.ConfirmModalContent}>
            <div className={styles.ConfirmModalTitle}>{title}</div>
            <div className={styles.ConfirmModalParagraph}>{paragraph}</div>
            <div className={styles.ConfirmModalButtons}>
              <Button onClick={onConfirm} type="red" isLoading={isLoading}>
                {confirmLabel}
              </Button>

              <Button onClick={onClose} type="white">
                {cancelLabel}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};

export default ConfirmModal;
