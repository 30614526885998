import React from 'react';
import classnames from 'classnames';
import styles from './styles.module.scss';

interface RotateLoader {
  relative?: boolean;
  bg?: boolean;
}

export default ({ relative, bg }: RotateLoader) => {
  const classes = classnames(styles.RotateLoader, {
    [styles.RotateLoaderRelative]: relative,
  });

  if (bg) {
    return (
      <div className={styles.RotateLoaderWrapper}>
        <div className={styles.RotateLoaderBg} />
        <div className={classes} />
      </div>
    );
  }
  return <div className={classes} />;
};
