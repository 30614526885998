import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(() => ({
    small: {
        '& input': {
            paddingTop: '10.5px',
            paddingBottom: '10.5px',
        },
        '& .MuiOutlinedInput-adornedEnd': {
            paddingRight: 0,
        },
        '& .MuiInputAdornment-positionEnd': {
            marginLeft: 0,
        },
    },
}))
