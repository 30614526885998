import shared from 'shared/sagas';
import campaigns from 'modules/Campaign/sagas';
import auth from 'modules/Auth/sagas';
import surveys from 'modules/Survey/sagas';
import completionSurveys from 'modules/CompletionSurveys/sagas';
import lotteries from 'modules/Lottery/sagas';
import discussions from 'modules/Discussion/sagas';

export default [
  ...shared,
  ...campaigns,
  ...auth,
  ...surveys,
  ...discussions,
  ...completionSurveys,
  ...lotteries,
];
