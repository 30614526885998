import AWS from 'aws-sdk';
import { buffers, eventChannel, END } from 'redux-saga';
import get from 'lodash/get';
import { saveFile } from 'shared/utils/file';

const config = new AWS.Config({
  region: process.env.REACT_APP_AWS_REGION,
  accessKeyId: process.env.REACT_APP_AWS_PUBLIC_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
});

const s3Files = new AWS.S3(config);

export const isImage = (url) => {
  return url.match(/\.(jpeg|jpg|gif|png)$/) !== null;
};
export const isVideo = (url) => {
  return url.match(/\.(mp4|mov|avi)$/) !== null;
};
export const isAudio = (url) => {
  return url.match(/\.(mp3|mp3a)$/) !== null;
};
export const getSignedUrl = (params) => {
  if (!params.Key || params.Key.length < 1) {
    return '';
  }
  const url = s3Files.getSignedUrl('getObject', params);
  return url;
};
export const downloadS3File = (params) => {
  if (!params.Key || params.Key.length < 1) {
    return '';
  }
  s3Files.getObject(params, (err, data) => {
    if (err || !data.Body) {
      console.log('error');
      return;
    }
    saveFile(data.Body, params.Key.split(/[\\/]/).pop(), data.ContentType);
  });
};

export const getUrlWithoutHost = (originalUrl) => {
  if (!originalUrl) {
    return '';
  }
  const url = originalUrl
    .replace(`${process.env.REACT_APP_AWS_DISCUSSION_BUCKET_URL}/`, '')
    .replace(`${process.env.REACT_APP_AWS_DISCUSSION_UPLOAD_BUCKET_URL}/`, '');
  return url;
};

export const fetchHeadObjectS3 = async (file) => {
  const params = {
    Bucket: process.env.REACT_APP_AWS_BUCKET,
    Key: file.Key,
  };
  const data = await s3Files.headObject(params).promise();
  const result = {
    ...file,
    realFileName: get(data, 'Metadata.x-acr-filename'),
    acrFilter: get(data, 'Metadata.x-acr-filter'),
  };
  return result;
};

export const fetchCampaignFilesFromS3 = async (campaignName) => {
  const result = [];
  const params = {
    Bucket: process.env.REACT_APP_AWS_BUCKET,
    Delimiter: '',
    Prefix: `${campaignName}/`,
  };

  const data = await s3Files.listObjects(params).promise();
  let contents = data.Contents;
  for (let i = 0; i < contents.length; i++) {
    let element = contents[i];

    const fileObject = await fetchHeadObjectS3(element);
    result.push(fileObject);
  }

  return result;
};

const calculateProgress = (emitter) => (progress) => {
  const progressCalculated = Math.round((progress.loaded / progress.total) * 100);
  emitter({ progress: progressCalculated });
  return progressCalculated;
};
export const uploadFileToS3 = (config) => {
  return eventChannel((emitter) => {
    s3Files
      .upload(config, (err, data) => {
        if (err) {
          emitter({ error: new Error('Upload failed') });
          emitter(END);
          return;
        }

        emitter({ success: true, data });
        emitter(END);
        return;
      })
      .on('httpUploadProgress', calculateProgress(emitter));
    return () => {}; // eslint-disable-line
  }, buffers.sliding(2));
};
