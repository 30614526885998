import React from 'react'
import MuiBox from '@material-ui/core/Box'
import MuiGrid from '@material-ui/core/Grid'
import { Button, TextHeaderField } from 'components'

interface FormHeaderProps {
    field: ReturnType<typeof TextHeaderField>
    cta?: ReturnType<typeof Button>
}

export const FormHeader = ({ field, cta }: FormHeaderProps) => (
    <MuiBox
        bgcolor="#fff"
        boxShadow="0 0 3px gray"
        p="30px 60px"
        position="sticky"
        top={50}
        zIndex={1}>
        <MuiGrid container alignItems="center" spacing={3}>
            <MuiGrid item xs>
                {field}
            </MuiGrid>
            {cta && <MuiGrid item>{cta}</MuiGrid>}
        </MuiGrid>
    </MuiBox>
)
