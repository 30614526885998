import React, { PureComponent } from 'react';
import classnames from 'classnames';
import InputMask from 'react-input-mask';
import FieldWrapper from '../FieldWrapper';
import styles from './styles.module.scss';

interface TextFieldProps {
  meta?: {
    error: string;
    touched: boolean;
  };
  onClick?: (...args: any[]) => void;
  input?: any;
  disabled?: boolean;
  hiddenDisabled?: boolean;
  icon?: boolean | React.ReactNode;
  id?: string;
  isRequired?: boolean;
  isLoading?: boolean;
  small?: boolean;
  multiLine?: any;
  maxLength?: number;
  autoComplete?: string;
  tooltip?: string;
}

class TextField extends PureComponent<TextFieldProps> {
  ref;
  onClick = e => {
    if (this.props.onClick) {
      this.props.onClick(e);
    }
  };
  setRef = ref => (this.ref = ref);
  render() {
    const {
      input,
      disabled,
      icon,
      isRequired,
      hiddenDisabled,
      isLoading,
      multiLine,
      maxLength,
      small,
      meta,
      tooltip,
      ...props
    } = this.props;
    // NIE MA IS REQUIRED
    const inputClasses = classnames(styles.TextFieldInput, {
      [styles.TextFieldInputDisabled]: disabled && !hiddenDisabled,
      [styles.TextFieldInputIcon]: icon,
      'TextField__input--required': isRequired,
      [styles.TextFieldInputSmall]: small,
    });
    const Component = multiLine ? 'textarea' : InputMask;
    if (multiLine && this.ref) {
      this.ref.style.height = 'auto';
      this.ref.style.height = this.ref.scrollHeight + 'px';
    }

    const charactersClasses = classnames(styles.TextFieldCharacters, {
      [styles.TextFieldCharactersError]: input.value.length > maxLength,
    });

    return (
      
      <FieldWrapper {...this.props} onClick={this.onClick} isLoading={isLoading} tooltip={tooltip} isRequired={isRequired} meta={meta}>
        <Component
          {...input}
          value={input.value}
          id={input.name}
          {...props}
          className={inputClasses}
          disabled={disabled}
          ref={this.setRef}
        />
        {maxLength && (
          <span className={charactersClasses}>
            {input.value.length} / {maxLength}
          </span>
        )}
      </FieldWrapper>
    );
  }
}

export default TextField;
