import React, { useContext, useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'
import cn from 'classnames'
import AddLocationIcon from '@material-ui/icons/AddLocation'
import PanToolIcon from '@material-ui/icons/PanTool'
import { GMapContext } from './GMapProvider'
import { useStyles } from './Controls.styles'

export type SelectionType = 'MARKER' | 'HAND'

interface ControlsProps {
    onSelect: (type: SelectionType) => void
    selectionType: SelectionType
}
export const Controls = ({ onSelect, selectionType }: ControlsProps) => {
    const { map } = useContext(GMapContext)
    const containerElement = useRef<HTMLDivElement>(document.createElement('div'))
    const styles = useStyles()

    const handleClick = (type: SelectionType) => () => {
        onSelect(type)
    }

    useEffect(() => {
        if (map) {
            map.controls[google.maps.ControlPosition.TOP_CENTER].push(containerElement.current)
        }
    }, [map])

    const buttonClassName = (type: SelectionType) => cn(styles.button, {
        [styles.active]: type === selectionType,
    })

    return createPortal((
            <div className={styles.container}>
                <button className={buttonClassName('MARKER')} type="button" onClick={handleClick('MARKER')}>
                    <AddLocationIcon />
                </button>
                <div className={styles.divider} />
                <button className={buttonClassName('HAND')} type="button" onClick={handleClick('HAND')}>
                    <PanToolIcon />
                </button>
            </div>
        ), containerElement.current)
}
