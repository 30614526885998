import React from 'react';
import styles from './styles.module.scss';

interface SimpleProgessBarTypeScript {
  current: number;
  max: number;
}

export default ({ current = 0, max }: SimpleProgessBarTypeScript) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.bar}>
        <span className={styles.fill} style={{ width: `${(current / max) * 100}%` }}></span>
      </div>
      {current} / {max}
    </div>
  );
};
