import React, { useContext, useEffect, useRef, useState, useCallback } from 'react';
import { createPortal } from 'react-dom';
import { DefaultRootState, useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import MuiBox from '@material-ui/core/Box';
import { Country, WithId } from 'shared/types';
import { LocationDetailsForm } from 'components';
import { useLocationsManagement, LocationResponse } from 'modules/campaignM';
import { utcDateToLocal } from 'shared/services/date';
import { GMapContext } from './GMapProvider';
import { Marker } from './Marker';
import { Circle } from './Circle';
import { InfoBox } from './InfoBox';
import { Controls, SelectionType } from './Controls';
import { CENTER } from './constants';

interface GMapProps {
  locations: LocationResponse[];
  onLocationUpdate: (location: LocationResponse) => void;
  onLocationAdd: (location: LocationResponse) => void;
}

interface RootState extends DefaultRootState {
  app: {
    country: Country;
    // other properties in the 'app' slice of state
  };
}

export const GMap = ({ locations, onLocationUpdate, onLocationAdd }: GMapProps) => {
  const { setMap, map } = useContext(GMapContext);
  const [selectedLocation, selectLocation] = useState<WithId<LocationResponse>>();
  const [selectedMarker, selectMarker] = useState<google.maps.MVCObject | null>(null);
  const [newLocation, setNewLocation] = useState<Pick<
    LocationResponse,
    'latitude' | 'longitude' | 'radius'
  > | null>(null);
  const mapRef = useRef<HTMLDivElement>();
  const { createLocation, updateLocations } = useLocationsManagement();
  const country: Country = useSelector(({ app }: RootState) => app.country);
  const [selectionType, setSelectionType] = useState<SelectionType>('HAND');
  const mapClickListener = useRef<google.maps.MapsEventListener>();

  const handleLocationEdit = async (
    id: number | string,
    data: Partial<LocationResponse>,
  ) => {
    const location = locations.find(l => id === l.id);
    const newData = { ...location, ...data };
    if (location && !isEqual(location, newData)) {
      const [result] = await updateLocations([newData]);
      onLocationUpdate(result);
      selectLocation(newData);
    }
  };

  const handleLocationClick = (id: string | number) => {
    setNewLocation(null);
    const location = locations.find(l => id === l.id);
    if (location) {
      selectLocation({
        ...location,
        dateFrom: utcDateToLocal(location.dateFrom),
        dateTo: utcDateToLocal(location.dateTo),
      });
    }
  };

  const handleLocationDetailsSubmit = useCallback(
    async values => {
      if (selectedLocation) {
        const [result] = await updateLocations([
          {
            id: selectedLocation.id,
            campaignId: selectedLocation.campaignId,
            ...values,
          },
        ]);
        onLocationUpdate(result);
      }
    },
    [selectedLocation],
  );

  const handleCreateLocation = async values => {
    const result = await createLocation(values);
    setNewLocation(null);
    onLocationAdd(result);
  };

  const updateNewLocation = (data: Partial<LocationResponse>) => {
    setNewLocation({ ...newLocation, ...data });
  };

  const handleSelectMapAction = (type: SelectionType) => {
    setSelectionType(type);
  };

  useEffect(() => {
    const m = new google.maps.Map(mapRef.current, {
      center: CENTER[country],
      zoom: 6,
      maxZoom: 18,
    });

    setMap(m);
  }, []);

  useEffect(() => {
    if (map) {
      mapClickListener.current = map.addListener('click', event => {
        if (selectionType === 'MARKER') {
          selectMarker(null);
          selectLocation(null);
          const newLocationData = {
            latitude: event.latLng.lat(),
            longitude: event.latLng.lng(),
            radius: 100,
          };
          setNewLocation(newLocationData);
        }
      });
    }

    return () => {
      google.maps.event.removeListener(mapClickListener.current);
    };
  }, [selectionType]);

  return (
    <>
      <MuiBox position="relative" height="calc(100vh - 190px)">
        <MuiBox
          {...({ ref: mapRef } as any)}
          position="absolute"
          top={0}
          right={0}
          bottom={0}
          left={0}
        >
          <Controls selectionType={selectionType} onSelect={handleSelectMapAction} />
          {locations.map(({ latitude, longitude, radius, id }) => {
            const position = {
              lat: latitude,
              lng: longitude,
            };
            return (
              <Marker
                key={id}
                onClick={marker => {
                  handleLocationClick(id);
                  selectMarker(marker);
                }}
                position={position}
              >
                {anchor => (
                  <Circle
                    anchor={anchor}
                    editable={selectedLocation && selectedLocation.id === id}
                    radius={radius}
                    onClick={() => {
                      handleLocationClick(id);
                    }}
                    onEdit={circle => {
                      handleLocationEdit(id, circle);
                    }}
                  />
                )}
              </Marker>
            );
          })}
          {newLocation && (
            <Marker position={{ lat: newLocation.latitude, lng: newLocation.longitude }}>
              {anchor => (
                <>
                  <Circle
                    anchor={anchor}
                    editable={true}
                    onEdit={circle => updateNewLocation(circle)}
                    radius={newLocation.radius}
                  />
                  <InfoBox anchor={anchor} onClose={() => setNewLocation(null)}>
                    <LocationDetailsForm
                      defaultValues={newLocation}
                      onCancel={() => {
                        setNewLocation(null);
                      }}
                      onSubmit={handleCreateLocation}
                    />
                  </InfoBox>
                </>
              )}
            </Marker>
          )}
          {selectedMarker && selectedLocation && (
            <InfoBox anchor={selectedMarker} onClose={() => selectMarker(null)}>
              <LocationDetailsForm
                defaultValues={selectedLocation}
                onCancel={() => {
                  selectMarker(null);
                }}
                onSubmit={handleLocationDetailsSubmit}
              />
            </InfoBox>
          )}
        </MuiBox>
      </MuiBox>
    </>
  );
};
