/* eslint no-console: 0 */

export default class Storage {
  key: string;

  constructor(key: string) {
    this.key = key;
  }

  load<T>(): T | null {
    const defaultValue = null;

    try {
      const value = window.localStorage.getItem(this.key);

      if (value) {
        try {
          return JSON.parse(value);
        } catch (e) {
          console.warn('This value is not JSON.parse-able', e);
          return defaultValue;
        }
      }

      return defaultValue;
    } catch (e) {
      console.warn('Error getting access to storage. Are cookies blocked?', e);
      return defaultValue;
    }
  }

  save(value: unknown): void | null {
    try {
      window.localStorage.setItem(this.key, JSON.stringify(value));
    } catch (e) {
      console.warn('Error getting access to storage. Are cookies blocked?', e);
      return null;
    }
  }

  clear() {
    this.save(null);
  }
}
