import { Query } from './types'

const camelToSnakeCase = (str: string) =>
    str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)

export const normalizeQuery = (query?: Query) => {
    const sort = query.sort && `${camelToSnakeCase(query.sort.field)},${query.sort.sort}`

    return {
        ...query,
        sort,
    }
}
