import apiService from 'shared/services/api'
import { normalizeQuery, Query } from 'services'
import { UserRequest, UsersResponse, CreateUserRequest } from './types'
import { WithId } from 'shared/types'
import { localDateToUtc } from 'shared/services/date';

const normalizeRequest = (data: UserRequest): UserRequest => ({
    ...data
})

const normalizeResponse = (data: UsersResponse): UsersResponse => ({
    ...data,
    orderDate: localDateToUtc(data.orderDate),
})

export const getUsers = async (query?: Query) => {
    const { data } = await apiService({}).get('user-details', {
        params: normalizeQuery(query),
    })
    return data
}

export const getUser = async (id: string) => {
    const { data } = await apiService({}).get(`/user-details/${id}`)

    return normalizeResponse(data)
}

export const getUserGiftCard = async (id: string) => {
    const { data } = await apiService({}).get(`/user-details/${id}`)

    return normalizeResponse(data)
}

export const putUser = async ({ id, ...data }: WithId<UserRequest>) => {
    return await apiService({}).put(`/user-details/${id}`, normalizeRequest(data))
}

export const addPointsToUser = async (pointsToAdd, userId) => {
    const payload = {
        points: pointsToAdd,
        id: userId
    };

    const apiWithoutCountryParam = apiService({
        baseUrl: '',
        headers: {},
        includeCountryParam: false
    });

    try {
        const response = await apiWithoutCountryParam.post(`/points/add`, payload);
        console.log('Points successfully added:', response);
        return normalizeResponse(response.data);
    } catch (error) {
        if (error.response && error.response.status === 204) {
            console.log('Points successfully added:', error.response);
            return { success: true, status: 204 };
        } else {
            console.error('Failed to add points:', error);
            throw error;
        }
    }
};

export const createUser = async (data: CreateUserRequest) => {
    const apiWithoutCountryParam = apiService({
        baseUrl: '',
        headers: {},
        includeCountryParam: false
    });
  
    try {
        const response = await apiWithoutCountryParam.post('/user-details/add', data);
        return response.data;
    } catch (error) {
        console.error('Error creating user:', error);
        throw error;
    }
  };
