import { ReactComponent as ArrowRight } from 'assets/icons/arrow-right.svg';
import classnames from 'classnames';
import { Field } from 'redux-form';
import map from 'lodash/map';
import SelectField from 'shared/components/SelectField';
import styles from './styles.module.scss';

interface PaginationProps {
  pages: number;
  perPage?: number;
  onPerPageChange?: (...args: any[]) => void;
  visibleOffset?: number;
  currentPage: number;
  offset?: number;
  onPageChange?: (...args: any[]) => void;
}

const Pagination = ({
  pages,
  currentPage,
  offset = 3,
  onPageChange,
  visibleOffset = 0,
  onPerPageChange,
  perPage,
}: PaginationProps) => {
  const pageArray = [];
  for (let i = 0; i <= pages; i++) {
    const isLastPage = pages === i;
    const isFirstPage = i === 0;
    if (i < currentPage - offset && !isFirstPage && !isLastPage) {
      if (i === 1) {
        pageArray.push({ label: '...' });
      }
      continue;
    }
    if (i > currentPage + offset && !isFirstPage && !isLastPage) {
      if (i + 1 === pages) {
        pageArray.push({ label: '...' });
      }
      continue;
    }
    pageArray.push({ page: i, label: i + visibleOffset });
  }
  const pageChange = (page) => () => onPageChange(page);
  return (
    <div className={styles.Pagination}>
      {currentPage > 1 && (
        <button className={styles.PaginationPrevious} onClick={pageChange(currentPage - 1)}>
          <ArrowRight />
        </button>
      )}
      {map(pageArray, (page: any, i) => (
        <button
          className={classnames(styles.PaginationPage, {
            [styles.PaginationPageActive]: currentPage === page.page,
            [styles.PaginationPageDisabled]: page.page === undefined,
          })}
          onClick={page.page !== undefined ? pageChange(page.page) : null}
          key={`pagination-${page.page}-${page.label}-${i}`}
          disabled={page.page === undefined}
        >
          {page.label}
        </button>
      ))}
      {currentPage < pages && (
        <button className={styles.PaginationConinue} onClick={pageChange(currentPage + 1)}>
          <ArrowRight />
        </button>
      )}
      <div className={styles.PaginationPerPage}>
        <Field
          name="perPage"
          component={SelectField}
          input={{ value: perPage, onChange: onPerPageChange }}
          meta={{}}
          label="Results per page"
          dataSource={[
            {
              value: 15,
              label: '15',
            },
            {
              value: 30,
              label: '30',
            },
            {
              value: 50,
              label: '50',
            },
          ]}
          readOnly
        />
      </div>
    </div>
  );
};
export default Pagination;
