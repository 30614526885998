import React from 'react';
import styles from './styles.module.scss';

interface SurveyBucketFieldTypes {
  input: {
    name: string;
    value: number;
  };
  label: any;//?
  type: string;
  meta: {
    touched: boolean;
    error: any;
  };
}

export const SurveyBucketField = ({ input, label, type, meta: { touched, error } }: SurveyBucketFieldTypes) => {
  return (
    <div className={styles.wrapper}>
      <input {...input} type={type} placeholder={label} className={styles.field} />
      {touched && error && <span className={styles.error}>{error}</span>}
    </div>
  );
};

export default SurveyBucketField;
