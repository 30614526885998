const defaults = {
  /** @type {Number[]} */
  statusCodes: [
    401 // Unauthorized
  ]
};

function createAuthRefreshInterceptor(axios, refreshTokenCall, options = {}) {
  const id = axios.interceptors.response.use(
    res => res,
    error => {
      // Reject promise if the error status is not in options.ports or defaults.ports

      const statusCodes =
        options.hasOwnProperty('statusCodes') && options.statusCodes.length
          ? options.statusCodes
          : defaults.statusCodes;
      if (
        !error.response ||
        (error.response.status &&
          statusCodes.indexOf(+error.response.status) === -1)
      ) {
        return Promise.reject(error);
      }

      // Remove the interceptor to prevent a loop
      // in case token refresh also causes the 401
      axios.interceptors.response.eject(id);

      const refreshCall = refreshTokenCall(error);

      // Create interceptor that will bind all the others requests
      // until refreshTokenCall is resolved
      const requestQueueInterceptorId = axios.interceptors.request.use(
        request => refreshCall.then(() => request)
      );

      // When response code is 401 (Unauthorized), try to refresh the token.
      return refreshCall
        .then(() => {
          axios.interceptors.request.eject(requestQueueInterceptorId);
          return axios(error.response.config);
        })
        .catch(error => {
          axios.interceptors.request.eject(requestQueueInterceptorId);
          return Promise.reject(error);
        })
        .finally(() => {
          return createAuthRefreshInterceptor(axios, refreshTokenCall, options);
        });
    }
  );
  return axios;
}
export default createAuthRefreshInterceptor;
