import React, { PureComponent } from 'react';
import classnames from 'classnames';
import FieldError from '../FieldError';
import styles from './styles.module.scss';

interface HeaderTextFieldProps {
  disabled?: boolean;
  label?: React.ReactNode;
  labelLeft?: boolean;
  id?: string;
  icon?: 'scope' | 'arrow_down' | boolean;
  hiddenDisabled?: boolean;
  isRequired?: boolean;
  onClick?: any;
  input?: any;
  meta?: any;
  setRef?: any;
}

class HeaderTextField extends PureComponent<HeaderTextFieldProps> {
  onClick = (e) => {
    if (this.props.onClick) {
      this.props.onClick(e);
    }
  };

  render() {
    const {
      input,
      meta,
      disabled,
      icon,
      setRef,
      isRequired,
      hiddenDisabled,
      ...props
    } = this.props;

    const inputClasses = classnames(styles.HeaderTextFieldInput, {
      [styles.HeaderTextFieldInputDisabled]: disabled && !hiddenDisabled,
      [styles.HeaderTextFieldInputIcon]: icon,
      'HeaderTextField__input--required': isRequired,
    });

    return (
      <div className={styles.HeaderTextField}>
        <input
          {...input}
          value={input.value}
          id={input.name}
          {...props}
          className={inputClasses}
          disabled={disabled}
          ref={setRef}
        />
        <FieldError meta={meta} />
      </div>
    );
  }
}

export default HeaderTextField;
