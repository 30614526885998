import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useUserManagement, UsersResponse } from 'modules/user';
import { COUNTRY_OPTIONS, LANGUAGE_OPTIONS } from 'shared/types';
import moment from 'moment';
import { routes } from 'shared/constants/routes';
import ListHeading from 'shared/components/ListHeading';
import AppContent from 'shared/components/AppContent';
import Button from 'shared/components/Button';
import Modal from 'shared/components/Modal';
import {
  TextField,
  DataTable,
  AutocompleteField,
  DateField,
  Button as ComponentButton
} from 'components';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { createUser } from 'modules/user/api';

import styles from './styles.module.scss';

const COLUMNS = [
  {
    field: 'id',
    headerName: 'ID',
  },
  {
    field: 'email',
    headerName: 'Email',
  },
  {
    field: 'fullName',
    headerName: 'Name',
  },
  {
    field: 'phone',
    headerName: 'Phone',
  },
];

const GENDER_OPTIONS = [
  {
    value: 'MALE',
    label: 'Male',
  },
  {
    value: 'FEMALE',
    label: 'Female',
  }
];

const validationSchema = yup.object().shape({
  fullName: yup.string().required('Full name is required'),
  email: yup.string().email('Invalid email format').required('Email is required'),
  phone: yup
    .string()
    .matches(/^\+[1-9]\d{1,14}$/, 'Invalid phone number format')
    .required('Phone number is required'),
  country: yup.string().required('Country is required'),
  language: yup.string().required('Language is required'),
  gender: yup.string().required('Gender is required'),
  birthDate: yup.date().required('Date of birth is required'),
});

export const UsersList = () => {
  const {
    location: { search },
    push,
  } = useHistory();

  const { fetchUsers } = useUserManagement();

  const handleRowClick = (id: string | number) => {
    push({
      pathname: routes.users.edit(id).path,
      search,
    });
  };

  const [searchText, setSearchText] = useState('');
  const [isModalOpen, setModalOpen] = useState(false);
  const [formValues, setFormValues] = useState({});

  const methods = useForm({
    defaultValues: formValues,
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  const { handleSubmit, formState } = methods;
  const { isValid, isSubmitting } = formState;

  const onSearch = (event) => {
    const { value } = event.target;
    setSearchText(value);
  };

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleSubmitAddUser = async (values) => {
    const formattedValues = {
      ...values,
      birthDate: moment(values.birthdate).format('YYYY-MM-DD')
    };
    console.log(formattedValues)

    try {
      const response = await createUser(formattedValues);
        console.log('User created successfully:', response);
        setModalOpen(false);
        const { id, country } = response;
        push(`/app/users/edit/${id}?country=${country}`);
    } catch (error) {
      if (error.response && error.response.status === 409) {
        console.error('Conflict error:', error.response.data);
        alert(error.response.data);
      } else {
        console.error('Error creating user:', error);
        alert('An error occurred while creating the user');
      }
    }
  };

  return (
    <div>
      <ListHeading
        title="Users"
        right={
          <>
            <Button
              onClick={handleOpenModal}
              icon="plus"
              type="primary"
              buttonType="button"
              className={styles.UserListAddUserButton}
            />
            <input
              type="text"
              onChange={onSearch}
              placeholder="Type to search"
              value={searchText}
              className={styles.UserListSearchBox}
            />
          </>
        }
      />
      <AppContent>
        <DataTable<UsersResponse>
          columns={COLUMNS}
          onRowClick={handleRowClick}
          requestHandler={(query) => fetchUsers({ ...query, search: searchText })}
          key={searchText}
        />
      </AppContent>

      <Modal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="add-user-modal-title"
        aria-describedby="add-user-modal-description"
        title="Create User"
        style={{ maxWidth: '50%' }}
      >
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(handleSubmitAddUser)}>
            <TextField label="Full Name" name="fullName" required />
            <TextField label="Email" name="email" required />
            <TextField label="Phone" name="phone" required />
            <AutocompleteField
              label="Country"
              name="country"
              options={COUNTRY_OPTIONS}
              required
            />
            <AutocompleteField
              label="Language"
              name="language"
              options={LANGUAGE_OPTIONS}
              required
            />
            <AutocompleteField
              label="Gender"
              name="gender"
              options={GENDER_OPTIONS}
              required
            />
            <DateField label="Date of birth" name="birthDate" required />
            <div className={styles.ButtonWrapper}>
              <ComponentButton
                type="submit"
                disabled={!isValid || isSubmitting}
                loading={isSubmitting}
                className={styles.CreateUserButton}
              >
                Create User
              </ComponentButton>
            </div>
          </form>
        </FormProvider>
      </Modal>
    </div>
  );
};
