import { specialCharsRegex, specialCharsRegexMessage } from 'shared/utils/regex';
import * as yup from 'yup';

const date = yup.date().transform((cv, ov) => (ov === null ? undefined : cv));

export const schema = yup.object({
  name: yup.string().required().matches(specialCharsRegex, specialCharsRegexMessage),
  clientName: yup.string().required(),
  dateFrom: date.required().when('dateTo', (dateTo, s) => {
    if (!dateTo) {
      return s;
    }

    return yup.date().max(dateTo);
  }),
  dateTo: date.required().min(yup.ref('dateFrom')),
});
