import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ThemeProvider } from '@material-ui/core/styles'
import { Route } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import 'services/validation'
import 'normalize.css'
import { theme } from 'views/App/App.theme'
import EnvBanner from 'shared/components/EnvBanner'
import App from './modules/App'

import AuthorizedRoute from './shared/components/AuthorizedRoute'
import { routes } from './shared/constants/routes'
import Auth from './modules/Auth'
import { store, history } from './store'
import './assets/styles/main.scss'

ReactDOM.render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <ThemeProvider theme={theme}>
                <EnvBanner />
                <AuthorizedRoute path="/app/" component={App} />
                <Route path={routes.auth.default.path} component={Auth} />
            </ThemeProvider>
        </ConnectedRouter>
    </Provider>,
    document.getElementById('root'),
)
