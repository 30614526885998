import React from 'react';
import classnames from 'classnames';
import styles from './styles.module.scss';

interface TypeScriptNotification {
  type?: string;
  children?: React.ReactNode;
}

const Notification = ({ type = 'default', children }: TypeScriptNotification) => {
  const classname = classnames(styles.notification, styles[type]);
  return <div className={classname}>{children}</div>;
};

export default Notification;
