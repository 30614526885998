import React from 'react';
import { useCountry } from 'hooks/use-country';
import styles from './styles.module.scss';

const env = process.env.REACT_APP_TYPE;

const EnvBanner = () => {
  const { selectedWorkspace } = useCountry();

  return (
    <div className={`${styles.EnvBanner} ${styles.EnvBannerDev}`}>
      <div>
        Workspace: <span style={{ fontWeight: 'bold' }}>{selectedWorkspace}</span>
      </div>
      {['dev', 'test'].includes(env || '') && <div>Environment: {env === 'dev' ? 'Development' : 'Test'}</div>}
    </div>
  );
};

export default EnvBanner;
