import { put, takeEvery } from 'redux-saga/effects';
import apiService, { getError } from 'shared/services/api';
import {
  COMPLETION_SURVEY_QUESTIONS_FETCH_LIST,
  COMPLETION_SURVEY_QUESTIONS_DELETE,
  fetchCompletionSurveyQuestions,
} from '../../../reducers/question/list';
import axios from 'axios';
import { toast } from 'react-toastify';
import { TOAST_MESSAGE } from 'shared/constants/message';

axios.defaults.validateStatus = (status) => {
  return [200, 400, 500, 404].includes(status);
};

function* fetchCompletionSurveyCategoriesSaga() {
  try {
    const { data } = yield apiService({}).get('/profile/questions');
    yield put({
      type: COMPLETION_SURVEY_QUESTIONS_FETCH_LIST.SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield put({
      type: COMPLETION_SURVEY_QUESTIONS_FETCH_LIST.FAILURE,
      payload: getError(err),
    });
  }
}

function* deleteCompletionSurveySurveyQuestion(action) {
  try {
    const response = yield apiService({}).delete(`/profile/questions/${action.payload.id}`);
    const data = response?.data;
    
    if(response?.status == 200) {
      toast.success(TOAST_MESSAGE.question.deleted)
    } else {
      toast.error(TOAST_MESSAGE[500])
    }
    yield put(fetchCompletionSurveyQuestions());
    yield put({
      type: COMPLETION_SURVEY_QUESTIONS_DELETE.SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield put({
      type: COMPLETION_SURVEY_QUESTIONS_DELETE.FAILURE,
      payload: getError(err),
    });
  }
}

export default [
  function* () {
    yield takeEvery(
      COMPLETION_SURVEY_QUESTIONS_FETCH_LIST.REQUEST,
      fetchCompletionSurveyCategoriesSaga,
    );
  },
  function* () {
    yield takeEvery(
      COMPLETION_SURVEY_QUESTIONS_DELETE.REQUEST,
      deleteCompletionSurveySurveyQuestion,
    );
  },
];
