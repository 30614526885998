import { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { Trash } from 'shared/components/Icons';
import TextField from '@material-ui/core/TextField';

interface EditMediaFileProps {
  fileName?: string;
  media?: string;
  filter?: string;
  client?: string;
  channel?: string
  onDelete?: (...args: any[]) => void;
  onMetadataChange,
}

const MEDIA_TYPES = [
  { value: 'OLV', label: 'Online Video' },
  { value: 'Radio', label: 'Radio' },
  { value: 'OLR', label: 'Online Radio' },
  { value: 'Podcast', label: 'Podcast' },
  { value: 'Cinema', label: 'Cinema' },
  { value: 'Social', label: 'Social Media' },
  { value: 'OD', label: 'Online Display' },
  { value: 'TV', label: 'Television'},
  { value: 'VOD', label: 'Video On Demand'}
];

//TODO: Make this for each different field and different types of values
const validateFieldValue = (field, value) => {
  if (value == '') {
    return false
  }

  const regex = /^[a-zA-Z0-9]+( [a-zA-Z0-9]+)*$/;
  if (!regex.test(value)) {
    return false
  }

  if (field == "media") {
    if (!MEDIA_TYPES.some(mediaType => mediaType.value === value)) {
      return false
    }
  }

  return true;
};

const EditMediaFile = ({
  fileName,
  media,
  client,
  filter,
  channel,
  onDelete,
  onMetadataChange
}: EditMediaFileProps) => {
  const [editMode, setEditMode] = useState({ client: false, media: false, channel: false, filter: false });
  const [values, setValues] = useState({ client, media, channel, filter });
  const [originalValues, setOriginalValues] = useState({});

  useEffect(() => {
    setOriginalValues({ client, media, channel, filter });
  }, [client, media, channel, filter]);

  const toggleEditMode = (field) => {
    if (field === 'client') return;

    setEditMode((prev) => ({ ...prev, [field]: !prev[field] }));
  };

  const handleValueChange = (field) => (event) => {
    setValues((prev) => ({ ...prev, [field]: event.target.value }));
  };

  const handleKeyPress = (field) => (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      const newValue = values[field];
      if (!validateFieldValue(field, newValue)) {
        setValues((prev) => ({ ...prev, [field]: originalValues[field] }));
        alert(`The new value for ${field} is not valid.`);
      }
      onMetadataChange(field, newValue);
      toggleEditMode(field);
    }
  };

  const renderField = (field, value) => {
    return editMode[field] ? (
      <TextField
        size="small"
        variant="outlined"
        fullWidth
        value={value}
        onChange={handleValueChange(field)}
        onBlur={() => toggleEditMode(field)}
        onKeyPress={handleKeyPress(field)}
        autoFocus
      />
    ) : (
      <span onDoubleClick={() => toggleEditMode(field)}>{value || 'N/A'}</span>
    );
  };

  return (
    <div className={styles.MediaFile}>
      <div className="MediaFile__left">
        <div className={styles.MediaFileFile}>{fileName}</div>
        <div className={styles.MediaFileMedia}>
          {renderField('client', values.client)} - {renderField('media', values.media)} - {renderField('channel', values.channel)} - {renderField('filter', values.filter)}
        </div>
      </div>

      <div className={styles.MediaFileRight}>
        <button className={styles.MediaFileTrash} type="button" onClick={onDelete}>
          <Trash />
        </button>
      </div>
    </div>
  );
};

export default EditMediaFile;