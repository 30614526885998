import React, { useState, useEffect, useContext, useRef } from 'react'
import { createPortal } from 'react-dom'
import { GMapContext } from './GMapProvider'

interface InfoBoxProps {
    anchor: google.maps.MVCObject
    children: React.ReactNode
    onClose?: () => void
}

export const InfoBox = ({ anchor, children, onClose }: InfoBoxProps) => {
    const { map } = useContext(GMapContext)
    const [infoWindow, setInfoWindow] = useState<google.maps.InfoWindow>()
    const containerElement = useRef<HTMLDivElement>(document.createElement('div'))

    useEffect(() => {
        const i = new google.maps.InfoWindow({
            maxWidth: 350,
        })
        const clickListener = i.addListener('closeclick', () => {
            onClose()
        })
        setInfoWindow(i)

        return () => {
            google.maps.event.removeListener(clickListener)
        }
    }, [])

    useEffect(() => {
        if (infoWindow && anchor) {
            infoWindow.open(map, anchor)
            infoWindow.setContent(containerElement.current)
        }
        return () => {
            if (infoWindow) {
                infoWindow.close()
                containerElement.current.remove()
            }
        }
    }, [infoWindow, anchor])

    return createPortal(children, containerElement.current)
}
