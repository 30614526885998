import React from 'react';
import styles from './styles.module.scss';

interface TypeScriptFormError {
  meta: {
    error?: string;
    touched?: boolean;
  };
}

const FieldError = (props: TypeScriptFormError) => {
  if (!(props.meta.error && props.meta.touched)) {
    return null;
  }
  return <div className={styles.FieldError}>{props.meta.error}</div>;
};

export default FieldError;
