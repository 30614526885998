import update from 'immutability-helper';

import { createReducer } from 'shared/utils/redux';
import { loadingStates, setLoadingStates } from 'shared/constants/redux';
import { createRequestTypes } from 'shared/utils/request';
import { addTimeToDate, localDateToUtc } from 'shared/services/date';

export const DISCUSSIONS_MANAGE_FETCH_CAMPAIGNS = createRequestTypes(
  'discussions/manage/FETCH_CAMPAIGNS',
);
export const DISCUSSIONS_MANAGE_FETCH_CLIENTS = createRequestTypes(
  'discussions/manage/FETCH_CLIENTS',
);
export const DISCUSSIONS_MANAGE_FETCH_DISCUSSION = createRequestTypes(
  'discussions/manage/FETCH_DISCUSSION',
);
export const DISCUSSIONS_MANAGE_FETCH_MONKEY_DISCUSSIONS = createRequestTypes(
  'discussions/manage/FETCH_MONKEY_DISCUSSIONS',
);
export const DISCUSSIONS_MANAGE_SAVE_DISCUSSION = createRequestTypes(
  'discussions/manage/SAVE_DISCUSSION',
);
export const DISCUSSIONS_MANAGE_UPDATE_BUCKETS = createRequestTypes(
  'discussions/manage/UPDATE_BUCKETS',
);
export const DISCUSSIONS_MANAGE_FETCH_BUCKETS = createRequestTypes(
  'discussions/manage/FETCH_BUCKETS',
);
export const DISCUSSIONS_MANAGE_CALCULATE_BUCKETS = createRequestTypes(
  'discussions/manage/CALCULATE_BUCKETS',
);
export const DISCUSSIONS_MANAGE_LAUNCH = createRequestTypes('discussions/manage/LAUNCH');
export const DISCUSSIONS_MANAGE_CANCEL = createRequestTypes('discussions/manage/CANCEL');
export const DISCUSSIONS_MANAGE_RESET_TO_INIT = 'discussions/manage/RESET';

export const resetToInit = () => ({
  type: DISCUSSIONS_MANAGE_RESET_TO_INIT,
  payload: {},
});

export const fetchCampaigns = () => ({
  type: DISCUSSIONS_MANAGE_FETCH_CAMPAIGNS.REQUEST,
  payload: {},
});
export const fetchClients = () => ({
  type: DISCUSSIONS_MANAGE_FETCH_CLIENTS.REQUEST,
  payload: {},
});

export const fetchDiscussion = ({ id }) => ({
  type: DISCUSSIONS_MANAGE_FETCH_DISCUSSION.REQUEST,
  payload: { id },
});

export const calculateBuckets = ({ id }) => ({
  type: DISCUSSIONS_MANAGE_CALCULATE_BUCKETS.REQUEST,
  payload: { id },
});

export const launchDiscussion = ({ discussionId }) => ({
  type: DISCUSSIONS_MANAGE_LAUNCH.REQUEST,
  payload: { discussionId },
});
export const cancelDiscussion = ({ discussionId }) => ({
  type: DISCUSSIONS_MANAGE_CANCEL.REQUEST,
  payload: { discussionId },
});

export const updateDiscussionBuckets = ({ id, newBuckets, updateBuckets, deleteBuckets }) => ({
  type: DISCUSSIONS_MANAGE_UPDATE_BUCKETS.REQUEST,
  payload: { id, newBuckets, updateBuckets, deleteBuckets },
});
export const fetchDiscussionBuckets = ({ discussionId }) => ({
  type: DISCUSSIONS_MANAGE_FETCH_BUCKETS.REQUEST,
  payload: { discussionId },
});

export const saveDiscussion = ({
  title,
  dateFrom,
  dateFromTime,
  dateTo,
  dateToTime,
  id,
  ...rest
}) => ({
  type: DISCUSSIONS_MANAGE_SAVE_DISCUSSION.REQUEST,
  payload: {
    title,
    dateFrom: localDateToUtc(addTimeToDate(dateFrom, dateFromTime)),
    dateTo: localDateToUtc(addTimeToDate(dateTo, dateToTime)),
    ...rest,
  },
  id,
});

const initState = {
  campaigns: {
    states: loadingStates,
    data: [],
    response: null,
  },
  clients: {
    states: loadingStates,
    data: [],
    response: null,
  },
  monkeyDiscussions: {
    states: loadingStates,
    data: [],
    response: null,
  },
  discussion: {
    states: loadingStates,
    data: {},
    response: null,
  },
  save: {
    states: loadingStates,
    response: null,
  },
  discussionBuckets: {
    states: loadingStates,
    data: [],
  },
  updatingDiscussionBuckets: {
    states: loadingStates,
    data: [],
  },
  calculate: {
    states: loadingStates,
    data: {},
    response: null,
  },
  launch: {
    states: loadingStates,
    data: {},
    response: null,
  },
  cancel: {
    states: loadingStates,
    data: {},
    response: null,
  },
};

export default createReducer(initState, {
  [DISCUSSIONS_MANAGE_RESET_TO_INIT]: () => {
    return initState;
  },
  [DISCUSSIONS_MANAGE_FETCH_DISCUSSION.REQUEST]: (state) => {
    return update(state, {
      discussion: {
        $merge: {
          states: setLoadingStates({ isLoading: true }),
        },
      },
    });
  },
  [DISCUSSIONS_MANAGE_FETCH_DISCUSSION.REQUEST]: (state) => {
    return update(state, {
      discussion: {
        $merge: {
          states: setLoadingStates({ isLoading: true }),
        },
      },
    });
  },
  [DISCUSSIONS_MANAGE_FETCH_DISCUSSION.SUCCESS]: (state, action) => {
    return update(state, {
      discussion: {
        $merge: {
          states: setLoadingStates({ isLoaded: true }),
          data: action.payload,
        },
      },
    });
  },
  [DISCUSSIONS_MANAGE_FETCH_DISCUSSION.FAILURE]: (state, action) => {
    return update(state, {
      discussion: {
        $merge: {
          states: setLoadingStates({ hasError: true }),
          response: action.payload.response,
        },
      },
    });
  },
  [DISCUSSIONS_MANAGE_FETCH_CAMPAIGNS.REQUEST]: (state) => {
    return update(state, {
      campaigns: {
        $merge: {
          states: setLoadingStates({ isLoading: true }),
        },
      },
    });
  },
  [DISCUSSIONS_MANAGE_FETCH_CAMPAIGNS.SUCCESS]: (state, action) => {
    return update(state, {
      campaigns: {
        $merge: {
          states: setLoadingStates({ isLoaded: true }),
          data: action.payload,
        },
      },
    });
  },
  [DISCUSSIONS_MANAGE_FETCH_CAMPAIGNS.FAILURE]: (state, action) => {
    return update(state, {
      campaigns: {
        $merge: {
          states: setLoadingStates({ hasError: true }),
          response: action.payload.response,
        },
      },
    });
  },
  [DISCUSSIONS_MANAGE_FETCH_CLIENTS.REQUEST]: (state) => {
    return update(state, {
      clients: {
        $merge: {
          states: setLoadingStates({ isLoading: true }),
        },
      },
    });
  },
  [DISCUSSIONS_MANAGE_FETCH_CLIENTS.SUCCESS]: (state, action) => {
    return update(state, {
      clients: {
        $merge: {
          states: setLoadingStates({ isLoaded: true }),
          data: action.payload,
        },
      },
    });
  },
  [DISCUSSIONS_MANAGE_FETCH_CLIENTS.FAILURE]: (state, action) => {
    return update(state, {
      clients: {
        $merge: {
          states: setLoadingStates({ hasError: true }),
          response: action.payload.response,
        },
      },
    });
  },
  [DISCUSSIONS_MANAGE_SAVE_DISCUSSION.REQUEST]: (state) => {
    return update(state, {
      save: {
        $merge: {
          states: setLoadingStates({ isLoading: true }),
        },
      },
    });
  },
  [DISCUSSIONS_MANAGE_SAVE_DISCUSSION.SUCCESS]: (state, action) => {
    return update(state, {
      save: {
        $merge: {
          states: setLoadingStates({ isLoaded: true }),
          data: action.payload,
        },
      },
    });
  },
  [DISCUSSIONS_MANAGE_SAVE_DISCUSSION.FAILURE]: (state, action) => {
    return update(state, {
      save: {
        $merge: {
          states: setLoadingStates({ hasError: true }),
          response: action.payload.response,
        },
      },
    });
  },
  [DISCUSSIONS_MANAGE_UPDATE_BUCKETS.REQUEST]: (state) => {
    return update(state, {
      updatingDiscussionBuckets: {
        $merge: {
          states: setLoadingStates({ isLoading: true }),
        },
      },
    });
  },
  [DISCUSSIONS_MANAGE_UPDATE_BUCKETS.SUCCESS]: (state, action) => {
    return update(state, {
      discussion: {
        $merge: {
          data: { ...state.discussion.data, discussionBuckets: action.payload },
        },
      },
      updatingDiscussionBuckets: {
        $merge: {
          states: setLoadingStates({ isLoaded: true }),
          data: action.payload,
        },
      },
    });
  },
  [DISCUSSIONS_MANAGE_UPDATE_BUCKETS.FAILURE]: (state, action) => {
    return update(state, {
      updatingDiscussionBuckets: {
        $merge: {
          states: setLoadingStates({ hasError: true }),
          response: action.payload.response,
        },
      },
    });
  },
  [DISCUSSIONS_MANAGE_FETCH_BUCKETS.REQUEST]: (state) => {
    return update(state, {
      discussionBuckets: {
        $merge: {
          states: setLoadingStates({ isLoading: true }),
        },
      },
    });
  },
  [DISCUSSIONS_MANAGE_FETCH_BUCKETS.SUCCESS]: (state, action) => {
    return update(state, {
      discussionBuckets: {
        $merge: {
          states: setLoadingStates({ isLoaded: true }),
          data: action.payload,
        },
      },
    });
  },
  [DISCUSSIONS_MANAGE_FETCH_BUCKETS.FAILURE]: (state, action) => {
    return update(state, {
      discussionBuckets: {
        $merge: {
          states: setLoadingStates({ hasError: true }),
          response: action.payload.response,
        },
      },
    });
  },
  [DISCUSSIONS_MANAGE_CALCULATE_BUCKETS.REQUEST]: (state) => {
    return update(state, {
      calculate: {
        $merge: {
          states: setLoadingStates({ isLoading: true }),
        },
      },
    });
  },
  [DISCUSSIONS_MANAGE_CALCULATE_BUCKETS.SUCCESS]: (state, action) => {
    return update(state, {
      discussionBuckets: {
        $merge: {
          data: action.payload.discussionBuckets,
        },
      },
      discussion: {
        $merge: {
          data: {
            ...state.discussion.data,
            discussionBuckets: action.payload.discussionBuckets,
          },
        },
      },
      calculate: {
        $merge: {
          states: setLoadingStates({ isLoaded: true }),
          data: action.payload,
        },
      },
    });
  },
  [DISCUSSIONS_MANAGE_CALCULATE_BUCKETS.FAILURE]: (state, action) => {
    return update(state, {
      calculate: {
        $merge: {
          states: setLoadingStates({ hasError: true }),
          response: action.payload.response,
        },
      },
    });
  },
  [DISCUSSIONS_MANAGE_LAUNCH.REQUEST]: (state) => {
    return update(state, {
      launch: {
        $merge: {
          states: setLoadingStates({ isLoading: true }),
        },
      },
    });
  },
  [DISCUSSIONS_MANAGE_LAUNCH.SUCCESS]: (state, action) => {
    return update(state, {
      discussion: {
        $merge: {
          data: action.payload,
        },
      },
      launch: {
        $merge: {
          states: setLoadingStates({ isLoaded: true }),
          data: action.payload,
        },
      },
    });
  },
  [DISCUSSIONS_MANAGE_LAUNCH.FAILURE]: (state, action) => {
    return update(state, {
      launch: {
        $merge: {
          states: setLoadingStates({ hasError: true }),
          response: action.payload.response,
        },
      },
    });
  },
  [DISCUSSIONS_MANAGE_CANCEL.REQUEST]: (state) => {
    return update(state, {
      cancel: {
        $merge: {
          states: setLoadingStates({ isLoading: true }),
        },
      },
    });
  },
  [DISCUSSIONS_MANAGE_CANCEL.SUCCESS]: (state, action) => {
    return update(state, {
      discussion: {
        $merge: {
          data: action.payload,
        },
      },
      cancel: {
        $merge: {
          states: setLoadingStates({ isLoaded: true }),
          data: action.payload,
        },
      },
    });
  },
  [DISCUSSIONS_MANAGE_CANCEL.FAILURE]: (state, action) => {
    return update(state, {
      cancel: {
        $merge: {
          states: setLoadingStates({ hasError: true }),
          response: action.payload.response,
        },
      },
    });
  },
});
