import update from 'immutability-helper';

import { createReducer } from 'shared/utils/redux';
import { loadingStates, setLoadingStates } from 'shared/constants/redux';
import { createRequestTypes } from 'shared/utils/request';
import { addTimeToDate, localDateToUtc } from 'shared/services/date';

export const SURVEYS_MANAGE_FETCH_CAMPAIGNS = createRequestTypes('surveys/manage/FETCH_CAMPAIGNS');
export const SURVEYS_MANAGE_FETCH_SURVEY = createRequestTypes('surveys/manage/FETCH_SURVEY');
export const SURVEYS_MANAGE_FETCH_MONKEY_SURVEYS = createRequestTypes(
  'surveys/manage/FETCH_MONKEY_SURVEYS',
);
export const SURVEYS_MANAGE_SAVE_SURVEY = createRequestTypes('surveys/manage/SAVE_SURVEY');
export const SURVEYS_MANAGE_UPDATE_BUCKETS = createRequestTypes('surveys/manage/UPDATE_BUCKETS');
export const SURVEYS_MANAGE_FETCH_BUCKETS = createRequestTypes('surveys/manage/FETCH_BUCKETS');
export const SURVEYS_MANAGE_CALCULATE_BUCKETS = createRequestTypes(
  'surveys/manage/CALCULATE_BUCKETS',
);
export const SURVEYS_MANAGE_LAUNCH = createRequestTypes('surveys/manage/LAUNCH');
export const SURVEYS_MANAGE_CANCEL = createRequestTypes('surveys/manage/CANCEL');
export const SURVEYS_MANAGE_REOPEN = createRequestTypes('surveys/manage/REOPEN');
export const SURVEYS_MANAGE_RESET_TO_INIT = 'surveys/manage/RESET';

export const SURVEYS_FETCH_EXCLUDE_LIST = createRequestTypes('surveys/FETCH_EXCLUDE_LIST');

export const resetToInit = () => ({
  type: SURVEYS_MANAGE_RESET_TO_INIT,
  payload: {},
});

export const fetchCampaigns = () => ({
  type: SURVEYS_MANAGE_FETCH_CAMPAIGNS.REQUEST,
  payload: {},
});

export const fetchMonkeySurveys = () => ({
  type: SURVEYS_MANAGE_FETCH_MONKEY_SURVEYS.REQUEST,
  payload: {},
});

export const fetchSurvey = ({ id }) => ({
  type: SURVEYS_MANAGE_FETCH_SURVEY.REQUEST,
  payload: { id },
});

export const calculateBuckets = ({ id }) => ({
  type: SURVEYS_MANAGE_CALCULATE_BUCKETS.REQUEST,
  payload: { id },
});

export const launchSurvey = ({ surveyId }) => ({
  type: SURVEYS_MANAGE_LAUNCH.REQUEST,
  payload: { surveyId },
});
export const reopenSurvey = ({ surveyId }) => ({
  type: SURVEYS_MANAGE_REOPEN.REQUEST,
  payload: { surveyId },
});
export const cancelSurvey = ({ surveyId }) => ({
  type: SURVEYS_MANAGE_CANCEL.REQUEST,
  payload: { surveyId },
});

export const updateSurveyBuckets = ({ id, newBuckets, updateBuckets, deleteBuckets }) => ({
  type: SURVEYS_MANAGE_UPDATE_BUCKETS.REQUEST,
  payload: { id, newBuckets, updateBuckets, deleteBuckets },
});
export const fetchSurveyBuckets = ({ id }) => ({
  type: SURVEYS_MANAGE_FETCH_BUCKETS.REQUEST,
  payload: { id },
});

export const saveSurvey = ({ title, dateFrom, dateFromTime, dateTo, dateToTime, id, ...rest }) => ({
  type: SURVEYS_MANAGE_SAVE_SURVEY.REQUEST,
  payload: {
    title,
    dateFrom: localDateToUtc(addTimeToDate(dateFrom, dateFromTime)),
    dateTo: localDateToUtc(addTimeToDate(dateTo, dateToTime)),
    ...rest,
  },
  id,
});

export const populateFormFields = (payload) => ({
  type: SURVEYS_MANAGE_FETCH_SURVEY.SUCCESS,
  payload,
});

export const fetchSurveysToExclude = () => ({
  type: SURVEYS_FETCH_EXCLUDE_LIST.REQUEST,
});

const initState = {
  toExclude: {
      data: [],
  },
  campaigns: {
    states: loadingStates,
    data: [],
    response: null,
  },
  monkeySurveys: {
    states: loadingStates,
    data: [],
    response: null,
  },
  survey: {
    states: loadingStates,
    data: {},
    response: null,
  },
  save: {
    states: loadingStates,
    response: null,
  },
  surveyBuckets: {
    states: loadingStates,
    data: [],
  },
  updatingSurveyBuckets: {
    states: loadingStates,
    data: [],
  },
  calculate: {
    states: loadingStates,
    data: {},
    response: null,
  },
  launch: {
    states: loadingStates,
    data: {},
    response: null,
  },
  cancel: {
    states: loadingStates,
    data: {},
    response: null,
    successfulCanceled: false,
  },
  reopen: {
    states: loadingStates,
    data: {},
    response: null,
    successfulCanceled: false,
  },
};

export default createReducer(initState, {
  [SURVEYS_MANAGE_RESET_TO_INIT]: () => {
    return initState;
  },
  [SURVEYS_MANAGE_FETCH_SURVEY.REQUEST]: (state) => {
    return update(state, {
      survey: {
        $merge: {
          states: setLoadingStates({ isLoading: true }),
        },
      },
    });
  },
  [SURVEYS_MANAGE_FETCH_SURVEY.REQUEST]: (state) => {
    return update(state, {
      survey: {
        $merge: {
          states: setLoadingStates({ isLoading: true }),
        },
      },
    });
  },
  [SURVEYS_MANAGE_FETCH_SURVEY.SUCCESS]: (state, action) => {
    return update(state, {
      survey: {
        $merge: {
          states: setLoadingStates({ isLoaded: true }),
          data: action.payload,
        },
      },
    });
  },
  [SURVEYS_MANAGE_FETCH_SURVEY.FAILURE]: (state, action) => {
    return update(state, {
      survey: {
        $merge: {
          states: setLoadingStates({ hasError: true }),
          response: action.payload.response,
        },
      },
    });
  },
  [SURVEYS_MANAGE_FETCH_CAMPAIGNS.REQUEST]: (state) => {
    return update(state, {
      campaigns: {
        $merge: {
          states: setLoadingStates({ isLoading: true }),
        },
      },
    });
  },
  [SURVEYS_MANAGE_FETCH_CAMPAIGNS.SUCCESS]: (state, action) => {
    return update(state, {
      campaigns: {
        $merge: {
          states: setLoadingStates({ isLoaded: true }),
          data: action.payload,
        },
      },
    });
  },
  [SURVEYS_MANAGE_FETCH_CAMPAIGNS.FAILURE]: (state, action) => {
    return update(state, {
      campaigns: {
        $merge: {
          states: setLoadingStates({ hasError: true }),
          response: action.payload.response,
        },
      },
    });
  },
  [SURVEYS_MANAGE_SAVE_SURVEY.REQUEST]: (state) => {
    return update(state, {
      save: {
        $merge: {
          states: setLoadingStates({ isLoading: true }),
        },
      },
    });
  },
  [SURVEYS_MANAGE_SAVE_SURVEY.SUCCESS]: (state, action) => {
    return update(state, {
      save: {
        $merge: {
          states: setLoadingStates({ isLoaded: true }),
          data: action.payload,
        },
      },
    });
  },
  [SURVEYS_MANAGE_SAVE_SURVEY.FAILURE]: (state, action) => {
    return update(state, {
      save: {
        $merge: {
          states: setLoadingStates({ hasError: true }),
          response: action.payload.response,
        },
      },
    });
  },
  [SURVEYS_MANAGE_FETCH_MONKEY_SURVEYS.REQUEST]: (state) => {
    return update(state, {
      monkeySurveys: {
        $merge: {
          states: setLoadingStates({ isLoading: true }),
        },
      },
    });
  },
  [SURVEYS_MANAGE_FETCH_MONKEY_SURVEYS.SUCCESS]: (state, action) => {
    return update(state, {
      monkeySurveys: {
        $merge: {
          states: setLoadingStates({ isLoaded: true }),
          data: action.payload,
        },
      },
    });
  },
  [SURVEYS_MANAGE_FETCH_MONKEY_SURVEYS.FAILURE]: (state, action) => {
    return update(state, {
      monkeySurveys: {
        $merge: {
          states: setLoadingStates({ hasError: true }),
          response: action.payload.response,
        },
      },
    });
  },
  [SURVEYS_MANAGE_UPDATE_BUCKETS.REQUEST]: (state) => {
    return update(state, {
      updatingSurveyBuckets: {
        $merge: {
          states: setLoadingStates({ isLoading: true }),
        },
      },
    });
  },
  [SURVEYS_MANAGE_UPDATE_BUCKETS.SUCCESS]: (state, action) => {
    return update(state, {
      survey: {
        $merge: {
          data: { ...state.survey.data, surveyBuckets: action.payload },
        },
      },
      updatingSurveyBuckets: {
        $merge: {
          states: setLoadingStates({ isLoaded: true }),
          data: action.payload,
        },
      },
    });
  },
  [SURVEYS_MANAGE_UPDATE_BUCKETS.FAILURE]: (state, action) => {
    return update(state, {
      updatingSurveyBuckets: {
        $merge: {
          states: setLoadingStates({ hasError: true }),
          response: action.payload.response,
        },
      },
    });
  },
  [SURVEYS_MANAGE_FETCH_BUCKETS.REQUEST]: (state) => {
    return update(state, {
      surveyBuckets: {
        $merge: {
          states: setLoadingStates({ isLoading: true }),
        },
      },
    });
  },
  [SURVEYS_MANAGE_FETCH_BUCKETS.SUCCESS]: (state, action) => {
    return update(state, {
      surveyBuckets: {
        $merge: {
          states: setLoadingStates({ isLoaded: true }),
          data: action.payload,
        },
      },
    });
  },
  [SURVEYS_MANAGE_FETCH_BUCKETS.FAILURE]: (state, action) => {
    return update(state, {
      surveyBuckets: {
        $merge: {
          states: setLoadingStates({ hasError: true }),
          response: action.payload.response,
        },
      },
    });
  },
  [SURVEYS_MANAGE_CALCULATE_BUCKETS.REQUEST]: (state) => {
    return update(state, {
      calculate: {
        $merge: {
          states: setLoadingStates({ isLoading: true }),
        },
      },
    });
  },
  [SURVEYS_MANAGE_CALCULATE_BUCKETS.SUCCESS]: (state, action) => {
    return update(state, {
      surveyBuckets: {
        $merge: {
          data: action.payload.surveyBuckets,
        },
      },
      survey: {
        $merge: {
          data: {
            ...state.survey.data,
            surveyBuckets: action.payload.surveyBuckets,
          },
        },
      },
      calculate: {
        $merge: {
          states: setLoadingStates({ isLoaded: true }),
          data: action.payload,
        },
      },
    });
  },
  [SURVEYS_MANAGE_CALCULATE_BUCKETS.FAILURE]: (state, action) => {
    return update(state, {
      calculate: {
        $merge: {
          states: setLoadingStates({ hasError: true }),
          response: action.payload.response,
        },
      },
    });
  },
  [SURVEYS_MANAGE_LAUNCH.REQUEST]: (state) => {
    return update(state, {
      launch: {
        $merge: {
          states: setLoadingStates({ isLoading: true }),
        },
      },
    });
  },
  [SURVEYS_MANAGE_LAUNCH.SUCCESS]: (state, action) => {
    return update(state, {
      survey: {
        $merge: {
          data: action.payload,
        },
      },
      launch: {
        $merge: {
          states: setLoadingStates({ isLoaded: true }),
          data: action.payload,
        },
      },
    });
  },
  [SURVEYS_MANAGE_LAUNCH.FAILURE]: (state, action) => {
    return update(state, {
      launch: {
        $merge: {
          states: setLoadingStates({ hasError: true }),
          response: action.payload.response,
        },
      },
    });
  },
  [SURVEYS_MANAGE_CANCEL.REQUEST]: (state) => {
    return update(state, {
      cancel: {
        $merge: {
          states: setLoadingStates({ isLoading: true }),
        },
      },
    });
  },
  [SURVEYS_FETCH_EXCLUDE_LIST.SUCCESS]: (state, action) => {
    return update(state, {
      toExclude: {
        $merge: {
          data: action.payload,
        },
      },
    });
  },
  [SURVEYS_MANAGE_CANCEL.SUCCESS]: (state, action) => {
    return update(state, {
      survey: {
        $merge: {
          data: action.payload,
        },
      },
      cancel: {
        $merge: {
          states: setLoadingStates({ isLoaded: true }),
          data: action.payload,
          successfulCanceled: true,
        },
      },
    });
  },
  [SURVEYS_MANAGE_CANCEL.FAILURE]: (state, action) => {
    return update(state, {
      cancel: {
        $merge: {
          states: setLoadingStates({ hasError: true }),
          response: action.payload.response,
          successfulCanceled: false,
        },
      },
    });
  },
  [SURVEYS_MANAGE_REOPEN.REQUEST]: (state) => {
    return update(state, {
      reopen: {
        $merge: {
          states: setLoadingStates({ isLoading: true }),
        },
      },
    });
  },
  [SURVEYS_MANAGE_REOPEN.SUCCESS]: (state, action) => {
    return update(state, {
      survey: {
        $merge: {
          data: action.payload,
        },
      },
      reopen: {
        $merge: {
          states: setLoadingStates({ isLoaded: true }),
          data: action.payload,
          successfulCanceled: true,
        },
      },
    });
  },
  [SURVEYS_MANAGE_REOPEN.FAILURE]: (state, action) => {
    return update(state, {
      reopen: {
        $merge: {
          states: setLoadingStates({ hasError: true }),
          response: action.payload.response,
          successfulCanceled: false,
        },
      },
    });
  },
});
