import React, { ReactNode, useContext, useEffect, useState } from 'react'
import MarkerClusterer from '@googlemaps/markerclustererplus'
import { GMapContext } from './GMapProvider'

interface MarkerProps extends google.maps.ReadonlyMarkerOptions {
    children?: (marker: google.maps.Marker) => ReactNode
    clusterer?: MarkerClusterer
    onClick?: (marker: google.maps.Marker) => void
}

export const Marker = ({ children, clusterer, onClick, ...options }: MarkerProps) => {
    const { map } = useContext(GMapContext)
    const [marker, setMarker] = useState<google.maps.Marker>()

    useEffect(() => {
        const m = new google.maps.Marker({ ...options })
        setMarker(m)
    }, [])

    useEffect(() => {
        let clickListener: google.maps.MapsEventListener
        if (marker && onClick) {
            clickListener = marker.addListener('click', () => {
                onClick(marker)
            })
        }

        return () => {
            if (clusterer) {
                clusterer.removeMarker(marker)
            } else if (marker) {
                marker.setMap(null)
            }
            google.maps.event.removeListener(clickListener)
        }
    }, [marker])

    useEffect(() => {
        if (marker) {
            if (clusterer) {
                clusterer.addMarker(marker)
                clusterer.fitMapToMarkers(10)
            } else if (map) {
                marker.setMap(map)
            }
        }
    }, [marker, map])

    useEffect(() => {
        if (marker) {
            marker.setPosition(options.position)
        }
    }, [options.position.lat, options.position.lng])

    return <>{marker && map && children && children(marker)}</>
}
