import React from 'react'
import MuiInputLabel from '@material-ui/core/InputLabel'
import MuiBox from '@material-ui/core/Box'
import { Tooltip } from 'components'
import { useStyles } from './FieldLabel.styles'

interface FieldLabelProps {
    disabled?: boolean
    htmlFor?: string
    label: string
    required?: boolean
    small?: boolean
    tooltip?: string
}

export const FieldLabel = ({
    disabled,
    htmlFor,
    label,
    required,
    small,
    tooltip,
}: FieldLabelProps) => {
    const styles = useStyles()

    return (
        <MuiInputLabel
            classes={{ root: small ? styles.small : '' }}
            disableAnimation
            disabled={disabled}
            htmlFor={htmlFor}
            shrink
        >
            {label}
            {required && <span aria-hidden>&thinsp;{'*'}</span>}
            {tooltip && (
                <MuiBox ml={2}>
                    <Tooltip title={tooltip} />
                </MuiBox>
            )}
        </MuiInputLabel>
    )
}
