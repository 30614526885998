import { toast } from 'react-toastify'
import { WithId } from 'shared/types'
import { getUser, getUsers, getUserGiftCard, putUser } from './api'
import { UserRequest } from './types'
import { TOAST_MESSAGE } from 'shared/constants/message'

export const useUserManagement = () => {
    const updateUser = async (data: WithId<UserRequest>): Promise<void> => {
        try {
            const response  = await putUser(data)
            if(response.status === 200) {
                toast.success(TOAST_MESSAGE.user.updated)
            } else {
                toast.error(TOAST_MESSAGE[500])
            }            
            
        } catch (error) { 
            toast.error(TOAST_MESSAGE[500])
        }
    }

    return {
        fetchUsers: getUsers,
        fetchUser: getUser,
        fetchUserGiftCard: getUserGiftCard,
        updateUser
    }
}
