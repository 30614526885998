// TODO: refactor handling of countryOfAvailability
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { RequestStatus } from 'services';
import { Country } from 'shared/types';
import { getSettings, putSettings, getAppVersion, putAppVersion } from './api';
import {
  SettingsResponse,
  AppVersionRespose,
  UpdateSettingsRequest,
  UpdateAppVersionRequest,
} from './types';

export const useSettingsManagement = () => {
  const [status, setStatus] = useState<RequestStatus>();
  const [settings, setSettings] = useState<SettingsResponse & AppVersionRespose>();
  const country: Country = useSelector(({ app }: any) => app.country);

  const readSettings = async () => {
    setStatus('PENDING');
    try {
      const settingsResult = await getSettings();
      const appVersionResult = await getAppVersion();
      setSettings({ ...settingsResult, ...appVersionResult });
      setStatus('RESOLVED');
    } catch (error) {
      setStatus('REJECTED');
    }
  };

  const updateSettings = async (
    data: UpdateSettingsRequest & UpdateAppVersionRequest,
  ) => {
    try {
      const {
        pointsForAcr,
        pointsForLocation,
        maxPointsPerMonth,
        pointsForMemoPanelRegistration,

        androidForceUpdateVersion,
        androidSoftUpdateVersion,
        iosForceUpdateVersion,
        iosSoftUpdateVersion,
      } = data;
      await Promise.all([
        putSettings({
          pointsForAcr,
          pointsForLocation,
          maxPointsPerMonth,
          pointsForMemoPanelRegistration,
          countryOfAvailability: country,
        }),
        putAppVersion({
          androidForceUpdateVersion,
          androidSoftUpdateVersion,
          iosForceUpdateVersion,
          iosSoftUpdateVersion,
        }),
      ]);
      toast.success('Settings successfully saved');
    } catch (error) {
      toast.error('Something went wrong. Please try again later.');
    }
  };

  useEffect(() => {
    readSettings();
  }, [country]);

  return {
    onSubmit: updateSettings,
    settings,
    status,
  };
};
