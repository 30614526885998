import React from 'react'
import MuiTable from '@material-ui/core/Table'
import MuiTableContainer from '@material-ui/core/TableContainer'
import MuiTableBody from '@material-ui/core/TableBody'
import MuiTableRow from '@material-ui/core/TableRow'
import MuiTableCell from '@material-ui/core/TableCell'
import { ContentLoader } from 'components'
import { Query, Paginated } from 'services'
import { ColumnResizer } from './ColumnResizer'
import { DataTablePagination } from './DataTablePagination'
import { Column, DataTableHead } from './DataTableHead'
import { useDataTable } from './hooks'
import { useStyles } from './DataTable.styles'

interface DataTableProps<TData> {
    columns: Column[]
    idField?: keyof TData
    onRowClick: (id: string | number) => void
    requestHandler: (query?: Query) => Promise<Paginated<TData>>
}

export const DataTable = <TData,>({
    columns,
    idField = 'id' as keyof TData,
    onRowClick,
    requestHandler,
}: DataTableProps<TData>) => {
    const { root } = useStyles()
    const {
        data,
        onPageChange,
        onPageSizeChange,
        onSortModelChange,
        pagination,
        requestStatus,
        sortModel,
    } = useDataTable<TData>({
        requestHandler,
    })

    return (
        <>
            <ContentLoader status={requestStatus}>
                <MuiTableContainer>
                    <MuiTable classes={{ root }}>
                        <DataTableHead
                            orderBy={sortModel && sortModel.field}
                            sortingOrder={(sortModel && sortModel.sort) || 'asc'}
                            columns={columns}
                            onSortModelChange={onSortModelChange}
                        />
                        <MuiTableBody>
                            {data.map(row => (
                                <MuiTableRow
                                    key={(row[idField] as unknown) as number}
                                    onClick={() => {
                                        onRowClick((row[idField] as unknown) as number)
                                    }}
                                    hover>
                                    {columns.map((column, index) => (
                                        <>
                                            {index > 0 && <ColumnResizer disabled={true} />}
                                            <MuiTableCell key={row[column.field]}>
                                                {column.valueGetter
                                                    ? column.valueGetter(row)
                                                    : row[column.field]}
                                            </MuiTableCell>
                                        </>
                                    ))}
                                </MuiTableRow>
                            ))}
                        </MuiTableBody>
                    </MuiTable>
                </MuiTableContainer>
            </ContentLoader>
            { pagination?.totalPages > 1 ? <DataTablePagination
                onPageChange={onPageChange}
                onPageSizeChange={onPageSizeChange}
                {...pagination}
            />: <></>}

        </>
    )
}
