import map from 'lodash/map';
import { pickBy, identity } from 'lodash';
const REQUEST = 'REQUEST';
const SUCCESS = 'SUCCESS';
const FAILURE = 'FAILURE';

/**
 * @param {String} base
 * @returns {{REQUEST,SUCCESS,FAILURE}}
 */
export const createRequestTypes = (base, additional = []) => {
  return [REQUEST, SUCCESS, FAILURE, ...additional].reduce(
    (acc, type) => Object.assign(acc, { [type]: `${base}_${type}` }),
    {}
  );
};

export const arrayToIds = (data) => map(data, (id) => ({ id }));

export const removeUndefinedValues = (obj) => pickBy(obj, identity);
