import React from 'react'
import MuiTableHead from '@material-ui/core/TableHead'
import MuiTableRow from '@material-ui/core/TableRow'
import MuiTableCell from '@material-ui/core/TableCell'
import MuiTableSortLabel from '@material-ui/core/TableSortLabel';
import MuiCheckbox from '@material-ui/core/Checkbox'

export interface Column<TData> {
    field: string
    headerName: string
    valueGetter?: (data: TData, index: number) => React.ReactNode
}

export type Order = 'asc' | 'desc'

interface TableHeadProps<TData> {
    checkboxSelection?: boolean
    columns: Column<TData>[]
    numSelected: number
    onSort: (event: React.MouseEvent<unknown>, property: keyof TData) => void
    onSelectAll: (event: React.ChangeEvent<HTMLInputElement>) => void
    order: Order
    orderBy: keyof TData
    rowCount: number
}

export const TableHead = <TData,>({
    checkboxSelection,
    columns,
    numSelected,
    onSort,
    onSelectAll,
    order,
    orderBy,
    rowCount,
}: TableHeadProps<TData>) => {
    const createSortHandler = (property: keyof TData) => (event: React.MouseEvent<unknown>) => {
        onSort(event, property)
    }

    return (
        <MuiTableHead>
            <MuiTableRow>
                {checkboxSelection && (
                    <MuiTableCell padding="checkbox">
                        <MuiCheckbox
                            checked={rowCount > 0 && numSelected === rowCount}
                            color="primary"
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            onChange={onSelectAll}
                        />
                    </MuiTableCell>
                )}
                {columns.map(column => (
                    <MuiTableCell
                        align={column.headerName === 'Actions' ? 'center' : 'left'}
                        key={column.headerName}>
                        <MuiTableSortLabel
                            active={orderBy === column.field}
                            direction={orderBy === column.field ? order : 'asc'}
                            onClick={createSortHandler(column.field as keyof TData)}>
                            {column.headerName}
                        </MuiTableSortLabel>
                    </MuiTableCell>
                ))}
            </MuiTableRow>
        </MuiTableHead>
    )
}
