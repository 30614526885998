import forEach from 'lodash/forEach';
import {isUrlValid, isNumeric} from 'shared/services/validator';
import * as types from '../../types/SurveyManageFormTypes';

const requiredFields = [
    'title',
    'campaignId',
    'imageUrl',
    'dateFrom',
    'dateFromTime',
    'dateTo',
    'dateToTime',
    'surveyMonkeyMobileCollectorHash',
    'points',
    'surveyMonkeySurveyId',
];

const testTime = (time) => /^(2[0-3]|[0-1]?[\d]):[0-5][\d]$/.test(time);

export default (values: types.SurveryFormValidationTypes) => {
    const errors = {};
    forEach(requiredFields, (field) => {
        if (!values[field]) {
            errors[field] = `Is required`;
        }
    });
    if (!isNumeric(values.points)) {
        errors['points'] = 'Must be number';
    }
    if (values.respondentsLimit && !isNumeric(values.respondentsLimit)) {
        errors['respondentsLimit'] = 'Must be number';
    }
    if (!isUrlValid(values.imageUrl)) {
        errors['imageUrl'] = 'Must be url';
    }
    if (values.pushNoteTitle && values.pushNoteTitle.length > 65) {
        errors['pushNoteTitle'] = 'Max length is 65';
    }
    if (values.pushNoteBody && values.pushNoteBody.length > 240) {
        errors['pushNoteBody'] = 'Max length is 240';
    }
    if (values.dateFromTime && !testTime(values.dateFromTime)) {
        errors['dateFromTime'] = 'Time must be in format hh:mm';
    }
    if (values.dateToTime && !testTime(values.dateToTime)) {
        errors['dateToTime'] = 'Time must be in format hh:mm';
    }
    if (values.surveyMonkeyMobileCollectorHash && values.surveyMonkeyMobileCollectorHash.indexOf(' ') !== -1) {
        errors['surveyMonkeyMobileCollectorHash'] = 'Mobile collector must not contain spaces';
    }
    if (values.surveyMonkeyMobileCollectorHash && values.surveyMonkeyMobileCollectorHash.length != 7) {
        errors['surveyMonkeyMobileCollectorHash'] = 'Mobile collector should be 7 characters long';
    }
    return errors;
};
