import forEach from 'lodash/forEach';
import { getSecondsFromMinutes } from 'shared/services/date';
import { isUrlValid, isNumeric } from 'shared/services/validator';
interface DiscussionFormValues {
  [key: string]: any;
  format: 'VIDEO' | 'IMAGE';
}

interface Errors {
  [key: string]: string;
}
const requiredFields = [
  'title',
  'imageUrl',
  'dateFrom',
  'dateFromTime',
  'dateTo',
  'dateToTime',
  'points',
  'format',
];

const testTime = (time: string) => /^(2[0-3]|[0-1]?[\d]):[0-5][\d]$/.test(time);
const testVideoTime = (time: string) => /^(2[0-9]|[0-9]?[\d]):[0-5][\d]$/.test(time);

export default (values: DiscussionFormValues): Errors => {
  const errors: Errors = {};
  forEach(requiredFields, (field) => {
    if (!values[field]) {
      errors[field] = 'Fill in';
    }
  });
  if (!isNumeric(values.points)) {
    errors['points'] = 'Must be number';
  }
  if (values.respondentsLimit && !isNumeric(values.respondentsLimit)) {
    errors['respondentsLimit'] = 'Must be number';
  }
  if (!isUrlValid(values.imageUrl)) {
    errors['imageUrl'] = 'Must be url';
  }
  if (values.pushNoteTitle && values.pushNoteTitle.length > 65) {
    errors['pushNoteTitle'] = 'Max length is 65';
  }
  if (values.pushNoteBody && values.pushNoteBody.length > 240) {
    errors['pushNoteBody'] = 'Max length is 240';
  }
  if (values.dateFromTime && !testTime(values.dateFromTime)) {
    errors['dateFromTime'] = 'Time must be in format hh:mm';
  }
  if (values.dateToTime && !testTime(values.dateToTime)) {
    errors['dateToTime'] = 'Time must be in format hh:mm';
  }
  if (values.format === 'VIDEO') {
    if (!testVideoTime(values?.videoDurationMin)) {
      errors['videoDurationMin'] = 'Time must be in format mm:ss';
    }
    if (!testVideoTime(values?.videoDurationMax)) {
      errors['videoDurationMax'] = 'Time must be in format mm:ss';
    }
    if (
      getSecondsFromMinutes(values?.videoDurationMin) >
      getSecondsFromMinutes(values?.videoDurationMax)
    ) {
      errors['videoDurationMin'] = 'Min value must be lower than max';
    }
  }
  if (values.faceRecognitionPercentage) {
    const val = Number(String(values.faceRecognitionPercentage).replace('%', ''));
    if (val < 0 || val > 100) {
      errors['faceRecognitionPercentage'] = 'Must be between 0 and 100';
    }
  }
  return errors;
};
