import React from 'react'
import MuiBox from '@material-ui/core/Box'
import MuiTypography from '@material-ui/core/Typography'
import MuiCircularProgress from '@material-ui/core/CircularProgress'
import { RequestStatus } from 'services'
import { useStyles } from './ContentLoader.styles'

interface ContentLoaderProps {
    status?: RequestStatus
    children: React.ReactNode
}

export const ContentLoader = ({ status, children }: ContentLoaderProps) => {
    const styles = useStyles()

    return (
        <MuiBox className={styles.wrapper}>
            {children}
            {status === 'PENDING' && (
                <MuiBox className={styles.overlay}>
                    <MuiCircularProgress />
                </MuiBox>
            )}
            {status === 'REJECTED' && (
                <MuiBox className={styles.overlay}>
                    <MuiTypography display="block" gutterBottom variant="h5">
                        Sorry, something went wrong.
                    </MuiTypography>
                    <MuiTypography display="block">We are already working on this!</MuiTypography>
                    <MuiBox className={styles.icon}>
                        <MuiBox className={styles.mouth} />
                    </MuiBox>
                </MuiBox>
            )}
        </MuiBox>
    )
}
