import React from 'react'
import MuiTypography from '@material-ui/core/Typography'
import { useStyles } from './DropZone.styles'

interface DropZoneProps {
    accept?: string
    onDrop: (files: File[]) => void
    placeholder?: string
}

export const DropZone = ({
    onDrop,
    placeholder = 'Drop files here',
}: DropZoneProps) => {
    const styles = useStyles()

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault()
        let data: File[]
        const { items, files } = event.dataTransfer
        if (items && items.length > 0) {
            data = Array.from(items, item => item.getAsFile())
        } else if (files && files.length > 0) {
            let data = Array.from(files)
        }
        onDrop(data)
    }

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault()
    }

    return (
        <div className={styles.area} onDragOver={handleDragOver} onDrop={handleDrop}>
            <MuiTypography>{placeholder}</MuiTypography>
        </div>
    )
}
