import update from 'immutability-helper';

import { createReducer } from 'shared/utils/redux';
import { loadingStates, setLoadingStates } from 'shared/constants/redux';
import { createRequestTypes } from 'shared/utils/request';

export const SURVEYS_FETCH_LIST = createRequestTypes('surveys/FETCH_LIST');

export const fetchSurveys = ({ term, search }) => ({
  type: SURVEYS_FETCH_LIST.REQUEST,
  payload: { term, search },
});

const initState = {
  states: loadingStates,
  data: {
    content: [],
    totalPages: 0,
    totalElements: 0,
    size: 5,
  },
  response: null,
};

export default createReducer(initState, {
  [SURVEYS_FETCH_LIST.REQUEST]: (state) => {
    return update(state, {
      $merge: {
        states: setLoadingStates({ isLoading: true }),
      },
    });
  },
  [SURVEYS_FETCH_LIST.SUCCESS]: (state, action) => {
    return update(state, {
      $merge: {
        states: setLoadingStates({ isLoaded: true }),
        data: action.payload,
      },
    });
  },
  [SURVEYS_FETCH_LIST.FAILURE]: (state, action) => {
    return update(state, {
      $merge: {
        states: setLoadingStates({ hasError: true }),
        response: action.payload.response,
      },
    });
  },
});
