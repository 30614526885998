// TODO: refactor apiService
import apiService from 'shared/services/api'
import { WithId } from 'shared/types'
import { QuestionCategoryRequest } from './types'

export const getQuestionCategory = async (id: string) => {
    const { data } = await apiService({}).get(`/profile/questions/categories/${id}`)

    return data
}

export const postQuestionCategory = async (data: QuestionCategoryRequest) => {
    return await apiService({}).post('/profile/questions/categories', data)
}

export const putQuestionCategory = async ({ id, ...data }: WithId<QuestionCategoryRequest>) => {
    return await apiService({}).put(`/profile/questions/categories/${id}`, data)
}
