import { createMuiTheme  } from '@material-ui/core/styles';

const FONT_FACES = 'Open Sans, sans-serif';

export const theme = createMuiTheme ({
  palette: {
    primary: {
      main: '#42c19a',
      contrastText: '#fff',
    },
    secondary: {
      main: '#cb354b',
    },
    error: {
      main: '#cb354b',
    },
  },
  typography: {
    fontFamily: FONT_FACES,
  },
  props: {
    MuiInputBase: {
      rows: 3,
    },
    MuiButton: {
      disableElevation: true,
      disableFocusRipple: true,
      disableRipple: true,
      type: 'submit',
      variant: 'contained',
    },
    MuiTooltip: {
      arrow: true,
      placement: 'right',
    },
    MuiOutlinedInput: {
      notched: false,
    },
  },
  overrides: {
    MuiTable: {
      root: {
        tableLayout: 'fixed',
      },
    },
    MuiTableCell: {
      head: {
        color: '#7783a1',
        fontWeight: 700,
        '&.MuiTableCell-sizeSmall': {
          fontSize: '10px',
          fontWeight: 300,
        },
      },
      sizeSmall: {
        padding: '8px',
        '&.MuiTableCell-paddingCheckbox': {
          width: '48px',
        },
      },
    },
    MuiTablePagination: {
      spacer: {
        flex: '0 0 auto',
      },
    },
    MuiFormHelperText: {
      root: {
        position: 'absolute',
        bottom: 0,
      },
    },
    MuiTableRow: {
      hover: {
        cursor: 'pointer',
      },
    },
    MuiTableSortLabel: {
      active: {
        color: '#42c19a !important',
      },
    },
    MuiTypography: {
      h1: {
        fontSize: '34px',
        fontWeight: 700,
      },
      h2: {
        fontSize: '21px',
        fontWeight: 700,
        margin: '16px 0',
      },
      h3: {
        fontSize: '16px',
        fontWeight: 700,
      },
    },
    MuiButton: {
      root: {
        fontSize: '14px',
        fontWeight: 700,
        textTransform: 'none',
      },
      contained: {
        height: '40px',
        padding: '0px 30px',
      },
      text: {
        padding: 0,
        textTransform: 'uppercase',
        fontWeight: 'normal',
        fontSize: '12px',
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
      sizeSmall: {
        height: 'auto',
      },
    },
    MuiFormControl: {
      root: {
        width: '100%',
        paddingBottom: '18px',
      },
      marginNormal: {
        marginBottom: 0,
        marginTop: 0,
      },
    },
    MuiFormLabel: {
      root: {
        minHeight: '23px',
        color: '#a9b5c7',
        fontSize: '11px',
        marginBottom: '4px',
        textTransform: 'uppercase',
      },
    },
    MuiTextField: {
      root: {
        paddingBottom: 0,
      },
    },
    MuiInputBase: {
      input: {
        '&::-webkit-input-placeholder': {
          color: '#9c9c9c',
        },
        '&::-moz-placeholder': {
          color: '#9c9c9c',
        },
        '&:-ms-input-placeholder': {
          color: '#9c9c9c',
        },
        '&:-moz-placeholder': {
          color: '#9c9c9c',
        },
      },
    },
    MuiOutlinedInput: {
      inputMarginDense: {
        paddingTop: '11px',
        paddingBottom: '11px',
      },
    },
    MuiInputLabel: {
      root: {
        alignItems: 'center',
        display: 'flex',
      },
      shrink: {
        transform: 'none',
      },
      formControl: {
        position: 'static',
        transform: 'none',
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: 'white',
        boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.15)',
        color: '#a9b5c7',
        fontSize: '11px',
        padding: '10px 15px',
        minWidth: '200px',
      },
      arrow: {
        '&:before': {
          boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.15)',
          color: 'white',
          clipPath: 'inset(-8px -8px -2px -2px)',
        },
      },
    },
  },
});
