import React from 'react'
import MuiDialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'

interface DialogProps {
    children: React.ReactNode
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false
    fullWidth?: boolean
    onClose?: () => void
    open?: boolean
    title?: string
}

export const Dialog = ({
    children,
    fullWidth = false,
    maxWidth = 'md',
    onClose,
    open,
    title,
}: DialogProps) => {
    return (
        <MuiDialog open={open} fullWidth={fullWidth} maxWidth={maxWidth} onClose={onClose}>
            {title && <MuiDialogTitle>{title}</MuiDialogTitle>}
            {children}
        </MuiDialog>
    )
}

Dialog.Content = MuiDialogContent
Dialog.Actions = MuiDialogActions
