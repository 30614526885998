import React, { PureComponent } from 'react';
import styles from './styles.module.scss';

interface HeaderTitleProps {
  children: React.ReactNode;
}

class HeaderTitle extends PureComponent<HeaderTitleProps> {
  render() {
    const { children } = this.props;

    return <div className={styles.HeaderTitle}>{children}</div>;
  }
}
export default HeaderTitle;
