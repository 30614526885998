import qs from 'query-string';
import { put, takeEvery } from 'redux-saga/effects';
import apiService, { getError } from 'shared/services/api';
import { SURVEYS_FETCH_LIST } from '../../reducers/list/';
import { removeUndefinedValues } from 'shared/utils/request';

function* fetchSurveys(action) {
  try {
    const query = qs.stringify(removeUndefinedValues(action.payload.search));
    let path = `/surveys?${query}`;
    if (action.payload.search.term) {
      path = `/surveys/find?${query}`;
    }
    const { data } = yield apiService({}).get(path);

    yield put({
      type: SURVEYS_FETCH_LIST.SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield put({ type: SURVEYS_FETCH_LIST.FAILURE, payload: getError(err) });
  }
}

export default [
  function* () {
    yield takeEvery(SURVEYS_FETCH_LIST.REQUEST, fetchSurveys);
  },
];
