import React, { PureComponent } from 'react';
import classnames from 'classnames';
import map from 'lodash/map';
import get from 'lodash/get';
import styles from './styles.module.scss';

interface FieldOptionsProps {
  onSelect: (...args: any[]) => any;
  onClose?: () => void;
  dataSource: any;
  isOpen: boolean;
  name: string;
  currentValue: any;
}

class FieldOptions extends PureComponent<FieldOptionsProps> {
  wrapper = null;
  state = {
    showOnTop: false,
  };
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    this.updatePosition();
  }
  componentDidUpdate(prevProps) {
    if (this.wrapper && !prevProps.isOpen && this.props.isOpen) {
      this.updatePosition();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  updatePosition = () => {
    if (!this.wrapper) {
      return;
    }
    const wrapperHeight = get(this.wrapper, 'clientHeight');
    const wrapperOffsetTop = this.wrapper.getBoundingClientRect().top;

    if (wrapperHeight + wrapperOffsetTop > window.innerHeight && !this.state.showOnTop) {
      this.setState({ showOnTop: true });
    } else if (this.state.showOnTop) {
      this.setState({ showOnTop: false });
    }
  };

  handleClickOutside = (event) => {
    if (
      this.wrapper &&
      !this.wrapper.contains(event.target) &&
      this.props.isOpen &&
      this.props.onClose
    ) {
      event.stopPropagation();
      event.preventDefault();
      this.props.onClose();
    }
  };

  render() {
    const { dataSource, onSelect, isOpen, currentValue, name } = this.props;
    const optionsClasses = classnames(styles.FieldOptions, {
      [styles.FieldOptionsActive]: isOpen,
      [styles.FieldOptionsShowTop]: this.state.showOnTop,
    });

    return (
      <div
        className={optionsClasses}
        ref={(n) => {
          this.wrapper = n;
        }}
      >
        {map(dataSource, (option) => (
          <button
            type="button"
            className={classnames(styles.FieldOptionsOption, {
              [styles.FieldOptionsOptionActive]: option.value === currentValue,
              [styles.FieldOptionsOptionDisabled]: option.disabled,
            })}
            key={`${name}-${option.value}`}
            onClick={!option.disabled ? onSelect(option.value) : null}
          >
            {option.label}
          </button>
        ))}
      </div>
    );
  }
}

export default FieldOptions;
