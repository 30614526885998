import React, {useEffect, useCallback, useRef, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import qs from 'query-string';
import cn from 'classnames';
import {Country} from 'shared/types';
import {toast} from 'react-toastify';
import {performCountrySelect} from 'modules/App/reducers/country';
import {ReactComponent as NLFlag} from 'assets/flags/nl.svg';
import {ReactComponent as BE_WLFlag} from 'assets/flags/be_wl.svg';
import {ReactComponent as BE_FLFlag} from 'assets/flags/be_fl.svg';
import {ReactComponent as FRFlag} from 'assets/flags/fr.svg';
import {ReactComponent as GBFlag} from 'assets/flags/gb.svg';
import {ReactComponent as DEFlag} from 'assets/flags/de.svg';
import arrow from 'assets/icons/arrow-right.svg';
import styles from './styles.module.scss';

interface SelectorProps {
    country: Country;
    prettyCountry: (country: string) => string;
    isSelected?: boolean;
    onSelect: (country: Country) => void;
}

const isCountry = (country?: string): country is Country =>
    ['NL', 'BE_FL', 'FR', 'BE_WL', 'UK', 'DE'].includes(country || '');


//TODO: Replace German Flag
const flagMap: Record<Country,
    React.FC<React.SVGProps<SVGSVGElement> & { title?: string }>> = {
    NL: NLFlag,
    BE_FL: BE_FLFlag,
    FR: FRFlag,
    BE_WL: BE_WLFlag,
    UK: GBFlag,
    DE: DEFlag
};

const Selector = ({country, prettyCountry, isSelected, onSelect}: SelectorProps) => {
    const Flag = flagMap[country];
    const className = cn(styles.Selector, {
        [styles['Selector--selected']]: isSelected,
    });

    return (
        <li className={className} onClick={() => onSelect(country)}>
            <button type="button" >
                <Flag className={styles.Flag}/>
            </button>
            <p className={styles.FlagName}>{prettyCountry(country)}</p>
        </li>
    );
};

const CountrySelector = () => {
    const {push} = useHistory();
    const {pathname, search} = useLocation();
    const dispatch = useDispatch();

    const current: Country = useSelector(({ app }: any) => app.country);
    const {country: queryCountry, ...query} = qs.parse(search);

    const DEFAULT_LIST_HEIGHT_SINGLE = 40;
    const DEFAULT_LIST_HEIGHT_DOUBLE = 55;
    const MAX_LIST_HEIGHT = 180;
    const LIST_CHARACTER_LIMIT = 11;
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [contentHeight, setContentHeight] = useState<number>(DEFAULT_LIST_HEIGHT_SINGLE);
    const handleClick = () => setIsOpen(!isOpen);

    const onSelect = useCallback(country => dispatch(performCountrySelect(country)), [
        dispatch,
    ]);
    const handleSelect = useCallback(
        (country: Country) => {
            toast.info(`You are now in ${prettyCountry(country)}`);
            const search = qs.stringify({...query, country});
            const routeParts = pathname.split('/');
            if (routeParts.length > 3) {
                const nextRoute = routeParts.slice(0, 3).join('/');
                push({pathname: nextRoute, search});
            } else {
                push({search});
            }
            onSelect(country);
            setIsOpen(false);
        },
        [pathname, onSelect],
    );

    const prettyCountry = (country: string) => {
        switch (country) {
            case 'NL':
                return 'Netherlands';
            case 'BE_FL':
                return 'Belgium (Flanders)';
            case 'FR':
                return 'France';
            case 'BE_WL':
                return 'Belgium (Wallonia)';
            case 'UK':
                return 'United Kingdom';
            case 'DE':
                return 'Germany';
            default:
                return country;
        }
    }

    useEffect(() => {
        const country = isCountry(queryCountry as string) ? queryCountry : 'NL';
        const search = qs.stringify({...query, country});
        push({search});
        onSelect(country);
    }, [queryCountry]);

    useEffect(() => {
      if (isOpen) {
        const height = MAX_LIST_HEIGHT;
        setContentHeight(height);
      } else {
        if(prettyCountry(current).length > LIST_CHARACTER_LIMIT) {
            setContentHeight(DEFAULT_LIST_HEIGHT_DOUBLE);
        } else {
            setContentHeight(DEFAULT_LIST_HEIGHT_SINGLE);
        }
      }
    }, [isOpen, current]);

    return (
      <div className={styles.CountrySelector}>
        <img
          src={arrow}
          alt="arrow"
          className={`${isOpen ? styles.ArrowDown : styles.ArrowUp}`}
          onClick={handleClick}
        />
        <ul className={styles.LanguageSelector} style={{ height: contentHeight }}>
          {current && (
            <Selector
              key={current}
              country={current as Country}
              prettyCountry={prettyCountry}
              isSelected={true}
              onSelect={handleClick}
            />
          )}
          <div className={styles.OtherOptions}>
            {Object.keys(flagMap)
              .sort((a, b) => {
                const prettyA = prettyCountry(a);
                const prettyB = prettyCountry(b);
                return prettyA.localeCompare(prettyB);
              })
              .map((f: string) => {
                if (f !== current) {
                  return (
                    <Selector
                      key={f}
                      country={f as Country}
                      prettyCountry={prettyCountry}
                      isSelected={false}
                      onSelect={handleSelect}
                    />
                  );
                }
                return null;
              })}
          </div>
        </ul>
      </div>
    );
};

export default CountrySelector;
