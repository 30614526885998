import React from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Country, IntlString, LanguageTranslation } from 'shared/types';
import { LANGUAGE_TRANSLATION } from 'shared/constants/lang';

interface TranslationSelectorProps {
  onSelect: (value: LanguageTranslation) => void;
  required?: boolean;
  selected: LanguageTranslation;
  value: IntlString;
}

const LANGUAGES: LanguageTranslation[] = ['NL', 'FR', 'EN'];

const useStyles = makeStyles((t: Theme) => ({
  ul: {
    position: 'absolute',
    bottom: '3px',
    right: '14px',
    display: 'flex',
    alignItems: 'center',
    margin: 0,
    padding: 0,
    listStyle: 'none',
  },
  li: {
    margin: '0 2px',
    padding: 0,
  },
  button: {
    fontSize: '13px',
    margin: 0,
    padding: 0,
    border: 0,
    color: t.palette.grey[400],
    cursor: 'pointer',
    background: 'transparent',
    borderBottom: '1px solid transparent',
  },
  filled: {
    color: t.palette.grey[900],
  },
  selected: {
    color: t.palette.primary.main,
    borderBottomColor: t.palette.primary.main,
  },
}));

export const TranslationSelector = ({
  onSelect,
  required,
  selected,
  value,
}: TranslationSelectorProps) => {
  const selectedCountry: Country = useSelector(({ app }: any) => app.country);
  const styles = useStyles();
  const selectedLocal = LANGUAGE_TRANSLATION[selectedCountry];
  const sortedLanguages = LANGUAGES.sort((a, b) => {
    if (a === selectedLocal) {
      return -1;
    }
    if (b === selectedLocal) {
      return 1;
    }
    return 0;
  });

  return (
    <ul className={styles.ul}>
      {sortedLanguages.map(c => (
        <li className={styles.li} key={c}>
          <button
            className={cn(styles.button, {
              [styles.filled]: Boolean(value[c]),
              [styles.selected]: selected === c,
            })}
            onClick={() => onSelect(c)}
            type="button"
          >
            {c}
            {required && selectedLocal === c && <span aria-hidden>*</span>}
          </button>
        </li>
      ))}
    </ul>
  );
};
