import Amplify, { Auth } from 'aws-amplify';
Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID
  },
  Analytics: {
    disabled: true
  }
});

export const amplifyLogin = async (email, password) => {
  const { signInUserSession } = await Auth.signIn(email, password);
  return signInUserSession;
};

export const amplifySignUp = async (username, password, email) => {
  await Auth.signUp({
    username,
    password,
    attributes: { email }
  });
  await Auth.confirmSignUp(username, '498066');
};
