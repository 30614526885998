import { isNumber } from 'lodash';
import moment from 'moment';

export const dateToReadable = (date) => {
  return moment(date).format('DD-MM-YYYY HH:mm');
};

export const possibleTimeRange = () => {
  const results = [];
  for (let i = 0; i <= 24; i++) {
    results.push({
      value: i,
      label: `${i}:00`,
    });
  }
  return results;
};

export const addTimeToDate = (date, time) => {
  if (isNumber(time)) {
    return moment(date).set('hour', time).set('minute', 0).set('second', 0);
  }
  const timeArr = time.split(':');
  return moment(date)
    .set('hour', timeArr[0])
    .set('minute', timeArr[1])
    .set('second', 0)
    .utc()
    .toDate();
};

export const getSecondsFromMinutes = (hour) => {
  if (!hour) {
    return undefined;
  }
  // mm:ss
  return hour.split(':').reduce((acc, time) => 60 * acc + +time);
};
export const getHourFormatFromSeconds = (seconds) => {
  const minutes = Math.floor((seconds % 3600) / 60)
    .toString()
    .padStart(2, '0');
  const secondsLeft = Math.floor(seconds % 60)
    .toString()
    .padStart(2, '0');
  return `${minutes}:${secondsLeft}`;
  // mm:ss
};
export const getHourFromDate = (date) => {
  return moment(date).format('HH:mm'); //.get('hour');
};

export const utcDateToLocal = (date) => {
  return new Date(moment.utc(date).local());
};
export const localDateToUtc = (date) => {
  const result = moment(new Date(date).toUTCString()).toDate();
  return result;
};
