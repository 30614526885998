import { useState, useEffect, useCallback } from 'react';
import { DefaultRootState, useSelector } from 'react-redux';
import {
  RequestStatus,
  Pagination,
  Paginated,
  SortDirection,
  SortModel,
  Query,
} from 'services';
import { Country } from 'shared/types';

interface UseDataTableOptions<TData> {
  requestHandler: (query?: Query) => Promise<Paginated<TData>>;
}

interface RootState extends DefaultRootState {
  app: {
    country: Country;
    // other properties in the 'app' slice of state
  };
}

const DEFAULT_PAGINATION: Pagination = {
  pageNumber: 0,
  pageSize: 15,
  totalPages: 1,
};

export const useDataTable = <TData>({ requestHandler }: UseDataTableOptions<TData>) => {
  const [pagination, setPagination] = useState<Pagination>(DEFAULT_PAGINATION);
  const [sortModel, setSortModel] = useState<SortModel>();
  const [requestStatus, setRequestStatus] = useState<RequestStatus>();
  const [data, setData] = useState<TData[]>([]);
  const country: Country = useSelector(({ app }: RootState) => app.country);

  const fetchData = async (query?: Query) => {
    setRequestStatus('PENDING');
    try {
      const {
        content,
        totalPages,
        pageable: { pageNumber, pageSize },
      } = await requestHandler(query);
      setData(content);
      setPagination({ pageNumber, pageSize, totalPages });
      setRequestStatus('RESOLVED');
    } catch (error) {
      setRequestStatus('REJECTED');
    }
  };

  const onPageChange = useCallback(
    (page: number) => {
      const query = {
        page,
        size: pagination.pageSize,
        sort: sortModel,
      };
      setPagination({ ...pagination, pageNumber: page });
      fetchData(query);
    },
    [
      pagination.pageSize,
      pagination.totalPages,
      requestHandler,
      sortModel && sortModel.field,
      sortModel && sortModel.sort,
    ],
  );

  const onPageSizeChange = useCallback(
    (size: number) => {
      const query = {
        page: 0,
        size,
        sort: sortModel,
      };
      setPagination({ ...pagination, pageSize: size });
      fetchData(query);
    },
    [
      pagination.pageNumber,
      pagination.totalPages,
      requestHandler,
      sortModel && sortModel.field,
      sortModel && sortModel.sort,
    ],
  );

  const onSortModelChange = useCallback(
    (field: string) => {
      const isAsc = sortModel && sortModel.field === field && sortModel.sort === 'asc';
      const sort = (isAsc ? 'desc' : 'asc') as SortDirection;
      const query = {
        page: pagination.pageNumber,
        size: pagination.pageSize,
        sort: {
          field,
          sort,
        },
      };
      setSortModel({ field, sort });
      fetchData(query);
    },
    [
      pagination.pageNumber,
      pagination.pageSize,
      requestHandler,
      sortModel && sortModel.field,
      sortModel && sortModel.sort,
    ],
  );

  useEffect(() => {
    const query = {
      page: DEFAULT_PAGINATION.pageNumber,
      size: DEFAULT_PAGINATION.pageSize,
    };
    setPagination(DEFAULT_PAGINATION);
    fetchData(query);
  }, [country]);

  return {
    data,
    onPageChange,
    onPageSizeChange,
    onSortModelChange,
    pagination,
    requestStatus,
    sortModel,
  };
};
