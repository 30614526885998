import React, { PureComponent } from 'react';
import Select from 'react-select';
import find from 'lodash/find';
import FieldLabel from '../FieldLabel';

interface MultiSelectFieldProps {
  input?: any;
  label?: React.ReactNode;
  dataSource?: {
    value: number;
    label: string;
  }[];
  handleChange?: (...args: any[]) => any;
  placeholder?: string;
  disabled?: boolean;
}

class MultiSelectField extends PureComponent<MultiSelectFieldProps> {
  state = { added: [] };
  render() {
    const { input, label, dataSource, placeholder, disabled } = this.props;
    const source = [...dataSource, ...this.state.added];
    const getValue = () => {
      return find(source, { value: String(input.value) }) || input.value || '';
    };
    return (
      <div className={'AutocompleteReactSelect'}>
        <FieldLabel>{label}</FieldLabel>
        <Select
          {...input}
          className="AutocompleteReactSelect__input"
          classNamePrefix="ARS"
          isMulti
          isDisabled={disabled}
          value={getValue()}
          onChange={(value, e) => {
            input.onChange(value);
            // handleChange(value);
          }}
          onBlur={() => input.onBlur(input.value)}
          options={source}
          placeholder={placeholder}
        />
      </div>
    );
  }
}

export default MultiSelectField;
