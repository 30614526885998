import React from 'react'
import { useFormContext } from 'react-hook-form'
import MuiOutlinedInput from '@material-ui/core/OutlinedInput'
import MuiGrid from '@material-ui/core/Grid'
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { FieldWrapper, Button, DropZone } from 'components'
import { useStyles } from './FileField.styles'

interface FileFieldProps {
    accept?: string
    label?: string
    name: string
    placeholder?: string
    required?: boolean
    tooltip?: string
}

const getFileSize = (size: number) => `${(size / (1024 * 1024)).toFixed(2)} MB`

const getInputValue = (files?: File[]) =>
    files && files.length > 0 ? `${files[0].name} - ${getFileSize(files[0].size)}` : undefined

export const FileField = ({
    accept,
    label,
    name,
    placeholder = 'Please select file',
    required,
    tooltip,
}: FileFieldProps) => {
    const styles = useStyles()
    const { setValue } = useFormContext()

    const handleSelect = fileRef => () => {
        fileRef.current.click()
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault()
        const { files } = event.target
        const data = Array.from(files)
        setValue(name, data, { shouldValidate: true })
    }

    const handleDrop = (files: File[]) => {
        setValue(name, files, { shouldValidate: true })
    }

    return (
        <>
            <FieldWrapper
                defaultValue={[]}
                label={label}
                name={name}
                required={required}
                tooltip={tooltip}>
                {({ value, ref }) => (
                    <MuiGrid container alignItems="stretch">
                        <MuiGrid item xs>
                            <input
                                accept={accept}
                                className={styles.file}
                                onChange={handleChange}
                                ref={ref}
                                type="file"
                            />
                            <MuiOutlinedInput
                                classes={{ root: styles.field }}
                                fullWidth
                                onClick={handleSelect(ref)}
                                placeholder={placeholder}
                                readOnly
                                value={getInputValue(value)}
                            />
                        </MuiGrid>
                        <MuiGrid item>
                            <Button
                                className={styles.button}
                                color="default"
                                onClick={handleSelect(ref)}
                                startIcon={<CloudUploadIcon />}
                                type="button">
                                Select
                            </Button>
                        </MuiGrid>
                    </MuiGrid>
                )}
            </FieldWrapper>
            <DropZone accept={accept} onDrop={handleDrop} placeholder="or drop here" />
        </>
    )
}
