import * as yup from 'yup';
import { specialCharsRegex } from 'shared/utils/regex';
import { validationMessage } from 'shared/constants/message';

const date = yup.date().transform((cv, ov) => (ov === null ? undefined : cv));
const string = yup
  .string()
  .transform((cv, ov) => (ov === '' ? null : cv))
  .nullable()
  .notRequired();

export const schema = yup.object({
  dateFrom: date.required(),
  dateTo: date.required(),
  filterName: yup
    .string()
    .required()
    .matches(specialCharsRegex, validationMessage.filterNamePunctuation),
  radius: yup
    .number()
    .transform((cv, ov) => (ov === '' ? undefined : cv))
    .required(),
  locationType: string,
  locationDetailName: string,
  locationDetailId: string,
  address: string,
  postalCode: string,
  city: string,
  province: string,
  country: string,
});
