import React, { PureComponent } from 'react';
import FieldWrapper from '../FieldWrapper';
import styles from './styles.module.scss';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import moment from 'moment';
import MomentLocaleUtils from 'react-day-picker/moment';

interface DateFieldProps {
  input: {
    value: string;
    onChange: (day: string) => void;
    name: string;
  };
  disabled: boolean;
}

class DateField extends PureComponent<DateFieldProps> {
  handleDayChange = (date: Date) => {
    if (date !== undefined) {
      const dateWithNoTime = moment(date).format('LL');
      const dateFormatted = moment(dateWithNoTime).format('YYYY-MM-DD');
      this.props.input.onChange(dateFormatted);
    }

    if (date === undefined) {
      this.props.input.onChange('');
    }
  };

  render() {
    return (
      <FieldWrapper {...this.props}>
        <DayPickerInput
          inputProps={{
            className: styles.DateFieldInput,
            disabled: this.props.disabled,
            maxlength: '10',
          }}
          value={
            this.props.input.value !== '' && moment(this.props.input.value).format('DD-MM-YYYY')
          }
          formatDate={MomentLocaleUtils.formatDate}
          parseDate={MomentLocaleUtils.parseDate}
          format="DD-MM-YYYY"
          placeholder="DD-MM-YYYY"
          onDayChange={this.handleDayChange}
          dayPickerProps={{
            todayButton: 'Today',
            firstDayOfWeek: 1,
          }}
        />
      </FieldWrapper>
    );
  }
}

export default DateField;
