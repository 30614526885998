import React from 'react'
import { Field } from 'redux-form';
import MuiBox from '@material-ui/core/Box';
import { Trash } from 'shared/components/Icons';
import IconButton from 'shared/components/IconButton';
import TextField from 'shared/components/TextField';
import CheckboxField from 'shared/components/CheckboxField';
import TextFieldI18N from 'shared/components/TextFieldI18N';
import SelectField from 'shared/components/SelectField';
import FieldLabel from 'shared/components/FieldLabel';

import styles from './styles.module.scss';

interface AnswerItemProps {
    bucketCategories: any[]
    formValues: any
    index: number
    field: string
    onDelete: (index: number) => void
}

export const AnswerItem = ({
    bucketCategories,
    formValues,
    index,
    onDelete,
    field,
}: AnswerItemProps) => {
    return (
        <>
            <div className="row">
                <div className="col-md-1 d-flex justify-content-center align-items-center">
                    {index + 1}.{' '}
                </div>
                <div className="col-md-10">
                    <MuiBox display="flex" alignItems="center" justifyContent="flex-start" mb={2}>
                        <FieldLabel className={styles.inline}>Filter Value (for Dashboard)</FieldLabel>
                        {formValues.type === 'CHECKBOX' && (
                            <Field
                                component={CheckboxField}
                                className={styles.inline}
                                name={`${field}.exclusive`}
                            >
                                Make answer exclusive
                            </Field>
                        )}
                    </MuiBox>
                    <Field
                        component={TextField}
                        name={`${field}.value`}
                        tooltip="Write a clear value for clients to understand. This is not visible within the THX. app!"
                        className="CampaignHeader__input"
                        isRequired
                    />
                </div>
                <div className="col-md-1 d-flex justify-content-center align-items-center">
                    <IconButton onClick={() => { onDelete(index) }}>
                        <Trash />
                    </IconButton>
                </div>
            </div>
            <div className="row">
                <div className="col-md-1" />
                <div className="col-md-10">
                    <Field
                        component={TextFieldI18N}
                        name={`${field}.titles`}
                        label="Answer in the APP"
                        tooltip="This value is visible in the THX. app and should be easy to understand for users."
                        className="CampaignHeader__input"
                        isRequired
                    />
                </div>
            </div>
            {formValues.categorizedAnswers && (
                <div className="row">
                    <div className="col-md-1" />
                    <div className="col-md-10">
                        <Field
                            component={SelectField}
                            dataSource={bucketCategories.map((c) => ({ value: c, label: c }))}
                            name={`${field}.bucketCategory`}
                            label="Category"
                            isRequired
                            readOnly
                        />
                    </div>
                </div>
            )}
        </>
    )
}
