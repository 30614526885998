import React from 'react';
import { UserInfoForm } from 'components/UserInfoForm';
import { useDetails } from 'hooks';
import { useUserManagement } from 'modules/user';
import { routes } from 'shared/constants/routes';
import { ContentLoader } from 'components';

export const UserDetails = () => {
  const { fetchUser, updateUser } = useUserManagement();
  const { data, onSave, requestStatus } = useDetails<any>({
    fetch: fetchUser,
    create: updateUser,
    update: updateUser,
    redirectTo: routes.users.default.path,
  });

  const defaultValues =  data ? {...data, referralAwarded: data?.referralAwarded ? "Yes" : "No"} : { pushNoteEnabled: true };

  return (
    <ContentLoader status={requestStatus}>
      <UserInfoForm defaultValues={defaultValues} onSubmit={onSave} />
    </ContentLoader>
  );
};
