import React from 'react'
import { useHistory } from 'react-router-dom'
import qs from 'query-string'
import { useClientManagement, ClientResponse } from 'modules/campaignM'
import { Page, PageHeader, DataTable } from 'components'

import { routes } from 'shared/constants/routes'

const COLUMNS = [
    {
        field: 'name',
        headerName: 'Name',
    },
    {
        field: 'campaignCount',
        headerName: 'Campaign Count',
    },
]

export const ClientsList = () => {
    const {
        location: { search },
        push,
    } = useHistory()
    const { fetchClients } = useClientManagement()

    const handleRowClick = (name: string) => {
        push({
            pathname: routes.campaigns.default.path,
            search: `?${qs.stringify({ ...qs.parse(search), client: name })}`,
        })
    }

    return (
        <Page header={<PageHeader title="Clients" />}>
            <DataTable<ClientResponse>
                columns={COLUMNS}
                idField="name"
                onRowClick={handleRowClick}
                requestHandler={fetchClients}
            />
        </Page>
    )
}
