import { useState, useEffect } from 'react'
import { uniqBy } from 'lodash'

interface UsePlacesOptions {
    map?: google.maps.Map
}

const CITIES = ['Amsterdam', 'Rotterdam', 'Utrecht', 'Groningen', 'Zwolle', 'Maastricht']

export const useGooglePlaces = ({ map }: UsePlacesOptions) => {
    const [service, setService] = useState<google.maps.places.PlacesService>()

    const locationSearch = async (
        query: string,
        city: string,
    ): Promise<google.maps.places.PlaceResult[]> =>
        new Promise(resolve => {
            let data: google.maps.places.PlaceResult[] = []
            const request = {
                query: `${query} ${city}`,
                fields: ['name', 'geometry'],
            }
            service.textSearch(request, (places, _, pagination) => {
                data = [...data, ...places]
                if (pagination.hasNextPage) {
                    pagination.nextPage()
                } else {
                    resolve(data)
                }
            })
        })

    const onSearch = async (query: string): Promise<google.maps.places.PlaceResult[]> => {
        try {
            const results = await Promise.all(CITIES.map(city => locationSearch(query, city)))
            let data: google.maps.places.PlaceResult[] = []
            results.forEach(result => {
                data = [...data, ...result]
            })
            const uniqueData = uniqBy(data, 'place_id')
            return uniqueData
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if (map) {
            setService(new google.maps.places.PlacesService(map))
        }
    }, [map])

    return {
        onSearch,
    }
}
