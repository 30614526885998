import qs from 'query-string';
import { put, takeEvery } from 'redux-saga/effects';
import apiService, { getError } from 'shared/services/api';
import { DISCUSSIONS_FETCH_LIST } from '../../reducers/list/';

function* fetchQuestions(action) {
  try {
    const query = qs.stringify(action.payload.search);
    let path = `/discussions?${query}`;
    if (action.payload.search.term) {
      path = `/discussions/find?${query}`;
    }
    const { data } = yield apiService({}).get(path);

    yield put({
      type: DISCUSSIONS_FETCH_LIST.SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield put({ type: DISCUSSIONS_FETCH_LIST.FAILURE, payload: getError(err) });
  }
}

export default [
  function* () {
    yield takeEvery(DISCUSSIONS_FETCH_LIST.REQUEST, fetchQuestions);
  },
];
