import axios from 'axios';
import storeJS from 'store';
import { Auth } from 'aws-amplify';
import { toast } from 'react-toastify';
import get from 'lodash/get';
import createAuthRefreshInterceptor from 'shared/interceptors/refreshToken';
import { store } from '../../store';
import { STORAGE_AUTH_KEY } from '../constants/auth';
import { performLogout } from '../../modules/Auth/reducers/login';

export default ({ baseUrl = '', headers: newHeaders = {}, includeCountryParam = true }) => {
  const auth = storeJS.get(STORAGE_AUTH_KEY);
  const appStore = store.getState();
  const { token } = get(appStore, 'auth.data') || {};
  const authToken = get(auth, 'idToken.jwtToken') || token;

  const headers = {
    authorization: authToken,
    ...newHeaders
  };

  const instance = axios.create({
    baseURL: baseUrl || process.env.REACT_APP_BACKEND_URL,
    headers
  });
  const refreshTokenCall = failedRequest =>
    new Promise(async (resolve, reject) => { // eslint-disable-line
      try {
        const cognitoUser = await Auth.currentAuthenticatedUser();
        const currentSession = await Auth.currentSession();
        cognitoUser.refreshSession(
          currentSession.refreshToken,
          (err, session) => {
            if (err) {
              store.dispatch(performLogout());
              reject(err);
            }
            const { idToken } = session;
            storeJS.set(STORAGE_AUTH_KEY, session);
            failedRequest.response.config.headers['authorization'] =
              idToken.jwtToken;

            return resolve(true);
          }
        );
      } catch (e) {
        store.dispatch(performLogout());
        reject({});
      }
    });
  createAuthRefreshInterceptor(instance, refreshTokenCall);
  instance.interceptors.request.use((config) => {
    if (includeCountryParam) {
      const spacer = config.url.includes('?') ? '&' : '?';
      const lang = get(appStore, 'app.country') || 'NL';
      const url = `${config.url}${spacer}country=${lang}`;
      return {
        ...config,
        url,
      };
    }

    const url = config.url
    return {
      ...config,
      url,
    };
  })
  instance.interceptors.response.use(
    res => res,
    err => {
      const status = get(err, 'response.data.status');
      if (status === 504) {
        toast.error('Error: Backend timeout 504');
      }
      return Promise.reject(err);
    }
  );
  return instance;
};

export const getError = response => {
  const result = {
    response: get(response, 'errors[0]') || response.error || 'error'
  };

  if (response.error === 'invalid_token') {
    result.type = 'UNAUTHORIZED_TOKEN';
  }

  return result;
};
