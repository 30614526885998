import React from 'react'
import { useFormContext } from 'react-hook-form'
import { uniqueId } from 'lodash'
import MomentUtils from '@date-io/moment'
import 'moment/locale/en-gb'
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from '@material-ui/pickers'
import { FieldWrapper } from 'components'
import { useStyles } from './DateTimeField.styles'

interface DateTimeFieldProps {
    defaultValue?: Date | string | null
    disabled?: boolean
    label?: string
    name: string
    placeholder?: string
    required?: boolean
    small?: boolean
    tooltip?: string
}

export const DateTimeField = ({
    defaultValue = null,
    disabled,
    label,
    name,
    placeholder = "DD-MM-YYYY HH:mm",
    required,
    small,
    tooltip,
}: DateTimeFieldProps) => {
    const styles = useStyles()
    const { trigger } = useFormContext()
    const handleChange = onChange => (...args: any[]) => {
        onChange(...args)
        trigger(name)
    }
    const id = uniqueId(name)

    return (
        <MuiPickersUtilsProvider utils={MomentUtils} locale="en-GB">
            <FieldWrapper
                defaultValue={defaultValue}
                disabled={disabled}
                htmlFor={id}
                label={label}
                name={name}
                required={required}
                small={small}
                tooltip={tooltip}>
                {({ ref, value, onChange, ...field }, { invalid }) => (
                    <KeyboardDateTimePicker
                        ampm={false}
                        autoOk
                        className={small ? styles.small: ''}
                        format="DD-MM-YYYY HH:mm"
                        maxDateMessage=""
                        minDateMessage=""
                        invalidDateMessage=""
                        id={id}
                        placeholder={placeholder}
                        InputProps={{
                            error: invalid,
                            inputRef: ref,
                            fullWidth: true,
                            ...field,
                        }}
                        inputVariant="outlined"
                        onChange={handleChange(onChange)}
                        value={value}
                        variant="inline"
                    />
                )}
            </FieldWrapper>
        </MuiPickersUtilsProvider>
    )
}
