import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { CampaignForm, ContentLoader } from 'components'
import { GMapProvider, GMap, GMapSearch } from 'components/GMap'
import { useDetails } from 'hooks'
import {
    CampaignResponse,
    LocationResponse,
    useCampaignManagement,
    useClientManagement,
} from 'modules/campaignM'
import { sortByLabel } from 'helpers'
import { routes } from 'shared/constants/routes'
import { Misc } from './Misc'

export const CampaignDetails = () => {
    const [clients, setClients] = useState<{ value: string; label: string }[]>([])
    const [locations, setLocations] = useState<LocationResponse[]>([])
    const { createCampaign, fetchCampaign, updateCampaign } = useCampaignManagement()
    const { fetchClients } = useClientManagement()

    const { data, onSave, requestStatus } = useDetails<CampaignResponse>({
        fetch: fetchCampaign,
        create: createCampaign,
        update: updateCampaign,
        redirectTo: routes.campaigns.default.path,
    })

    useEffect(() => {
        const fetch = async () => {
            const { content } = await fetchClients({ size: 100000 })
            setClients(content.map(({ name }) => ({ value: name, label: name })).sort(sortByLabel))
        }
        fetch()
    }, [])

    useEffect(() => {
        if (data && data.locations) {
            setLocations(data.locations)
        }
    }, [data && data.locations])


    const handleLocationUpdate = (location: LocationResponse) => {
        const newLocations = locations.map(l => l.id === location.id ? location : l)
        setLocations(newLocations)
    }

    const handleAddLocation = (location: LocationResponse) => {
        setLocations([...locations, location])
    }


    const map = data && data.id ? <GMap locations={locations} onLocationAdd={handleAddLocation} onLocationUpdate={handleLocationUpdate} /> : null

    const misc = data && data.id ? <Misc data={data} locations={locations} setLocations={setLocations} /> : null

    return (
        <ContentLoader status={requestStatus}>
            <GMapProvider>
                <CampaignForm
                    clients={clients}
                    defaultValues={data}
                    map={map}
                    misc={misc}
                    onSubmit={onSave}
                />
            </GMapProvider>
        </ContentLoader>
    )
}
