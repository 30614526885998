import React from 'react'
import MuiRadioGroup from '@material-ui/core/RadioGroup'
import MuiRadio from '@material-ui/core/Radio'
import MuiFormControlLabel from '@material-ui/core/FormControlLabel'
import { FieldWrapper } from 'components'

interface Option {
    label: string
    value: string
}

interface RadioGroupProps {
    defaultValue?: string
    disabled?: boolean
    label?: string
    name: string
    options: Option[]
    required?: boolean
    tooltip?: string
}

export const RadioGroup = ({
    defaultValue = '',
    disabled,
    label,
    name,
    options,
    required,
    tooltip,
}: RadioGroupProps) => (
    <FieldWrapper
        defaultValue={defaultValue}
        disabled={disabled}
        label={label}
        name={name}
        required={required}
        tooltip={tooltip}>
        {field => (
            <MuiRadioGroup {...field} row>
                {options.map(option => (
                    <MuiFormControlLabel
                        control={<MuiRadio color="default" />}
                        key={option.value}
                        label={option.label}
                        value={option.value}
                    />
                ))}
            </MuiRadioGroup>
        )}
    </FieldWrapper>
)
