import forEach from 'lodash/forEach';
import * as types from '../../types/QuestionManageTypes';

const requiredFields = ['filterName', 'categoryId', 'points', 'type'];

export default (
  values: types.QuestionManageFormTypesValues,
  props: types.QuestionManageFormTypesProps,
) => {
  const formValues =
    Object.keys(props.formValues || {}).length < 1 ? props.initialValues : props.formValues;
  const errors = { titles: '', shortTitles: '', answers: {} };
  if (!formValues.titles) {
    errors.titles = 'Fill in';
  }
  if (!formValues.shortTitles) {
    errors.shortTitles = 'Fill in';
  }

  forEach(formValues.answers, (answer: any = {}, index) => {
    errors.answers[index] = { titles: '', value: '' };
    forEach(answer.titles, (title, titleIndex) => {
      errors.answers[index].titles = '';
      if (!answer.titles) {
        errors.answers[index].titles = 'Fill in';
      }
    });
    if (!answer.value) {
      errors.answers[index].value = 'Fill in';
    }
  });
  forEach(requiredFields, (field) => {
    if (!values[field]) {
      errors[field] = 'Fill in';
    }
  });
  return errors;
};
