import * as yup from 'yup'
import { stringIntl, stringImageUrl } from 'services/validation'

const date = yup.date().transform((cv, ov) => (ov === null ? undefined : cv))

export const schema = yup.object({
    name: yup.string().required(),
    description: stringIntl('description'),
    pushNoteEnabled: yup.boolean(),
    pushNoteTitle: stringIntl('pushNoteTitle'),
    pushNoteBody: stringIntl('pushNoteBody', false),
    pushNoteDate: date.nullable().notRequired(),
    pointsNeeded: yup
        .number()
        .transform((cv, ov) => (ov === '' ? undefined : cv))
        .required()
        .integer()
        .min(0),
    maximumAmount: yup
        .number()
        .transform((cv, ov) => (ov === '' ? undefined : cv))
        .nullable()
        .notRequired()
        .integer()
        .min(0),
    imageUrl: stringImageUrl.required(),
    dateFrom: date.required().when('dateTo', (dateTo, s) => {
        if (!dateTo) {
            return s
        }

        return yup.date().max(dateTo)
    }),
    dateTo: date.required().min(yup.ref('dateFrom')),
})
