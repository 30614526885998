import { CountryLanguageTranslation } from 'shared/types';

export const LANGUAGES = [{ value: 'nl', label: 'Dutch' }];

export const LANGUAGE_TRANSLATION: CountryLanguageTranslation = {
  BE_FL: 'NL',
  NL: 'NL',
  BE_WL: 'FR',
  FR: 'FR',
  UK: 'EN',
  DE: 'DE'
};
