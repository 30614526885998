import { useEffect, useMemo } from 'react'
import { useForm as useReactHookForm, DefaultValues } from 'react-hook-form'
import Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

interface UseFormOptions<TFieldValues> {
    defaultValues?: DefaultValues<TFieldValues>
    schema?: Yup.AnyObjectSchema
}

export const useForm = <TFieldValues>({ defaultValues, schema }: UseFormOptions<TFieldValues>) => {
    const formMethods = useReactHookForm<TFieldValues>({
        defaultValues,
        mode: 'onBlur',
        resolver: schema && yupResolver(schema),
    })

    const {
        formState: { isSubmitting, isValid, isSubmitted },
    } = formMethods

    const isSubmitDisabled = useMemo(() => isSubmitting || (isSubmitted && !isValid), [
        isSubmitting,
        isSubmitted,
        isValid,
    ])

    useEffect(() => {
        formMethods.reset(defaultValues)
    }, [defaultValues])

    return {
        formMethods,
        isSubmitDisabled,
        isSubmitting,
        isValid
    }
}
