import { combineReducers } from 'redux';

import list from './list';
import answers from './answers';
import manage from './manage';

export default combineReducers({
  answers,
  list,
  manage,
});
