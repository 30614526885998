import axios from 'axios';

const headers = {
  Authorization: process.env.REACT_APP_PEXELS_API_KEY
};

const instance = axios.create({
  baseURL: 'https://api.pexels.com/v1/',
  headers
});

export const searchPexelsByQuery = (query) => {
  return instance.get(`search?query=${query}`)
}
export const searchPexels = (query) => {
  return instance.get(`search?${query}`)
}

