import React from 'react';
import { Route } from 'react-router-dom';
import { routes } from 'shared/constants/routes';
import { QuestionCategory } from 'views';
import CategoriesList from './containers/CategoriesList';
import QuestionsList from './containers/QuestionsList';
import QuestionsManage from './containers/QuestionsManage';

export default () => (
  <>
    <Route
      path={routes.completionSurveys.questions.list.path}
      key="completions-surveys-questions-list"
      component={QuestionsList}
      exact
    />
    <Route
      path={routes.completionSurveys.questions.manage('(edit|create)/:id?').path}
      key="completions-surveys-questions-manage"
      component={QuestionsManage}
      exact
    />
    <Route
      path={routes.completionSurveys.categories.list.path}
      key="completions-surveys-categories-list"
      component={CategoriesList}
      exact
    />
    <Route
      path={routes.completionSurveys.default.path}
      key="completions-surveys-default"
      component={CategoriesList}
      exact
    />
    <Route
      path={routes.completionSurveys.categories.manage('(edit|create)/:id?').path}
      key="completion-surveys-categories-manage"
      component={QuestionCategory}
      exact
    />
  </>
);
