import React from 'react'
import { Form, FormProps, TextIntlField, Button } from 'components'
import { schema } from './validation'
import { QuestionCategoryFormData } from './types'
import {TextField} from 'components'
import { DAYS_AFTER_TOOLKIT } from './constants'

export const QuestionCategoryForm = ({
    defaultValues,
    onSubmit,
}: FormProps<QuestionCategoryFormData>) => (
    <Form<QuestionCategoryFormData>
        defaultValues={defaultValues}
        onSubmit={onSubmit}
        schema={schema}>
        {({ isSubmitDisabled, isSubmitting }) => (
            <>
                <TextIntlField label="Title" name="titles" required />
                <TextField
                    label="Days After"
                    name="daysAfter"
                    required
                    tooltip={DAYS_AFTER_TOOLKIT}
                />
                <Button disabled={isSubmitDisabled} loading={isSubmitting}>
                    Save
                </Button>
            </>
        )}
    </Form>
)
