import React from 'react';
import CheckboxField from 'shared/components/CheckboxField';
import { Field } from 'redux-form';
import get from 'lodash/get';
import FieldLabel from 'shared/components/FieldLabel';
import SurveyBucketField from '../SurveyBucketField';
import styles from './styles.module.scss';
import Button from 'shared/components/Button';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';
import keys from 'lodash/keys';

const calculateAllocationByPercentage = ({ percentage, capacity }) => (capacity * percentage) / 100;
const calculateAllocationPercentageByAllocation = ({ allocation, capacity }) =>
  ((allocation * 100) / capacity).toFixed(2);

interface SurveyBucketOptionsTypes {
  name: string;
  label: string;
  options: {
    label: string;
    value: string;
  }[];
  isNested: boolean;
  values: any;
  change: (...args: any[]) => any;
  capacity: number;
}

const SurveyBucketOptions = ({ name, label, options, isNested, values, change, capacity }: SurveyBucketOptionsTypes ) => {
  const splitEvenly = () => {
    const activeOptions = keys(pickBy(values, identity)) || [];
    activeOptions.forEach((key) => {
      const percentage = Number((1 / activeOptions.length) * 100);
      const allocation = Number((capacity * percentage) / 100);
      change(`${name}.${key}.allocation`, allocation.toFixed(0));
      change(`${name}.${key}.allocationPercentage`, percentage.toFixed(2));
    });
  };

  const onAllocationPercentageChange = (key) => (e) => {
    change(
      `${name}.${key}.allocation`,
      calculateAllocationByPercentage({
        percentage: e.target.value,
        capacity,
      }),
    );
  };
  const onAllocationChange = (key) => (e) => {
    change(
      `${name}.${key}.allocationPercentage`,
      calculateAllocationPercentageByAllocation({
        allocation: e.target.value,
        capacity,
      }),
    );
  };

  return (
    <table>
      <tbody>
        <tr>
          <td>
            <FieldLabel>{label}</FieldLabel>
          </td>
        </tr>
        {options.map((option) => (
          <tr>
            <td className={styles.left} valign="middle">
              <Field
                component={CheckboxField}
                isRequired
                className={styles.checkbox}
                name={`${name}[${option.value}]`}
              >
                {option.label}
              </Field>
            </td>
            <td className={styles.right} valign="middle">
              {get(values, option.value) && isNested && (
                <>
                  <div className="d-flex">
                    <Field
                      component={SurveyBucketField}
                      name={`${name}[${option.value}].allocation`}
                      onChange={onAllocationChange(option.value)}
                    />
                    (
                    <Field
                      component={SurveyBucketField}
                      name={`${name}[${option.value}].allocationPercentage`}
                      onChange={onAllocationPercentageChange(option.value)}
                    />
                    %)
                  </div>
                </>
              )}
            </td>
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <td>
            {isNested && (
              <Button ultraSmall buttonType="button" onClick={splitEvenly}>
                Split evenly
              </Button>
            )}
          </td>
        </tr>
      </tfoot>
    </table>
  );
};
export default SurveyBucketOptions;
