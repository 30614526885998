// TODO: refactor apiService
import apiService from 'shared/services/api'

import { WithId } from 'shared/types'
import { SurveyRequest, SurveyQuestionRequest, SurveyQuestionResponse } from './types'

export const getSurvey = async (id: string) => {
    const { data } = await apiService({}).get(`/surveys/${id}`)

    return data
}

export const postSurvey = async (data: SurveyRequest) => {
    await apiService({}).post('/surveys', data)
}

export const putSurvey = async ({ id, ...data }: WithId<SurveyRequest>) => {
    await apiService({}).put(`/surveys/${id}`, data)
}

export const getMonkeys = async () => {
    const { data } = await apiService({}).get('/surveys/survey-monkey')

    return data
}

export const getSurveyQuestions = async (id: string): Promise<SurveyQuestionResponse[]> => {
    const { data } = await apiService({}).get(`/surveys/${id}/questions`)

    return data
}

export const putSurveyQuestions = async (data: SurveyQuestionRequest[]): Promise<SurveyQuestionResponse[]> => {
    const response = await apiService({}).put(`/surveys/${data[0].surveyId}/questions`, data)

    return response.data
}
