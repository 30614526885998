import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import map from 'lodash/map';
import find from 'lodash/find';
import filter from 'lodash/filter';
import get from 'lodash/get';
import forEach from 'lodash/forEach';
import Button from 'shared/components/Button';
import MediaFile from 'shared/components/MediaFile';
import EditMediaFile from 'shared/components/EditMediaFile';
import AddMediaModal from '../../components/AddMediaModal';
import { uploadMedia, deleteMediaFromAcr, checkForS3Files } from '../../reducers/manage';
import styles from './styles.module.scss';
import * as types from '../../types/CampaignManageMediaTypes';

class Manage extends PureComponent<
  types.CampaignManageMediaProps,
  types.CampaignManageMediaState
> {
  s3FilesTimer = false;
  timer;
  state = {
    addMediaModal: false,
    files: [],
    clientNames: [],
    mediaTypes: [],
    filterNames: [],
    processingFiles: [],
    fileMetadata: {}
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.processingS3FilesChecker && this.props.processingS3FilesChecker) {
      this.timer = setInterval(this.checkS3Files, 5000);
    }
    if (
      prevProps.processingS3FilesChecker &&
      !this.props.processingS3FilesChecker &&
      this.timer
    ) {
      clearInterval(this.timer);
      this.setState({ processingFiles: [] });
      this.timer = null;
    }
    if (prevProps.uploadMediaFiles !== this.props.uploadMediaFiles) {
      const updatedProcessingFiles = [...this.state.processingFiles];
      const remainingFiles = [];
      map(this.state.files, file => {
        const fileData = find(this.props.uploadMediaFiles, { id: file.id });
        if (fileData) {
          if (fileData.progress > 0) {
            file.progress = fileData.progress;
            updatedProcessingFiles.push(file);
          } else {
            remainingFiles.push(file);
          }
        }
      });
  
      this.setState({
        processingFiles: updatedProcessingFiles,
        files: remainingFiles,
      });
    }
  }  

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  checkS3Files = () => {
    const { campaignId } = this.props;
    this.props.checkForS3Files({ campaignId });
  };

  buildFilter = (fileId) => {
    if (this.state.fileMetadata[fileId].channel) {
      return String(this.state.fileMetadata[fileId].media + " - " + this.state.fileMetadata[fileId].channel + " - " + this.state.fileMetadata[fileId].filter)
    } else {
      return String(this.state.fileMetadata[fileId].media + " - " + this.state.fileMetadata[fileId].filter)
    }
  }

  uploadFiles = () => {
    const { data, campaignId } = this.props;
    forEach(this.state.files, file => {
      this.props.uploadMedia({
        file: file.file,
        id: file.id,
        campaignId: campaignId,
        campaignName: get(data, 'name'),
        client: get(data, 'clientName'),
        filter: this.buildFilter(file.id),
        bucketName: get(data, 'acrCloud.bucketName'),
      });
    });
  };

  removeFiles = () => {
    this.setState({files: []})
  }

  sanitizeFilename = filename => {
    let normalized = filename.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    let sanitized = normalized.replace(/[^a-zA-Z0-9\-_.]/g, '');
    return sanitized;
  }

  onAdd = mediaFormValues => {
    let newFiles = [];
    let newFileMetadata = { ...this.state.fileMetadata };
    let index = 0;

    for (let file of mediaFormValues.files) {
      const nameParts = file.name.split('.');
      const ext = nameParts.pop();
      const nameWithoutExt = nameParts.join('.');

      const sanitizedBaseName = this.sanitizeFilename(nameWithoutExt);
      const sanitizedFileName = `${sanitizedBaseName}.${ext}`;

      let f = new File([file], sanitizedFileName, { type: file.type });
      const fileId = sanitizedFileName;
      
      newFiles.push({ id: fileId, file: f });

      if (mediaFormValues.channelNames) {
        newFileMetadata[fileId] = {
          filter: mediaFormValues.filterNames[index],
          client: mediaFormValues.clientNames[index],
          media: mediaFormValues.mediaTypes[index],
          channel: mediaFormValues.channelNames[index]
        };
      } else {
        newFileMetadata[fileId] = {
          filter: mediaFormValues.filterNames[index],
          client: mediaFormValues.clientNames[index],
          media: mediaFormValues.mediaTypes[index]
        };
      }

      index++;
    }
    this.setState({
      files: [...this.state.files, ...newFiles],
      fileMetadata: newFileMetadata,
      addMediaModal: false
    });
  };

  onDeleteFile = id => () => {
    this.setState({
      files: filter(this.state.files, file => file.id !== id),
    });
  };

  onDeleteAcrFile = fileId => () => {
    const { campaignId } = this.props;
    this.props.deleteMediaFromAcr({
      campaignId,
      fileId,
    });
  };

  isFileDeleting = fileId => {
    return find(this.props.deleteAcrFile, { fileId });
  };

  toggleModal = () => {
    this.setState({ addMediaModal: !this.state.addMediaModal });
  };

  handleFileMetadataChange = (fileId, field, value) => {
    this.setState(prevState => ({
      fileMetadata: {
        ...prevState.fileMetadata,
        [fileId]: {
          ...prevState.fileMetadata[fileId],
          [field]: value
        }
      }
    }));
  };


  render() {
    return (
      <div>
        <AddMediaModal
          onAdd={this.onAdd}
          isOpen={this.state.addMediaModal}
          onClose={this.toggleModal}
          clientName={this.props.data.clientName}
        />
        <h3 className={styles.CampaignFormMediaTitle}>
          Media{' '}
          <button
            type="button"
            className={styles.CampaignFormMediaAdd}
            onClick={this.toggleModal}
          >
            +
          </button>
        </h3>
        {get(this.props.media, 'length') > 0 && (
          <h4>Uploaded files ({get(this.props.media, 'length')})</h4>
        )}
        {map(this.props.media, (file, index) => {
          return (
            <MediaFile
              fileData={{}}
              key={`media-file-${file.audioId}-${index}`}
              onDelete={this.onDeleteAcrFile(file.acrId)}
              fileName={file.audioTitle}
              isDeleting={this.isFileDeleting(file.acrId)}
              media={get(file, 'metadata.media')}
              filter={get(file, 'metadata.filter')}
              client={get(file, 'metadata.client')}
              channel={get(file, 'metadata.channel')}
            />
          );
        })}

        {(get(this.state.processingFiles, 'length') > 0 ||
          get(this.props.processingS3Files, 'length') > 0) && <h4>Processing files</h4>}

        {!get(this.props.processingS3Files, 'length') &&
          map(this.state.processingFiles, (file, index) => {
            const fileData = find(this.props.uploadMediaFiles, {
              id: file.id,
            });
            return (
              <MediaFile
                fileData={fileData}
                key={`media-file-${file.file.name}-${index}`}
                onDelete={this.onDeleteFile(file.id)}
                isProcessing
                fileName={file.file.name}
                media={this.state.fileMetadata[file.id].media}
                filter={this.state.fileMetadata[file.id].filter}
                client={this.state.fileMetadata[file.id].client}
                channel={this.state.fileMetadata[file.id].channel}
              />
            );
          })}

        {map(this.props.processingS3Files, (file, index) => {
          return (
            <MediaFile
              fileData={{}}
              key={`media-file-${file.Key}-${index}`}
              onDelete={null}
              isProcessing
              fileName={file.realFileName || file.Key}
              media={file.acrFilter}
            />
          );
        })}
        {get(this.state.files, 'length') > 0 && <h4>Files to upload</h4>}
        {map(this.state.files, (file, index) => {
          const fileData = find(this.props.uploadMediaFiles, {
            id: file.id,
          });
          return (
            <EditMediaFile
              key={`media-file-${file.file.name}-${index}`}
              onDelete={this.onDeleteFile(file.id)}
              fileName={file.file.name}
              media={this.state.fileMetadata[file.id].media}
              filter={this.state.fileMetadata[file.id].filter}
              client={this.state.fileMetadata[file.id].client}
              channel={this.state.fileMetadata[file.id].channel}
              onMetadataChange={(field, value) => this.handleFileMetadataChange(file.id, field, value)}
            />
          );
        })}
        {this.state.files.length > 0 && (
          <div style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
            <Button onClick={this.uploadFiles} type="primary" icon="upload" buttonType="button">
              Upload these files
            </Button>
            <Button onClick={this.removeFiles} type="red" icon="trash" buttonType="button">
              Remove all files
            </Button>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ campaigns: { manage } }, { campaignId }) => ({
  media: campaignId ? manage.campaign.media : [],
  states: manage.campaign.states,
  processingS3Files: manage.campaign.processingS3Files,
  processingS3FilesChecker: manage.campaign.processingS3FilesChecker,
  isUploadingLocations: manage.uploadLocations.states.isLoading,
  uploadMediaFiles: manage.uploadMedia,
  deleteAcrFile: manage.deleteAcrFile,
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      uploadMedia,
      deleteMediaFromAcr,
      checkForS3Files,
    },
    dispatch,
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(Manage);
