import React, { useState, useContext, useEffect } from 'react'
import { unionBy } from 'lodash'
import moment from 'moment'
import { saveAs } from 'file-saver'
import MuiBox from '@material-ui/core/Box'
import MuiGrid from '@material-ui/core/Grid'
import MuiTypography from '@material-ui/core/Typography'
import MuiToolbar from '@material-ui/core/Toolbar';
import SaveIcon from '@material-ui/icons/Save';
import { dateToReadable, utcDateToLocal, localDateToUtc } from 'shared/services/date'
import {
    AddCsvLocationsForm,
    Button,
    CampaignForm,
    ContentLoader,
    LocationBulkDetailsModalForm,
    Dialog,
    Table,
} from 'components'
import { GMapContext, GMapSearch } from 'components/GMap'
import {
    CampaignResponse,
    LocationResponse,
    UploadLocationsResponse,
    useCampaignManagement,
    useClientManagement,
    useLocationsManagement,
    csvifyLocations,
} from 'modules/campaignM'
import { createSetLocationProperties } from './helpers'
// TODO: Refactor ManageMedia form
import ManageMedia from '../../../modules/Campaign/containers/ManageMedia'

interface MiscProps {
    data: any
    locations: LocationResponse[]
    setLocations: (data: LocationResponse[]) => void
}

const createLabel = (numSelected: number, prefix: string) => {
    if (numSelected === 0) {
        return `${prefix} all locations`
    }
    if (numSelected === 1) {
        return `${prefix} 1 location`
    }

    return `${prefix} ${numSelected} locations`
}

const COLUMNS = [
    {
        headerName: '#',
        field: 'id',
    },
    {
        field: 'latitude',
        headerName: 'Latitude',
        valueGetter: ({ latitude }) => latitude.toFixed(4),
    },
    {
        field: 'longitude',
        headerName: 'Longitude',
        valueGetter: ({ longitude }) => longitude.toFixed(4),
    },
    {
        field: 'dateFrom',
        headerName: 'Date from',
        valueGetter: ({ dateFrom }) => dateToReadable(utcDateToLocal(dateFrom)),
    },
    {
        field: 'dateTo',
        headerName: 'Date to',
        valueGetter: ({ dateTo }) => dateToReadable(utcDateToLocal(dateTo)),
    },
    {
        field: 'radius',
        headerName: 'Radius',
    },
    {
        field: 'filterName',
        headerName: 'Filter',
    },
    {
        field: 'locationType',
        headerName: 'Location type',
    },
    {
        field: 'locationDetailName',
        headerName: 'Location detail name',
    },
    {
        field: 'locationDetailId',
        headerName: 'Location detail ID',
    },
    {
        field: 'address',
        headerName: 'Address',
    },
    {
        field: 'postalCode',
        headerName: 'Postal code',
    },
    {
        field: 'city',
        headerName: 'City',
    },
    {
        field: 'province',
        headerName: 'Province',
    },
    {
        field: 'country',
        headerName: 'Country',
    },
]

export const Misc = ({ data, locations, setLocations }: MiscProps) => {
    const { map } = useContext(GMapContext)
    const { uploadCsvLocations, updateLocations, removeLocations } = useLocationsManagement()
    const [selectedLocations, selectLocations] = useState<LocationResponse[]>([])
    const [isEditFormOpen, setEditFormOpen] = useState<boolean>(false)
    const [isCsvModalOpen, setCsvModalOpen] = useState<boolean>(false)
    const [csvResults, setCsvResults] = useState<UploadLocationsResponse>({
        invalidCampaignLocations: [],
        validCampaignLocations: [],
    })
    const [bulkEditDefaultValues, setBulkEditDefaultValues] = useState<any>()

    const handleRemoveLocations = async () => {
        if (data && data.id) {
            await removeLocations(data.id, selectedLocations)
            const otherLocations = locations.filter(l => !selectedLocations.includes(l))
            setLocations(otherLocations)
            selectLocations([])
        }
    }

    const handleUploadCsvLocations = async ({ files }: { files: File[] }) => {
        try {
            const data = await uploadCsvLocations(files[0])
            if (data) {
                setCsvResults(data)
                setLocations([...locations, ...data.validCampaignLocations])
                setCsvModalOpen(true)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleBulkEdit = async (values) => {
        const setLocationProperties = createSetLocationProperties(values)
        const locationsToUpdate = selectedLocations.length > 0 ? selectedLocations : locations
        const newLocations = locationsToUpdate.map(setLocationProperties)
        const result = await updateLocations(newLocations)
        const updated = locations.map(location => locationsToUpdate.includes(location)
            ? setLocationProperties(location)
            : location)
        setLocations(updated)
    }

    const handleDownload = (data: LocationResponse[]) => {
        const file = new File([csvifyLocations(localDateToUtc)(data)], 'invalid_locations.csv', { type: 'text/csv;charset=utf-8' })
        saveAs(file)
    }

    useEffect(() => {
        const defaultValues = selectedLocations.length > 0 ? {
            ...selectedLocations[0],
        } : {
            ...locations[0],
        }

        const locationsToUpdate = selectedLocations.length > 0 ? selectedLocations : locations
        locationsToUpdate.forEach(location => {
            Object.keys(location).forEach(key => {
                if (defaultValues[key] !== undefined) {
                    defaultValues[key] = defaultValues[key] === location[key] ? defaultValues[key] : undefined
                }
            })
        })

        setBulkEditDefaultValues(defaultValues)

    }, [selectedLocations.length])

    return (
        <>
            <MuiBox mb={10}>
                <ManageMedia data={data} campaignId={`${data.id}`} />
            </MuiBox>
            <MuiTypography variant="h3" paragraph>
                Locations <small>({locations.length})</small>
            </MuiTypography>
            <GMapSearch campaignId={data.id} locations={locations} setLocations={setLocations} />
            <MuiTypography variant="h3" gutterBottom>
                Manual Upload
            </MuiTypography>
            <AddCsvLocationsForm onSubmit={handleUploadCsvLocations} />
            <Table<LocationResponse>
                columns={COLUMNS}
                noDataText="No locations for this campaign"
                onSelect={selectLocations}
                rows={locations}
            />
            {locations.length > 0 && (
                <MuiBox pt={4}>
                    <MuiGrid container justify="flex-end" spacing={2}>
                        <MuiGrid item>
                            <Button color="info" onClick={() => setEditFormOpen(true)}>
                                {createLabel(selectedLocations.length, 'Edit')}
                            </Button>
                        </MuiGrid>
                        <MuiGrid item>
                            <Button color="secondary" onClick={handleRemoveLocations}>
                                {createLabel(selectedLocations.length, 'Delete')}
                            </Button>
                        </MuiGrid>
                    </MuiGrid>
                </MuiBox>
            )}
            <LocationBulkDetailsModalForm
                defaultValues={bulkEditDefaultValues}
                onCancel={() => setEditFormOpen(false)}
                onSubmit={handleBulkEdit}
                open={isEditFormOpen}
                title={createLabel(selectedLocations.length, 'Edit')}
            />
            <Dialog title="Manual Location Upload" open={isCsvModalOpen} onClose={() => setCsvModalOpen(false)}>
                <Dialog.Content>
                <MuiTypography gutterBottom>Valid Locations: <span style={{ fontWeight: 700 }}>{csvResults.validCampaignLocations.length}</span></MuiTypography>
                {csvResults.invalidCampaignLocations.length > 0 ? (
                    <>
                        <MuiToolbar disableGutters>
                            <MuiBox display="flex" alignItems="center" justifyContent="space-between" width="100%">
                                <MuiTypography>Invalid Locations: <span style={{ fontWeight: 700 }}>{csvResults.invalidCampaignLocations.length}</span></MuiTypography>
                                <Button
                                    type="button"
                                    color="info"
                                    onClick={() => handleDownload(csvResults.invalidCampaignLocations)}
                                    startIcon={<SaveIcon />}>
                                    Download invalid locations
                                </Button>
                            </MuiBox>
                        </MuiToolbar>
                        <Table<LocationResponse>
                            columns={COLUMNS}
                            rows={csvResults.invalidCampaignLocations}
                        />
                    </>
                ) : (
                    <MuiTypography>Invalid Locations: <span style={{ fontWeight: 700 }}>{csvResults.invalidCampaignLocations.length}</span></MuiTypography>
                )}
                </Dialog.Content>
                <Dialog.Actions>
                <Button type="button" color="default" onClick={() => setCsvModalOpen(false)}>
                    Close
                </Button>
                </Dialog.Actions>
            </Dialog>
        </>
    )
}
