import * as yup from 'yup'
import { stringIntl } from 'services/validation'

export const numberReg: RegExp = /^\d+$/;

export const schema = yup.object({
    titles: stringIntl('titles'),
    daysAfter: yup
        .string()
        .trim()
        .required() 
        .matches(numberReg, 'Must be an integer value')             
})