import update from 'immutability-helper';

import { createReducer } from 'shared/utils/redux';
import { loadingStates, setLoadingStates } from 'shared/constants/redux';
import { createRequestTypes } from 'shared/utils/request';

export const DISCUSSIONS_FETCH_ANSWERS_LIST = createRequestTypes('discussions/FETCH_ANSWERS_LIST');
export const DISCUSSIONS_FETCH_ANSWERS_DETAILS = createRequestTypes(
  'discussions/FETCH_ANSWERS_DETAILS',
);

export const fetchAnswers = ({ term, search }) => ({
  type: DISCUSSIONS_FETCH_ANSWERS_LIST.REQUEST,
  payload: { term, search },
});

export const fetchAnswersByDiscussion = ({ id, params }) => ({
  type: DISCUSSIONS_FETCH_ANSWERS_DETAILS.REQUEST,
  payload: { id, params },
});

const initState = {
  details: {
    states: loadingStates,
    data: {},
    response: null,
  },
  list: {
    states: loadingStates,
    data: [],
    response: null,
  },
};

export default createReducer(initState, {
  [DISCUSSIONS_FETCH_ANSWERS_DETAILS.REQUEST]: (state) => {
    return update(state, {
      details: {
        $merge: {
          states: setLoadingStates({ isLoading: true }),
        },
      },
    });
  },
  [DISCUSSIONS_FETCH_ANSWERS_DETAILS.SUCCESS]: (state, action) => {
    return update(state, {
      details: {
        $merge: {
          states: setLoadingStates({ isLoaded: true }),
          data: action.payload,
        },
      },
    });
  },
  [DISCUSSIONS_FETCH_ANSWERS_DETAILS.FAILURE]: (state, action) => {
    return update(state, {
      details: {
        $merge: {
          states: setLoadingStates({ hasError: true }),
          response: action.payload.response,
        },
      },
    });
  },
  [DISCUSSIONS_FETCH_ANSWERS_LIST.REQUEST]: (state) => {
    return update(state, {
      list: {
        $merge: {
          states: setLoadingStates({ isLoading: true }),
        },
      },
    });
  },
  [DISCUSSIONS_FETCH_ANSWERS_LIST.SUCCESS]: (state, action) => {
    return update(state, {
      list: {
        $merge: {
          states: setLoadingStates({ isLoaded: true }),
          data: action.payload,
        },
      },
    });
  },
  [DISCUSSIONS_FETCH_ANSWERS_LIST.FAILURE]: (state, action) => {
    return update(state, {
      list: {
        $merge: {
          states: setLoadingStates({ hasError: true }),
          response: action.payload.response,
        },
      },
    });
  },
});
