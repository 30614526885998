import React, { PureComponent } from 'react';
import { reduxForm, FieldArray, getFormValues } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { uniqueId } from 'lodash'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { LANGUAGES } from 'shared/constants/lang';
import MuiBox from '@material-ui/core/Box';
import TextField from 'shared/components/TextField';
import CheckboxField from 'shared/components/CheckboxField';
import TextFieldI18N from 'shared/components/TextFieldI18N';
import SelectField from 'shared/components/SelectField';
import FieldLabel from 'shared/components/FieldLabel';
import { filter, find, get } from 'lodash';
import { Trash } from 'shared/components/Icons';
import Button from 'shared/components/Button';
import IconButton from 'shared/components/IconButton';
import * as types from '../../types/AnswerComposerTypes';
import styles from './styles.module.scss';
import { AnswerItem } from './AnswerItem';
const FORM_NAME = 'completionSurvey/question/MANAGE';

class AnswerComposer extends PureComponent<types.AnswerComponentProps> {
    addAnswer = () => {
        const answer = {
            value: '',
            titles: {},
            exclusive: this.props.formValues.type === 'CHECKBOX' ? false : undefined,
        }
        this.props.array.push('possibleAnswers', answer)
    };

    handleDragEnd = ({ destination, source }) => {
        this.props.array.move('possibleAnswers', source.index, destination.index)
    }

    renderAnswers = ({ fields }) => (
        <>
            {fields.map((field, index) => (
                <Draggable
                    draggableId={`drag-${field}`}
                    index={index}
                    key={`drag-${field}`}>
                    {({ innerRef, draggableProps, dragHandleProps }) => (
                        <div
                            {...draggableProps}
                            {...dragHandleProps}
                            ref={innerRef}>
                            <AnswerItem
                                field={field}
                                bucketCategories={this.props.bucketCategories}
                                formValues={this.props.formValues}
                                index={index}
                                onDelete={fields.remove}
                            />
                        </div>
                    )}
                </Draggable>
            ))}
        </>
    )

    render() {
        return (
            <div>
                <DragDropContext onDragEnd={this.handleDragEnd}>
                    <Droppable droppableId="possibleAnswers">
                        {({ innerRef, droppableProps, placeholder }) => (
                            <div ref={innerRef} {...droppableProps}>
                                <FieldArray name="possibleAnswers" component={this.renderAnswers} />
                                {placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
                <Button onClick={this.addAnswer} type="primary" small icon="plus" buttonType="button">
                    Add answer
                </Button>
            </div>
        );
    }
}

export default compose<any>(
    connect((state) => ({
        formValues: getFormValues(FORM_NAME)(state),
        bucketCategories: get(state, 'completionSurveys.question.manage.bucketCategories.data', []),
    })),
    reduxForm({ form: FORM_NAME, destroyOnUnmount: false }),
)(AnswerComposer);
