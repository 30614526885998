import React from 'react';
import { Route } from 'react-router-dom';
import { routes } from 'shared/constants/routes';
import { LotteriesList } from 'views'
import Manage from './containers/Manage';

export default () => (
  <>
    <Route path={routes.lotteries.default.path} key="lotteries-default" component={LotteriesList} exact />
    <Route
      path={routes.lotteries.manage('(edit|create)/:id?').path}
      key="lotteries-manage"
      component={Manage}
      exact
    />
  </>
);
