import React from 'react'
import { Route } from 'react-router-dom'
import { routes } from 'shared/constants/routes'
import { CharitiesList } from './CharitiesList'
import { CharityDetails } from './CharityDetails'

export const Charities = () => (
    <>
        <Route path={routes.charities.default.path} component={CharitiesList} exact />
        <Route
            path={routes.charities.manage('(edit|create)/:id?').path}
            component={CharityDetails}
            exact
        />
    </>
)
