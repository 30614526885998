import { put, takeEvery, take, call } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';
import { getError } from 'shared/services/api';
import { uploadFileToS3 } from 'shared/services/aws';
import { UPLOAD_MEDIA } from '../../reducers/files/';

function* uploadMedia(action) {
  try {
    const { file, id, prefix, bucket } = action.payload;
    const extensionRegexp = /(?:\.([^.]+))?$/;
    const ext = extensionRegexp.exec(file.name)[1].toLowerCase();

    const channel = yield call(uploadFileToS3, {
      Key: `${prefix}/${uuidv4()}.${ext}`,
      Body: file,
      Bucket: bucket,
    });
    while (true) {
      const { progress = 0, data, success, error } = yield take(channel);
      if (success) {
        yield put({
          type: UPLOAD_MEDIA.SUCCESS,
          payload: { ...data, id },
        });
        return;
      }
      if (error) {
        yield put({ type: UPLOAD_MEDIA.FAILURE, payload: { id, error } });
        return;
      }
      yield put({ type: UPLOAD_MEDIA.PROGRESS, payload: { id, progress } });
    }
  } catch (err) {
    yield put({ type: UPLOAD_MEDIA.FAILURE, payload: getError(err) });
  }
}

export default [
  function* () {
    yield takeEvery(UPLOAD_MEDIA.REQUEST, uploadMedia);
  },
];
