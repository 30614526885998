import { put, takeEvery } from 'redux-saga/effects';
import qs from 'query-string';
import apiService, { getError } from 'shared/services/api';
import { utcDateToLocal } from 'shared/services/date';
import { CAMPAIGNS_FETCH_LIST, CAMPAIGNS_FETCH_CLIENT_LIST } from '../../reducers/list/';
import { removeUndefinedValues } from 'shared/utils/request';

const normalizeDatesResponse = (data) => ({
    ...data,
    dateFrom: data.dateFrom ? utcDateToLocal(data.dateFrom) : undefined,
    dateTo: data.dateTo ? utcDateToLocal(data.dateTo) : undefined,
})

function* fetchCampaigns(action) {
  try {
    const query = `${qs.stringify(removeUndefinedValues(action.payload.search))}`;
    let path = `/campaigns?${query}`;
    if (action.payload.search.term) {
      path = `/campaigns/find?${query}`;
    }
    const { data } = yield apiService({}).get(path);
    yield put({
      type: CAMPAIGNS_FETCH_LIST.SUCCESS,
      payload: normalizeDatesResponse(data),
    });
  } catch (err) {
    yield put({ type: CAMPAIGNS_FETCH_LIST.FAILURE, payload: getError(err) });
  }
}
function* fetchClients() {
  try {
    const { data } = yield apiService({}).get(`/campaigns/clients?size=1000000`);
    yield put({
      type: CAMPAIGNS_FETCH_CLIENT_LIST.SUCCESS,
      payload: data.content,
    });
  } catch (err) {
    yield put({
      type: CAMPAIGNS_FETCH_CLIENT_LIST.FAILURE,
      payload: getError(err),
    });
  }
}

export default [
  function* () {
    yield takeEvery(CAMPAIGNS_FETCH_LIST.REQUEST, fetchCampaigns);
  },
  function* () {
    yield takeEvery(CAMPAIGNS_FETCH_CLIENT_LIST.REQUEST, fetchClients);
  },
];
