import { toast } from 'react-toastify'
import { getLotteries, postLottery } from './api'
import { LotteryRequest } from './types'

export const useLotteryManagement = () => {
    const createLottery = async (data: LotteryRequest) => {
        try {
            await postLottery(data)
            toast.success('Lottery successfully saved')
        } catch (error) {
            toast.error('Something went wrong. Please try again later.')
        }
    }

    return {
        createLottery,
        fetchLotteries: getLotteries,
    }
}
