import update from 'immutability-helper';

import { createReducer } from 'shared/utils/redux';
import { loadingStates, setLoadingStates } from 'shared/constants/redux';
import { createRequestTypes } from 'shared/utils/request';

export const DISCUSSIONS_FETCH_LIST = createRequestTypes('discussions/FETCH_QUESTIONS_LIST');

export const fetchDiscussions = ({ term, search }) => ({
  type: DISCUSSIONS_FETCH_LIST.REQUEST,
  payload: { term, search },
});

const initState = {
  states: loadingStates,
  data: [],
  response: null,
};

export default createReducer(initState, {
  [DISCUSSIONS_FETCH_LIST.REQUEST]: (state) => {
    return update(state, {
      $merge: {
        states: setLoadingStates({ isLoading: true }),
      },
    });
  },
  [DISCUSSIONS_FETCH_LIST.SUCCESS]: (state, action) => {
    return update(state, {
      $merge: {
        states: setLoadingStates({ isLoaded: true }),
        data: action.payload,
      },
    });
  },
  [DISCUSSIONS_FETCH_LIST.FAILURE]: (state, action) => {
    return update(state, {
      $merge: {
        states: setLoadingStates({ hasError: true }),
        response: action.payload.response,
      },
    });
  },
});
