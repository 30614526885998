import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as formReducer } from 'redux-form';
import shared from 'shared/reducers';
import campaigns from 'modules/Campaign/reducers';
import auth from 'modules/Auth/reducers';
import surveys from 'modules/Survey/reducers';
import completionSurveys from 'modules/CompletionSurveys/reducers';
import lotteries from 'modules/Lottery/reducers';
import discussions from 'modules/Discussion/reducers';
import app from 'modules/App/reducers';

export default history =>
  combineReducers({
    form: formReducer,
    router: connectRouter(history),
    auth,
    campaigns,
    discussions,
    shared,
    surveys,
    completionSurveys,
    lotteries,
    app,
  });
