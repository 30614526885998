import qs from 'query-string';
import { put, takeEvery } from 'redux-saga/effects';
import apiService, { getError } from 'shared/services/api';
import {
  DISCUSSIONS_FETCH_ANSWERS_LIST,
  DISCUSSIONS_FETCH_ANSWERS_DETAILS,
} from '../../reducers/answers/';

function* fetchAnswers(action) {
  try {
    const query = qs.stringify(action.payload.search);
    let path = `/discussions/live?${query}`;
    if (action.payload.search.term) {
      path = `/discussions/live/find?${query}`;
    }
    const { data } = yield apiService({}).get(path);

    yield put({
      type: DISCUSSIONS_FETCH_ANSWERS_LIST.SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield put({
      type: DISCUSSIONS_FETCH_ANSWERS_LIST.FAILURE,
      payload: getError(err),
    });
  }
}
function* fetchDetails(action) {
  try {
    const { data } = yield apiService({}).get(
      `/discussions/${action.payload.id}/answers?${action.payload.params}`,
    );

    yield put({
      type: DISCUSSIONS_FETCH_ANSWERS_DETAILS.SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield put({
      type: DISCUSSIONS_FETCH_ANSWERS_DETAILS.FAILURE,
      payload: getError(err),
    });
  }
}

export default [
  function* () {
    yield takeEvery(DISCUSSIONS_FETCH_ANSWERS_LIST.REQUEST, fetchAnswers);
  },
  function* () {
    yield takeEvery(DISCUSSIONS_FETCH_ANSWERS_DETAILS.REQUEST, fetchDetails);
  },
];
