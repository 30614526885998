import React from 'react';
import styles from './styles.module.scss';

interface IconButtonProps {
  children?: React.ReactNode;
  onClick?: (...args: any[]) => void;
  type?: 'button' | 'submit'
}

export default ({ children, onClick, type = 'button' }: IconButtonProps) => (
  <button onClick={onClick} className={styles.IconButton} type={type}>
    {children}
  </button>
);
