// TODO Fix on the pipeline TS
// export enum SurveyStatus {
//   'SCHEDULED' = 'SCHEDULED',
//   'LIVE' = 'LIVE',
//   'CANCELED' = 'CANCELED',
//   'CLOSED' = 'CLOSED',
//   'OPEN' = 'OPEN',
// }
// type SurveyStatusLabel = {
//   [key in SurveyStatus]: string;
// };
// export const SURVEY_STATUS_LABEL: SurveyStatusLabel = {

export const SurveyStatus = {
  SCHEDULED: 'SCHEDULED',
  LIVE: 'LIVE',
  CANCELED: 'CANCELED',
  CLOSED: 'CLOSED',
  OPEN: 'OPEN',
};
export const SURVEY_STATUS_LABEL = {
  SCHEDULED: 'Scheduled',
  LIVE: 'Live',
  CANCELED: 'Canceled',
  CLOSED: 'Closed',
  OPEN: 'Open',
};
