import React from 'react';
import MuiGrid from '@material-ui/core/Grid';
import {
  Button,
  DateField,
  DateTimeField,
  FieldLabel,
  Form,
  FormHeader,
  FormProps,
  Page,
  RadioGroup,
  SwitchField,
  TextField,
  TextHeaderField,
  TextIntlField,
} from 'components';
import { schema } from './validation';
import { GiftCardFormData } from './types';

export const GiftCardForm = ({ defaultValues, onSubmit }: FormProps<GiftCardFormData>) => (
  <Form<GiftCardFormData> defaultValues={defaultValues} onSubmit={onSubmit} schema={schema}>
    {({ isSubmitDisabled, isSubmitting }) => (
      <Page
        header={
          <FormHeader
            field={<TextHeaderField name="title" placeholder="Enter giftcard name" />}
            cta={
              <Button disabled={isSubmitDisabled} loading={isSubmitting}>
                Save
              </Button>
            }
          />
        }
      >
        <MuiGrid container>
          <MuiGrid item xs={7}>
            <TextIntlField label="Description" multiline name="description" required />
            <SwitchField
              label="Enable push notification"
              name="pushNoteEnabled"
              // readOnly={Boolean(defaultValues)}
            />
            <TextIntlField label="Push notification and in-app title" maxLength={65} name="pushNoteTitle" required />
            <TextIntlField label="Push note body" maxLength={240} multiline name="pushNoteBody" />
            <DateTimeField name="pushNoteDate" label="Push note date and time" />
            <TextField label="Image or video" name="imageUrl" required />
            <TextField label="Available Giftcards" name="availableGiftcards" required />
            <TextField label="Points needed" name="pointsNeeded" required />
            <MuiGrid container spacing={3}>
              <MuiGrid item xs>
                <TextField label="Maximum number of gifts per user" name="maximumAmountPerUser" required />
              </MuiGrid>
              <MuiGrid item xs>
                <TextField label="Maximum number of gifts per user/months" name="maximumAmountMonth" required />
              </MuiGrid>
            </MuiGrid>
            <FieldLabel label="Period" required />
            <MuiGrid container spacing={3}>
              <MuiGrid item xs>
                <DateField name="dateFrom" required />
              </MuiGrid>
              <MuiGrid item xs>
                <DateField name="dateTo" required />
              </MuiGrid>
            </MuiGrid>
            <RadioGroup
              label="Status"
              name="status"
              options={[
                {
                  value: 'OPEN',
                  label: 'Open',
                },
                {
                  value: 'CLOSED',
                  label: 'Closed',
                },
              ]}
              required
            />
          </MuiGrid>
        </MuiGrid>
      </Page>
    )}
  </Form>
);
