import React from 'react'
import { Form, FormProps, TextField, Button } from 'components'
import { schema } from './validation'
import { SettingsFormData } from './types'

export const SettingsForm = ({ defaultValues, onSubmit }: FormProps<SettingsFormData>) => (
    <Form<SettingsFormData> defaultValues={defaultValues} onSubmit={onSubmit} schema={schema}>
        {({ isSubmitDisabled, isSubmitting }) => (
            <>
                <h2>App Points</h2>
                <TextField
                    label="Points For Acr (per 1 hour)"
                    name="pointsForAcr"
                    placeholder="10"
                    tooltip="Number of points user receives for 1h of ACR (default = 10)"
                />
                <TextField
                    label="Points for location (per 1 hour)"
                    name="pointsForLocation"
                    placeholder="10"
                    tooltip="Number of points user receives for 1h of location sharing (default = 10)"
                />
                <TextField
                    label="Max Points Per Month (per user). Max numer is: smallint: 2^15-1 (32,767)"
                    name="maxPointsPerMonth"
                    placeholder="200"
                    tooltip="Maximum number of points per month user can get (default = 200). Warning the max is a smallint: '2^15-1 (32,767)'"
                />
                <h2>MeMoPanel</h2>
                <TextField
                    label="Points for Memo panel registration"
                    name="pointsForMemoPanelRegistration"
                    placeholder="500"
                    tooltip="Number of points user receives for registering to memo panel (default = 500). Please be aware that the max is: smallint: 2^15-1 (32,767)"
                />
                <h2>Updates (Soft &amp; Force)</h2>
                <TextField label="Android soft update version" name="androidSoftUpdateVersion" />
                <TextField label="Android force update version" name="androidForceUpdateVersion" />
                <TextField label="iOS soft update version" name="iosSoftUpdateVersion" />
                <TextField label="iOS force update version" name="iosForceUpdateVersion" />
                <Button disabled={isSubmitDisabled} loading={isSubmitting}>
                    Save
                </Button>
            </>
        )}
    </Form>
)
