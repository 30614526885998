import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import csvParse from 'csv-parse/lib/sync'
import { localDateToUtc } from 'shared/services/date'
import { WithId } from 'shared/types'
import { readFileAsText } from 'helpers'
import {
    deleteAllLocations,
    deleteLocation,
    postLocation,
    putCsvLocations,
    putLocations,
} from '../api'
import { csvifyLocations } from '../helpers'
import { LocationRequest, LocationResponse, UploadLocationsResponse } from '../types'

export const useLocationsManagement = () => {
    const { id } = useParams<{ id?: string }>()

    const createLocations = async (data: LocationRequest[]): Promise<LocationResponse[]> => {
        try {
            const response = await Promise.all(
                data.map(({ dateFrom, dateTo, ...location }) =>
                    postLocation({
                        ...location,
                        dateFrom: localDateToUtc(dateFrom),
                        dateTo: localDateToUtc(dateTo),
                    }),
                ),
            )
            toast.success('Locations successfully saved')
            return response
        } catch (error) {
            console.log(error)
            toast.error('Something went wrong. Please try again later.')
        }
    }

    const createLocation = async (data: LocationRequest): Promise<LocationResponse> => {
        try {
            const response = await postLocation({ ...data, campaignId: +id });
            toast.success('Location successfully saved')
            return response
        } catch (error) {
            console.log(error)
            toast.error('Something went wrong. Please try again later.')
        }
    }

    const uploadCsvLocations = async (file: File): Promise<UploadLocationsResponse> => {
        try {
            let csvStr: string = await readFileAsText(file)
            const records = csvParse(csvStr, {
                columns: true,
                skip_empty_lines: true
            })
            csvStr = csvifyLocations(localDateToUtc)(records)
            const base64encodedCsv = window.btoa(csvStr)
            const data = await putCsvLocations({ id, base64encodedCsv })
            toast.success('Locations successfully saved')
            return data
        } catch (error) {
            console.log(error)
            toast.error('Unable to parse locations file.')
        }
    }

    const updateLocations = async (data: WithId<LocationRequest>[]): Promise<LocationResponse[]> => {
        try {
            const result = await putLocations(id, data)
            toast.success('Locations successfully updated')
            return result
        } catch (error) {
            console.log(error)
            toast.error('Something went wrong. Please try again later.')
        }
    }

    const removeLocations = async (campaignId: number, locations?: LocationResponse[]): Promise<void> => {
        try {
            if (locations && locations.length > 0) {
                await Promise.all(
                    locations.map(location => deleteLocation(campaignId, location.id as number)),
                )
            } else {
                await deleteAllLocations(campaignId)
            }
            toast.success('Location(s) successfully removed')
        } catch (error) {
            console.log(error)
        }
    }

    return {
        createLocation,
        createLocations,
        removeLocations,
        updateLocations,
        uploadCsvLocations,
    }
}
