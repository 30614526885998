import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, getFormValues } from 'redux-form';
import map from 'lodash/map';
import flatMap from 'lodash/flatMap';
import filter from 'lodash/filter';
import toLower from 'lodash/toLower';
import includes from 'lodash/includes';
import get from 'lodash/get';
import { bindActionCreators, compose } from 'redux';
import ListHeading from 'shared/components/ListHeading';
import Button from 'shared/components/Button';
import { routes } from 'shared/constants/routes';
import { Thead, Tbody, Table, Th, Tr, Td } from 'shared/components/Table';
import AppContent from 'shared/components/AppContent';
import {
  fetchCompletionSurveyCategories,
  deleteCompletionSurveyCategory,
} from 'modules/CompletionSurveys/reducers/category/list';
import ConfirmModal from 'shared/components/ConfirmModal';
import { Trash } from 'shared/components/Icons';
import IconButton from 'shared/components/IconButton';
import TextField from 'shared/components/TextField';
import * as types from '../../types/CategoriesListTypes';
const FORM_NAME = 'completionSurvey/categories/LIST';

class CategoriesList extends PureComponent<types.CategoriesListProps, types.CategoriesListState> {
  state: types.CategoriesListState = { deleteModal: false, deleteModalData: {} };

  componentDidMount() {
    this.props.fetchCompletionSurveyCategories();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.deletingStates.isLoading && this.props.deletingStates.isLoaded) {
      this.setState({
        deleteModal: false,
        deleteModalData: {},
      });
    }

    if (prevProps.selectedCountry !== this.props.selectedCountry) {
      this.props.fetchCompletionSurveyCategories();
    }
  }

  goToCreate = () => {
    const { history } = this.props;
    history.push({
      pathname: routes.completionSurveys.categories.create.path,
      search: history.location.search,
    });
  };

  goToEdit = (id) => () => {
    const { history } = this.props;
    history.push({
      pathname: routes.completionSurveys.categories.edit(id).path,
      search: history.location.search,
    });
  };

  toggleModal = (data = {}) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      deleteModal: !this.state.deleteModal,
      deleteModalData: data,
    });
  };

  deleteCategory = () => {
    this.props.deleteCompletionSurveyCategory({
      id: this.state.deleteModalData.categoryId,
    });
  };

  render() {
    const { data, states, formValues, deletingStates } = this.props;
    const lowerValue = toLower(formValues.search);
    const dataToShow = filter(data, ({ titles }) => {
      const title = flatMap(titles, (value) => value).toString();
      if (includes(toLower(title), lowerValue)) {
        return true;
      }
      return false;
    });
    return (
      <div>
        <ConfirmModal
          paragraph="Are you sure you want to delete selected category?"
          isOpen={this.state.deleteModal}
          isLoading={deletingStates.isLoading}
          onClose={this.toggleModal()}
          onConfirm={this.deleteCategory}
          title="Delete a category"
          confirmLabel="Delete"
          cancelLabel="Cancel"
        />
        <ListHeading
          title="Categories"
          right={
            <Field component={TextField} name="search" placeholder="Type to search" noMargin />
          }
        >
          <Button noMargin type="primary" onClick={this.goToCreate}>
            Add new
          </Button>
        </ListHeading>
        <AppContent>
          <div className="row">
            <div className="col-md-12">
              <Table isLoading={states.isLoading}>
                <Thead>
                  <Tr>
                    <Th>Name</Th>
                    <Th>Days After</Th>
                    <Th>
                        <div style={{ textAlign: 'right', marginRight: '40px' }}>
                           Action
                        </div>
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {map(dataToShow, (category) => (
                    <Tr key={`cat-${category.id}`} onClick={this.goToEdit(category.id)}>
                      <Td>
                        {get(category.titles, 'en') ||
                          get(category.titles, Object.keys(category.titles)[0])}
                      </Td>
                      <Td>
                        {category?.daysAfter}
                      </Td>
                      <Td>
                        <div style={{ textAlign: 'right', marginRight: '40px' }}>
                          <IconButton
                            onClick={this.toggleModal({
                              categoryId: category.id,
                            })}
                          >
                            <Trash />
                          </IconButton>
                          </div>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </div>
          </div>
        </AppContent>
      </div>
    );
  }
}

const mapStateToProps = ({ completionSurveys: { category }, ...state }) => ({
  data: category.list.data,
  states: category.list.states,
  deletingStates: category.list.delete.states,
  formValues: getFormValues(FORM_NAME)(state) || {},
  selectedCountry: state.app.country,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchCompletionSurveyCategories, deleteCompletionSurveyCategory }, dispatch);

export default compose<any>(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({ form: FORM_NAME }),
)(CategoriesList);
