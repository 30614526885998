// TODO: Add virutalization for large lists
import React from 'react'
import MuiAutocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'
import MuiOutlinedInput from '@material-ui/core/OutlinedInput'
import { FieldWrapper } from 'components'
import { useStyles } from './AutocompleteField.styles'

interface Option {
    inputValue?: string
    value: string | number | boolean
    label: string
}

interface AutocompleteFieldProps {
    creatable?: boolean
    defaultValue?: string | number | boolean
    disabled?: boolean
    label?: string
    multiple?: boolean
    name: string
    options: Option[]
    placeholder?: string
    required?: boolean
    small?: boolean
    tooltip?: string
}

const filter = createFilterOptions<Option>()

export const AutocompleteField = ({
    creatable,
    defaultValue = '',
    disabled,
    label,
    // multiple,
    name,
    options,
    placeholder,
    required,
    small,
    tooltip,
}: AutocompleteFieldProps) => {
    const styles = useStyles()
    const margin = small ? 'dense' : 'none'
    const handleChange = (onChange: (value: string | number | boolean) => void) => (_, value: Option) => {
        if (value) {
            onChange(value.value)
        } else {
            onChange(undefined)
        }
    }

    const getValue = (data: string | number) => {
        const value = options.find(option => option.value === data)
        return value || { value: data as string, label: data as string, inputValue: data as string }
    }

    return (
        <FieldWrapper
            defaultValue={defaultValue}
            disabled={disabled}
            label={label}
            name={name}
            required={required}
            small={small}
            tooltip={tooltip}>
            {({ onChange, value }) => (
                <MuiAutocomplete
                    classes={styles}
                    disableClearable={!getValue(value).value}
                    filterOptions={(opts, params) => {
                        const filtered = filter(opts, params)
                        if (creatable && params.inputValue !== '') {
                            filtered.push({
                                inputValue: params.inputValue,
                                value: params.inputValue,
                                label: `Create "${params.inputValue}"`,
                            })
                        }

                        return filtered
                    }}
                    forcePopupIcon
                    freeSolo={creatable}
                    getOptionLabel={option => option.inputValue || option.label}
                    getOptionSelected={(option, val) =>
                        Boolean(val.value) && option.value === val.value
                    }
                    id={name}
                    // multiple={multiple}
                    onChange={handleChange(onChange)}
                    options={options}
                    renderInput={({ inputProps, InputProps }) => (
                        <MuiOutlinedInput
                            {...InputProps}
                            fullWidth
                            inputProps={inputProps}
                            inputRef={InputProps.ref}
                            margin={margin}
                            placeholder={placeholder}
                        />
                    )}
                    renderOption={option => option.label}
                    selectOnFocus
                    value={getValue(value)}
                />
            )}
        </FieldWrapper>
    )
}
