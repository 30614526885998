import React, { useState } from 'react'
import MuiBox from '@material-ui/core/Box'
import MuiGrid from '@material-ui/core/Grid'
import { AutocompleteField, Button, DateTimeField, Form, FormProps, Table, TextField } from 'components'
import { RequestStatus } from 'services'
import { searchSchema, placesSchema } from './validation'
import { AddLocationFormData, SearchPlacesFormData, Place } from './types'

interface AddLocationsFormProps extends FormProps<AddLocationFormData> {
    onSearch: (search: string) => Promise<google.maps.places.PlaceResult[]>
}

const COLUMNS = [
    {
        field: 'name',
        headerName: 'Name',
    },
    {
        field: 'formatted_address',
        headerName: 'Address',
    },
]

export const AddLocationsForm = ({ onSearch, onSubmit }: AddLocationsFormProps) => {
    const [requestStatus, setRequestStatus] = useState<RequestStatus>()
    const [numSelected, setNumSelected] = useState(0)
    const [places, setPlaces] = useState<google.maps.places.PlaceResult[]>([])
    const handleSearch = async ({ query }: SearchPlacesFormData) => {
        setRequestStatus('PENDING')
        try {
            const results = await onSearch(query)
            setPlaces(results)
            setRequestStatus('RESOLVED')
        } catch (error) {
            setRequestStatus('REJECTED')
        }
    }

    const handleSelect = setValue => (data: google.maps.places.PlaceResult[]) => {
        const selection: Place[] = data
            .map(({ geometry: { location } }) => ({
                latitude: location.lat(),
                longitude: location.lng(),
            }))
        setNumSelected(selection.length)
        setValue('places', selection)
    }

    return (
        <>
            <Form<SearchPlacesFormData> onSubmit={handleSearch} schema={searchSchema}>
                {({ isSubmitDisabled }) => (
                    <>
                        <TextField label="Search for places" name="query" />
                        <MuiBox display="flex" justifyContent="flex-end">
                            <Button disabled={isSubmitDisabled}>Search</Button>
                        </MuiBox>
                    </>
                )}
            </Form>
            <Form<AddLocationFormData> onSubmit={onSubmit} schema={placesSchema}>
                {({ isSubmitDisabled, isSubmitting }, { setValue }) => (
                    <>
                        <MuiBox mb={4}>
                            <Table<google.maps.places.PlaceResult>
                                columns={COLUMNS}
                                idField="place_id"
                                onSelect={handleSelect(setValue)}
                                rows={places}
                                noDataText="Search for places"
                                status={requestStatus}
                            />
                        </MuiBox>
                        <TextField name="places" type="hidden" />
                        <MuiGrid container spacing={2}>
                            <MuiGrid item xs>
                                <DateTimeField label="Date From" name="dateFrom" required />
                            </MuiGrid>
                            <MuiGrid item xs>
                                <DateTimeField label="Date to" name="dateTo" required />
                            </MuiGrid>
                            <MuiGrid item xs>
                                <TextField label="Radius in meters" name="radius" required />
                            </MuiGrid>
                        </MuiGrid>
                        <TextField label="Filter for locations" name="filterName" required />
                        <AutocompleteField
                            label="Location type"
                            name="locationType"
                            options={[
                                {
                                    value: 'OOH',
                                    label: 'OOH',
                                },
                                {
                                    value: 'DOOH',
                                    label: 'DOOH',
                                },
                                {
                                    value: 'Stores',
                                    label: 'Stores',
                                },
                                {
                                    value: 'Venues',
                                    label: 'Venues',
                                }
                            ]}
                        />
                        <TextField
                            label="Location detail name"
                            name="locationDetailName"
                        />
                        <TextField
                            label="Location detail ID"
                            name="locationDetailId"
                        />
                        <MuiBox display="flex" justifyContent="flex-end" pb="24px">
                            <Button
                                disabled={isSubmitDisabled || numSelected < 1}
                                loading={isSubmitting}>
                                {numSelected === 0 && 'Upload locations'}
                                {numSelected === 1 && 'Upload 1 location'}
                                {numSelected > 1 && `Upload ${numSelected} locations`}
                            </Button>
                        </MuiBox>
                    </>
                )}
            </Form>
        </>
    )
}
