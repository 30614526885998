import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import map from 'lodash/map';
import get from 'lodash/get';
import { ReactComponent as ImageIcon } from 'assets/icons/image.svg';
import debounce from 'lodash/debounce';
import { bindActionCreators, compose } from 'redux';
import ListHeading from 'shared/components/ListHeading';
import { Thead, Tbody, Table, Th, Tr, Td } from 'shared/components/Table';
import { fetchAnswersByDiscussion } from 'modules/Discussion/reducers/answers';
import { dateToReadable, utcDateToLocal } from 'shared/services/date';
import AnswerData from 'modules/Discussion/components/AnswerData';
import styles from './styles.module.scss';
import {
  getSignedUrl,
  getUrlWithoutHost,
  isImage,
  downloadS3File,
  isVideo,
  isAudio,
} from 'shared/services/aws';
import AnswersDetailsFilters, { ANSWER_DETAILS_FORM } from '../AnswersDetailsFilters';
import Button from 'shared/components/Button';
import * as types from '../../types/AnwersDetailsTypes';

class AnswersDetails extends PureComponent<
  types.DiscussionAnwersDetailsProps,
  types.DiscussionAnwersDetailsState
> {
  state: types.DiscussionAnwersDetailsState = {
    activeAnswer: {},
  };

  componentDidMount() {
    const id = get(this.props, 'match.params.id');
    if (id) {
      this.props.fetchAnswersByDiscussion({ id, params: '' });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.formValues !== this.props.formValues) {
      this.fetchWithParams(this.serialize(this.props.formValues));
    }
  }

  fetchWithParams = debounce((params) => {
    const id = get(this.props, 'match.params.id');
    this.props.fetchAnswersByDiscussion({ id, params });
  }, 400);

  serialize = (obj) => {
    const str = [];
    for (const p in obj)
      if (obj.hasOwnProperty(p) && obj[p]) {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
      }
    return str.join('&');
  };

  getSignedUrlForAnswer = (uploadUrl) => {
    return getSignedUrl({
      Bucket: process.env.REACT_APP_AWS_DISCUSSION_UPLOAD_BUCKET,
      Key: getUrlWithoutHost(decodeURIComponent(uploadUrl)),
    });
  };

  onDownload = (uploadUrl) => {
    return downloadS3File({
      Bucket: process.env.REACT_APP_AWS_DISCUSSION_UPLOAD_BUCKET,
      Key: getUrlWithoutHost(decodeURIComponent(uploadUrl)),
    });
  };

  render() {
    const { data } = this.props;
    const { activeAnswer } = this.state;
    return (
      <div>
        <ListHeading title={data.title}></ListHeading>
        <div className={styles.content}>
          <div className="row">
            <div className="col-md-8">
              <div className={styles.subHeader}>
                <AnswerData title="Client">{data.client}</AnswerData>
                <AnswerData title="Campaign Name">{data.campaignName}</AnswerData>
                <AnswerData title="Period">
                  {dateToReadable(utcDateToLocal(data.dateFrom))}
                  {' - '}
                  {dateToReadable(utcDateToLocal(data.dateTo))}
                </AnswerData>
              </div>
            </div>
          </div>
          <div className={styles.answersContent}>
            <div className="row">
              <div className="col-md-7">
                <div className={styles.answersLeftCol}>
                  <div className={styles.filters}>
                    <AnswersDetailsFilters />
                  </div>
                  <h3 className={styles.answersTitle}>Answers</h3>
                  <Table isLoading={this.props.states.isLoading}>
                    <Thead>
                      <Tr>
                        <Th>Name</Th>
                        <Th>Date</Th>
                        <Th>Gender</Th>
                        <Th>Education</Th>
                        <Th>Age</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {map(data.answers, (answer) => (
                        <Tr
                          key={`answer-${answer.id}`}
                          active={this.state.activeAnswer.id === answer.id}
                          onClick={() => {
                            this.setState({ activeAnswer: answer });
                          }}
                        >
                          <Td>{answer.fullName}</Td>
                          <Td>{dateToReadable(utcDateToLocal(answer.timestamp))}</Td>
                          <Td>{answer.gender}</Td>
                          <Td>{answer.education}</Td>
                          <Td>{answer.age}</Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </div>
              </div>
              <div className="col-md-5">
                <div className={styles.answersRightCol}>
                  {!activeAnswer.id && (
                    <div className={styles.answerPlaceholder}>
                      <ImageIcon />
                      Select answer
                    </div>
                  )}
                  {activeAnswer.id && (
                    <div>
                      <div className={styles.answerObject}>
                        {isImage(activeAnswer.uploadUrl) && (
                          <img
                            src={this.getSignedUrlForAnswer(activeAnswer.uploadUrl)}
                            style={{ width: '100%' }}
                            alt=""
                          />
                        )}
                        {isVideo(activeAnswer.uploadUrl) && (
                          <video
                            style={{ width: '100%', height: '200px' }}
                            controls
                            src={this.getSignedUrlForAnswer(activeAnswer.uploadUrl)}
                          >
                            <source
                              src={this.getSignedUrlForAnswer(activeAnswer.uploadUrl)}
                              type="video/mp4"
                            />
                          </video>
                        )}
                        {isAudio(activeAnswer.uploadUrl) && (
                          <audio controls>
                            <source
                              src={this.getSignedUrlForAnswer(activeAnswer.uploadUrl)}
                              type="audio/mpeg"
                            />
                          </audio>
                        )}
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <AnswerData title="Client">{activeAnswer.fullName}</AnswerData>
                          <AnswerData title="Gender">{activeAnswer.gender}</AnswerData>
                          <AnswerData title="Age">{activeAnswer.age}</AnswerData>
                        </div>
                        <div className="col-md-6">
                          <AnswerData title="Date">
                            {dateToReadable(utcDateToLocal(activeAnswer.timestamp))}
                          </AnswerData>
                          <AnswerData title="User id">{activeAnswer.userId}</AnswerData>
                          <AnswerData title="Education">{activeAnswer.education}</AnswerData>
                        </div>
                        <div className="col-md-12 d-flex justify-content-center">
                          <Button onClick={() => this.onDownload(activeAnswer.uploadUrl)}>
                            Download
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ discussions, ...state }) => ({
  data: discussions.answers.details.data,
  states: discussions.answers.details.states,
  formValues: getFormValues(ANSWER_DETAILS_FORM)(state),
});
const mapDispatchToProps = (dispatch) => bindActionCreators({ fetchAnswersByDiscussion }, dispatch);

export default compose(connect(mapStateToProps, mapDispatchToProps))(AnswersDetails);
