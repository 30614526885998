import React from 'react'
import { useController, useFormContext, ControllerRenderProps, InputState } from 'react-hook-form'
import MuiBox from '@material-ui/core/Box'
import MuiFormControl from '@material-ui/core/FormControl'
import { ErrorMessage } from '@hookform/error-message'
import { FieldLabel, FormHelperText } from 'components'
import { useStyles } from './FieldWrapper.styles'

interface FieldWrapperProps {
    children: (field: ControllerRenderProps, meta: InputState) => React.ReactNode
    className?: string
    defaultValue: unknown
    disabled?: boolean
    htmlFor?: string
    label?: string
    name: string
    required?: boolean
    small?: boolean
    tooltip?: string
}

export const FieldWrapper = ({
    children,
    className,
    defaultValue,
    disabled,
    htmlFor,
    label,
    name,
    required,
    small,
    tooltip,
}: FieldWrapperProps) => {
    const styles = useStyles()
    const { control, errors } = useFormContext()
    const { field, meta } = useController({
        control,
        defaultValue,
        name,
    })

    return (
        <MuiFormControl
            classes={{ root: small ? styles.small : styles.root }}
            className={className}
            error={meta.invalid}
            disabled={disabled}
            fullWidth
        >
            <MuiBox
                alignItems="center"
                display={small ? 'flex' : 'block'}
                justifyContent="center"
                style={{ width: '100%' }}
            >
                {label && (
                    <MuiBox flex="0 0 120px">
                        <FieldLabel
                            disabled={disabled}
                            htmlFor={htmlFor}
                            label={label}
                            required={required}
                            small={small}
                            tooltip={tooltip}
                        />
                    </MuiBox>
                )}
                {children(field, meta)}
            </MuiBox>
            <ErrorMessage
                errors={errors}
                name={name}
                render={({ message }) => <FormHelperText error>{message}</FormHelperText>}
            />
        </MuiFormControl>
    )
}
