import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Country, WithId } from 'shared/types';
import { fetchCampaignFilesFromS3 } from 'shared/services/aws';
import {
  getCampaign,
  getCampaignMedia,
  getCampaigns,
  postCampaign,
  putCampaign,
} from '../api';
import { CampaignRequest, CampaignResponse } from '../types';

import { FETCH_CAMPAIGN_DATA } from '../../Campaign/reducers/manage'
import { TOAST_MESSAGE } from 'shared/constants/message'

export const useCampaignManagement = () => {
  const dispatch = useDispatch();
  const country: Country = useSelector(({ app }: any) => app.country);

  const fetchCampaign = async (campaignId: string): Promise<CampaignResponse> => {
    const [data] = await Promise.all([
      getCampaign(campaignId),
      fetchCampaignMedia(campaignId),
    ]);

    return data;
  };

  // TODO: refactor when form is out of redux
  const fetchCampaignMedia = async (campaignId: string): Promise<void> => {
    const [media, files] = await Promise.all([
      getCampaignMedia(campaignId),
      fetchCampaignFilesFromS3(campaignId),
    ]);
    dispatch({
      type: FETCH_CAMPAIGN_DATA.SUCCESS,
      payload: {
        media,
        files,
      },
    });
  };

    const createCampaign = async (
        data: Omit<CampaignRequest, 'countryOfAvailability'>,
    ): Promise<void> => {
        try {
            const response = await postCampaign({ ...data, countryOfAvailability: country })
            if(response.status == 200) {
                toast.success(TOAST_MESSAGE.campaign.created)
            } else {
                toast.error(TOAST_MESSAGE[500])
            }                      
        } catch (error) {
            toast.error(TOAST_MESSAGE[500])
        }
    }
  

    const updateCampaign = async (
        data: WithId<Omit<CampaignRequest, 'countryOfAvailability'>>,
    ): Promise<void> => {
        try {
            const response = await putCampaign(data)
            if(response.status == 200) {
                toast.success(TOAST_MESSAGE.campaign.updated)
            } else {
                toast.error(TOAST_MESSAGE[500])
            }
        } catch (error) {
            toast.error(TOAST_MESSAGE[500])
        }
    };
  

  return {
    createCampaign,
    fetchCampaign,
    fetchCampaigns: getCampaigns,
    updateCampaign,
  };
};
