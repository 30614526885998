import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';
import keys from 'lodash/keys';
import { BUCKET_OPTION_TYPES } from 'shared/constants/buckets';

const calculateAllocation = (values = [], capacity) => {
  const allocationPercentage = values.reduce((a, b) => Number(a) * (Number(b) / 100)).toFixed(2);
  const allocation = ((capacity * allocationPercentage) / 100).toFixed(0);
  return {
    allocationPercentage,
    allocation,
  };
};

export const getPreviewBuckets = (values, capacity) => {
  const results = [];
  const { genderType, educationType, gender: genderObj, education: educationObj, ageBuckets } =
    values || {};

  if (!ageBuckets) {
    return results;
  }

  const gender = keys(pickBy(genderObj, identity));
  const education = keys(pickBy(educationObj, identity));

  ageBuckets.forEach((bucket) => {
    // Both Filtered
    if (
      genderType === BUCKET_OPTION_TYPES.filtered &&
      educationType === BUCKET_OPTION_TYPES.filtered
    ) {
      results.push({ ...bucket, gender, education });
    }

    // Gender nested
    // Education filtered
    if (
      genderType === BUCKET_OPTION_TYPES.nested &&
      educationType === BUCKET_OPTION_TYPES.filtered
    ) {
      gender.forEach((gender) => {
        const { allocationPercentage, allocation } = calculateAllocation(
          [bucket.allocationPercentage, genderObj[gender].allocationPercentage],
          capacity,
        );
        results.push({
          ...bucket,
          gender: [gender],
          education,
          allocationPercentage,
          allocation,
        });
      });
    }
    // Gender filtered
    // Education nested
    if (
      genderType === BUCKET_OPTION_TYPES.filtered &&
      educationType === BUCKET_OPTION_TYPES.nested
    ) {
      education.forEach((education) => {
        const { allocationPercentage, allocation } = calculateAllocation(
          [bucket.allocationPercentage, educationObj[education].allocationPercentage],
          capacity,
        );
        results.push({
          ...bucket,
          gender,
          education: [education],
          allocationPercentage,
          allocation,
        });
      });
    }
    // Both nested
    if (genderType === BUCKET_OPTION_TYPES.nested && educationType === BUCKET_OPTION_TYPES.nested) {
      gender.forEach((gender) => {
        education.forEach((education) => {
          const { allocationPercentage, allocation } = calculateAllocation(
            [
              bucket.allocationPercentage,
              genderObj[gender].allocationPercentage,
              educationObj[education].allocationPercentage,
            ],
            capacity,
          );
          results.push({
            ...bucket,
            gender: [gender],
            education: [education],
            allocationPercentage,
            allocation,
          });
        });
      });
    }
  });

  const resultsWithoutNan = results.map((result) => ({
    ...result,
    allocation: isNaN(result.allocation) ? 0 : result.allocation,
    allocationPercentage: isNaN(result.allocationPercentage) ? 0 : result.allocationPercentage,
  }));
  return resultsWithoutNan;
};

export const areBucketsValid = (buckets) => {
  const validBuckets = buckets.filter(
    (bucket) =>
      bucket.ageFrom &&
      bucket.ageTo &&
      bucket.allocation &&
      bucket.allocationPercentage &&
      bucket.gender,
  );
  return !!(buckets.length && buckets.length === validBuckets.length);
};
