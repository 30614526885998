import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import MuiOutlinedInput from '@material-ui/core/OutlinedInput';
import MuiBox from '@material-ui/core/Box';
import { FieldWrapper } from 'components';
import { IntlString, LanguageTranslation } from 'shared/types';
import { TranslationSelector } from './TranslationSelector';
import { useStyles } from './TextIntlField.styles';
import { useCountry } from 'hooks';
import { LANGUAGE_TRANSLATION } from 'shared/constants/lang';

interface TextIntlFieldProps {
  defaultValue?: IntlString;
  disabled?: boolean;
  label?: string;
  maxLength?: number;
  multiline?: boolean;
  name: string;
  placeholder?: string;
  required?: boolean;
  tooltip?: string;
}

export const TextIntlField = ({
  defaultValue = { NL: undefined },
  disabled,
  label,
  maxLength,
  multiline,
  name,
  placeholder,
  required,
  tooltip,
}: TextIntlFieldProps) => {
  const styles = useStyles();
  const { selectedCountryCode } = useCountry();
  const defaultCountry = LANGUAGE_TRANSLATION[selectedCountryCode];
  const [translation, selectTranslation] = useState<LanguageTranslation>(defaultCountry); 

  const { setValue, trigger } = useFormContext();

  const handleBlur = onBlur => () => {
    trigger(name);
    onBlur();
  };

  const handleChange = value => event => {
    const val = event.target.value;
    setValue(name, {
      ...value,
      [translation]: maxLength ? val.slice(0, maxLength) : val,
    });
  };

  return (
    <FieldWrapper
      defaultValue={defaultValue}
      disabled={disabled}
      label={label}
      name={name}
      required={required}
      tooltip={tooltip}
    >
      {({ onBlur, ref, value, ...field }) => (
        <MuiBox position="relative">
          <MuiOutlinedInput
            {...field}
            classes={{ root: styles.input }}
            fullWidth
            id={name}
            inputRef={ref}
            multiline={multiline}
            onBlur={handleBlur(onBlur)}
            onChange={handleChange(value)}
            placeholder={placeholder}
            value={value[translation] || ''}
          />
          <TranslationSelector
            onSelect={selectTranslation}
            selected={translation}
            value={value}
            required={required}
          />
          {maxLength && (
            <MuiBox position="absolute" top="3px" right="14px">
              {(value[translation] || '').length} / {maxLength}
            </MuiBox>
          )}
        </MuiBox>
      )}
    </FieldWrapper>
  );
};
