import * as yup from 'yup'

const string = yup.string().transform((cv, ov) => (ov === '' ? undefined : cv)).nullable().notRequired()

export const schema = yup.object({
    label: string,
    medium: string,
    creativeName: string,
    creativeLink: string.url(),
})
