import React from 'react'
import MuiBox from '@material-ui/core/Box'
import MuiCheckbox from '@material-ui/core/Checkbox'
import {
    AutocompleteField,
    Button,
    DateTimeField,
    Dialog,
    FieldArray,
    Form,
    ModalFormProps,
    SurveyBulkQuestionsModalForm,
    Table,
    TextField,
} from 'components'
import { SurveyQuestionFormRow } from './types'

interface FormRowProps {
    field: SurveyQuestionFormRow
    index: number
    isSelected: boolean
    onChange: () => void
}

const MEDIUM_OPTIONS = [
    'Cinema',
    'Facebook',
    'Instagram',
    'Online Radio',
    'Online Video',
    'Podcast',
    'Radio',
    'Snapchat',
    'Social Media',
    'Television',
    'Twitter',
    'OOH',
    'DOOH',
    'BC',
].map(o => ({ value: o, label: o }))

export const FormRow = ({ field, index, isSelected, onChange }: FormRowProps) => (
    <MuiBox display="flex" alignItems="center">
        <MuiBox flex="0 0 50px">
            <MuiCheckbox checked={isSelected} color="primary" onChange={onChange} />
        </MuiBox>
        <MuiBox flex="0 0 250px" style={{ width: '250px', overflowX: 'hidden' }}>
            {field.surveyMonkeyQuestion}
        </MuiBox>
        <MuiBox ml={1} flex="0 0 50px">
            {field.surveyMonkeyQuestionPageNumber}
        </MuiBox>
        <MuiBox ml={1} flex="0 0 50px">
            {field.surveyMonkeyQuestionPagePosition}
        </MuiBox>
        <MuiBox ml={1} flex="1 1 0%">
            <TextField small name={`questions[${index}].label`} defaultValue={field.label} />
        </MuiBox>
        <MuiBox ml={1} flex="1 1 0%">
            <AutocompleteField
                defaultValue={field.medium}
                name={`questions[${index}].medium`}
                options={MEDIUM_OPTIONS}
                small
            />
        </MuiBox>
        <MuiBox ml={1} flex="1 1 0%">
            <TextField
                small
                name={`questions[${index}].creativeName`}
                defaultValue={field.creativeName}
            />
        </MuiBox>
        <MuiBox ml={1} flex="1 1 0%">
            <TextField
                small
                name={`questions[${index}].creativeLink`}
                defaultValue={field.creativeLink}
            />
        </MuiBox>
    </MuiBox>
)
