// TODO: refactor apiService
import apiService from 'shared/services/api'
import { normalizeQuery, Query } from 'services'
import { LotteryRequest } from './types'

export const getLotteries = async (query?: Query) => {
    const { data } = await apiService({}).get('/lotteries', {
        params: normalizeQuery(query),
    })

    return data
}

export const postLottery = async (data: LotteryRequest) => {
    await apiService({}).post('/lotteries', data)
}
