import * as types from '../../types/AuthFormValidateTypes';

const validate = ({ email, password }: types.FormValues) => {
  const errors: any = {};
  if (!email) {
    errors.email = 'Please enter email';
  }
  if (!password) {
    errors.password = 'Please enter password';
  }

  return errors;
};

export default validate;
