import React from 'react'
import MuiButton, { ButtonProps as MuiButtonProps } from '@material-ui/core/Button'
import MuiBox from '@material-ui/core/Box'
import MuiCircularProgress from '@material-ui/core/CircularProgress'
import { useStyles } from './Button.styles'

interface ButtonProps extends Omit<MuiButtonProps, 'color'> {
    loading?: boolean
    color?: 'default' | 'primary' | 'secondary' | 'info'
}

export const Button = ({ children, color = 'primary', loading, ...props }: ButtonProps) => {
    const { contained, text, ...styles } = useStyles({ loading, color })

    return (
        <MuiButton {...props} classes={{ contained, text }}>
            <MuiBox className={styles.label}>{children}</MuiBox>
            {loading && (
                <MuiBox className={styles.spinner}>
                    <MuiCircularProgress size={25} />
                </MuiBox>
            )}
        </MuiButton>
    )
}
