import React from 'react'
import { saveAs } from 'file-saver'
import MuiBox from '@material-ui/core/Box'
import { Button, Form, FormProps, TextField, FileField } from 'components'
import { csvifyLocations } from 'modules/campaignM'
import { AddCsvLocationsFormData } from './types'
import { schema } from './validation'

export const AddCsvLocationsForm = ({ onSubmit }: FormProps<AddCsvLocationsFormData>) => {
    const file = new File(
        ['latitude,longitude,dateFrom,dateTo,radius,filterName,locationType,locationDetailName,locationDetailId,address,postalCode,city,province,country\r\n'],
        'inputFileManualUpload.csv',
        { type: 'text/csv;charset=utf-8' },
    )
    const handleDownload = () => {
        saveAs(file)
    }

    return (
        <Form<AddCsvLocationsFormData> onSubmit={onSubmit} schema={schema}>
            {({ isSubmitDisabled, isSubmitting }) => (
                <>
                    <MuiBox position="relative" mb={4}>
                        <FileField accept="text/csv" label="Source file" name="files" required />
                        <MuiBox position="absolute" top={0} right={0}>
                            <Button color="primary" variant="text" type="button" onClick={handleDownload}>
                                Download input file
                            </Button>
                        </MuiBox>
                    </MuiBox>
                    <MuiBox display="flex" justifyContent="flex-end" pb="24px">
                        <Button disabled={isSubmitDisabled} loading={isSubmitting}>
                            Upload locations
                        </Button>
                    </MuiBox>
                </>
            )}
            </Form>
    )
}
