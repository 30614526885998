import React from 'react'
import MuiTypography from '@material-ui/core/Typography'
import MuiGrid from '@material-ui/core/Grid'
import MuiBox from '@material-ui/core/Box'
import { Button } from 'components'

interface PageHeaderProps {
    title?: string
    cta?: ReturnType<typeof Button>
    children?: React.ReactNode
}

export const PageHeader = ({ title, cta, children }: PageHeaderProps) => (
    <MuiBox
        bgcolor="#fff"
        boxShadow="0 0 3px gray"
        p="30px 60px"
        position="sticky"
        top={50}
        zIndex={1}>
        <MuiGrid container alignItems="center" spacing={3}>
            {title && (
                <MuiGrid item>
                    <MuiTypography variant="h1">{title}</MuiTypography>
                </MuiGrid>
            )}
            {cta && <MuiGrid item>{cta}</MuiGrid>}
            {children && <MuiGrid item>{children}</MuiGrid>}
        </MuiGrid>
    </MuiBox>
)
