import React from 'react';
import { Route } from 'react-router-dom';
import { routes } from 'shared/constants/routes';
import Questions from './containers/Questions';
import QuestionsManage from './containers/QuestionsManage';
import Answers from './containers/Answers';
import AnswersDetails from './containers/AnswersDetails';

export default () => (
  <>
    <Route
      path={routes.discussions.questions.list.path}
      key="discussions-questions-list"
      component={Questions}
      exact
    />
    <Route
      path={routes.discussions.questions.manage('(edit|create)/:id?').path}
      key="discussions-questions-manage"
      component={QuestionsManage}
      exact
    />
    <Route
      path={routes.discussions.answers.list.path}
      key="discussions-answers-list"
      component={Answers}
      exact
    />
    <Route
      path={routes.discussions.answers.manage(':id').path}
      key="discussions-answers-manage"
      component={AnswersDetails}
      exact
    />
  </>
);
