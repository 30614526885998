// TODO: refactor apiService
import apiService from 'shared/services/api'
import { utcDateToLocal, localDateToUtc } from 'shared/services/date';
import { normalizeQuery, Query } from 'services'
import { WithId } from 'shared/types'
import { CampaignRequest, UploadLocationsRequest, LocationRequest } from './types'
import moment from 'moment'

const dateUTCAdd = date => {
  const dateUTC = moment(date, 'YYYY-MM-DD');
  const updatedDate = dateUTC
    .set('hour', 6)
    .set('minute', 0)
    .set('second', 0)
    .set('millisecond', 0);

  return updatedDate;
};

const normalizeDatesRequest = (data) => ({
        ...data,
        dateFrom: localDateToUtc(dateUTCAdd(data.dateFrom)),
        dateTo: localDateToUtc(dateUTCAdd(data.dateTo)),
})

const normalizeDatesResponse = (data) => ({
    ...data,
    dateFrom: data.dateFrom ? utcDateToLocal(data.dateFrom) : undefined,
    dateTo: data.dateTo ? utcDateToLocal(data.dateTo) : undefined,
})

export const getClients = async (query?: Query) => {
    const { data } = await apiService({}).get('/campaigns/clients', {
        params: normalizeQuery(query),
    })

    return normalizeDatesResponse(data)
}

export const getCampaigns = async (query?: Query) => {
    const { data } = await apiService({}).get('/campaigns', {
        params: normalizeQuery(query),
    })

    return normalizeDatesResponse(data)
}

export const getCampaign = async (id: string) => {
    const { data } = await apiService({}).get(`/campaigns/${id}`)

    return normalizeDatesResponse(data)
}

export const postCampaign = async (data: CampaignRequest) => {
    return await apiService({}).post('/campaigns', normalizeDatesRequest(data))
}

export const putCampaign = async ({
    id,
    ...data
}: WithId<Omit<CampaignRequest, 'countryOfAvailability'>>) => {
    return await apiService({}).put(`/campaigns/${id}`, normalizeDatesRequest(data))
}

export const getCampaignMedia = async (id: string) => {
    const { data } = await apiService({}).get(`/campaigns/${id}/media`)

    return data
}

export const postLocation = async (data: LocationRequest) => {
    const response = await apiService({}).post(`/campaigns/${data.campaignId}/locations`, data)

    return normalizeDatesResponse(response.data)
}

export const putLocations = async (campaignId: string, data: WithId<LocationRequest>[]) => {
    const response = await apiService({}).put(`/campaigns/${campaignId}/locations`, data)

    return response.data
}

export const putCsvLocations = async ({ id, ...data }: UploadLocationsRequest) => {
    const response = await apiService({}).put(`/campaigns/${id}/locations/csv`, data)

    return response.data
}

export const deleteLocation = async (campaignId: number, locationId: number) => {
    await apiService({}).delete(`/campaigns/${campaignId}/locations/${locationId}`)
}

export const deleteAllLocations = async (campaignId: number) => {
    await apiService({}).delete(`/campaigns/${campaignId}/locations`)
}
