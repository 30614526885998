import React, { useState } from 'react';
import MuiBox from '@material-ui/core/Box';
import {TextField as MuiTextField} from '@material-ui/core'
import {Button as MuiButton} from '@material-ui/core'
import { 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogContentText, 
  DialogTitle 
} from '@material-ui/core'
import MuiTypography from '@material-ui/core/Typography';
import {
  Form,
  TextField,
  Button,
  DataTable,
  AutocompleteField,
} from 'components';
import { schema } from './validation';
import styles from './styles.module.scss';
import { COUNTRY_OPTIONS, LANGUAGE_OPTIONS, TRUE_FALSE_OPTIONS } from 'shared/types';
import moment from 'moment';
import {  Query } from 'services'
import Modal from 'shared/components/Modal';
import { CheckMark, Close } from 'shared/components/Icons';
import { addPointsToUser } from 'modules/user/api';


const COLUMNS = [
  {
    field: 'orderDate',
    headerName: 'Order Date',
  },
  {
    field: 'ordered',
    headerName: 'Ordered',
  },
  {
    field: 'sent',
    headerName: 'Sent',
  },
  {
    field: 'cardNumber',
    headerName: 'Card Number',
  },
  {
    field: 'cardPin',
    headerName: 'Card Pin',
  },
  {
    field: 'giftCard',
    headerName: 'Gift Card',
  },
  {
    field: 'expiryDate',
    headerName: 'Expiry Date',
  },
  {
    field: 'giftCardLink',
    headerName: 'Gift Card Link',
  },
];

export const UserInfoForm = ({ defaultValues, onSubmit }) => {

  const getGiftCards = async (query?: Query): Promise<any> => {

    if (!defaultValues.userGiftCards || defaultValues.userGiftCards.length === 0) {
        return {
            content: [],
            totalPages: 0,
            pageable: { pageNumber: 0, pageSize: 0 },
        };
    }

    let mappedUserGiftCards = defaultValues.userGiftCards.map((item, index) => {
      let url = '#'

      if(item.giftCardLink) {
        let tempUrl = item.giftCardLink.replace('https://', '').replace('http://', '');
        url = 'http://' + tempUrl;
      }

      return {
        orderDate: moment(item.purchaseDate).format('DD-MM-YYYY'),
        ordered: item.tradeOrdered ? 'Yes' : 'No',
        sent: item.tradeCardSent || "--",
        cardNumber: item.tradeCardNumber || "--",
        cardPin: item.tradeCardPin || "--",
        giftCardLink: <a href={url} target='_blank' className={styles.giftCardLink}>{item.giftCardLink}</a>,
        giftCardLinkSort: item.giftCardLink,
        giftCard: item.giftCard.title,
        expiryDate: item.tradeCardExpirationDate ? moment(item.tradeCardExpirationDate).format('DD-MM-YYYY') : "--",
      }
    });

    if(query?.sort) {
      mappedUserGiftCards = mappedUserGiftCards.sort((a, b) => {
        let field = query.sort.field

        if(field === 'giftCardLink') {
          field = 'giftCardLinkSort'
        }

        const nameA = a[field] ? a[field].toLowerCase() : '';
        const nameB = b[field] ? b[field].toLowerCase() : '';
        if (nameA > nameB) return query.sort.sort === 'asc' ? 1 : -1;
        if (nameA < nameB) return query.sort.sort === 'asc' ? -1 : 1;
        return 0;
      });
    }

    const response = {
      content: mappedUserGiftCards,
      totalPages: 0,
      pageable: { pageNumber: 1, pageSize: 1 },
    };

    return new Promise((resolve, reject) => {
      resolve(response);
    });
  };

  const [isModalOpen, setModalOpen] = useState(false);
  const [isConfirmOpen, setConfirmOpen] = useState(false);
  const [points, setPoints] = useState('');

  const [isPasswordPromptOpen, setPasswordPromptOpen] = useState(false);
  const [passwordInput, setPasswordInput] = useState('');

  const style: React.CSSProperties = {
    maxWidth: '50%',
    maxHeight: '50%'
  };

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handlePointsChange = (event) => {
    setPoints(event.target.value);
  };

  const handleSubmitPoints = (event) => {
    event.preventDefault();
    setConfirmOpen(true);
  };

  const confirmAddPoints = () => {
    console.log(`Points to add: ${points} for userID: ${defaultValues.id}`);
    addPointsToUser(parseInt(points), defaultValues.id)
    setPoints('');
    setConfirmOpen(false);
    handleCloseModal();
  };

  const handlePasswordInputChange = (event) => {
    setPasswordInput(event.target.value);
  };

  const handleAddPointsClick = () => {
    setPasswordPromptOpen(true);
    console.log(defaultValues.fraudulent)
  };

  const verifyPassword = (password) => {
    return password === "jqT#T_9IHM3V?7=8+.1N"; 
  };

  const handleSubmitPassword = () => {
    if (verifyPassword(passwordInput)) {
      setPasswordPromptOpen(false);
      setModalOpen(true);
      setPasswordInput('');
    } else {
      alert('Incorrect password. Please try again.');
      setPasswordInput('');
    }
  };

  return (
    <>
    <Dialog open={isPasswordPromptOpen} onClose={() => setPasswordPromptOpen(false)}>
      <DialogTitle>Authenticate</DialogTitle>
      <DialogContent>
        <DialogContentText>Please enter the password to proceed.</DialogContentText>
        <MuiTextField
            margin="dense"
            id="password"
            label="Password"
            type="password"
            value={passwordInput}
            onChange={handlePasswordInputChange}
            style={{marginBottom: '10%'}}
        />
      </DialogContent>
      <DialogActions style={{ justifyContent: 'space-between'}}>
        <MuiButton onClick={() => setPasswordPromptOpen(false)} color="secondary">
          <Close></Close>
        </MuiButton>
        <MuiButton onClick={handleSubmitPassword} color="primary">
          <CheckMark></CheckMark>
        </MuiButton>
        </DialogActions>
    </Dialog>
    <Dialog
    open={isConfirmOpen}
    onClose={() => setConfirmOpen(false)}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Confirm Adding Points"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {`Are you sure you want to award ${points} point(s) to ID: ${defaultValues.id}?`}
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'space-between'}}>
        <MuiButton onClick={() => setConfirmOpen(false)} color="secondary">
          <Close></Close>
        </MuiButton>
        <MuiButton onClick={confirmAddPoints} color="primary">
          <CheckMark></CheckMark>
        </MuiButton>
      </DialogActions>
    </Dialog>
    <div>
      <Modal
        isOpen={isModalOpen}
        onClose={handleCloseModal} // Close the modal when backdrop is clicked or escape key is pressed
        aria-labelledby="add-points-modal-title"
        aria-describedby="add-points-modal-description"
        title='Add Points'
        style={style}
      >
        <form onSubmit={handleSubmitPoints}>
          <div>
            <MuiTextField
              label="Points"
              name="points"
              type="number"
              value={points} // Use the state value
              onChange={handlePointsChange} // Set up the change handler
              required
              inputProps={{ 
                max: 10000
              }}
              className={styles.AddPointsInput}
            />
          </div>
          <Button type="submit" className={styles.AddPointsSubmit}>
            Add Points
          </Button>
        </form>
      </Modal>
    </div>
      <div className={styles.UserInfoForm}>
        <div className={styles.UserInfoHeader}>
          <div className={styles.UserInfoHeaderText}>{defaultValues.email}</div>
        </div>
        <div className={styles.UserInfo}>
          <div className={styles.UserInfoCard}>
            <MuiBox>
              <Form<any>
                defaultValues={defaultValues}
                onSubmit={(values) => {
                  const payload = {
                    id: values.id,
                    email: values.email,
                    country: values.country,
                    language: values.language,
                    phone: values.phone,
                    source: values.source,
                    referralAwarded: values.referralAwarded === "Yes" ? true : null,
                    fraudulent: values.fraudulent,
                    username: values.username,
                  }
                  onSubmit(payload)
                  
                }}
                schema={schema}
              >
                {({ isSubmitting, isValid, isSubmitDisabled }) => (
                  <>
                    <Button
                      type="submit"
                      loading={isSubmitting}
                      disabled={!isValid}
                      className={styles.SubmitButton}
                      >
                      Save
                    </Button>
                    <Button
                      type="button"
                      className={styles.AddPointsButton}
                      onClick={handleAddPointsClick}
                      >
                      Add Points
                    </Button>
                    

                    <MuiTypography variant="h2">User Information</MuiTypography>

                    <TextField label="ID" name="id" disabled />
                    <TextField label="Username" name="username" disabled />
                    <TextField label="Register Date" name='registerDateDisable' defaultValue={defaultValues?.registerDate && moment(defaultValues.registerDate).format('DD-MM-YYYY')} disabled />
                    <TextField label="Latest OS" name="os" defaultValue={defaultValues?.userDevice?.os} disabled readOnly/>
                   
                    <TextField label="Email" name="email" required/>

                    <AutocompleteField
                      label="Country"
                      name="country"
                      options={COUNTRY_OPTIONS}
                      required
                    />

                    <AutocompleteField
                      label="Language"
                      name="language"
                      options={LANGUAGE_OPTIONS}
                      required
                    />

                    <TextField label="Phone" name="phone" required />
                    <TextField label="Source" name="source" disabled/>

                    <AutocompleteField
                      label="Fraudulent"
                      name="fraudulent"
                      options={TRUE_FALSE_OPTIONS}
                      required
                    />
                    <TextField label="Referral Awarded" name="referralAwarded" disabled />                    
                  </>
                )}
              </Form>
            </MuiBox>
          </div>

          <div className={styles.UserGiftCard}>
            <MuiTypography variant="h1">Gift Cards</MuiTypography>
            {defaultValues?.userGiftCards?.length > 0 ?
              <DataTable<any>
                columns={COLUMNS}
                onRowClick={() => {}}
                requestHandler={getGiftCards}

              />
              : ''
            }
            { defaultValues?.userGiftCards?.length === 0 ?
              <div className={styles.NoGiftcard}>
                <MuiTypography variant="h2">
                  No luck! This user hasn't ordered any gift cards yet.
                </MuiTypography>
              </div>
              : ''
            }
          </div>
        </div>
      </div>
    </>
  );
};
