import * as yup from 'yup';
import { specialCharsRegex, specialCharsRegexMessage } from 'shared/utils/regex';

const date = yup.date().transform((cv, ov) => (ov === null ? undefined : cv));
const string = yup
  .string()
  .transform((cv, ov) => (ov === '' ? undefined : cv))
  .nullable()
  .notRequired();

export const searchSchema = yup.object({
  query: yup.string().notRequired(),
});

export const placesSchema = yup.object({
  dateFrom: date.required().when('dateTo', (dateTo, s) => {
    if (!dateTo) {
      return s;
    }

    return yup.date().max(dateTo);
  }),
  dateTo: date.required().min(yup.ref('dateFrom')),
  radius: yup
    .number()
    .transform((cv, ov) => (ov === '' ? undefined : cv))
    .required()
    .integer()
    .positive(),
  filterName: yup
    .string()
    .required()
    .matches(specialCharsRegex, specialCharsRegexMessage),
  locationType: string,
  locationDetailName: string,
  locationDetailId: string,
});
