import React from 'react'
import MuiBox from '@material-ui/core/Box'

interface PageProps {
    children: React.ReactNode
    header?: React.ReactNode
}

export const Page = ({ header, children }: PageProps) => (
    <MuiBox>
        {header}
        <MuiBox p="55px 65px">{children}</MuiBox>
    </MuiBox>
)
