import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Field } from 'redux-form';
import { uploadMedia } from 'shared/reducers/files';
import Button from 'shared/components/Button';
import FileField from 'shared/components/FileField';
import FormButtons from 'shared/components/FormButtons';
import MediaFile from 'shared/components/MediaFile';

interface UploadFileProps {
  id: number;
  prefix?: string | number;
  accept?: string;
  onFileUploaded: (location: string) => void;
}

const UploadFile = ({
  id,
  onFileUploaded,
  accept = '.jpg,.mp4,.png,.jpeg',
  prefix = 'defaultPrefix',
}: UploadFileProps) => {
  const [file, setFile] = useState<File>();
  const dispatch = useDispatch();
  const uploadMediaState = useSelector(({ shared }: any) => shared.files.uploadMedia);
  const selectedFile = useMemo(
    () => uploadMediaState.find(f => f.id === id),
    [uploadMediaState, id],
  );
  const showFile = useMemo(() => selectedFile && file, [selectedFile, file]);

  const handleUpload = useCallback(
    () =>
      dispatch(
        uploadMedia({
          file: file,
          id: id,
          prefix,
          bucket: process.env.REACT_APP_AWS_DISCUSSION_BUCKET,
        }),
      ),
    [file, id, prefix, process.env.REACT_APP_AWS_DISCUSSION_BUCKET, dispatch],
  );
  const memoizedOnFileUploaded = useCallback(onFileUploaded, [onFileUploaded]);

  useEffect(() => {
    if (selectedFile && selectedFile.success) {
      memoizedOnFileUploaded(selectedFile.success.Location);
    }
  }, [selectedFile, memoizedOnFileUploaded]);

  return (
    <div>
      <Field
        component={FileField}
        name="file"
        label="File"
        accept={accept}
        tooltip={`Supported files: ${accept}. Special characters are not accepted. é,ź etc. are not supported, and upload will not work.`}
        onChange={setFile}
      />
      {showFile && (
        <MediaFile
          fileData={selectedFile}
          isProcessing={!selectedFile.success}
          fileName={file.name}
        />
      )}
      <FormButtons>
        <Button
          type="primary"
          disabled={!file}
          onClick={handleUpload}
          buttonType="button"
        >
          Use selected file
        </Button>
      </FormButtons>
    </div>
  );
};

export default UploadFile;
