import React from 'react';
import MuiSwitch from '@material-ui/core/Switch';
import MuiBox from '@material-ui/core/Box';
import FieldLabel from '../FieldLabel';

const Switch = props => {
  const handleChange = (...args) => {
    props.readOnly || props.input.onChange(...args);
  };
  return (
    <MuiBox display="flex" alignItems="center" mb={2}>
      <FieldLabel noMargin>Enable push notification</FieldLabel>
      <MuiSwitch color="primary" onChange={handleChange} checked={props.input.value} />
    </MuiBox>
  );
};

export default Switch;
