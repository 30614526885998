import update from 'immutability-helper';

import { createReducer } from 'shared/utils/redux';
import { loadingStates, setLoadingStates } from 'shared/constants/redux';
import { createRequestTypes } from 'shared/utils/request';

export const CAMPAIGNS_FETCH_LIST = createRequestTypes('campaigns/FETCH_LIST');
export const CAMPAIGNS_FETCH_CLIENT_LIST = createRequestTypes('campaigns/list/FETCH_CLIENTS');

export const fetchCampaigns = ({ search }) => ({
  type: CAMPAIGNS_FETCH_LIST.REQUEST,
  payload: { search },
});

export const fetchClients = () => ({
  type: CAMPAIGNS_FETCH_CLIENT_LIST.REQUEST,
  payload: {},
});

const initState = {
  states: loadingStates,
  data: {
    content: [],
    totalPages: 0,
    totalElements: 0,
    size: 5,
  },
  response: null,
  clients: {
    states: loadingStates,
    data: [],
  },
};

export default createReducer(initState, {
  [CAMPAIGNS_FETCH_LIST.REQUEST]: (state) => {
    return update(state, {
      $merge: {
        states: setLoadingStates({ isLoading: true }),
      },
    });
  },
  [CAMPAIGNS_FETCH_LIST.SUCCESS]: (state, action) => {
    return update(state, {
      $merge: {
        states: setLoadingStates({ isLoaded: true }),
        data: action.payload,
      },
    });
  },
  [CAMPAIGNS_FETCH_LIST.FAILURE]: (state, action) => {
    return update(state, {
      $merge: {
        states: setLoadingStates({ hasError: true }),
        response: action.payload.response,
      },
    });
  },
  [CAMPAIGNS_FETCH_CLIENT_LIST.REQUEST]: (state) => {
    return update(state, {
      clients: {
        $merge: {
          states: setLoadingStates({ isLoading: true }),
        },
      },
    });
  },
  [CAMPAIGNS_FETCH_CLIENT_LIST.SUCCESS]: (state, action) => {
    return update(state, {
      clients: {
        $merge: {
          states: setLoadingStates({ isLoaded: true }),
          data: action.payload,
        },
      },
    });
  },
  [CAMPAIGNS_FETCH_CLIENT_LIST.FAILURE]: (state, action) => {
    return update(state, {
      clients: {
        $merge: {
          states: setLoadingStates({ hasError: true }),
          response: action.payload.response,
        },
      },
    });
  },
});
