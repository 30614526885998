import { makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((t: Theme) => ({
    '@keyframes emoji1-eyes-blink': {
        '0%': {
            height: '10px',
        },
        '97%': {
            height: '10px',
        },
        '100%': {
            height: '0px',
        },
    },
    '@keyframes emoji1-face-move': {
        '0%': {
            transform: 'translateX(0)',
        },
        '30%': {
            transform: 'translateX(0)',
        },
        '35%': {
            transform: 'translateX(-20px)',
        },
        '65%': {
            transform: 'translateX(-20px)',
        },
        '70%': {
            transform: 'translateX(-10px)',
        },
        '85%': {
            transform: 'translateX(-10px)',
        },
        '90%': {
            transform: 'translateX(0)',
        },
        '100%': {
            transform: 'translateX(0)',
        },
    },
    wrapper: {
        position: 'relative',
    },
    overlay: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'rgba(255, 255, 255, 0.8)',
        '& > *': {
            flex: '0 0 auto',
        },
    },
    icon: {
        borderRadius: '50%',
        position: 'relative',
        display: 'inline-block',
        width: '100px',
        height: '100px',
        backgroundColor: t.palette.primary.main,
        transform: 'scale(0.7)',
        '&:after': {
            left: '30px',
        },
        '&:before': {
            left: '85px',
        },
        '&:before, &:after': {
            position: 'absolute',
            content: '""',
            height: '10px',
            width: '10px',
            backgroundColor: '#414141',
            borderRadius: '50%',
            top: '48px',
            animation:
                '$emoji1-face-move 5s ease-in-out infinite, $emoji1-eyes-blink 2s ease-in-out infinite',
            animationDirection: 'alternate',
        },
    },
    mouth: {
        position: 'absolute',
        height: '3px',
        width: '35px',
        backgroundColor: '#414141',
        borderRadius: '5px',
        top: '68px',
        left: '45px',
        animation: '$emoji1-face-move 5s ease-in-out infinite',
        animationDirection: 'alternate',
    },
}))
