import React from 'react';
import styles from './styles.module.scss';

interface DiscussionAnswerData {
  children: React.ReactNode;
  title: string;
}

export default ({ children, title }: DiscussionAnswerData) => {
  return (
    <div className={styles.answerData}>
      <span className={styles.answerDataTitle}>{title}</span> {children}
    </div>
  );
};
