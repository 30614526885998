import { put, takeEvery, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import store from 'store';
import apiService, { getError } from 'shared/services/api';
import { STORAGE_AUTH_KEY } from 'shared/constants/auth';
import {
  AUTH_PERFORM_LOGIN,
  AUTH_PERFORM_LOGOUT,
  AUTH_PERFORM_REFRESH_TOKEN,
} from '../../reducers/login/';
import { routes } from '../../../../shared/constants/routes';
import { amplifyLogin } from 'shared/services/amplify';

function* performLogout() {
  try {
    store.remove(STORAGE_AUTH_KEY);
    const { app: { country } } = yield select();
    yield put(push({ pathname: routes.auth.login.path, search: `?country=${country}` }));
  } catch (err) {
    yield put({ type: AUTH_PERFORM_LOGOUT.FAILURE, payload: getError(err) });
  }
}

function* performLogin(action) {
  try {
    const config = yield amplifyLogin(action.payload.email, action.payload.password);

    store.set(STORAGE_AUTH_KEY, config);
    yield put({ type: AUTH_PERFORM_LOGIN.SUCCESS, payload: config });
    const { app: { country } } = yield select();
    yield put(push({ pathname: routes.app.default.path, search: `?country=${country}` }));
  } catch (err) {
    yield put({ type: AUTH_PERFORM_LOGIN.FAILURE, payload: getError(err) });
  }
}
function* performRefreshToken(action) {
  try {
    const requestData = new FormData();
    requestData.append('grant_type', 'refresh_token');
    requestData.append('refresh_token', action.payload.refreshToken);
    requestData.append('scope', 'any');
    const basicToken = btoa(`test:test`);
    const { data } = yield apiService({
      baseUrl: process.env.REACT_APP_OAUTH_URL,
      headers: {
        Authorization: `Basic ${basicToken}`,
      },
    }).post('', requestData);

    if (action.payload.remember) {
      store.set(STORAGE_AUTH_KEY, data);
    }
    yield put({ type: AUTH_PERFORM_REFRESH_TOKEN.SUCCESS, payload: data });
    const { app: { country } } = yield select();
    yield put(push({ pathname: routes.app.default.path, search: `?country=${country}` }));
  } catch (err) {
    yield put({
      type: AUTH_PERFORM_REFRESH_TOKEN.FAILURE,
      payload: getError(err),
    });
  }
}

function* loginSaga() {
  yield takeEvery(AUTH_PERFORM_LOGIN.REQUEST, performLogin);
}

function* logoutSaga() {
  yield takeEvery(AUTH_PERFORM_LOGOUT.REQUEST, performLogout);
}

function* refreshTokenSaga() {
  yield takeEvery(AUTH_PERFORM_REFRESH_TOKEN.REQUEST, performRefreshToken);
}

export default [loginSaga, logoutSaga, refreshTokenSaga];
