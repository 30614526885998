import update from 'immutability-helper';

import map from 'lodash/map';
import filter from 'lodash/filter';
import { createReducer } from 'shared/utils/redux';
import { loadingStates, setLoadingStates } from 'shared/constants/redux';
import { createRequestTypes } from 'shared/utils/request';

export const FETCH_CAMPAIGN_DATA = createRequestTypes('campaigns/manage/FETCH_DATA');
export const FETCH_CLIENTS = createRequestTypes('campaigns/manage/FETCH_CLIENTS');

export const DELETE_MEDIA_FROM_ACR = createRequestTypes('campaigns/manage/DELETE_MEDIA_ACR');
export const DELETE_LOCATIONS = createRequestTypes('campaigns/manage/DELETE_LOCATIONS');

export const UPLOAD_LOCATIONS = createRequestTypes('campaigns/manage/UPLOAD_LOCATIONS');

export const SAVE_CAMPAIGN_DATA = createRequestTypes('campaigns/manage/SAVE_CAMPAIGN_DATA');

export const CAMPAIGNS_RESET_TO_INIT = 'campaigns/manage/RESET';
export const UPLOAD_MEDIA = createRequestTypes('campaigns/manage/UPLOAD_MEDIA', ['PROGRESS']);
export const CHECK_FOR_S3_FILES = createRequestTypes('campaigns/manage/CHECK_FOR_S3_FILES');
export const FETCH_ACR_MEDIA = createRequestTypes('campaigns/manage/FETCH_ACR_MEDIA');

export const START_CHECK_FOR_S3_FILES = 'campaigns/manage/START_CHECK_FOR_S3_FILES';
export const STOP_CHECK_FOR_S3_FILES = 'campaigns/manage/STOP_CHECK_FOR_S3_FILES';

export const startCheckingForS3Files = ({ campaignId }) => ({
  type: START_CHECK_FOR_S3_FILES,
  payload: { campaignId },
});
export const deleteLocations = ({ campaignId }) => ({
  type: DELETE_LOCATIONS.REQUEST,
  payload: { campaignId },
});
export const checkForS3Files = ({ campaignId }) => ({
  type: CHECK_FOR_S3_FILES.REQUEST,
  payload: { campaignId },
});
export const fetchAcrMedia = ({ campaignId }) => ({
  type: FETCH_ACR_MEDIA.REQUEST,
  payload: { campaignId },
});
export const stopCheckingForS3Files = () => ({
  type: STOP_CHECK_FOR_S3_FILES,
});
export const resetToInit = () => ({
  type: CAMPAIGNS_RESET_TO_INIT,
  payload: {},
});
export const fetchCampaign = ({ id }) => ({
  type: FETCH_CAMPAIGN_DATA.REQUEST,
  payload: { id },
});
export const fetchClients = () => ({
  type: FETCH_CLIENTS.REQUEST,
  payload: {},
});

export const uploadMedia = ({ file, id, campaignName, campaignId, client, filter }) => ({
  type: UPLOAD_MEDIA.REQUEST,
  payload: { id, file, campaignName, client, filter, campaignId },
});

export const deleteMediaFromAcr = ({ fileId, campaignId }) => ({
  type: DELETE_MEDIA_FROM_ACR.REQUEST,
  payload: { campaignId, fileId },
});
export const uploadLocations = ({ locationsContent, campaignId, filterName }) => ({
  type: UPLOAD_LOCATIONS.REQUEST,
  payload: { campaignId, locationsContent, filterName },
});

export const saveCampaign = ({ id, files, clientName, name, dateFrom, dateTo, acrCloud, countryOfAvailability }) => ({
  type: SAVE_CAMPAIGN_DATA.REQUEST,
  payload: {
    clientName,
    name,
    dateFrom,
    dateTo,
    acrCloud,
    countryOfAvailability,
  },
  id,
  files,
});

const initState = {
  campaign: {
    states: loadingStates,
    data: {},
    media: {
      items: [],
    },
    processingS3Files: [],
    processingS3FilesChecker: false,
  },
  clients: {
    data: [],
    states: loadingStates,
  },
  uploadLocations: {
    states: loadingStates,
  },
  deleteAcrFile: [],
  deleteLocations: {
    states: loadingStates,
  },
  uploadMedia: [],
  save: {
    states: loadingStates,
    data: {},
  },
};

export default createReducer(initState, {
  [CAMPAIGNS_RESET_TO_INIT]: () => {
    return initState;
  },
  [FETCH_ACR_MEDIA.SUCCESS]: (state, action) => {
    return update(state, {
      campaign: {
        $merge: {
          media: action.payload,
        },
      },
    });
  },
  [CHECK_FOR_S3_FILES.SUCCESS]: (state, action) => {
    return update(state, {
      campaign: {
        $merge: {
          processingS3Files: action.payload,
        },
      },
    });
  },
  [START_CHECK_FOR_S3_FILES]: (state) => {
    return update(state, {
      campaign: {
        $merge: {
          processingS3FilesChecker: true,
        },
      },
    });
  },
  [STOP_CHECK_FOR_S3_FILES]: (state) => {
    return update(state, {
      campaign: {
        $merge: {
          processingS3FilesChecker: false,
        },
      },
    });
  },
  [UPLOAD_MEDIA.PROGRESS]: (state, action) => {
    return update(state, {
      uploadMedia: {
        $set: map(state.uploadMedia, (file) => {
          if (file.id !== action.payload.id) {
            return file;
          }
          return {
            ...file,
            progress: action.payload.progress,
          };
        }),
      },
    });
  },
  [UPLOAD_MEDIA.REQUEST]: (state, action) => {
    return update(state, {
      uploadMedia: {
        $push: [
          {
            id: action.payload.id,
            data: action.payload,
            progress: 0,
          },
        ],
      },
    });
  },
  [SAVE_CAMPAIGN_DATA.REQUEST]: (state) => {
    return update(state, {
      save: {
        $merge: {
          states: setLoadingStates({ isLoading: true }),
        },
      },
    });
  },
  [SAVE_CAMPAIGN_DATA.SUCCESS]: (state, action) => {
    return update(state, {
      save: {
        $merge: {
          states: setLoadingStates({ isLoaded: true }),
          data: action.payload,
        },
      },
    });
  },
  [SAVE_CAMPAIGN_DATA.FAILURE]: (state) => {
    return update(state, {
      save: {
        $merge: {
          states: setLoadingStates({ hasError: true }),
        },
      },
    });
  },
  [DELETE_LOCATIONS.REQUEST]: (state) => {
    return update(state, {
      deleteLocations: {
        $merge: {
          states: setLoadingStates({ isLoading: true }),
        },
      },
    });
  },
  [DELETE_LOCATIONS.SUCCESS]: (state, action) => {
    return update(state, {
      deleteLocations: {
        $merge: {
          states: setLoadingStates({ isLoaded: true }),
          data: action.payload,
        },
      },
      campaign: {
        $merge: {
          data: {
            ...state.campaign.data,
            locations: [],
          },
        },
      },
    });
  },
  [DELETE_LOCATIONS.FAILURE]: (state) => {
    return update(state, {
      deleteLocations: {
        $merge: {
          states: setLoadingStates({ hasError: true }),
        },
      },
    });
  },
  [FETCH_CLIENTS.REQUEST]: (state) => {
    return update(state, {
      clients: {
        $merge: {
          states: setLoadingStates({ isLoading: true }),
        },
      },
    });
  },
  [FETCH_CLIENTS.SUCCESS]: (state, action) => {
    return update(state, {
      clients: {
        $merge: {
          states: setLoadingStates({ isLoaded: true }),
          data: action.payload,
        },
      },
    });
  },
  [FETCH_CLIENTS.FAILURE]: (state) => {
    return update(state, {
      clients: {
        $merge: {
          states: setLoadingStates({ hasError: true }),
        },
      },
    });
  },
  [UPLOAD_LOCATIONS.REQUEST]: (state) => {
    return update(state, {
      uploadLocations: {
        $merge: {
          states: setLoadingStates({ isLoading: true }),
        },
      },
    });
  },
  [UPLOAD_LOCATIONS.SUCCESS]: (state, action) => {
    return update(state, {
      uploadLocations: {
        $merge: {
          states: setLoadingStates({ isLoaded: true }),
        },
      },
      campaign: {
        $merge: {
          data: action.payload,
        },
      },
    });
  },
  [UPLOAD_LOCATIONS.FAILURE]: (state) => {
    return update(state, {
      uploadLocations: {
        $merge: {
          states: setLoadingStates({ hasError: true }),
        },
      },
    });
  },
  [FETCH_CAMPAIGN_DATA.REQUEST]: (state) => {
    return update(state, {
      campaign: {
        $merge: {
          states: setLoadingStates({ isLoading: true }),
        },
      },
    });
  },
  [FETCH_CAMPAIGN_DATA.SUCCESS]: (state, action) => {
    return update(state, {
      campaign: {
        $merge: {
          states: setLoadingStates({ isLoaded: true }),
          data: action.payload.data,
          media: action.payload.media,
          processingS3Files: action.payload.files,
        },
      },
    });
  },
  [FETCH_CAMPAIGN_DATA.FAILURE]: (state, action) => {
    return update(state, {
      campaign: {
        $merge: {
          states: setLoadingStates({ hasError: true }),
          response: action.payload.response,
        },
      },
    });
  },
  [DELETE_MEDIA_FROM_ACR.REQUEST]: (state, action) => {
    return update(state, {
      deleteAcrFile: {
        $push: [
          {
            fileId: action.payload.fileId,
            states: setLoadingStates({ isLoading: true }),
          },
        ],
      },
    });
  },
  [DELETE_MEDIA_FROM_ACR.SUCCESS]: (state, action) => {
    return update(state, {
      campaign: {
        media: {
          $set: filter(state.campaign.media, (file) => file.acrId !== action.payload.fileId),
        },
      },
      deleteAcrFile: {
        $set: map(state.deleteAcrFile, (file) => {
          if (file.fileId !== action.payload.fileId) {
            return file;
          }
          return {
            fileId: file.fileId,
            states: setLoadingStates({ isLoaded: true }),
          };
        }),
      },
    });
  },
  [DELETE_MEDIA_FROM_ACR.FAILURE]: (state, action) => {
    return update(state, {
      deleteAcrFile: {
        $set: map(state.deleteAcrFile, (file) => {
          if (file.fileId !== action.payload.fileId) {
            return file;
          }
          return {
            fileId: file.fileId,
            states: setLoadingStates({ hasError: true }),
          };
        }),
      },
    });
  },
});
