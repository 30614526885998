import React from 'react'
import { DefaultValues, FormProvider, SubmitHandler, UseFormMethods } from 'react-hook-form'
import Yup from 'yup'
import { useForm } from './hooks'

interface ChildrenOptions {
    isSubmitDisabled: boolean
    isSubmitting: boolean
    isValid: boolean
}

interface LocalFormProps<TFieldValues> {
    children: (
        options: ChildrenOptions,
        formMethods: UseFormMethods<TFieldValues>,
    ) => React.ReactNode
    defaultValues?: DefaultValues<TFieldValues>
    onSubmit: SubmitHandler<TFieldValues>
    schema?: Yup.AnyObjectSchema
}

export interface FormProps<TFieldValues> {
    defaultValues?: DefaultValues<TFieldValues>
    onSubmit: (data: TFieldValues) => Promise<void>
}

export interface ModalFormProps<TFieldValues> extends FormProps<TFieldValues> {
    onCancel: () => void
    open?: boolean
    title?: string
}

export const Form = <TFieldValues,>({
    children,
    defaultValues,
    onSubmit,
    schema,
}: LocalFormProps<TFieldValues>) => {
    const { formMethods, isSubmitDisabled, isSubmitting, isValid } = useForm<TFieldValues>({
        defaultValues,
        schema,
    })

    return (
        <FormProvider {...formMethods}>
            <form onSubmit={formMethods.handleSubmit<TFieldValues>(onSubmit)}>
                {children(
                    {
                        isSubmitDisabled,
                        isSubmitting,
                        isValid
                    },
                    formMethods,
                )}
            </form>
        </FormProvider>
    )
}
