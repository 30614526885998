import React, { PureComponent } from 'react';
import classnames from 'classnames';
import FieldError from '../FieldError';
import RotateLoader from '../RotateLoader';
import styles from './styles.module.scss';
import FieldLabel from '../FieldLabel';

interface FieldWrapperProps {
  meta?: any;
  className?: string;
  labelLeft?: boolean;
  noMargin?: boolean;
  disabled?: boolean;
  hiddenDisabled?: boolean;
  noBorder?: boolean;
  iconLeft?: React.ReactNode;
  onClick?: (...args: any[]) => void;
  onKeyDown?: (...args: any[]) => void;
  label?: React.ReactNode | string;
  icon?: any;
  tooltip?: any;
  input?: any;
  isRequired?: boolean;
  isLoading?: boolean;
  suffix?: any;
}

class FieldWrapper extends PureComponent<FieldWrapperProps> {
  constructor(props) {
    super(props);
    if (!props.input.value && props.defaultValue) {
      props.input.onChange(props.defaultValue);
    }
  }
  render() {
    const {
      meta,
      label,
      tooltip,
      labelLeft,
      noMargin,
      className,
      iconLeft,
      icon,
      input,
      children,
      onClick,
      onKeyDown,
      isRequired,
      disabled,
      hiddenDisabled,
      isLoading,
      noBorder,
      suffix,
    } = this.props;
    const classes = classnames(styles.FieldWrapper, className, {
      [styles.FieldWrapperLabelLeft]: labelLeft,
      [styles.FieldWrapperNoMargin]: noMargin,
    });

    const wrapperClasess = classnames(styles.FieldWrapperInputWrapper, className, {
      [styles.FieldWrapperInputWrapperDisabled]: disabled && !hiddenDisabled,
      [styles.FieldWrapperInputWrapperError]: meta.touched && meta.error,
      [styles.FieldWrapperInputWrapperNoBorder]: noBorder,
    });

    return (
      <div className={classes} onClick={onClick} onKeyDown={onKeyDown}>
        {label && (
          <FieldLabel
            isRequired={isRequired}
            labelLeft={labelLeft}
            tooltip={tooltip}
            htmlFor={input.name}
          >
            {' '}
            {label}
          </FieldLabel>
        )}
        <div className={wrapperClasess}>
          {iconLeft && <div className={styles.FieldWrapperIconLeft}>{iconLeft}</div>}
          {children}
          {suffix && <div className={styles.FieldWrapperSuffix}>{suffix}</div>}
          {icon && (
            <img
              src={require(`assets/icons/${icon}.svg`).default}
              alt="icon"
              className={styles.FieldWrapperIcon}
            />
          )}
          {isLoading && (
            <div className={styles.FieldWrapperLoader}>
              <RotateLoader relative />
            </div>
          )}
        </div>
        <FieldError meta={meta} />
      </div>
    );
  }
}

export default FieldWrapper;
