import React from 'react';
import styles from './styles.module.scss';

interface AppContentTypes {
  children: React.ReactNode;
}

export default ({ children }: AppContentTypes) => (
  <div className={styles.AppContent}>{children}</div>
);
