import React from 'react'
import { Route } from 'react-router-dom'
import { routes } from 'shared/constants/routes'
import { GiftCardsList } from './GiftCardsList'
import { GiftCardDetails } from './GiftCardDetails'

export const GiftShop = () => (
    <>
        <Route
            component={GiftCardsList}
            exact
            key="giftshop-default"
            path={routes.giftshop.default.path}
        />
        <Route
            component={GiftCardDetails}
            exact
            path={routes.giftshop.manage('(edit|create)/:id?').path}
        />
    </>
)
