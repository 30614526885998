import React, { PureComponent } from 'react';
import classnames from 'classnames';
import styles from './styles.module.scss';
import FieldError from '../FieldError';

interface CheckboxFieldProps {
  input: any;
  meta?: any;
  className?: string;
  disabled?: boolean;
  children?: React.ReactNode | string;
  isLight?: boolean;
  id: string;
  label: string;
}

class CheckboxField extends PureComponent<CheckboxFieldProps> {
  render() {
    const { input, meta, className, disabled, children, isLight, id, ...props } = this.props;

    // nie ma klasy checkboxField-light
    const inputClasses = classnames(styles.CheckboxFieldInput, {});

    const classes = classnames(styles.CheckboxField, className, {
      'CheckboxField--light': isLight,
      [styles.CheckboxFieldDisabled]: disabled,
    });

    const boxClasses = classnames(styles.CheckboxFieldBox, {
      [styles.CheckboxFieldBoxActive]: input.value,
    });

    return (
      <label className={classes} htmlFor={id}>
        <input
          type="checkbox"
          id={id}
          {...input}
          {...props}
          className={inputClasses}
          disabled={disabled}
        />
        <div className={boxClasses}>&#10003;</div>
        <span className={styles.CheckboxFieldLabel}>{children}</span>
        <FieldError meta={meta} />
      </label>
    );
  }
}

export default CheckboxField;
