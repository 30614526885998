import update from 'immutability-helper';

import { createReducer } from 'shared/utils/redux';
import { loadingStates, setLoadingStates } from 'shared/constants/redux';
import { createRequestTypes } from 'shared/utils/request';

export const COMPLETION_SURVEY_CATEGORIES_FETCH_LIST = createRequestTypes(
  'completionSurveyCategories/FETCH_LIST',
);
export const COMPLETION_SURVEY_CATEGORIES_DELETE = createRequestTypes(
  'completionSurveyCategories/DELETE_CATEGORY',
);

export const fetchCompletionSurveyCategories = () => ({
  type: COMPLETION_SURVEY_CATEGORIES_FETCH_LIST.REQUEST,
  payload: {},
});
export const deleteCompletionSurveyCategory = ({ id }) => ({
  type: COMPLETION_SURVEY_CATEGORIES_DELETE.REQUEST,
  payload: { id },
});

const initState = {
  data: [],
  states: loadingStates,
  response: null,
  delete: {
    states: loadingStates,
    response: null,
  },
};

export default createReducer(initState, {
  [COMPLETION_SURVEY_CATEGORIES_FETCH_LIST.REQUEST]: (state) => {
    return update(state, {
      $merge: {
        states: setLoadingStates({ isLoading: true }),
      },
    });
  },
  [COMPLETION_SURVEY_CATEGORIES_FETCH_LIST.SUCCESS]: (state, action) => {
    return update(state, {
      $merge: {
        states: setLoadingStates({ isLoaded: true }),
        data: action.payload,
      },
    });
  },
  [COMPLETION_SURVEY_CATEGORIES_FETCH_LIST.FAILURE]: (state, action) => {
    return update(state, {
      $merge: {
        states: setLoadingStates({ hasError: true }),
        response: action.payload.response,
      },
    });
  },
  [COMPLETION_SURVEY_CATEGORIES_DELETE.REQUEST]: (state) => {
    return update(state, {
      delete: {
        $merge: {
          states: setLoadingStates({ isLoading: true }),
        },
      },
    });
  },
  [COMPLETION_SURVEY_CATEGORIES_DELETE.SUCCESS]: (state) => {
    return update(state, {
      delete: {
        $merge: {
          states: setLoadingStates({ isLoaded: true }),
        },
      },
    });
  },
  [COMPLETION_SURVEY_CATEGORIES_DELETE.FAILURE]: (state, action) => {
    return update(state, {
      delete: {
        $merge: {
          states: setLoadingStates({ hasError: true }),
          response: action.payload.response,
        },
      },
    });
  },
});
