import { saveAs } from 'file-saver'
import { utcDateToLocal, localDateToUtc } from 'shared/services/date';
import { csvifyLocations, LocationResponse } from 'modules/campaignM'

export const saveCsv = (data: LocationResponse[]) => {
    const csvStr = csvifyLocations(utcDateToLocal)(data)
    const file = new File([csvStr], 'invalid_locations.csv', { type: 'text/csv;charset=utf-8' })
    saveAs(file)
}

export const createSetLocationProperties = (newValues: LocationResponse) => (oldValues: LocationResponse): LocationResponse => ({
    ...oldValues,
    dateFrom: newValues.dateFrom || oldValues.dateFrom,
    dateTo: newValues.dateTo || oldValues.dateTo,
    filterName: newValues.filterName || oldValues.filterName,
    radius: newValues.radius || oldValues.radius,
    address: newValues.address || oldValues.address,
    city: newValues.city || oldValues.city,
    country: newValues.country || oldValues.country,
    locationDetailId: newValues.locationDetailId || oldValues.locationDetailId,
    locationDetailName: newValues.locationDetailName || oldValues.locationDetailName,
    locationType: newValues.locationType || oldValues.locationType,
    postalCode: newValues.postalCode || oldValues.postalCode,
    province: newValues.province || oldValues.province,
})
