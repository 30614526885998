import {Country} from 'shared/types';


//TODO: Check DE, FR en NL (WRONG!!!!!!)
const BE_FL: google.maps.LatLngLiteral = {
    lat: 50.495697,
    lng: 3.3452029,
};
const EN: google.maps.LatLngLiteral = {
    lat: 52.817075,
    lng: -4.5698759,
};
const NL: google.maps.LatLngLiteral = {
    lat: 52.2076822,
    lng: 4.1584806,
};
const FR: google.maps.LatLngLiteral = {
    lat: 52.2076822,
    lng: 4.1584806,
};
const BE_WL: google.maps.LatLngLiteral = {
    lat: 50.543279,
    lng: 3.991538,
};
const UK: google.maps.LatLngLiteral = {
    lat: 51.5074,
    lng: -0.1278,
};
const DE: google.maps.LatLngLiteral = {
    lat: 52.2076822,
    lng: 4.1584806
}

export const CENTER: Record<Country, google.maps.LatLngLiteral> = {
    BE_FL,
    NL,
    FR,
    BE_WL,
    UK,
    DE
};

export const MARKER_OPTIONS: google.maps.ReadonlyMarkerOptions = {
    icon: {
        path:
            'M256,0C153.755,0,70.573,83.182,70.573,185.426c0,126.888,165.939,313.167,173.004,321.035 c6.636,7.391,18.222,7.378,24.846,0c7.065-7.868,173.004-194.147,173.004-321.035C441.425,83.182,358.244,0,256,0z M256,278.719 c-51.442,0-93.292-41.851-93.292-93.293S204.559,92.134,256,92.134s93.291,41.851,93.291,93.293S307.441,278.719,256,278.719z',
        fillColor: '#00aa87',
        // fillOpacity: .6,
        // anchor: new google.maps.Point(0,0),
        // strokeWeight: 0,
        scale: 1,
    },
};

export const CIRCLE_OPTIONS: google.maps.CircleOptions = {
    fillColor: '#00aa87',
    fillOpacity: 0.2,
    strokeColor: '#00aa87',
    strokeOpacity: 0.8,
    strokeWeight: 2,
};
