import React from 'react'
import { useHistory } from 'react-router-dom'
import { useGiftShopManagement, GiftCardResponse } from 'modules/giftshop'
import { Page, PageHeader, Button, DataTable } from 'components'

import { routes } from 'shared/constants/routes'

const COLUMNS = [
    {
        field: 'id',
        headerName: '#',
    },
    {
        field: 'title',
        headerName: 'Name',
    },
    {
        field: 'dateFrom',
        headerName: 'From',
    },
    {
        field: 'dateTo',
        headerName: 'To',
    },
    {
        field: 'status',
        headerName: 'Status',
    },
]

export const GiftCardsList = () => {
    const {
        location: { search },
        push,
    } = useHistory()
    const { fetchGiftCards } = useGiftShopManagement()

    const handleRowClick = (id: string | number) => {
        push({
            pathname: routes.giftshop.edit(id).path,
            search,
        })
    }
    const handleCta = () => {
        push({
            pathname: routes.giftshop.create.path,
            search,
        })
    }

    return (
        <Page
            header={
                <PageHeader title="Giftshop" cta={<Button onClick={handleCta}>Add new</Button>} />
            }>
            <DataTable<GiftCardResponse>
                columns={COLUMNS}
                onRowClick={handleRowClick}
                requestHandler={fetchGiftCards}
            />
        </Page>
    )
}
