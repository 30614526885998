import React, { PureComponent } from 'react';
import classnames from 'classnames';
import get from 'lodash/get';
import FieldWrapper from '../FieldWrapper';
import styles from './styles.module.scss';
import { Upload } from '../Icons';

interface FileFieldProps {
  label?: React.ReactNode;
  labelLeft?: boolean;
  id?: string;
  icon?: 'scope' | 'arrow_down' | React.ReactNode;
  onClick?: (...args: any[]) => void;
  input?: any;
  disabled?: boolean;
  isRequired?: boolean;
  hiddenDisabled?: boolean;
  isLoading?: boolean;
  accept?: string;
  dispatchChange: (formName: string, field: string, value: any) => void;
}

class MultiFileField extends PureComponent<FileFieldProps> {
  file = null;

  onClick = e => {
    if (this.props.onClick) {
      this.props.onClick(e);
    }
  };

  onChange = e => {
    e.preventDefault();
    const newFiles = Array.from(e.target.files);
    const existingFiles = this.props.input.value || [];
    const allFiles = [...existingFiles, ...newFiles];
    this.props.input.onChange(allFiles);
  };

  triggerFile = () => {
    this.file.click();
  };

  state = {
    draggingOver: false,
  };

  handleDragOver = (e) => {
    e.preventDefault(); // Correct usage as you want to prevent the default drag over action
    e.stopPropagation();
    if (!this.state.draggingOver) {
        this.setState({ draggingOver: true });
    }
  };

  handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ draggingOver: false });
  };

  handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const droppedFiles = Array.from(e.dataTransfer.files);
    const existingFiles = this.props.input.value || [];
    const allFiles = [...existingFiles, ...droppedFiles];
    this.props.input.onChange(allFiles);
  };

  render() {
    const { input, disabled, isRequired, hiddenDisabled, isLoading, accept } = this.props;
    const selectedFileNames = () => {
      if (!input.value) {
        return 'Please select file(s)';
      }
      let fileNames = ``;
      for (let f of input.value) {
        fileNames += f.name + ', ';
      }
      return fileNames;
    };
    const inputClasses = classnames(styles.FileFieldInput, {
      [styles.FileFieldInputDisabled]: disabled && !hiddenDisabled,
      'FileField__input--required': isRequired,
    });
    const placeholderClasses = classnames(styles.FileFieldPlaceholder, {
      [styles.FileFieldPlaceholderSelected]: get(this.props.input, 'value.name'),
    });

    const totalFileSize = () => {
      if (!input.value) {
        return '';
      }
      let total_size = 0;
      for (let x of input.value) {
        total_size += x.size;
      }
      return `${(total_size / (1024 * 1024)).toFixed(2)} MB`;
    };

    const dragOverClass = this.state.draggingOver ? styles.dragOver : '';

    return (
      <FieldWrapper {...this.props} onClick={this.onClick} isLoading={isLoading}>
        <input
          type="file"
          multiple
          id={input.name}
          onChange={this.onChange}
          className={inputClasses}
          disabled={disabled}
          ref={n => {
            this.file = n;
          }}
          accept={accept}
        />
        <div 
          className={`${placeholderClasses} ${dragOverClass}`} 
          onDragOver={this.handleDragOver}
          onDragLeave={this.handleDragLeave}
          onDrop={this.handleDrop}
        >          
          <span className={styles.FileFieldFileName}> {selectedFileNames()}</span>
          <span className={styles.FileFieldFileSize}>{totalFileSize()}</span>
        </div>
        <button
          className={styles.FileFieldButton}
          type="button"
          onClick={this.triggerFile}
        >
          <Upload /> Select
        </button>
      </FieldWrapper>
    );
  }
}

export default MultiFileField;
