/* eslint no-template-curly-in-string: 0 */
import * as yup from 'yup';
import { IntlString } from 'shared/types';
import { dateToReadable } from 'shared/services/date';
import { store } from '../../store';
import { LANGUAGE_TRANSLATION } from 'shared/constants/lang';

yup.setLocale({
  mixed: {
    notType: 'Must be a ${type}',
    required: 'Fill in',
  },
  number: {
    integer: 'Must be an integer value',
    min: 'Must be greater than or equal to ${min}',
  },
  string: {
    url: 'Must be a valid URL',
  },
  date: {
    min: value => `Must be later than ${dateToReadable(value)}`,
    max: value => `Must be at earlier than ${dateToReadable(value)}`,
  },
});

export const stringIntl = (name: string, required = true) =>
  yup
    .object({
      NL: yup.string(),
      EN: yup.string(),
    })
    .test(
      name,
      () => {
        const {app: { country } } = store.getState();
        const selectedCountry = LANGUAGE_TRANSLATION[country];
        return `Fill in '${selectedCountry}' translation`;
      },
      (value: IntlString) => {
        if (!required) {
          return true;
        }
        const {
          app: { country },
        } = store.getState();
        const selectedCountry = LANGUAGE_TRANSLATION[country];
        return Boolean(value[selectedCountry]);
      },
    );

export const stringImageUrl = yup
  .string()
  .transform((cv, ov) => (ov === '' ? undefined : cv))
  .matches(/jpg|png|jpeg$/, 'Must be a valid image URL [*.jpg, *.jpeg, *.png]');
