import find from 'lodash/find';
import flatMapDeep from 'lodash/flatMapDeep';
import values from 'lodash/values';
import { routes } from '../../constants/routes';

const LOCATION_CHANGE = '@@router/LOCATION_CHANGE';

export default store => next => action => {
  if (action.type !== LOCATION_CHANGE) {
    return next(action);
  }
  const flattenRoutes = flatMapDeep(routes, innerRoutes => values(innerRoutes));
  const route = find(flattenRoutes, { path: action.payload.location.pathname });
  const titleParts = [];
  if (route) {
    titleParts.push(route.title);
  }

  titleParts.push('THX');
  document.title = titleParts.join(' - ');
  return next(action);
};
