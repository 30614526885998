import { useState } from 'react'
import { WithId } from 'shared/types'
import { getSurveyQuestions, putSurveyQuestions } from '../api'
import { SurveyQuestionRequest, SurveyQuestionResponse } from '../types'

export const useSurveyQuestions = () => {
    const [questions, setQuestionsOrdered] = useState<SurveyQuestionResponse[]>([])

    const setQuestions = (data: SurveyQuestionResponse[]) => {
        const ordered = data.sort((a, b) =>
            a.surveyMonkeyQuestionPageNumber - b.surveyMonkeyQuestionPageNumber || a.surveyMonkeyQuestionPagePosition - b.surveyMonkeyQuestionPagePosition
        )
        setQuestionsOrdered(data)

    }

    const fetchQuestions = async (id: string) => {
        try {
            const data = await getSurveyQuestions(id)
            setQuestions(data)
        } catch (error) {
            console.log(error)
        }
    }

    const updateSurveyQuestions = async (data: WithId<SurveyQuestionRequest>[]): Promise<void> => {
        try {
            setQuestions(data.map(d => ({ ...d, edited: true })))
            const response = await putSurveyQuestions(data)
            setQuestions(response)
        } catch (error) {
            console.log(error)
        }
    }

    return {
        questions,
        fetchQuestions,
        updateSurveyQuestions,
    }
}
