import React, { PureComponent } from 'react';
import cn from 'classnames';
import { Field, reduxForm } from 'redux-form';
import Button from 'shared/components/Button';
import TextField from 'shared/components/TextField';
import { parseNumber } from 'shared/utils/fields';
import RadioField from 'shared/components/RadioField';
import styles from './styles.module.scss';
import FieldLabel from 'shared/components/FieldLabel';
import CheckboxFields from 'shared/components/CheckboxFields';
import * as types from '../../types/AnswersDetailsFiltersTypes';
export const ANSWER_DETAILS_FORM = 'forms/ANSWER_DETAILS_FORM';
class AnswersDetailsFilters extends PureComponent<
  types.DiscussionAnswersDetailsFiltersProps,
  types.DiscussionAnswersDetailsFiltersState
> {
  state: types.DiscussionAnswersDetailsFiltersState = { isActive: false };

  render() {
    const classes = cn(styles.wrapper, {
      [styles.wrapperActive]: this.state.isActive,
    });
    return (
      <div className={styles.overflowWrapper}>
        <Button
          className={styles.filtersButton}
          icon="filter"
          onClick={() => this.setState({ isActive: !this.state.isActive })}
        >
          Filters
        </Button>
        <div className={classes}>
          <div className={styles.ageFilter}>
            <div className={styles.ageLabel}>
              <FieldLabel noMargin>Age:</FieldLabel>
            </div>
            <div className={styles.ageField}>
              <Field
                component={TextField}
                name="ageFrom"
                parse={parseNumber}
                isRequired
                small
                noMargin
              />
            </div>
            <div className={styles.ageField}>
              <Field
                component={TextField}
                name="ageTo"
                isRequired
                parse={parseNumber}
                small
                noMargin
              />
            </div>
          </div>
          <div className={styles.filters}>
            <Field
              component={RadioField}
              isRequired
              options={[
                {
                  value: 'MALE',
                  label: 'Male',
                },
                {
                  value: 'FEMALE',
                  label: 'Female',
                },
              ]}
              name="gender"
              label="Gender"
              small
            />
            <Field
              component={CheckboxFields}
              options={[
                {
                  value: 'LOW',
                  label: 'Low',
                },
                {
                  value: 'MEDIUM',
                  label: 'Medium',
                },
                {
                  value: 'HIGH',
                  label: 'High',
                },
              ]}
              name="education"
              label="education"
              small
            />
          </div>
          <div className={styles.clear}>
            <Button onClick={() => this.props.reset()} buttonType="button" small>
              Clear filters
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default reduxForm({
  form: ANSWER_DETAILS_FORM,
})(AnswersDetailsFilters);
