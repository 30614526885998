import * as yup from 'yup'

const points = yup
    .number()
    .transform((cv, ov) => (ov === '' ? null : cv))
    .nullable()
    .notRequired()
    .integer()
    .min(0)
    .max(32767)

export const schema = yup.object({
    maxPointsPerMonth: points,
    pointsForAcr: points,
    pointsForLocation: points,
    pointsForMemoPanelRegistration: points,
})
