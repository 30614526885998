import React from 'react';
import styles from './styles.module.scss';

interface ListHeadingProps {
  title?: string;
  children?: React.ReactNode;
  right?: React.ReactNode;
  subTitle?: string;
}

export default ({ title, children, right }: ListHeadingProps) => (
  <div className={styles.ListHeading}>
    <h1>{title}</h1>
    <div className={styles.ListHeadingContent}>{children}</div>
    {right && <div className={styles.ListHeadingRight}>{right}</div>}
  </div>
);
