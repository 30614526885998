import React from 'react'
import { useSettingsManagement } from 'modules/settings'
import { Page, PageHeader, SettingsForm, ContentLoader } from 'components'

export const Settings = () => {
    const { onSubmit, settings, status } = useSettingsManagement()

    return (
        <Page header={<PageHeader title="Settings" />}>
            <ContentLoader status={status}>
                <SettingsForm defaultValues={settings} onSubmit={onSubmit} />
            </ContentLoader>
        </Page>
    )
}
