import { put, takeEvery, select } from 'redux-saga/effects';
import apiService, { getError } from 'shared/services/api';
import { replace } from 'connected-react-router';
import { routes } from 'shared/constants/routes';
import { getHourFromDate, utcDateToLocal } from 'shared/services/date';
import {
  LOTTERIES_MANAGE_FETCH_LOTTERY,
  LOTTERIES_MANAGE_SAVE_LOTTERY,
} from '../../reducers/manage/';
function* fetchLottery(action) {
  try {
    const { data } = yield apiService({}).get(`/lotteries/${action.payload.id}`);
    yield put({
      type: LOTTERIES_MANAGE_FETCH_LOTTERY.SUCCESS,
      payload: {
        ...data,
        dateFrom: utcDateToLocal(data.dateFrom),
        drawDate: utcDateToLocal(data.drawDate),
        dateFromTime: getHourFromDate(utcDateToLocal(data.dateFrom)),
        drawTime: getHourFromDate(utcDateToLocal(data.drawDate)),
        pushNoteTime: getHourFromDate(utcDateToLocal(data.pushNoteDate)),
      },
    });
  } catch (err) {
    yield put({
      type: LOTTERIES_MANAGE_FETCH_LOTTERY.FAILURE,
      payload: getError(err),
    });
  }
}

function* saveLottery(action) {
  let data = {};
  try {
    if (action.id) {
      const { data: saveData } = yield apiService({}).put(
        `/lotteries/${action.id}`,
        action.payload,
      );
      data = saveData;
    } else {
      const { data: createData } = yield apiService({}).post('/lotteries', action.payload);
      data = createData;
    }
    yield put({
      type: LOTTERIES_MANAGE_SAVE_LOTTERY.SUCCESS,
      payload: data,
    });
    const { app: { country } } = yield select();
    yield put(replace({ pathname: routes.lotteries.default.path, search: `?country=${country}` }));
  } catch (err) {
    yield put({
      type: LOTTERIES_MANAGE_SAVE_LOTTERY.FAILURE,
      payload: getError(err),
    });
  }
}

export default [
  function* () {
    yield takeEvery(LOTTERIES_MANAGE_FETCH_LOTTERY.REQUEST, fetchLottery);
  },
  function* () {
    yield takeEvery(LOTTERIES_MANAGE_SAVE_LOTTERY.REQUEST, saveLottery);
  },
];
