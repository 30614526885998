import update from 'immutability-helper';

import { createReducer } from 'shared/utils/redux';
import { loadingStates, setLoadingStates } from 'shared/constants/redux';
import { createRequestTypes } from 'shared/utils/request';

export const MANAGE_QUESTION_FETCH_CATEGORIES = createRequestTypes(
  'completionSurvey/question/manage/FETCH_CATEGORIES',
);
export const MANAGE_QUESTION_FETCH_BUCKET_CATEGORIES = createRequestTypes(
  'completionSurvey/question/manage/FETCH_BUCKET_CATEGORIES',
);
export const MANAGE_QUESTION_FETCH_QUESTION = createRequestTypes(
  'completionSurvey/question/manage/FETCH_QUESTION',
);

export const MANAGE_QUESTION_SAVE = createRequestTypes('completionSurvey/question/manage/SAVE');
export const MANAGE_QUESTION_RESET_TO_INIT = 'completionSurvey/question/manage/RESET';

export const resetToInit = () => ({
  type: MANAGE_QUESTION_RESET_TO_INIT,
  payload: {},
});

export const fetchCategories = () => ({
  type: MANAGE_QUESTION_FETCH_CATEGORIES.REQUEST,
  payload: {},
});
export const fetchBucketCategories = () => ({
  type: MANAGE_QUESTION_FETCH_BUCKET_CATEGORIES.REQUEST,
  payload: {},
});
export const fetchQuestion = ({ id }) => ({
  type: MANAGE_QUESTION_FETCH_QUESTION.REQUEST,
  payload: { id },
});

export const saveQuestion = ({
  titles,
  shortTitles,
  filterName,
  categoryId,
  points,
  type,
  possibleAnswers,
  visible,
  questionId,
  countryOfAvailability,
}) => ({
  type: MANAGE_QUESTION_SAVE.REQUEST,
  payload: {
    titles,
    shortTitles,
    filterName,
    categoryId,
    points,
    type,
    possibleAnswers: possibleAnswers.map((a, i) => ({ ...a, answerOrder: i })),
    visible,
    countryOfAvailability,
  },
  questionId,
});

const initState = {
  categories: {
    states: loadingStates,
    data: {},
    response: null,
  },
  bucketCategories: {
    states: loadingStates,
    data: [],
    response: null,
  },
  question: {
    states: loadingStates,
    response: null,
    data: {},
  },
  save: {
    states: loadingStates,
    response: null,
  },
};

export default createReducer(initState, {
  [MANAGE_QUESTION_RESET_TO_INIT]: () => {
    return initState;
  },
  [MANAGE_QUESTION_FETCH_QUESTION.REQUEST]: (state) => {
    return update(state, {
      question: {
        $merge: {
          states: setLoadingStates({ isLoading: true }),
        },
      },
    });
  },
  [MANAGE_QUESTION_FETCH_QUESTION.SUCCESS]: (state, action) => {
    return update(state, {
      question: {
        $merge: {
          states: setLoadingStates({ isLoaded: true }),
          data: action.payload,
        },
      },
    });
  },
  [MANAGE_QUESTION_FETCH_QUESTION.FAILURE]: (state) => {
    return update(state, {
      question: {
        $merge: {
          states: setLoadingStates({ isLoading: true }),
        },
      },
    });
  },
  [MANAGE_QUESTION_FETCH_CATEGORIES.REQUEST]: (state) => {
    return update(state, {
      categories: {
        $merge: {
          states: setLoadingStates({ isLoading: true }),
        },
      },
    });
  },
  [MANAGE_QUESTION_FETCH_CATEGORIES.SUCCESS]: (state, action) => {
    return update(state, {
      categories: {
        $merge: {
          states: setLoadingStates({ isLoaded: true }),
          data: action.payload,
        },
      },
    });
  },
  [MANAGE_QUESTION_FETCH_CATEGORIES.FAILURE]: (state, action) => {
    return update(state, {
      bucketCategories: {
        $merge: {
          states: setLoadingStates({ hasError: true }),
          response: action.payload.response,
        },
      },
    });
  },

  [MANAGE_QUESTION_FETCH_BUCKET_CATEGORIES.REQUEST]: (state) => {
    return update(state, {
      bucketCategories: {
        $merge: {
          states: setLoadingStates({ isLoading: true }),
        },
      },
    });
  },
  [MANAGE_QUESTION_FETCH_BUCKET_CATEGORIES.SUCCESS]: (state, action) => {
    return update(state, {
      bucketCategories: {
        $merge: {
          states: setLoadingStates({ isLoaded: true }),
          data: action.payload,
        },
      },
    });
  },
  [MANAGE_QUESTION_FETCH_BUCKET_CATEGORIES.FAILURE]: (state, action) => {
    return update(state, {
      category: {
        $merge: {
          states: setLoadingStates({ hasError: true }),
          response: action.payload.response,
        },
      },
    });
  },
  [MANAGE_QUESTION_FETCH_CATEGORIES.REQUEST]: (state) => {
    return update(state, {
      categories: {
        $merge: {
          states: setLoadingStates({ isLoading: true }),
        },
      },
    });
  },
  [MANAGE_QUESTION_FETCH_CATEGORIES.SUCCESS]: (state, action) => {
    return update(state, {
      categories: {
        $merge: {
          states: setLoadingStates({ isLoaded: true }),
          data: action.payload,
        },
      },
    });
  },
  [MANAGE_QUESTION_FETCH_CATEGORIES.FAILURE]: (state, action) => {
    return update(state, {
      category: {
        $merge: {
          states: setLoadingStates({ hasError: true }),
          response: action.payload.response,
        },
      },
    });
  },
  [MANAGE_QUESTION_SAVE.REQUEST]: (state) => {
    return update(state, {
      save: {
        $merge: {
          states: setLoadingStates({ isLoading: true }),
        },
      },
    });
  },
  [MANAGE_QUESTION_SAVE.SUCCESS]: (state, action) => {
    return update(state, {
      save: {
        $merge: {
          states: setLoadingStates({ isLoaded: true }),
          data: action.payload,
        },
      },
    });
  },
  [MANAGE_QUESTION_SAVE.FAILURE]: (state, action) => {
    return update(state, {
      save: {
        $merge: {
          states: setLoadingStates({ hasError: true }),
          response: action.payload.response,
        },
      },
    });
  },
});
