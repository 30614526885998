import React from 'react'
import MuiBox from '@material-ui/core/Box'
import MuiGrid from '@material-ui/core/Grid'
import { Form, ModalFormProps, AutocompleteField, TextField, DateTimeField, Button, Dialog } from 'components'
import { LocationBulkDetailsFormData } from './types'
import { schema } from './validation'

export const LocationBulkDetailsModalForm = ({
    defaultValues,
    onCancel,
    onSubmit,
    open,
    title,
}: ModalFormProps<LocationBulkDetailsFormData>) => (
    <Dialog open={open} title={title} onClose={onCancel}>
        <Form<LocationBulkDetailsFormData> defaultValues={defaultValues} onSubmit={onSubmit} schema={schema}>
            {({ isSubmitDisabled, isSubmitting }, { handleSubmit }) => (
                <>
                    <Dialog.Content>
                        <MuiGrid container spacing={2}>
                            <MuiGrid item xs>
                                <DateTimeField
                                    label="Date from"
                                    name="dateFrom"
                                    placeholder="Multiple values"
                                />
                            </MuiGrid>
                            <MuiGrid item xs>
                                <DateTimeField
                                    label="Date to"
                                    name="dateTo"
                                    placeholder="Multiple values"
                                />
                            </MuiGrid>
                            <MuiGrid item xs>
                                <TextField
                                    label="Radius"
                                    name="radius"
                                    placeholder="Multiple values"
                                />
                            </MuiGrid>
                        </MuiGrid>
                        <TextField
                            label="Filter for location"
                            name="filterName"
                            placeholder="Multiple values"
                        />
                        <AutocompleteField
                            label="Location type"
                            name="locationType"
                            placeholder="Multiple values"
                            options={[
                                {
                                    value: 'OOH',
                                    label: 'OOH',
                                },
                                {
                                    value: 'DOOH',
                                    label: 'DOOH',
                                },
                                {
                                    value: 'Stores',
                                    label: 'Stores',
                                },
                                {
                                    value: 'Venues',
                                    label: 'Venues',
                                }
                            ]}
                        />
                        <TextField
                            label="Location detail name"
                            name="locationDetailName"
                            placeholder="Multiple values"
                        />
                        <TextField
                            label="Location detail ID"
                            name="locationDetailId"
                            placeholder="Multiple values"
                        />
                        <TextField
                            label="Address"
                            name="address"
                            placeholder="Multiple values"
                        />
                        <MuiGrid container spacing={2}>
                            <MuiGrid item xs={4}>
                                <TextField
                                    label="Postal code"
                                    name="postalCode"
                                    placeholder="Multiple values"
                                />
                            </MuiGrid>
                            <MuiGrid item xs={8}>
                                <TextField
                                    label="City"
                                    name="city"
                                    placeholder="Multiple values"
                                />
                            </MuiGrid>
                        </MuiGrid>
                        <MuiGrid container spacing={2}>
                            <MuiGrid item xs={8}>
                                <TextField
                                    label="Province"
                                    name="province"
                                    placeholder="Multiple values"
                                />
                            </MuiGrid>
                            <MuiGrid item xs={4}>
                                <TextField
                                    label="Country"
                                    name="country"
                                    placeholder="Multiple values"
                                />
                            </MuiGrid>
                        </MuiGrid>
                    </Dialog.Content>
                    <Dialog.Actions>
                        <Button color="default" onClick={onCancel} type="button">
                            Cancel
                        </Button>
                        <Button
                            disabled={isSubmitDisabled}
                            loading={isSubmitting}
                            onClick={handleSubmit(onSubmit)}
                            type="button">
                            Save settings
                        </Button>
                    </Dialog.Actions>
                </>
            )}
        </Form>
    </Dialog>
)
