import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(() => ({
    root: {
        marginBottom: '8px',
    },
    small: {
        marginBottom: '4px',
        paddingBottom: '16px',
    },
}))
