import React, { PureComponent } from 'react';
import classnames from 'classnames';
import get from 'lodash/get';
import FieldWrapper from '../FieldWrapper';
import styles from './styles.module.scss';
import { Upload } from '../Icons';

interface FileFieldProps {
  label?: React.ReactNode;
  labelLeft?: boolean;
  id?: string;
  icon?: 'scope' | 'arrow_down' | React.ReactNode;
  onClick?: (...args: any[]) => void;
  input?: any;
  disabled?: boolean;
  isRequired?: boolean;
  hiddenDisabled?: boolean;
  isLoading?: boolean;
  accept?: string;
}

class FileField extends PureComponent<FileFieldProps> {
  file = null;
  onClick = (e) => {
    if (this.props.onClick) {
      this.props.onClick(e);
    }
  };
  onChange = (e) => {
    e.preventDefault();
    this.props.input.onChange(e.target.files[0]);
  };

  triggerFile = () => {
    this.file.click();
  };

  render() {
    const { input, disabled, isRequired, hiddenDisabled, isLoading, accept } = this.props;
    const selectedName = get(this.props.input, 'value.name') || 'Please select file';
    const inputClasses = classnames(styles.FileFieldInput, {
      [styles.FileFieldInputDisabled]: disabled && !hiddenDisabled,
      'FileField__input--required': isRequired,
    });
    const placeholderClasses = classnames(styles.FileFieldPlaceholder, {
      [styles.FileFieldPlaceholderSelected]: get(this.props.input, 'value.name'),
    });
    const fileSize = input.value ? `${(input.value.size / (1024 * 1024)).toFixed(2)} MB` : '';
    return (
      <FieldWrapper {...this.props} onClick={this.onClick} isLoading={isLoading}>
        <input
          type="file"
          id={input.name}
          onChange={this.onChange}
          className={inputClasses}
          disabled={disabled}
          ref={(n) => {
            this.file = n;
          }}
          accept={accept}
        />
        <div className={placeholderClasses}>
          <span className={styles.FileFieldFileName}> {selectedName}</span>
          <span className={styles.FileFieldFileSize}>{fileSize}</span>
        </div>
        <button className={styles.FileFieldButton} type="button" onClick={this.triggerFile}>
          <Upload /> Select
        </button>
      </FieldWrapper>
    );
  }
}

export default FileField;
