import React from 'react'
import { uniqueId } from 'lodash'
import MuiOutlinedInput from '@material-ui/core/OutlinedInput'
import { FieldWrapper } from 'components'
import { useStyles } from './TextField.styles'

interface TextFieldProps {
    defaultValue?: string | number
    disabled?: boolean
    label?: string
    multiline?: boolean
    name: string
    placeholder?: string
    readOnly?: boolean
    required?: boolean
    small?: boolean
    tooltip?: string
    type?: 'text' | 'hidden'
}

export const TextField = ({
    defaultValue = '',
    disabled,
    label,
    multiline,
    name,
    placeholder,
    readOnly,
    required,
    small,
    tooltip,
    type = 'text',
}: TextFieldProps) => {
    const styles = useStyles()
    const className = type === 'hidden' ? styles.hidden : ''
    const inputClassName = readOnly ? styles.readOnly : ''
    const margin = small ? 'dense' : 'none'
    const id = uniqueId(name)

    return (
        <FieldWrapper
            className={className}
            defaultValue={defaultValue}
            disabled={disabled}
            htmlFor={id}
            label={label}
            name={name}
            required={required}
            small={small}
            tooltip={tooltip}>
            {({ ref, ...field }) => (
                <MuiOutlinedInput
                    {...field}
                    className={inputClassName}
                    fullWidth
                    id={id}
                    inputRef={ref}
                    margin={margin}
                    multiline={multiline}
                    readOnly={readOnly}
                    type={type}
                    placeholder={placeholder}
                />
            )}
        </FieldWrapper>
    )
}
