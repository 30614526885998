import React, { useState } from 'react';
import classNames from 'classnames';
import Modal from 'shared/components/Modal';
import Button from 'shared/components/Button';
import debounce from 'lodash/debounce';
import FormButtons from 'shared/components/FormButtons';
import TextField from 'shared/components/TextField';
import { reduxForm, Field } from 'redux-form';
import { searchPexelsByQuery, searchPexels } from 'shared/services/pexels';
import styles from './styles.module.scss';
import RotateLoader from 'shared/components/RotateLoader';

interface UseImageModalTypes {
  isOpen: boolean;
  onClose: () => void;
  onAdd: (...args: any[]) => void;
}

const UseImageModal = ({ isOpen, onClose, onAdd }: UseImageModalTypes) => {
  const [isLoading, setLoading] = useState(false);
  const [images, setImages] = useState([]);
  const [response, setResponse] = useState<any>({});
  const [selected, setSelected] = useState();
  const onSearch = debounce(async text => {
    if (!text) return;
    setLoading(true);
    const { data } = await searchPexelsByQuery(text);
    setLoading(false);
    setSelected(null);
    setResponse(data);
    setImages(
      data.photos.map(image => ({
        id: image.id,
        author: image.photographer,
        tiny: image.src.tiny,
        small: image.src.small,
        original: image.src.original,
        large: image.src.large,
      })),
    );
  }, 400);

  const loadMore = async () => {
    const params = String(response.next_page).split('?')[1];
    setLoading(true);
    const { data } = await searchPexels(params);
    setLoading(false);
    setResponse(data);
    setImages([
      ...images,
      ...data.photos.map(image => ({
        id: image.id,
        author: image.photographer,
        tiny: image.src.tiny,
        small: image.src.small,
        original: image.src.original,
        large: image.src.large,
      })),
    ]);
  };

  const onSelect = () => {
    const image = images.find(image => image.id === selected);
    onAdd(image.tiny);
    onClose();
  };

  return (
    <Modal title="Select image" isOpen={isOpen} onClose={onClose} big>
      <Field component={TextField} name="media" label="Search by keyword" onChange={e => onSearch(e.target.value)} />
      <div className={styles.imagesWrapper}>
        {isLoading && <RotateLoader bg />}
        <div className={styles.images}>
          {images.map(image => (
            <div
              key={image.id}
              onClick={() => setSelected(image.id)}
              className={classNames(styles.image, { [styles.imageActive]: selected === image.id })}
              style={{ backgroundImage: `url("${image.large}")` }}
            >
              <span className={styles.imageAuthor}>{image.author}</span>
            </div>
          ))}
          {response.next_page && !isLoading && (
            <div className={styles.loadMoreButton}>
              <Button onClick={loadMore}>Load more...</Button>
            </div>
          )}
        </div>
      </div>
      <div className={styles.buttons}>
        <FormButtons>
          <Button onClick={onClose} buttonType="button">
            Cancel
          </Button>
          <Button type="primary" onClick={onSelect} buttonType="button" disabled={!selected}>
            Use
          </Button>
        </FormButtons>
      </div>
    </Modal>
  );
};

export default reduxForm({ form: 'USE_IMAGE_MODAL' })(UseImageModal);
