import { useState, useEffect } from 'react'
import { WithId } from 'shared/types'
import { Order } from './TableHead'

interface UseTableOptions<TData> {
    idField?: keyof TData
    rows: TData[]
}

export const useTable = <TData>({
    idField = 'id' as keyof TData,
    rows,
}: UseTableOptions<TData>) => {
    const [order, setOrder] = useState<Order>('asc')
    const [orderBy, setOrderBy] = useState<keyof TData>(idField)
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [selected, setSelected] = useState<TData[]>([])

    const isSelected = (row: TData) => selected.findIndex(item => item[idField] === row[idField]) !== -1

    const onSelectRow = (row: TData) => {
        const selectedIndex = selected.findIndex(item => item[idField] === row[idField])
        let newSelected: TData[] = []

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, row)
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1))
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1))
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            )
        }

        setSelected(newSelected)
    }

    const onSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            setSelected(rows)
            return
        }
        setSelected([])
    }

    const onChangePage = (_: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage)
    }

    const onChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    const onSort = (event: React.MouseEvent<unknown>, property: keyof TData) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }

    useEffect(() => {
        const newSelected = selected.filter(row => rows.includes(row))
        setSelected(newSelected)
    }, [rows.length])

    return {
        isSelected,
        onChangePage,
        onChangeRowsPerPage,
        onSelectAll,
        onSelectRow,
        onSort,
        order,
        orderBy,
        page,
        rowsPerPage,
        selected,
    }
}
