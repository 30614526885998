export type Country = 'NL' | 'BE_FL' | 'FR' | 'BE_WL' | 'UK' | 'DE';

export type IntlString = Partial<Record<Country, string>>;

export type WithId<T> = T & { id: string | number };

export type LanguageTranslation = 'NL' | 'EN' | 'FR' | 'DE';

export type CountryLanguageTranslation = {
  [key in Country]: LanguageTranslation;
};

export enum Language {
    nl = 'NL',
    en = 'EN',
    fr = 'FR',
    de = 'DE'
}

export enum CountryLanguage {
    NL = Language.nl,
    BE_FL = Language.nl,
    FR = Language.fr,
    BE_WL = Language.fr,
    UK = Language.en,
    DE = Language.de
}

export const COUNTRY_OPTIONS = [
    {
        value: 'NL',
        label: 'Netherlands',
    },
    {
        value: 'BE_FL',
        label: 'Belgium - Flanders',
    },
    {
        value: 'FR',
        label: 'France',
    },
    {
        value: 'BE_WL',
        label: 'Belgium - Wallonia',
    },
    {
        value: 'UK',
        label: 'United Kingdom',
    },
    {
        value: 'DE',
        label: 'Germany'
    }
]

export const LANGUAGE_OPTIONS = [
    {
        value: 'EN',
        label: 'English',
    },
    {
        value: 'NL',
        label: 'Dutch',
    },
    {
        value: 'FR',
        label: 'French',
    },
    {
        value: 'DE',
        label: 'German'
    }
]

export const TRUE_FALSE_OPTIONS = [
    {
        value: true,
        label: 'Yes',
    },
    {
        value: false,
        label: 'No',
    },
]
