import React, { useEffect, useState } from 'react'
import { LocationRequest } from 'modules/campaignM'
import { CIRCLE_OPTIONS } from './constants'

interface CircleProps extends google.maps.CircleOptions {
    anchor: google.maps.MVCObject | null
    editable?: boolean
    onClick?: () => void
    onEdit?: (data: Partial<LocationRequest>) => void
}

export const Circle = ({ anchor, editable, onClick, onEdit, ...options }: CircleProps) => {
    const [circle, setCircle] = useState<google.maps.Circle>()

    useEffect(() => {
        const c = new google.maps.Circle({
            ...CIRCLE_OPTIONS,
            ...options,
        })
        c.bindTo('center', anchor, 'position')
        c.bindTo('map', anchor, 'map')
        let centerChangedListener: google.maps.MapsEventListener
        let clickListener: google.maps.MapsEventListener
        let radiusChangedListener: google.maps.MapsEventListener
        if (onEdit) {
            radiusChangedListener = c.addListener('radius_changed', () => {
                onEdit({
                    latitude: c.getCenter().lat(),
                    longitude: c.getCenter().lng(),
                    radius: Math.round(c.getRadius()),
                })
            })
            centerChangedListener = c.addListener('center_changed', () => {
                onEdit({
                    latitude: c.getCenter().lat(),
                    longitude: c.getCenter().lng(),
                    radius: Math.round(c.getRadius()),
                })
            })
            clickListener = c.addListener('click', () => {
                onClick()
            })
        }
        setCircle(c)

        return () => {
            google.maps.event.removeListener(radiusChangedListener)
            google.maps.event.removeListener(centerChangedListener)
            google.maps.event.removeListener(clickListener)
        }
    }, [])

    useEffect(() => {
        circle && circle.setEditable(editable)
    }, [circle, editable])

    return <></>
}
