import React, { PureComponent } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { routes } from 'shared/constants/routes';
import Campaign from 'modules/Campaign';
import Lottery from 'modules/Lottery';
import Survey from 'modules/Survey';
import CompletionSurveys from 'modules/CompletionSurveys';
import Discussion from 'modules/Discussion';
import Navigation from './containers/Navigation';
import 'react-toastify/dist/ReactToastify.css';
import styles from './styles.module.scss';
import { Charities, ClientsList, Users, Settings, GiftShop } from 'views'

export class App extends PureComponent {
  render() {
    return (
      <div className={styles.App}>
        <ToastContainer />
        <div className={styles.AppSidebar}>
          <Navigation />
        </div>
        <div className={styles.AppContent}>
          <Switch>
            <Route path={routes.app.default.path} exact>
              <Redirect to={routes.campaigns.default.path} />
            </Route>
            <Route path={routes.campaigns.default.path} component={Campaign} />
            <Route path={routes.charities.default.path} component={Charities} />
            <Route path={routes.surveys.default.path} component={Survey} />
            <Route path={routes.discussions.default.path} component={Discussion} />
            <Route path={routes.clients.default.path} component={ClientsList} exact />
            <Route path={routes.users.default.path} component={Users} />
            <Route path={routes.lotteries.default.path} component={Lottery} />
            <Route path={routes.settings.default.path} component={Settings} />
            <Route path={routes.giftshop.default.path} component={GiftShop} />
            <Route path={routes.completionSurveys.default.path} component={CompletionSurveys} />
          </Switch>
        </div>
      </div>
    );
  }
}

export default App
