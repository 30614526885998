// TODO: refactor apiService
import apiService from 'shared/services/api'
import { normalizeQuery, Query } from 'services'
import { WithId } from 'shared/types'
import { utcDateToLocal, localDateToUtc } from 'shared/services/date';
import { GiftCardRequest, GiftCardResponse } from './types'

const normalizeRequest = (data: GiftCardRequest): GiftCardRequest => ({
    ...data,
    dateFrom: localDateToUtc(data.dateFrom),
    dateTo: localDateToUtc(data.dateTo),
    pushNoteDate: data.pushNoteDate ? localDateToUtc(data.pushNoteDate) : null,
})

const normalizeResponse = (data: GiftCardResponse): GiftCardResponse => ({
    ...data,
    dateFrom: utcDateToLocal(data.dateFrom),
    dateTo: utcDateToLocal(data.dateTo),
    pushNoteDate: data.pushNoteDate ? utcDateToLocal(data.pushNoteDate) : null,
})

export const getGiftCards = async (query?: Query) => {
    const { data } = await apiService({}).get('/gift-cards', {
        params: normalizeQuery(query),
    })

    return data
}

export const getGiftCard = async (id: string) => {
    const { data } = await apiService({}).get(`/gift-cards/${id}`)

    return normalizeResponse(data)
}

export const postGiftCard = async (data: GiftCardRequest) => {
    await apiService({}).post('/gift-cards', normalizeRequest(data))
}

export const putGiftCard = async ({ id, ...data }: WithId<GiftCardRequest>) => {
    await apiService({}).put(`/gift-cards/${id}`, normalizeRequest(data))
}
