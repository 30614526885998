import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Country, WithId } from 'shared/types';
import { getCharities, getCharity, postCharity, putCharity } from './api';
import { CharityRequest } from './types';

export const useCharityManagement = () => {
  const country: Country = useSelector(({ app }: any) => app.country);

  const createCharity = async (data: CharityRequest): Promise<void> => {
    try {
      await postCharity({ ...data, countryOfAvailability: country });
      toast.success('Charity successfully saved');
    } catch (error) {
      toast.error('Something went wrong. Please try again later.');
    }
  };

  const updateCharity = async (data: WithId<CharityRequest>): Promise<void> => {
    try {
      await putCharity({
        ...data,
        countryOfAvailability: country,
      });
      toast.success('Charity successfully saved');
    } catch (error) {
      toast.error('Something went wrong. Please try again later.');
    }
  };

  return {
    createCharity,
    fetchCharities: getCharities,
    fetchCharity: getCharity,
    updateCharity,
  };
};
