import update from 'immutability-helper';

import { createReducer } from 'shared/utils/redux';
import { loadingStates, setLoadingStates } from 'shared/constants/redux';
import { createRequestTypes } from 'shared/utils/request';
import { addTimeToDate, localDateToUtc } from 'shared/services/date';

export const LOTTERIES_MANAGE_FETCH_LOTTERY = createRequestTypes('lotteries/manage/FETCH_LOTTERY');
export const LOTTERIES_MANAGE_SAVE_LOTTERY = createRequestTypes('lotteries/manage/SAVE_LOTTERY');
export const LOTTERIES_MANAGE_RESET_TO_INIT = 'lotteries/manage/RESET';

export const resetToInit = () => ({
  type: LOTTERIES_MANAGE_RESET_TO_INIT,
  payload: {},
});

export const fetchLottery = ({ id }) => ({
  type: LOTTERIES_MANAGE_FETCH_LOTTERY.REQUEST,
  payload: { id },
});

export const saveLottery = ({
  id,
  name,
  imageUrl,
  description,
  pointsNeeded,
  drawDate,
  drawTime,
  dateFrom,
  dateFromTime,
  minimumParticipants,
  notification,
  numberOfPlaces,
  status,
  visible,
  pushNoteTitle,
  pushNoteBody,
  pushNoteDate,
  pushNoteEnabled,
  pushNoteTime,
  countryOfAvailability,
}) => ({
  type: LOTTERIES_MANAGE_SAVE_LOTTERY.REQUEST,
  payload: {
    name,
    imageUrl,
    description,
    pointsNeeded,
    drawDate: localDateToUtc(addTimeToDate(drawDate, drawTime)),
    dateFrom: localDateToUtc(addTimeToDate(dateFrom, dateFromTime)),
    minimumParticipants,
    notification,
    numberOfPlaces,
    status,
    visible,
    pushNoteTitle,
    pushNoteBody,
    pushNoteDate: localDateToUtc(addTimeToDate(pushNoteDate, pushNoteTime)),
    pushNoteEnabled,
    countryOfAvailability,
  },
  id,
});

const initState = {
  lottery: {
    states: loadingStates,
    data: {},
    response: null,
  },
  save: {
    states: loadingStates,
    response: null,
  },
};

export default createReducer(initState, {
  [LOTTERIES_MANAGE_RESET_TO_INIT]: () => {
    return initState;
  },
  [LOTTERIES_MANAGE_FETCH_LOTTERY.REQUEST]: (state) => {
    return update(state, {
      lottery: {
        $merge: {
          states: setLoadingStates({ isLoading: true }),
        },
      },
    });
  },
  [LOTTERIES_MANAGE_FETCH_LOTTERY.REQUEST]: (state) => {
    return update(state, {
      lottery: {
        $merge: {
          states: setLoadingStates({ isLoading: true }),
        },
      },
    });
  },
  [LOTTERIES_MANAGE_FETCH_LOTTERY.SUCCESS]: (state, action) => {
    return update(state, {
      lottery: {
        $merge: {
          states: setLoadingStates({ isLoaded: true }),
          data: action.payload,
        },
      },
    });
  },
  [LOTTERIES_MANAGE_FETCH_LOTTERY.FAILURE]: (state, action) => {
    return update(state, {
      lottery: {
        $merge: {
          states: setLoadingStates({ hasError: true }),
          response: action.payload.response,
        },
      },
    });
  },
  [LOTTERIES_MANAGE_SAVE_LOTTERY.REQUEST]: (state) => {
    return update(state, {
      save: {
        $merge: {
          states: setLoadingStates({ isLoading: true }),
        },
      },
    });
  },
  [LOTTERIES_MANAGE_SAVE_LOTTERY.SUCCESS]: (state, action) => {
    return update(state, {
      save: {
        $merge: {
          states: setLoadingStates({ isLoaded: true }),
          data: action.payload,
        },
      },
    });
  },
  [LOTTERIES_MANAGE_SAVE_LOTTERY.FAILURE]: (state, action) => {
    return update(state, {
      save: {
        $merge: {
          states: setLoadingStates({ hasError: true }),
          response: action.payload.response,
        },
      },
    });
  },
});
