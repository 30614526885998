import { Field, reduxForm, formValueSelector } from 'redux-form';
import { useSelector } from 'react-redux';
import Button from 'shared/components/Button';
import Modal from 'shared/components/Modal';
import styles from './styles.module.scss';
import Alert from '@material-ui/lab/Alert';

interface BulkEditModalProps {
  handleBulkEditData: (data: string) => void;
  handleClose: () => void;
  initialValues: { textareaValue: string }
}

const BulkEditModal = ({ handleBulkEditData, handleClose, initialValues }: BulkEditModalProps) => {
  const selector = formValueSelector('bulkEditForm');
  const textareaValue = useSelector((state) => selector(state, 'textareaValue')) || initialValues.textareaValue;

  const handleSave = () => {
    handleBulkEditData(textareaValue);
    handleClose();
  };

  return (
    <Modal isOpen={true} onClose={handleClose} style={{ minWidth: '55%' }}>
      <Alert severity="info" style={{marginBottom: '1%'}}>{"Make sure to split each key with a comma! (Example: Albert Heijn, Online Video, YouTube)"}</Alert>
      <Field
        component="textarea"
        name="textareaValue"
        value={initialValues.textareaValue}
        className={styles.BulkEditModalTextArea}
      />
      <div className={styles.BulkEditModalButtonWrapper}>
        <Button onClick={handleClose} buttonType="button">
          Cancel
        </Button>
        <Button type="primary" onClick={handleSave} buttonType="button">
          Apply
        </Button>
      </div>
    </Modal>
  );
};

export default reduxForm({ form: 'bulkEditForm' })(BulkEditModal);