import React from 'react';
import { Route } from 'react-router-dom';
import { routes } from 'shared/constants/routes';
import List from './containers/List';
import Manage from './containers/Manage';
import { SurveyDetails } from 'views'

export default () => (
  <>
    <Route path={routes.surveys.default.path} key="surveys-default" component={List} exact />
    <Route
      path={routes.surveys.manage('(edit|create)/:id?').path}
      key="surveys-manage"
      component={Manage}
      exact
    />
  </>
);
