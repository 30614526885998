import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { Country, WithId } from 'shared/types'
import { getQuestionCategory, postQuestionCategory, putQuestionCategory } from './api'
import { QuestionCategoryRequest } from './types'
import { TOAST_MESSAGE } from 'shared/constants/message'
import { routes } from 'shared/constants/routes'
import { useHistory } from 'react-router-dom'

export const useQuestionCategoryManagement = () => {
    const { location: { search }, push, } = useHistory()
    const country: Country = useSelector(({ app }: any) => app.country)

    const createQuestionCategory = async (data: QuestionCategoryRequest) => {
        try {
            const response = await postQuestionCategory({ ...data, countryOfAvailability: country })        
            if(response.status === 200) {
                toast.success(TOAST_MESSAGE.category.created)
                push({ pathname: routes.completionSurveys.default.path, search })
            } else {
                toast.error(TOAST_MESSAGE[500])
            }         
        } catch (error) {
            toast.error(TOAST_MESSAGE[500])
        }
    }
  

  const updateQuestionCategory = async (data: WithId<QuestionCategoryRequest>) => {
    try {
      await putQuestionCategory({ ...data, countryOfAvailability: country });
      toast.success('Category successfully saved');
    } catch (error) {
      toast.error('Something went wrong. Please try again later.');
    }
  };

  return {
    createQuestionCategory,
    fetchQuestionCategory: getQuestionCategory,
    updateQuestionCategory,
  };
};
