import React, { useState, useEffect, useMemo } from 'react';
import MuiTable from '@material-ui/core/Table';
import MuiTableContainer from '@material-ui/core/TableContainer';
import MuiTableBody from '@material-ui/core/TableBody';
import MuiTableRow from '@material-ui/core/TableRow';
import MuiTableCell from '@material-ui/core/TableCell';
import MuiTableFooter from '@material-ui/core/TableFooter';
import MuiTablePagination from '@material-ui/core/TablePagination';
import MuiCheckbox from '@material-ui/core/Checkbox';
import { RequestStatus } from 'services';
import { ContentLoader } from 'components';
import { Column, TableHead, Order } from './TableHead';
import { useTable } from './hooks';
import { stableSort, getComparator } from './helpers';

interface TableProps<TData> {
  columns: Column<TData>[];
  idField?: keyof TData;
  noDataText?: string;
  onSelect?: (data: TData[]) => void;
  rows: TData[];
  status?: RequestStatus;
}

export const Table = <TData,>({
  columns,
  idField = 'id' as keyof TData,
  noDataText = 'No data',
  onSelect,
  rows,
  status,
}: TableProps<TData>) => {
  const {
    isSelected,
    onChangePage,
    onChangeRowsPerPage,
    onSelectAll,
    onSelectRow,
    onSort,
    order,
    orderBy,
    page,
    rowsPerPage,
    selected,
  } = useTable<TData>({ rows, idField });

  const items = useMemo(
    () =>
      stableSort<TData>(rows, getComparator<TData>(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [rows, order, orderBy, page, rowsPerPage],
  );

  const fullRowSpan = useMemo(() => {
    let span = columns.length;
    if (onSelect) {
      span += 1;
    }
    return span;
  }, [columns.length]);

  const createRowClickHandler = (row: TData) => () => {
    if (onSelect) {
      onSelectRow(row);
    }
  };

  const handleSelect = (data: TData[]) => {
    if (onSelect) {
      onSelect(data);
    }
  };

  useEffect(() => {
    handleSelect(selected);
  }, [selected.length]);

  return (
    <MuiTableContainer>
      <ContentLoader status={status}>
        <MuiTable size="small" style={{ minWidth: '100%', width: 'auto' }}>
          <TableHead<TData>
            checkboxSelection={Boolean(onSelect)}
            columns={columns}
            numSelected={selected.length}
            onSelectAll={onSelectAll}
            onSort={onSort}
            order={order}
            orderBy={orderBy}
            rowCount={rows.length}
          />
          <MuiTableBody>
            {rows.length === 0 && (
              <MuiTableRow>
                <MuiTableCell colSpan={fullRowSpan} align="center">
                  {noDataText}
                </MuiTableCell>
              </MuiTableRow>
            )}
            {items.map(row => {
              const isItemSelected = isSelected(row);
              return (
                <MuiTableRow key={row[idField] as unknown as string | number}>
                  {onSelect && (
                    <MuiTableCell padding="checkbox">
                      <MuiCheckbox
                        checked={isItemSelected}
                        color="primary"
                        onChange={createRowClickHandler(row)}
                      />
                    </MuiTableCell>
                  )}
                  {columns.map(column => (
                    <MuiTableCell key={column.field}>
                      {column.valueGetter
                        ? column.valueGetter(
                            row,
                            rows.findIndex(r => r[idField] === row[idField]),
                          )
                        : row[column.field]}
                    </MuiTableCell>
                  ))}
                </MuiTableRow>
              );
            })}
          </MuiTableBody>
          <MuiTableFooter>
            <MuiTableRow>
              <MuiTablePagination
                colSpan={fullRowSpan}
                count={rows.length}
                onChangePage={onChangePage}
                onChangeRowsPerPage={onChangeRowsPerPage}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[10, 25, 50]}
              />
            </MuiTableRow>
          </MuiTableFooter>
        </MuiTable>
      </ContentLoader>
    </MuiTableContainer>
  );
};
