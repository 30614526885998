import React from 'react';
import { Route } from 'react-router-dom';
import { routes } from 'shared/constants/routes';
// import Manage from './containers/Manage';
import List from './containers/List';
import { CampaignDetails } from 'views'

export default () => (
  <>
    <Route path={routes.campaigns.default.path} key="companies-default" component={List} exact />
    <Route
      path={routes.campaigns.manage('(edit|create)/:id?').path}
      key="campaigns-manage"
      component={CampaignDetails}
      exact
    />
  </>
);
