import Reac, { useState, useEffect, useCallback } from 'react'
import MuiTypography from '@material-ui/core/Typography'
import MuiBox from '@material-ui/core/Box'
import { useParams } from 'react-router-dom'
import { Button, SurveyQuestionsModalForm } from 'components'
import { useSurveyQuestions } from 'modules/surveyM'

interface EditSurveyQuestionsProps {
    edited: boolean
}

const EditSurveyQuestions = ({ edited }: EditSurveyQuestionsProps) => {
    const { id } = useParams<{ id?: string }>()
    const [isEdited, setEdited] = useState<boolean>(edited)
    const [isModalOpen, setModalOpen] = useState<boolean>(false)
    const { questions, fetchQuestions, updateSurveyQuestions } = useSurveyQuestions()

    const handleOpenModal = () => {
        setModalOpen(true)
    }

    const handleCloseModal = () => {
        setModalOpen(false)
    }

    const handleSubmit = useCallback(
        async (values) => {
            if (id) {
                const data = questions.map((q, i) => ({
                    ...q,
                    ...values.questions[i],
                }))
                await updateSurveyQuestions(data)
                setEdited(true)
                setModalOpen(false)
            }
        },
        [id, questions.length],
    )

    useEffect(() => {
        if (id) {
            fetchQuestions(id)
        }
    }, [id])

    useEffect(() => {
        setEdited(edited)
    }, [edited])

    return (
        <>
            <MuiBox position="relative">
                <Button color="info" type="button" onClick={handleOpenModal}>
                    Edit Survey Questions
                </Button>
                <MuiBox
                    bottom="-1.5rem"
                    left={0}
                    right={0}
                    fontWeight={isEdited ? 700 : 400}
                    position="absolute">
                    <MuiTypography
                        align="center"
                        color={isEdited ? 'primary' : 'initial'}
                        display="block"
                        variant="inherit">
                        Edited: {String(isEdited)}
                    </MuiTypography>
                </MuiBox>
            </MuiBox>
            <SurveyQuestionsModalForm
                title="Edit Survey Questions"
                defaultValues={{ questions }}
                onCancel={handleCloseModal}
                onSubmit={handleSubmit}
                open={isModalOpen}
            />
        </>
    )
}

export default EditSurveyQuestions
