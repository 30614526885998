import { makeStyles, darken, Theme } from '@material-ui/core/styles'

type Color = 'default' | 'primary' | 'secondary' | 'info'

interface ButtonProps {
    loading?: boolean
    color: Color
}

const COLORS: Record<Color, string> = {
    default: '#e0e0e0',
    primary: '#42c19a',
    secondary: '#cb354b',
    info: '#408ecb',
}

export const useStyles = makeStyles<Theme, ButtonProps>(() => ({
    contained: {
        color: ({ color }) => color === 'default' ? '#000' : '#fff',
        backgroundColor: ({ color }) => COLORS[color],
        '&:hover': {
            backgroundColor: ({ color }) => darken(COLORS[color], .4),
        }
    },
    text: {
        color: ({ color }) => COLORS[color],
    },
    label: {
        opacity: ({ loading }) => (loading ? 0 : 1),
    },
    spinner: {
        opacity: ({ loading }) => (loading ? 1 : 0),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
    },
}))
