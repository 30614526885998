import { put, takeEvery } from 'redux-saga/effects';
import apiService, { getError } from 'shared/services/api';
import {
  COMPLETION_SURVEY_CATEGORIES_FETCH_LIST,
  COMPLETION_SURVEY_CATEGORIES_DELETE,
  fetchCompletionSurveyCategories,
} from '../../../reducers/category/list/';
import axios from 'axios';
import { toast } from 'react-toastify';
import { TOAST_MESSAGE } from 'shared/constants/message';

axios.defaults.validateStatus = (status) => {
  return [200, 500, 400].includes(status);
};

function* fetchCompletionSurveyCategoriesSaga() {
  try {
    const { data } = yield apiService({}).get('/profile/questions/categories');
    yield put({
      type: COMPLETION_SURVEY_CATEGORIES_FETCH_LIST.SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield put({
      type: COMPLETION_SURVEY_CATEGORIES_FETCH_LIST.FAILURE,
      payload: getError(err),
    });
  }
}

function* deleteCompletionSurveyCategory(action) {
  try {
    const response = yield apiService({}).delete(
      `/profile/questions/categories/${action.payload.id}`,
    );
    const data = response?.data;
    if(response?.status == 200) {
      toast.success(TOAST_MESSAGE.category.deleted)
    } else if(response?.status == 500) {
      toast.error(TOAST_MESSAGE.category.unable_delete)
    } else {
      toast.error(TOAST_MESSAGE[500])
    }
    yield put(fetchCompletionSurveyCategories());
    yield put({
      type: COMPLETION_SURVEY_CATEGORIES_DELETE.SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield put({
      type: COMPLETION_SURVEY_CATEGORIES_DELETE.FAILURE,
      payload: getError(err),
    });
  }
}

export default [
  function* () {
    yield takeEvery(
      COMPLETION_SURVEY_CATEGORIES_FETCH_LIST.REQUEST,
      fetchCompletionSurveyCategoriesSaga,
    );
  },
  function* () {
    yield takeEvery(COMPLETION_SURVEY_CATEGORIES_DELETE.REQUEST, deleteCompletionSurveyCategory);
  },
];
