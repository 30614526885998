import React from 'react'
import MuiInput from '@material-ui/core/Input'
import { FieldWrapper } from 'components'
import { useStyles } from './TextHeaderField.styles'

interface TextHeaderFieldProps {
    defaultValue?: string
    disabled?: boolean
    name: string
    placeholder?: string
}

export const TextHeaderField = ({
    defaultValue = '',
    disabled,
    name,
    placeholder,
}: TextHeaderFieldProps) => {
    const styles = useStyles()

    return (
        <FieldWrapper defaultValue={defaultValue} disabled={disabled} name={name}>
            {({ ref, ...field }) => (
                <MuiInput
                    {...field}
                    classes={styles}
                    fullWidth
                    id={name}
                    inputRef={ref}
                    placeholder={placeholder}
                />
            )}
        </FieldWrapper>
    )
}
