import { useSelector } from 'react-redux';
import { Country } from 'shared/types';

const WORKSPACES: Record<Country, string> = {
    NL: 'Netherlands',
    BE_FL: 'Belgium - Flanders',
    FR: 'France',
    BE_WL: 'Belgium - Wallonia',
    UK: 'United Kingdom',
    DE: 'Germany'
};

export const useCountry = () => {
  const selectedCountryCode: Country = useSelector(({ app }: any) => app.country);
  const selectedWorkspace = WORKSPACES[selectedCountryCode];

  return {
    selectedCountryCode,
    selectedWorkspace,
  };
};
