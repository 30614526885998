import forEach from 'lodash/forEach';
import { isNumeric } from 'shared/services/validator';

const requiredFields = ['ageFrom', 'ageTo', 'gender', 'answersCap'];

export default (values) => {
  const errors: any = {};

  forEach(requiredFields, (field) => {
    if (!values[field]) {
      errors[field] = 'Fill in';
    }
  });
  if (!isNumeric(values.ageFrom)) {
    errors['ageFrom'] = 'Must be number';
  }
  if (!isNumeric(values.ageTo)) {
    errors['ageTo'] = 'Must be number';
  }
  if (!isNumeric(values.answersCap)) {
    errors['answersCap'] = 'Must be number';
  }
  errors.ageBuckets = {};

  if (values.ageBuckets) {
    values.ageBuckets.forEach((bucket, key) => {
      errors.ageBuckets[key] = {};
      values.ageBuckets.forEach((bucket2, key2) => {
        if (bucket.ageFrom <= bucket2.ageTo && bucket2.ageTo <= bucket.ageTo && key2 !== key) {
          errors.ageBuckets[key].ageFrom = `Current range overlaps with range #${key2 + 1}`;
        }
      });
      if (bucket.ageFrom >= bucket.ageTo) {
        errors.ageBuckets[key].ageFrom = 'Age from must be lower than age to';
      }
    });
  }

  return errors;
};
