import React from 'react'
import { useDetails } from 'hooks'
import { useCharityManagement, CharityResponse } from 'modules/charity'
import { CharityForm, ContentLoader } from 'components'
import { routes } from 'shared/constants/routes'

export const CharityDetails = () => {
    const { fetchCharity, createCharity, updateCharity } = useCharityManagement()
    const { data, onSave, requestStatus } = useDetails<CharityResponse>({
        fetch: fetchCharity,
        create: createCharity,
        update: updateCharity,
        redirectTo: routes.charities.default.path,
    })

    const defaultValues = data || { pushNoteEnabled: true }

    return (
        <ContentLoader status={requestStatus}>
            <CharityForm defaultValues={defaultValues} onSubmit={onSave} />
        </ContentLoader>
    )
}
