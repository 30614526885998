// TODO: refactor apiService
import apiService from 'shared/services/api'

import { UpdateSettingsRequest, UpdateAppVersionRequest } from './types'

export const getSettings = async () => {
    const { data } = await apiService({}).get('/settings/points')

    return data
}

export const putSettings = async (data: UpdateSettingsRequest) => {
    await apiService({}).put('/settings/points', data)
}

export const getAppVersion = async () => {
    const { data } = await apiService({}).get('/settings/app-version')

    return data
}

export const putAppVersion = async (data: UpdateAppVersionRequest) => {
    await apiService({}).put('/settings/app-version', data)
}
