import styles from './styles.module.scss';
import { Trash } from 'shared/components/Icons';
import RotateLoader from 'shared/components/RotateLoader';

interface MediaFileProps {
  fileName?: string;
  media?: string;
  filter?: string;
  client?: string;
  channel?: string;
  onDelete?: (...args: any[]) => void;
  fileData?: any;
  isDeleting?: boolean;
  isProcessing?: boolean;
}

export default ({
  fileName,
  media,
  client,
  filter,
  channel,
  onDelete,
  fileData = {},
  isDeleting,
  isProcessing,
}: MediaFileProps) => (
  <div className={styles.MediaFile}>
    <div className="MediaFile__left">
      <div className={styles.MediaFileFile}>{fileName}</div>
      <div className={styles.MediaFileMedia}>
        {[
          client, 
          media, 
          channel, 
          filter
        ].filter(Boolean).join(' - ')}
      </div>
    </div>

    <div className={styles.MediaFileRight}>
      {fileData.progress > 0 && `${fileData.progress}%`}
      {!fileData.progress && !isDeleting && !isProcessing && (
        <button className={styles.MediaFileTrash} type="button" onClick={onDelete}>
          <Trash />
        </button>
      )}
      {(isDeleting || isProcessing) && <RotateLoader relative />}
      {fileData.progress > 0 && (
        <div className={styles.MediaFileProgress} style={{ width: `${fileData.progress}%` }} />
      )}
    </div>
  </div>
);