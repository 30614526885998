import React from 'react';
import styles from './styles.module.scss';

interface FormSection {
  children?: React.ReactNode;
  isActive?: boolean;
}

export default ({ children, isActive }: FormSection) => {
  return (
    <div className={styles.FormSection}>
      {children}
      {!isActive && <div className={styles.FormSectionBlocked} />}
    </div>
  );
};
