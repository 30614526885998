import React from 'react'
import { useHistory } from 'react-router-dom'
import { dateToReadable, utcDateToLocal } from 'shared/services/date'
import { useLotteryManagement, LotteryResponse } from 'modules/lotteryM'
import { Page, PageHeader, Button, DataTable } from 'components'

import { routes } from 'shared/constants/routes'

const COLUMNS = [
    {
        field: 'id',
        headerName: '#',
    },
    {
        field: 'name',
        headerName: 'Name',
    },
    {
        field: 'drawDate',
        headerName: 'Draw date',
        valueGetter: ({ drawDate }) => dateToReadable(utcDateToLocal(drawDate)),
    },
    {
        field: 'status',
        headerName: 'Status',
    },
    {
        field: 'visible',
        headerName: 'Visible',
        valueGetter: ({ visible }) => (visible ? 'Visible' : 'Hidden'),
    },
]

export const LotteriesList = () => {
    const {
        location: { search },
        push,
    } = useHistory()
    const { fetchLotteries } = useLotteryManagement()

    const handleRowClick = (id: string | number) => {
        push({
            pathname: routes.lotteries.edit(id).path,
            search,
        })
    }

    const handleCta = () => {
        push({
            pathname: routes.lotteries.create.path,
            search,
        })
    }

    return (
        <Page
            header={
                <PageHeader title="Lotteries" cta={<Button onClick={handleCta}>Add new</Button>} />
            }>
            <DataTable<LotteryResponse>
                columns={COLUMNS}
                onRowClick={handleRowClick}
                requestHandler={fetchLotteries}
            />
        </Page>
    )
}
