import { useState, useCallback, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { RequestStatus } from 'services'
import { WithId } from 'shared/types'

interface UseDetailsOptions<TData> {
    fetch: (id: string) => Promise<TData>
    create: (data: TData) => Promise<void>
    update: (data: WithId<TData>) => Promise<void>
    redirectTo?: string
}

export const useDetails = <TData>({
    fetch,
    create,
    update,
    redirectTo,
}: UseDetailsOptions<TData>) => {
    const {
        location: { search },
        push,
    } = useHistory()
    const { id } = useParams<{ id?: string }>()
    const [data, setData] = useState<TData>()
    const [requestStatus, setRequestStatus] = useState<RequestStatus>()

    const onFetch = async (itemId: string) => {
        setRequestStatus('PENDING')
        try {
            const result = await fetch(itemId)
            setData(result)
            setRequestStatus('RESOLVED')
        } catch (error) {
            setRequestStatus('REJECTED')
        }
    }

    const onSave = useCallback(
        async values => {
            if (id) {
                await update({ ...values, id })
            } else {
                await create(values)
            }
            if (redirectTo) {
                push({ pathname: redirectTo, search })
            }
        },
        [id],
    )

    useEffect(() => {
        if (id) {
            onFetch(id)
        }
    }, [id])

    return {
        data,
        onSave,
        requestStatus,
    }
}
