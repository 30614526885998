import React, { PureComponent } from 'react';
import { Field } from 'redux-form';
import TextField from 'shared/components/TextField';
import UploadFile from 'shared/containers/UploadFile';
import { getSignedUrl, getUrlWithoutHost, isImage, isVideo, isAudio } from 'shared/services/aws';
import { bytesToMB } from 'shared/utils/file';
import * as types from '../../types/QuestionsManageMediaTypes';

class QuestionsManageMedia extends PureComponent<types.QuestionManageMediaProps, types.QuestionManageMediaState> {
  state: types.QuestionManageMediaState = {
    previewFileSize: undefined,
  };

  getMediaFileSize = async (url) => {
    if (!url || this.state.previewFileSize !== undefined) {
      return;
    }
    const bytes = await new Promise((resolve) => {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', url, true);
      xhr.onreadystatechange = () => {
        resolve(+xhr.getResponseHeader('Content-Length'));
        xhr.abort();
      };
      xhr.send();
    });
    this.setState({ previewFileSize: bytes ? `${bytesToMB(bytes)}MB` : '' });
  };

  render() {
    const { formValues, discussionId } = this.props;
    const signedUrl =
      formValues.mediaUrl &&
      formValues.mediaUrl.includes(process.env.REACT_APP_AWS_DISCUSSION_BUCKET)
        ? getSignedUrl({
            Bucket: process.env.REACT_APP_AWS_DISCUSSION_BUCKET,
            Key: getUrlWithoutHost(this.props.formValues.mediaUrl),
          })
        : formValues.mediaUrl;
    this.getMediaFileSize(signedUrl);

    return (
      <>
        <Field
          component={TextField}
          name="mediaUrl"
          label="Media url"
          className="CampaignHeader__input"
          isRequired
        />

        <UploadFile
          id={discussionId}
          prefix={discussionId}
          accept=".jpg,.mp4,.jpeg,.mp3"
          onFileUploaded={(value) => this.props.change('mediaUrl', value)}
        />

        {this.props.formValues.mediaUrl && (
          <h2>
            Preview <small>{this.state.previewFileSize}</small>
          </h2>
        )}
        {signedUrl && isImage(this.props.formValues.mediaUrl) && (
          <img src={signedUrl} style={{ width: '100%' }} alt="" />
        )}
        {signedUrl && isVideo(this.props.formValues.mediaUrl) && (
          <video style={{ width: '100%', height: '200px' }}>
            <source src={signedUrl} type="video/mp4" />
          </video>
        )}
        {signedUrl && isAudio(this.props.formValues.mediaUrl) && (
          <audio controls>
            <source src={signedUrl} type="audio/mpeg" />
          </audio>
        )}
      </>
    );
  }
}
export default QuestionsManageMedia;
