import * as yup from 'yup'

export const schema = yup.object({
    campaignId: yup
        .number()
        .transform((cv, ov) => (ov === '' ? undefined : cv))
        .required(),
    surveyMonkeyMobileCollectorHash: yup
        .string()
        .trim('The mobile collector cannot include leading and trailing spaces')
        .strict(true)
        .min(1, 'The contact mobile collector to be at least 1 char')
        .max(512, 'The mobile collector cannot exceed 512 char')
        .required('The contact Name is required'),
})
