import React from 'react'
import {
    Table,
    Form,
    ModalFormProps,
    AutocompleteField,
    TextField,
    DateTimeField,
    Button,
    Dialog,
} from 'components'
import { SurveyQuestionResponse } from 'modules/surveyM'
import { SurveyBulkQuestionsModalFormData } from './types'
import { schema } from './validation'

const MEDIUM_OPTIONS = [
    'Cinema',
    'Facebook',
    'Instagram',
    'Online Radio',
    'Online Video',
    'Podcast',
    'Radio',
    'Snapchat',
    'Social Media',
    'Television',
    'Twitter',
    'OOH',
    'DOOH',
    'BC',
].map(o => ({ value: o, label: o }))

export const SurveyBulkQuestionsModalForm = ({
    defaultValues,
    onCancel,
    onSubmit,
    open,
    title,
}: ModalFormProps<SurveyBulkQuestionsModalFormData>) => (
    <Dialog open={open} title={title} onClose={onCancel} maxWidth={false}>
        <Form<SurveyBulkQuestionsModalFormData>
            defaultValues={defaultValues}
            onSubmit={onSubmit}
            schema={schema}>
            {({ isSubmitDisabled, isSubmitting }, { handleSubmit }) => (
                <>
                    <Dialog.Content>
                        <TextField label="Label" name="label" />
                        <AutocompleteField label="Medium" options={MEDIUM_OPTIONS} name="medium" />
                        <TextField label="Creative Name" name="creativeName" />
                        <TextField label="Creative Link" name="creativeLink" />
                    </Dialog.Content>
                    <Dialog.Actions>
                        <Button color="default" onClick={onCancel} type="button">
                            Cancel
                        </Button>
                        <Button
                            disabled={isSubmitDisabled}
                            loading={isSubmitting}
                            onClick={handleSubmit(onSubmit)}
                            type="button">
                            Save
                        </Button>
                    </Dialog.Actions>
                </>
            )}
        </Form>
    </Dialog>
)
