import { bindActionCreators } from 'redux';
import { reduxForm, Field, getFormValues, change, reset } from 'redux-form';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { compose } from 'redux';
import Modal from 'shared/components/Modal';
import Button from 'shared/components/Button';
import TextField from 'shared/components/TextField';
import MultiFileField from 'shared/components/MultiFileField';
import AutocompleteFieldNew from "shared/components/AutocompleteFieldNew"
import FormControl from '@material-ui/core/FormControl';
import * as types from '../../types/AddMediaModalTypes';
import styles from './styles.module.scss';
import React, { useState } from 'react';
import { Checkbox } from '@material-ui/core';
import BulkEditModal from '../BulkEditModal';
import Alert from '@material-ui/lab/Alert';

const FORM_NAME = 'campaign/ADD_MEDIA_MODAL';
const CHANNEL_NAME_TOOLTIP_TEXT = "Channels in dropdown are suggestions, you can create other channels by typing a new channel (deepdive should be provided by consultant)"

const AddMediaModal = ({
  isOpen,
  onClose,
  onAdd,
  clientName,
  formValues,
  dispatchChange,
  resetForm
}: types.AddMediaModalProps) => {

  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [showBulkEdit, setShowBulkEdit] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [bulkEditValues, setBulkEditValues] = useState({});

  const onSubmit = () => {
    if (formValues) {
      const hasEmptyField = Object.keys(formValues.files).some((file, i) => {
        const clientName = formValues.clientNames?.[i];
        const mediaType = formValues.mediaTypes?.[i];
        const filterName = formValues.filterNames?.[i];
        return !clientName || !mediaType || !filterName;
      });
  
      if(hasEmptyField) {
        setAlertMessage("All fields are required");
      } else {
        onAdd({
          ...formValues,
          id: uuidv4(),
        });
        resetForm(FORM_NAME);
      }
    }
  };

  const getLabelFromValue = (value) => {
    let mediaType = MEDIA_TYPES.find(item => item.value === value);
    if (mediaType) {
      return mediaType.label;
    } else {
      mediaType = MEDIA_TYPES.find(item => item.label == value);
      return mediaType ? mediaType.label : null;
    }
  }

  const convertFormValuesToString = (formValues) => {
    if (!formValues || !formValues.files || !(formValues.files instanceof FileList)) {
      return '';
    }
    
    const { clientNames, mediaTypes, filterNames, channelNames } = formValues;
    
    const filesArray = Array.from(formValues.files);
    
    const fileStrings = filesArray.map((file, index) => {
      const clientName = clientNames && clientNames.length > 0 ? clientNames[index] || '' : '';
      const mediaType = mediaTypes && mediaTypes.length > 0 ? getLabelFromValue(mediaTypes[index]) || '' : '';
      const filterName = filterNames && filterNames.length > 0 ? filterNames[index] || '' : '';
      const channelName = channelNames && channelNames.length > 0 ? channelNames[index] || '' : '';
    
      const line = `${clientName}${mediaType ? ', ' + mediaType : ''}${filterName ? ', ' + filterName : ''}${channelName ? ', ' + channelName : ''}`;
      return line.endsWith(',') ? line : line + ',';
    });
    
      return fileStrings.join('\n');
    };

  const openBulkEdit = () => {
    const formValueString = convertFormValuesToString(formValues)
    setAlertMessage("");
    setShowBulkEdit(true);
    setBulkEditValues({textareaValue: formValueString});
  };

  const closeBulkEdit = () => {
    setShowBulkEdit(false);
  };

  const parseBulkEditData = (data) => {
    const filteredArray = data.replace(/(\r\n|\n|\r)/gm, ",").split(',').filter(Boolean);
    const clientNames = [];
    const mediaTypes = [];
    const filterNames = [];
    const channelNames = new Map();

    let index = 0

    for (let i = 0; i < filteredArray.length;) {
      if (filteredArray[i]) {
        const clientName = filteredArray[i].trim();
        clientNames.push(clientName);
      }
      if (filteredArray[i + 1]) {
        const mediaType = filteredArray[i + 1].trim();
        mediaTypes.push(mediaType);
      }
      if (filteredArray[i + 2]) {
        const filterName = filteredArray[i + 2].trim();
        filterNames.push(filterName);
      }
      if (filteredArray[i + 3]) {
        if (filteredArray[i + 3] != clientName) {
          const channelName = filteredArray[i + 3].trim();
          channelNames.set(index, channelName)
          i += 4
          index += 1
        } else {
          channelNames.set(index, "")
          i += 3
          index += 1
        }
      } else {
        channelNames.set(index, "")
        i += 3
        index += 1
      }
    }

    return { arrays: [clientNames, mediaTypes, filterNames], channels: channelNames };
  }

  const checkMediaTypes = (mediaTypes) => {
    for (let element of mediaTypes) {
      if (MEDIA_TYPES.every(item => item.label !== element)) {
        return false;
      }
    }

    return true;
  }

  const handleBulkEditData = (data) => {
    if (data) {
      const parsedData = parseBulkEditData(data);
      const parsedDataArrays = parsedData.arrays
      const parsedDataChannels = parsedData.channels
      const validMediaTypes = checkMediaTypes(parsedDataArrays[1])
  
      if (validMediaTypes) {
        const shortestArray = [parsedDataArrays[0], parsedDataArrays[1], parsedDataArrays[2]].reduce((acc, curr) => {
          return curr.length < acc.length ? curr : acc;
        });
  
        let length = 0
  
        if (shortestArray.length > formValues.files.length) {
          length = formValues.files.length
        } else {
          length = shortestArray.length
        }
  
        if (parsedDataArrays.every(arr => arr.length >= 1)) {
          for (let i = 0; i < length; i++) {
            dispatchChange(FORM_NAME, `${'clientNames'}[${i}]`, parsedDataArrays[0][i]);
            dispatchChange(FORM_NAME, `${'mediaTypes'}[${i}]`, parsedDataArrays[1][i]);
            dispatchChange(FORM_NAME, `${'filterNames'}[${i}]`, parsedDataArrays[2][i]);

            if (parsedDataChannels.has(i)) {
              dispatchChange(FORM_NAME, `${'channelNames'}[${i}]`, parsedDataChannels.get(i));
            }
          }
        } else {
          setAlertMessage("The length of your input is incorrect, it should be a minimum of three keys (Example: Albert Heijn, Online Video, YouTube)")
        }
      } else {
        setAlertMessage("One or more of the MediaTypes you entered were incorrect, please check your input.");
      }
    }
  };

  const handleApplyToAll = (name, value) => {
    selectedRows.forEach((index) => {
      dispatchChange(FORM_NAME, `${name}[${index}]`, value);
    });
  };

  const handleRowCheckboxChange = (index: number) => {
    setSelectedRows((prevSelectedRows) => {
      if (prevSelectedRows.includes(index)) {
        return prevSelectedRows.filter((rowIndex) => rowIndex !== index);
      } else {
        return [...prevSelectedRows, index];
      }
    });
  };

  const handleApplyToAllCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelectedRows(Object.keys(formValues.files).map(Number));
    } else {
      setSelectedRows([]);
    }
  };

  const getChannelDataSource = (mediaTypeValue) => {
    switch(mediaTypeValue) {
      case 'OLV': return OLV_CHANNELS;
      case 'TV': return TV_CHANNELS;
      case 'VOD': return VOD_CHANNELS;
      case 'OLR': return OLR_CHANNELS;
      case 'Social': return SOCIAL_CHANNELS;
      default: return [];
    }
  };

  const MEDIA_TYPES = [
    { value: 'OLV', label: 'Online Video' },
    { value: 'Radio', label: 'Radio' },
    { value: 'OLR', label: 'Online Radio' },
    { value: 'Podcast', label: 'Podcast' },
    { value: 'Cinema', label: 'Cinema' },
    { value: 'Social', label: 'Social Media' },
    { value: 'OD', label: 'Online Display' },
    { value: 'TV', label: 'Television'},
    { value: 'VOD', label: 'Video On Demand'}
  ];

  const TV_CHANNELS = [
    { value: 'TV Sponsorship', label: 'TV Sponsorship' },
    { value: 'Tag-On', label: 'Tag-On' }
  ]

  const OLV_CHANNELS = [
    { value: 'YouTube', label: 'YouTube' },
    { value: 'Twitch', label: 'Twitch' },
    { value: 'Teads', label: 'Teads' },
    { value: 'Forced', label: 'Forced' },
    { value: 'Skippable', label: 'Skippable' }
  ]

  const VOD_CHANNELS = [
    { value: 'Amazon Prime Video', label: 'Amazon Prime Video' },
    { value: 'Netflix', label: 'Netflix' }
  ]

  const OLR_CHANNELS = [
    { value: 'Spotify', label: 'Spotify' }
  ]

  const SOCIAL_CHANNELS = [
    { value: 'Meta', label: 'Meta' },
    { value: 'Facebook', label: 'Facebook' },
    { value: 'Instagram', label: 'Instagram' },
    { value: 'Snapchat', label: 'Snapchat' },
    { value: 'TikTok', label: 'TikTok' },
    { value: 'X', label: 'X' }
  ]

  return (
    <Modal title="Add media" isOpen={isOpen} onClose={onClose} style={{minWidth: '90%'}}>
      {alertMessage && (
        <Alert severity="error">{alertMessage}</Alert>
      )}
      <Field
        component={MultiFileField}
        name="files"
        label="Files"
        accept=".mp3,.mp4,.wav"
        className={styles.uploadField}
        noMargin
        dispatchChange={dispatchChange}
      />
      <div className={styles.AddMediaModalScrollable}>
        {formValues && formValues.files.length > 1 && (
          <div className={styles.AddMediaModalApplyToAllWrapper}>
            <Checkbox
              checked={selectedRows.length === Object.keys(formValues.files).length}
              onChange={handleApplyToAllCheckboxChange}
              color='primary'
            />
            <div style={{width: '15%'}}></div>
            <Button
              onClick={() => {
                if (formValues.clientNames) {
                  const fieldValue = formValues.clientNames[0];
                  if (fieldValue) {
                    handleApplyToAll('clientNames', fieldValue);
                  }
                }
              }}
              buttonType="button"
              type='primary'
              className={styles.AddMediaModalApplyToAllButton}
            >
              Apply To All
            </Button>
            <Button
              onClick={() => {
                if (formValues.mediaTypes) {
                  const fieldValue = formValues.mediaTypes[0];
                  if (fieldValue) {
                    handleApplyToAll('mediaTypes', fieldValue);
                  }
                }
              }}
              buttonType="button"
              type='primary'
              className={styles.AddMediaModalApplyToAllButton}
            >
              Apply To All
            </Button>
            <Button
              onClick={() => {
                if (formValues.channelNames) {
                  const fieldValue = formValues.channelNames[0];
                  if (fieldValue) {
                    handleApplyToAll('channelNames', fieldValue);
                  }
                }
              }}
              buttonType="button"
              type='primary'
              className={styles.AddMediaModalApplyToAllButton}
            >
              Apply To All
            </Button>
            <Button
              onClick={() => {
                if (formValues.filterNames) {
                  const fieldValue = formValues.filterNames[0];
                  if (fieldValue) {
                    handleApplyToAll('filterNames', fieldValue);
                  }
                }
              }}
              buttonType="button"
              type='primary'
              className={styles.AddMediaModalApplyToAllButton}
            >
              Apply To All
            </Button>
          </div>
        )}
        {formValues ? (
          Object.keys(formValues.files).map((file, i) => (
            <div key={`file-form-control-${i}`} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: '1.5%', height: '20%'}}>
              <Checkbox
                checked={selectedRows.includes(i)}
                onChange={() => handleRowCheckboxChange(i)}
                color='default'
                className={styles.AddMediaModalCheckBox}
              />
              <FormControl fullWidth className={ styles.AddMediaModalFieldControl }>
                <Field
                  id={`file-name-${i}`}
                  component={TextField}
                  name={`fileNames[${i}]`}
                  label="File Name"
                  key={`file-name-key-${i}`}
                  defaultValue={formValues.files[file].name}
                  tooltip={formValues.files[file].name}
                  disabled
                />
              </FormControl>
              <FormControl fullWidth className={ styles.AddMediaModalFieldControl }>
                <Field
                  id={`client-name-${i}`}
                  component={TextField}
                  name={`clientNames[${i}]`}
                  label="Client Name"
                  key={`client-name-key-${i}`}
                  defaultValue={clientName}
                  isRequired={true}
                />
              </FormControl>
              <FormControl fullWidth className={ styles.AddMediaModalFieldControl }>
                <Field
                  id={`media-type-${i}`}
                  component={AutocompleteFieldNew}
                  name={`mediaTypes[${i}]`}
                  dataSource={MEDIA_TYPES}
                  label="Media Type"
                  key={`media-type-key-${i}`}
                  isRequired={true}
                />
              </FormControl>
              <FormControl fullWidth className={ styles.AddMediaModalFieldControl }>
                <Field
                  id={`channel-name-${i}`}
                  component={AutocompleteFieldNew}
                  name={`channelNames[${i}]`}
                  dataSource={getChannelDataSource(formValues.mediaTypes?.[i])} // Dynamically determine the dataSource
                  label="Channel Name"
                  key={`channel-name-key-${i}`}
                  tooltip={CHANNEL_NAME_TOOLTIP_TEXT}
                  isCreatable={true}
                />
              </FormControl>
              <FormControl fullWidth className={ styles.AddMediaModalFieldControl }>
                <Field
                  id={`filter-name-${i}`}
                  component={TextField}
                  name={`filterNames[${i}]`}
                  label="Filter Name"
                  key={`filter-name-key-${i}`}
                  isRequired={true}
                />
              </FormControl>
            </div>
          ))
        ) : (
          <div></div>
        )}
      </div>
      <div className={styles.AddMediaModalBottomButtonWrapper}>
        {formValues && (
          <div className={styles.AddMediaModalBottomButtonWrapperLeft}>
            <Button type='default' onClick={openBulkEdit} buttonType="button">
              Bulk Edit
            </Button>
          </div>
        )}
        <div className={styles.AddMediaModalBottomButtonWrapperRight}>
          <Button onClick={onClose} buttonType="button">
            Cancel
          </Button>
          <Button type="primary" onClick={onSubmit} buttonType="button">
            Add
          </Button>
        </div>
      </div>
      {showBulkEdit && (
        <BulkEditModal handleBulkEditData={handleBulkEditData} handleClose={closeBulkEdit} initialValues={bulkEditValues} />
      )}
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchChange: bindActionCreators(change, dispatch),
    resetForm: bindActionCreators(reset, dispatch),
  };
};

export default compose<any>(
  connect(state => ({ formValues: getFormValues(FORM_NAME)(state) }), mapDispatchToProps),
  reduxForm({ form: FORM_NAME }),
)(AddMediaModal);