/* eslint-disable @typescript-eslint/no-explicit-any*/
import React, { PureComponent } from 'react';
import classnames from 'classnames';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import find from 'lodash/find';
import FieldLabel from '../FieldLabel';
import FieldError from '../FieldError';
import styles from './styles.module.scss';
import './styles.scss';

interface AutocompleteFieldProps {
  input: {
    value: string;
    name: string;
    onChange: (...args: any[]) => any;
    onBlur: (...args: any[]) => any;
  };
  label: string;
  tooltip?: string;
  dataSource: {
    label: string;
    value: string;
  }[];
  onSelect: (...args: any[]) => any;
  placeholder?: any; //not in props
  disabled?: boolean;
  isCreatable?: boolean;
  meta: {
    touched: boolean;
    error: any; //undefined
  };
  isRequired?: boolean;
}

class AutocompleteFieldNew extends PureComponent<AutocompleteFieldProps> {
  state = { added: [] };
  render() {
    const {
      input,
      label,
      tooltip,
      dataSource = [],
      onSelect,
      placeholder,
      disabled,
      isCreatable,
      isRequired,
    } = this.props;
    const source = [...dataSource, ...this.state.added];
    const getValue = () => {
      return find(source, (src) => String(src.value) === String(input.value)) || input.value || '';
    };
    const Component = isCreatable ? CreatableSelect : Select;
    const inputClasses = classnames('AutocompleteReactSelect__input', {
      AutocompleteReactSelect: true,
      [styles.AutocompleteReactSelectInputError]: this.props.meta.touched && this.props.meta.error,
    });

    return (
      <div className={styles.AutocompleteReactSelect}>
        <FieldLabel isRequired={isRequired} tooltip={tooltip}>{label}</FieldLabel>
        <Component
          {...input}
          className={inputClasses}
          classNamePrefix="ARS"
          isDisabled={disabled}
          value={getValue()}
          onChange={(value, e) => {
            if (e.action === 'select-option' && onSelect) {
              onSelect(value.value);
            }
            if (e.action === 'create-option') {
              this.setState({ added: [...this.state.added, value] });
            }
            input.onChange(value.value);
          }}
          onBlur={() => input.onBlur(input.value)}
          options={source}
          placeholder={placeholder}
        />
        <FieldError meta={this.props.meta} />
      </div>
    );
  }
}

export default AutocompleteFieldNew;
