// TODO: refactor apiService
import apiService from 'shared/services/api'
import { normalizeQuery, Query } from 'services'
import { WithId } from 'shared/types'
import { utcDateToLocal, localDateToUtc } from 'shared/services/date';
import { CharityRequest, CharityResponse } from './types'

const normalizeRequest = (data: CharityRequest): CharityRequest => ({
    ...data,
    dateFrom: localDateToUtc(data.dateFrom),
    dateTo: localDateToUtc(data.dateTo),
    pushNoteDate: data.pushNoteDate ? localDateToUtc(data.pushNoteDate) : null,
})

const normalizeResponse = (data: CharityResponse): CharityResponse => ({
    ...data,
    dateFrom: utcDateToLocal(data.dateFrom),
    dateTo: utcDateToLocal(data.dateTo),
    pushNoteDate: data.pushNoteDate ? utcDateToLocal(data.pushNoteDate) : null,
})

export const getCharities = async (query?: Query) => {
    const { data } = await apiService({}).get('/charities', {
        params: normalizeQuery(query),
    })

    return data
}

export const getCharity = async (id: string) => {
    const { data } = await apiService({}).get(`/charities/${id}`)

    return normalizeResponse(data)
}

export const postCharity = async (data: CharityRequest) => {
    await apiService({}).post('/charities', normalizeRequest(data))
}

export const putCharity = async ({ id, ...data }: WithId<CharityRequest>) => {
    await apiService({}).put(`/charities/${id}`, normalizeRequest(data))
}
