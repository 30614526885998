import React from 'react';
import styles from './styles.module.scss';

interface SurveyBucketsPreviewTypes {
  capacity: number;
  buckets: {
    ageFrom: number;
    ageTo: number;
    allocation: string | number;
    allocationPercentage: string | number;
    education: string[];
    gender: string[];
  }[];
}

const SurveyBucketsPreview = ({ capacity, buckets }: SurveyBucketsPreviewTypes) => {
  const totalAllocation = (field) => {
    const sum = buckets
      .map((bucket) => bucket[field])
      .filter((number) => !isNaN(number))
      .reduce((a, b) => Number(a) + Number(b), 0);
    return sum;
  };
  return (
    <>
      <h3>Preview</h3>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Age</th>
            <th>Gender</th>
            <th>Education</th>
            <th>Allocation</th>
          </tr>
        </thead>
        <tbody>
          {buckets.map((bucket) => (
            <tr>
              <td>
                {bucket.ageFrom}-{bucket.ageTo}
              </td>
              <td className={styles.lowerCase}>
                {Array.isArray(bucket.gender) ? bucket.gender.join(', ') : bucket.gender}
              </td>
              <td className={styles.lowerCase}>
                {Array.isArray(bucket.education) ? bucket.education.join(', ') : bucket.education}
              </td>
              <td>
                <input value={bucket.allocation} /> (
                <input value={bucket.allocationPercentage} />
                %)
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={4}>
              Allocated {totalAllocation('allocation')}/{capacity} (
              {totalAllocation('allocationPercentage')} / 100%)
            </td>
          </tr>
        </tfoot>
      </table>
    </>
  );
};

export default SurveyBucketsPreview;
