import React from 'react';
import { Close } from 'shared/components/Icons';
import classnames from 'classnames';
import { CSSTransition } from 'react-transition-group';
import styles from './styles.module.scss';

interface ModalProps {
  children?: React.ReactNode;
  isOpen?: boolean;
  onClose?: () => void;
  title?: string;
  subTitle?: boolean;
  titleLeft?: boolean;
  big?: boolean;
  style?: React.CSSProperties;
}

const Modal = ({ children, isOpen, onClose, title, subTitle, titleLeft, big, style }: ModalProps) => {
  const classes = classnames({
    [styles.Modal]: true,
    [`Modal__open`]: isOpen,
  });

  const titleClasses = classnames(styles.ModalTitle, {
    [styles.ModalTitleWithSub]: !!subTitle,
    [styles.ModalTitleLeft]: titleLeft,
  });
  const contentClasses = classnames(styles.ModalContent, {
    [styles.ModalContentBig]: big,
  });

  return (
    <div>
      <CSSTransition in={isOpen} timeout={200} classNames="modal-anim" unmountOnExit>
        <div className={classes}>
          <div className={styles.ModalWrapper}>
            <div className={contentClasses} style={style}>
              <button onClick={onClose} className={styles.ModalClose} type="button">
                <Close />
              </button>
              <div className={titleClasses}>{title}</div>
              {subTitle && <div className={styles.ModalSubTitle}>{subTitle}</div>}
              {children}
            </div>
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

export default Modal;
