import React from 'react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { routes } from 'shared/constants/routes';
import { NavContext } from 'shared/context/nav';
import { performLogout } from 'modules/Auth/reducers/login';
import CountrySelector from '../CountrySelector';
import NavigationItem from '../../components/NavigationItem';
import NavigationSubItem from '../../components/NavigationSubItem';
import { CampaignIcon, Surveys, PowerOff } from 'shared/components/Icons';
import { ReactComponent as Logo } from 'assets/logo_white.svg';
import styles from './styles.module.scss';
import * as types from '../../types/NavigationTypes';

const Navigation = ({ activeRoute, performLogout: logout }: types.NavigationTypes) => {
  return (
    <div className={styles.Navigation}>
      <div className={styles.NavigationLogo}>
        <Logo />
      </div>
      <div className={styles.CountrySelectorBox}>
        <CountrySelector />
      </div>
      <NavContext.Provider value={{ activeRoute }}>
        <NavigationItem
          to={routes.campaigns.default.path}
          label="Campaigns"
          tooltip="Campaigns"
          icon={<CampaignIcon />}
        />
        <NavigationItem
          to={routes.clients.default.path}
          label="Clients"
          tooltip="Clients"
          icon="C"
        />
        <NavigationItem
          to={routes.users.default.path}
          label="Users"
          tooltip="Users"
          icon="U"
        />
        <NavigationItem
          to={routes.surveys.default.path}
          label="Surveys"
          tooltip="Surveys"
          icon={<Surveys />}
        />

        <NavigationItem
          to={routes.discussions.questions.list.path}
          tooltip="Babbelbox"
          label="Babbelbox"
          icon=""
        >
          <NavigationSubItem to={routes.discussions.questions.list.path}>
            Questions
          </NavigationSubItem>
          <NavigationSubItem to={routes.discussions.answers.list.path}>
            Answers
          </NavigationSubItem>
        </NavigationItem>

        <NavigationItem
          to={routes.completionSurveys.default.path}
          tooltip="Profile Completion Surveys"
          label="Profile Completion Surveys"
          icon="PCS"
        >
          <NavigationSubItem to={routes.completionSurveys.questions.list.path}>
            Questions
          </NavigationSubItem>
          <NavigationSubItem to={routes.completionSurveys.categories.list.path}>
            Categories
          </NavigationSubItem>          
        </NavigationItem>
        <NavigationItem
          to={routes.charities.default.path}
          tooltip="Charities"
          label="Charities"
          icon={'CH'}
        />
        <NavigationItem
          to={routes.lotteries.default.path}
          tooltip="Lottery"
          label="Lottery"
          icon={'L'}
        />
        <NavigationItem
          to={routes.giftshop.default.path}
          tooltip="Gift Shop"
          label="Gift Shop"
          icon={'GS'}
        />
        <NavigationItem
          to={routes.settings.default.path}
          tooltip="Settings"
          label="Settings"
          icon={'S'}
        />
        <NavigationItem
          to={routes.auth.login.path}
          toBottom
          onClick={logout}
          tooltip="Logout"
          icon={<PowerOff />}
        />
      </NavContext.Provider>
    </div>
  );
};
const mapStateToProps = ({ router }) => ({
  activeRoute: router.location.pathname,
});
const mapDispatchToProps = dispatch => bindActionCreators({ performLogout }, dispatch);

export default compose(connect(mapStateToProps, mapDispatchToProps))(Navigation);
