import { makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((t: Theme) => ({
    area: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '80px',
        border: `2px dashed ${t.palette.info.main}`,
        borderRadius: '4px',
    },
}))
