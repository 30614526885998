import { specialCharsRegex } from 'shared/utils/regex';
import { validationMessage } from 'shared/constants/message';
import * as yup from 'yup';

const date = yup.date().transform((cv, ov) => (ov === null ? undefined : cv));
const string = yup
  .string()
  .transform((cv, ov) => (ov === '' ? undefined : cv))
  .nullable()
  .notRequired();

export const schema = yup.object({
  dateFrom: date,
  dateTo: date,
  filterName: yup
    .string()
    .required()
    .matches(specialCharsRegex, validationMessage.filterNamePunctuation),
  radius: yup.number().transform((cv, ov) => (ov === '' ? undefined : cv)),
  locationType: string,
  locationDetailName: string,
  locationDetailId: string,
  address: string,
  postalCode: string,
  city: string,
  province: string,
  country: string,
});
