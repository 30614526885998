import React from 'react';
import classnames from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import { isRouteActive } from 'shared/services/routes';
import { NavContext } from 'shared/context/nav';
import styles from './styles.module.scss';
import * as types from '../../types/NavigationItemTypes';

const NavigationItem = ({ children, to, exactActive, toBottom, onClick, tooltip, icon, label }: types.NavigationItemProps) => {
  const { search } = useLocation();
  const tooltipClasses = classnames(styles.NavigationItemTooltip, {
    [styles.NavigationItemTooltipWithSubMenu]: !!children,
  });

  return (
    <NavContext.Consumer>
      {({ activeRoute }) => {
        const classes = classnames(styles.NavigationItem, {
          [styles.NavigationItemActive]: isRouteActive(activeRoute, to, exactActive),
          [styles.NavigationItemBottom]: toBottom,
        });
        return (
          <div className={classes}>
            <Link className={styles.NavigationItemIcon} to={{ pathname: to, search }} onClick={onClick}>
              {label && <span className={styles.NavigationItemIconLabel}>{label}</span>}
              {!label && icon}
              <div className={tooltipClasses}>{tooltip}</div>
            </Link>
            {children && <div className={styles.NavigationItemSubMenu}>{children}</div>}
          </div>
        );
      }}
    </NavContext.Consumer>
  );
};

export default NavigationItem;
