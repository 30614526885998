import React from 'react';
import classnames from 'classnames';
import RotateLoader from '../RotateLoader';
import styles from './styles.module.scss';

interface ContentLoader {
  states: {
    isLoading?: boolean;
    isLoaded?: boolean;
    hasError?: boolean;
  };
  children?: React.ReactNode;
  isNew?: boolean;
}

export default ({ states: { isLoading, isLoaded, hasError }, children, isNew }: ContentLoader) => {
  const contentClasses = classnames(styles.ContentLoaderContent, {
    [styles.ContentLoaderContentActive]: isLoaded || isNew,
  });

  return (
    <div className={styles.ContentLoader}>
      <div className={contentClasses}>{children}</div>

      {isLoading && (
        <div className={styles.ContentLoaderLoader}>
          <RotateLoader relative />
        </div>
      )}
      {hasError && (
        // <div className="ContentLoader__error">
        <div className={styles.ContentLoaderError}>
          <div className={styles.ContentLoaderErrorTitle}>Sorry, something went wrong.</div>
          <div className={styles.ContentLoaderErrorDescription}>
            {' '}
            We are already working on that!
          </div>
          <div className={styles.ContentLoaderErrorIcon}>
            <div className={styles.ContentLoaderErrorIconMouth} />
          </div>
        </div>
        // </div>
      )}
    </div>
  );
};
