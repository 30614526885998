import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, getFormValues } from 'redux-form';
import map from 'lodash/map';
import debounce from 'lodash/debounce';
import { bindActionCreators, compose } from 'redux';
import ListHeading from 'shared/components/ListHeading';
import Button from 'shared/components/Button';
import TextField from 'shared/components/TextField';
import { routes } from 'shared/constants/routes';
import { Thead, Tbody, Table, Th, Tr, Td } from 'shared/components/Table';
import AppContent from 'shared/components/AppContent';
import { fetchSurveys } from 'modules/Survey/reducers/list';
import { dateToReadable, utcDateToLocal } from 'shared/services/date';
import Pagination from 'shared/components/Pagination';
import { SURVEY_STATUS_LABEL } from 'modules/Survey/constants';
import * as types from '../../types/SurveyListTypes';
const FORM_NAME = 'survey/LIST';

class List extends PureComponent<types.SurveyListProps, types.SurveryListState> {
  state: types.SurveryListState = { term: undefined, perPage: 15, sort: 'id,desc' };

  componentDidMount() {
    this.search({});
  }
  componentDidUpdate(prevProps) {
    if (prevProps.selectedCountry !== this.props.selectedCountry) {
      this.search({});
    }
  }
  goToCreate = () => {
    const { history } = this.props;
    history.push({
      pathname: routes.surveys.create.path,
      search: history.location.search,
    });
  };
  goToManage = (id) => () => {
    const { history } = this.props;
    history.push({
      pathname: routes.surveys.edit(id).path,
      search: history.location.search,
    });
  };

  onSearch = debounce((e) => {
    this.setState({ term: e.target.value });
    this.search({ term: e.target.value, page: 0 });
  }, 200);

  onPerPageChange = (perPage) => {
    this.search({ size: perPage });
    this.setState({ perPage });
  };

  onPageChange = (page) => {
    this.search({ page });
  };

  search = (props) => {
    const search = {
      page: this.props.data.number,
      size: this.state.perPage,
      // client: this.state.client, //nie ma pozycji client w surveys
      term: this.state.term,
      sort: this.state.sort,
      ...props,
    };
    this.props.fetchSurveys({
      search,
    });
  };
  getCurrentSortType = () => {
    return this.state.sort.split(',')[1];
  };
  onChangeSort = (colName) => {
    const currentSort = this.getCurrentSortType();
    let sortType = currentSort;
    if (this.isActiveColSort(colName)) {
      sortType = currentSort === 'desc' ? 'asc' : 'desc';
    }

    const sort = `${colName},${sortType}`;
    this.setState({
      sort,
    });
    this.search({ sort });
  };
  isActiveColSort = (colName) => {
    return colName === this.state.sort.split(',')[0];
  };
  render() {
    const { data, states } = this.props;
    const columns = [
      { label: '#', colName: 'id', sortable: true },
      { label: 'Title', colName: 'title', sortable: true },
      { label: 'From', colName: 'date_from', sortable: true },
      { label: 'To', colName: 'date_to', sortable: true },
      { label: 'Survey Monkey Title', colName: 'survey_monkey_title', sortable: true },
      {
        label: 'Respondents limit',
        colName: 'respondents_limit',
        sortable: true,
      },
      { label: 'Campaign name', colName: 'campaign_name', sortable: true },
      { label: 'Collector Hash', colName: 'survey_monkey_mobile_collector_hash', sortable: true },
      { label: 'Status', colName: 'status', sortable: true },
    ];

    return (
      <div>
        <ListHeading
          title="Surveys"
          right={
            <Field
              component={TextField}
              name="search"
              onChange={this.onSearch}
              placeholder="Type to search"
              noMargin
            />
          }
        >
          <Button noMargin type="primary" onClick={this.goToCreate}>
            Add new
          </Button>
        </ListHeading>
        <AppContent>
          <div className="row">
            <div className="col-md-12">
              <Table isLoading={states.isLoading}>
                <Thead>
                  <Tr>
                    {columns.map((col, index) => {
                      return (
                        <Th
                          key={`col-${col.label}`}
                          onClick={col.sortable ? () => this.onChangeSort(col.colName) : null}
                          currentSortType={this.getCurrentSortType()}
                          active={this.isActiveColSort(col.colName)}
                          sortable={col.sortable}
                          short={index === 0 || index === columns.length - 1}
                        >
                          {col.label}
                        </Th>
                      );
                    })}
                  </Tr>
                </Thead>
                <Tbody>
                  {map(data.content, (survey) => (
                    <Tr key={`survey-${survey.id}`} onClick={this.goToManage(survey.id)}>
                      <Td>{survey.id}</Td>
                      <Td>{survey.title}</Td>
                      <Td>{dateToReadable(utcDateToLocal(survey.dateFrom))}</Td>
                      <Td>{dateToReadable(utcDateToLocal(survey.dateTo))}</Td>
                      <Td>{survey.surveyMonkeyTitle}</Td>
                      <Td>{survey.respondentsLimit}</Td>
                      <Td>{survey.campaignName}</Td>
                      <Td>{survey.surveyMonkeyMobileCollectorHash}</Td>
                      <Td>{SURVEY_STATUS_LABEL[survey.status]}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
              <Pagination
                onPageChange={this.onPageChange}
                pages={data.totalPages - 1}
                currentPage={data.number}
                visibleOffset={1}
                perPage={this.state.perPage}
                onPerPageChange={this.onPerPageChange}
              />
            </div>
          </div>
        </AppContent>
      </div>
    );
  }
}

const mapStateToProps = ({ surveys, ...state }) => ({
  data: surveys.list.data,
  states: surveys.list.states,
  formValues: getFormValues(FORM_NAME)(state),
  selectedCountry: state.app.country,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({ fetchSurveys }, dispatch);

export default compose<any>(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({ form: FORM_NAME }),
)(List);
