import React, { PureComponent } from 'react';
import classnames from 'classnames';
import map from 'lodash/map';
import styles from './styles.module.scss';
import FieldError from '../FieldError';

interface CheckboxFieldsProps {
  className?: string;
  labelLeft?: boolean;
  noMargin?: boolean;
  small?: boolean;
  disabled?: boolean;
  onClick?: (...args: any[]) => void;
  input?: any;
  label?: React.ReactNode;
  meta?: any;
  options?: {
    value: string;
    label: string;
  }[];
  isRequired?: boolean;
  id?: string;
  icon?: 'scope' | 'arrow_down';
}

class CheckboxFields extends PureComponent<CheckboxFieldsProps> {
  onClick = (e) => {
    if (this.props.onClick) {
      this.props.onClick(e);
    }
  };

  render() {
    const {
      input,
      label,
      meta,
      noMargin,
      className,
      labelLeft,
      options,
      disabled,
      small,
    } = this.props;

    const classes = classnames(styles.CheckboxFields, className, {
      [styles.CheckboxFieldsLabelLeft2]: labelLeft,
      [styles.CheckboxFieldsNoMargin]: noMargin,
    });
    const labelClasses = classnames(styles.CheckboxFieldsLabel, className, {
      [styles.CheckboxFieldsLabelLeft]: labelLeft,
      [styles.CheckboxFieldsLabelDisabled]: disabled,
    });
    const inputClasses = classnames(styles.CheckboxFieldsInput, className, {
      [styles.CheckboxFieldsInputSmall]: small,
    });
    const inputLabelClasses = classnames(styles.CheckboxFieldsInputLabel, className, {
      [styles.CheckboxFieldsInputLabelDisabled]: disabled,
      [styles.CheckboxFieldsInputLabelSmall]: small,
    });
    const wrapperClasess = classnames(styles.CheckboxFieldsInputWrapper, className, {
      [styles.CheckboxFieldsInputWrapperError]: meta.touched && meta.error,
    });

    return (
      <div className={classes} onClick={this.onClick}>
        {label && (
          <label className={labelClasses}>
            {label} {this.props.isRequired && '*'}
          </label>
        )}
        <div className={wrapperClasess}>
          {map(options, (option) => (
            <div key={`${input.name}-${option.value}`} className={inputClasses}>
              <input
                className={styles.CheckboxFieldsInputField}
                type="checkbox"
                name={`${input.name}[${option.value}]`}
                value={option.value}
                checked={input.value.indexOf(option.value) !== -1}
                onChange={(event) => {
                  const newValue = [...input.value];
                  if (event.target.checked) {
                    newValue.push(option.value);
                  } else {
                    newValue.splice(newValue.indexOf(option.value), 1);
                  }

                  return input.onChange(newValue);
                }}
                id={`${input.name}-${option.value}`}
              />
              <label htmlFor={`${input.name}-${option.value}`} className={inputLabelClasses}>
                {option.label}
              </label>
            </div>
          ))}
        </div>
        <FieldError meta={meta} />
      </div>
    );
  }
}
export default CheckboxFields;
